var AfterLoginService = {
    GetCashbackBalance : function(auth){ 
        let au = auth?auth.api_access_token:'';   
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-cashback-balance",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GetCashbackHistory : function(auth){ 
        let au = auth?auth.api_access_token:'';   
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-cashback-history",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GetWalletBalance : function(auth){  
        let au = auth?auth.api_access_token:'';  
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-naira-balance",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GetWalletHistory : function(auth){ 
        let au = auth?auth.api_access_token:'';   
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-naira-history",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GetDetails : function(auth){ 
        let au = auth?auth.api_access_token:'';   
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/user-detail",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    UpdatePassword : function(item,auth){    
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/update-password",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GetCashbackWallet:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-cashback-balance",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetCashbackHistory:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-cashback-history",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    UpdateUserDetails:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/update-user-detail",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetTransaction:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-transaction-list",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetRefferWallet:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-referral-balance",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetRefferTransaction:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-referral-history",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    ValidatePassword:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/validate-password",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    WithdrawBalance:function(auth,item1)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/with-drawn-balance",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item1)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    CheckBankDetail:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-payment-detail",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetTransactionDetails:function(transaction_id,auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-transaction-detail?transaction_id="+transaction_id,{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    CashBackWithdrawBalance:function(auth,item1)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/with-drawn-cashback-balance",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item1)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    ReferralWithdrawBalance:function(auth,item1)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/with-drawn-referral-balance",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item1)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    UpdateUserEmail:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/update-email",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetBankList:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-bank-list",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    UpdateBankDetails:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/update-bank-details",{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetRedbillerAccountName:function(bankcode,accountno)
    {
        return  fetch("https://redb.qxchange.app/redbiller/verifyBankDetails.php?bank_code="+bankcode+'&account_no='+accountno,{
                method:'GET',
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetNotification:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-notification",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetTronUserWallet:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-tron-user-wallet",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetBtcWallet:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-btc-wallet",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetBitcoinRate:function()
    {
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/redbiller/getBitcoinRate.php",{
                 method:'GET',
                
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    kycSave:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/kycweb",{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetKycList:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/kyc/list",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetWalletCurrency:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/wallet/currency/list",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetCryptoSellList:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-instant-crypto-list",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    SaveBtcDepositAddress:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/save-btc-deposit-address",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    GetInstantCryptoTransaction:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-instant-crypto-transactions",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
}
export default AfterLoginService;