import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import QxchangeService from "../../Services/QxchangeService/QxchangeService";

function CustomDropdown({
  setFromCurrencny,
  api = "from",
  ticker,
  setToCurrecny,
  setMinExchangeAmount,
  toCurrency,
  fromCurrency,
  setFromInput,
  setXchangeAmount,
  setWalletAddress,
  setRefundWalletAddress,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchedCurrencies, setSearchedCurrencies] = useState([]);

  const toggleDropdown = async () => {
    setIsOpen(!isOpen);
    setLoading(true);
    if (!isOpen) {
      const { result } =
        api === "from"
          ? await QxchangeService.FromCurrencies()
          : await QxchangeService.ToCurrencies(ticker);
      setCurrencies(result);
    }
    setLoading(false);
  };

  const handleItemClick = async (option) => {
    handleCancle();
    setIsOpen(false);
    if (api === "from") {
      setFromCurrencny(option);
      setToCurrecny({});
      setFromInput("");
      setXchangeAmount("");
      setWalletAddress("");
      setRefundWalletAddress("");
      setMinExchangeAmount(null);
    } else {
      setToCurrecny(option);
      const { result } = await QxchangeService.GetMinExchangeAmount(
        `${ticker}_${option.ticker}`
      );
      setMinExchangeAmount(result.minAmount);
    }
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    setSearchedCurrencies(
      currencies.filter((item) =>
        item.ticker.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  };

  const handleCancle = () => {
    setSearchInput("");
    setSearchedCurrencies([]);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={toggleDropdown}
          className="bg-transparent text-gray-100 text-xs font-semibold shadow flex items-center"
        >
          {(api === "from" ? fromCurrency?.name : toCurrency?.name) ? (
            <>
              <img
                src={api === "from" ? fromCurrency?.image : toCurrency?.image}
                alt={api === "from" ? fromCurrency?.name : toCurrency?.name}
                className="mr-1"
              />
              <p>
                {api === "from"
                  ? fromCurrency?.ticker.toUpperCase()
                  : toCurrency?.ticker.toUpperCase()}
              </p>
            </>
          ) : (
            "Currency"
          )}
          <ExpandMoreIcon className={`ml-2 ${isOpen ? "rotate-180" : ""}`} />
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-48  bg-gray-800 ring-1 ring-black ring-opacity-5 z-20 left-0">
          <List
            component="nav"
            aria-label="list"
            style={{
              maxHeight: "380px",
              position: "fixed",
              overflowY: "scroll",
              background: "#252731",
              overflowX: "hidden",
              width: "250px",
              boxShadow: "0 0 10px 2px rgba(0, 0, 0, 0.5)",
              borderRadius: "5px",
            }}
          >
            {loading ? (
              <div className="p-4 flex justify-center items-center">
                <div className="mr-1">
                  <CircularProgress
                    color="secondary"
                    size={20}
                    className="mr-2"
                  />
                </div>
                <div>Loading ...</div>
              </div>
            ) : (
              <div>
                <InputBase
                  placeholder={`Search ${
                    api === "from" ? "From" : "To"
                  } Currency`}
                  variant="filled"
                  fullWidth
                  focused
                  type="text"
                  value={searchInput}
                  endAdornment={
                    searchInput && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleCancle} edge="end">
                          <CancelIcon style={{ color: "white" }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                  sx={{ padding: "5px 10px" }}
                  onChange={handleSearchChange}
                />
                <Divider
                  sx={{
                    backgroundColor: "black",
                  }}
                />
                {(searchedCurrencies.length
                  ? searchedCurrencies
                  : currencies
                ).map((item, index) => (
                  <div key={index}>
                    <ListItem
                      className="cursor-pointer"
                      onClick={() => handleItemClick(item)}
                    >
                      <img src={item.image} alt={item.name} className="mr-2" />
                      <ListItemText
                        primary={item.ticker.toUpperCase()}
                        secondary={item.name}
                        className="text-white"
                        style={{ fontSize: "10px; !important" }}
                      />
                    </ListItem>
                    <Divider style={{ backgroundColor: "white" }} />
                  </div>
                ))}
              </div>
            )}
          </List>
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
