import React, { useState, useEffect } from "react";
import "./transcation.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import Imagemodal from "./imagemodal";
import { useNavigate } from "react-router";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { Link } from "react-router-dom";

function Transcation() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [transaction, setTransaction] = useState([]);
  const [tranDetails, setTransactionDetail] = useState("");
  const [gettingWallet, setGettingWallet] = useState(false);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(0);
  const [tranV,setTranV] = useState([]);

  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
    setGettingWallet(true);
    getTransaction();
    getDetails(id);
  }, []);

  const getTransaction = async () => {
    const apicall = await AfterLoginService.GetTransaction(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setTransaction(resp.data);
          if (!resp.data) {
            getDetails(resp.data[0].id);
          }

          setGettingWallet(false);
          setLoader(true);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const getDetails = async (transaction_id) => {
    setLoader(true);
    const apicall = await AfterLoginService.GetTransactionDetails(
      transaction_id,
      auth
    );
    if (apicall?.state === true) {
      const resp = await apicall.result.json();
      if (resp?.status === 200) {
        setTransactionDetail(resp?.data);
        const vals = resp?.data.values;
        setTranV(vals.split(","))
      
        if (window.innerWidth <= 768) {
          // On mobile screens, hide elements and remove from layout
          document.getElementById("mobile").style.display = "none";
          document.getElementById("mobile-1").style.display = "none";
        }

        setLoader(false);
      } else {
        console.log(resp.message);
        setLoader(false);
      }
    } else {
      // Handle API call error
    }
  };

  // const getDetails = async (transaction_id) => {
  //   setLoader(true);
  //   const apicall = await AfterLoginService.GetTransactionDetails(
  //     transaction_id,
  //     auth
  //   );
  //   if (apicall?.state === true) {
  //     const resp = await apicall.result.json();
  //     if (resp?.status === 200) {
  //       setTransactionDetail(resp?.data);

  //       if (window.innerWidth > 768) {
  //         document.getElementById("mobile").style.display = "block";
  //         document.getElementById("mobile-1").style.display = "block";
  //       } else {
  //         document.getElementById("mobile").style.display = "none";
  //         document.getElementById("mobile-1").style.display = "none";
  //       }

  //       setLoader(false);
  //     } else {
  //       console.log(resp.message);
  //       setLoader(false);
  //     }
  //   } else {
  //   }
  // };

  // select button

  const [selectedButton, setSelectedButton] = useState("All");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const getButtonStyle = (buttonName) => {
    return {
      marginRight: "10px",
      backgroundColor: selectedButton === buttonName ? " #3c0d68" : "",
    };
  };

  // colse transaction detals
  // const [close,setClose] = useState(false)
  function closeBtn() {
    window.location.href = window.location.href;
    // setClose(false)
  }

  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "APPROVED":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  return (
    <div className="container-fluid position-relative d-flex p-0 transcation-container">
      <Header />
      <div className="content">
        <Subheader />
        <div className="container-fluid pt-4 px-4 transcation-table">
          
            <div className="row">
              <div className="back-icon-main">

                {/* <img src="/img/CaretLeft.png" onClick={closeBtn} /> */}
                <img src="/img/CaretLeft1.svg" onClick={goBack} />

              </div>
              <h3 className="heading-transaction mb-0">Transaction</h3>
              <p className="para-transaction ">
               View pertinent details of transactions carried out
              </p>
              {/* <Box className="d-none">
                <Tabs className="tab-box" aria-label="">
                  <Tab className="tab" label="All" />
                  <Tab className="tab" label="Crypto" />
                  <Tab className="tab" label="Gift Cards" />
                  <Tab className="tab" label="Naira" />
                </Tabs>
              </Box> */}

              <div className="row mt-2 ">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 col-xs-12">
                  <div style={{ display: 'flex' }} >
                  <button
                    type="button"
                    className="btn  btn-tansaction"
                    style={getButtonStyle("All")}
                    onClick={() => handleButtonClick("All")}
                  >
                    All
                  </button>

                  <button
                    type="button"
                    className="btn  btn-tansaction"
                    style={getButtonStyle("Crypto")}
                    onClick={() => handleButtonClick("Crypto")}
                  >
                    Crypto
                  </button>

                  <button
                    type="button"
                    className="btn  btn-tansaction "
                    style={getButtonStyle("Gift Cards")}
                    onClick={() => handleButtonClick("Gift Cards")}
                  >
                    Gift Cards
                  </button>
                     </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12 "> </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 "> </div>
              </div>
              <div className="transcation-div mt-2 ">
                <div className="col-md-8 col-lg-8 table-transcation">
                  <table class="table table-dark table-border" id="mobile-1">
                    <thead>
                      <tr className="fs-13">
                        <th>DETAILS</th>
                        <th className="trasaction-heading-mobile">&nbsp;</th>
                        <th className="d-none d-sm-table-cell">TYPE</th>
                        <th >STATUS</th>
                        <th className="d-none d-sm-table-cell">DATE</th>
                      </tr>
                    </thead>
                  {/* 1411 */}
                  
                  {selectedButton == 'All'?(<>
                    <tbody id="mobile">
                      {transaction.length > 0 ? (
                        transaction.map((item, index) => (
                          <tr onClick={() => getDetails(item.id)}>
                            <td className="d-flex align-items-center ">
                              <p className="gift-icon">
                                {/* <i class="bi bi-gift"></i> */}
                                <img src="/img/Withdrawal1.svg" alt=''  />
                              </p>
                              <p className="fs-12 ms-2 mb-0 trsanaction-table-details">
                                {item.giftCard}
                                <span className="d-block fs-10">
                                  <span className="mobile-gift">  Gift Cards worth</span>
                                  <span className="fs-13"> ₦{item?.total}</span>
                                </span>
                                <p className="fs-12 hide-type-desktop ">
                                {item?.cardType ? item.cardType : "Crypto"}
                                <p className="fs-10 hide-date-desktop mt-1 mb-0">{item?.timestamp}</p>
                              </p>
                              </p>
                            </td>
                            <td>
                              <p className="fs-12 hide-type-transaction">
                                {item?.cardType ? item.cardType : "Crypto"}
                              </p>
                            </td>
                            <td className="dashboard-btn">
                              <Link
                                to=""
                                className={`complete-btn ${getButtonColor(
                                  item?.status
                                )}`}
                              >
                                {/* <span className="dot">.</span> */}
                                {item?.status.toUpperCase()}
                              </Link>
                            </td>
                            <td className="">
                              <p className="fs-10 hide-type-transaction">{item?.timestamp}</p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          {gettingWallet ? (
                            <>  
                              <tr className="text-center not-ransctionbg">
                                <td colSpan={4}>
                                <span
                        className="text-white spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                          <span className="text-white"> Loading...</span>
                                </td>
                              </tr>
                            </>
                          ):(<>
                            <tr className="text-center not-ransctionbg">
                            <td colSpan={4}>
                              {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                              <div className="EmptyState">
                                <img
                                  src="img/EmptyState.svg"
                                  alt=""
                                 
                                  className="transaction-empty "
                                />
                                <h4 className="not-transction-transaction">
                                  Transaction
                                </h4>

                                <h5 className="not-transction-transaction-p text-center">
                                  You don't have any pending or completed
                                 <span className="not-transction-transaction-p-two"> transction.</span>
                                </h5>
                              </div>
                            </td>
                          </tr>
                          </>)}
                        </>
                      )}
                    </tbody>
                  </>):(<>
                  </>)}

                  {selectedButton == 'Crypto'?(<>
                    <tbody id="mobile">
                      {transaction.length > 0 ? (
                        transaction.map((item, index) => (
                          <>
                            {item.transactionType == 'crypto'?(<>
                              <tr onClick={() => getDetails(item.id)}>
                            <td className="d-flex align-items-center">
                              <p className="gift-icon">
                              <img src="/img/Withdrawal1.svg" alt=''  />
                              </p>
                              <p className="fs-12 ms-2 mb-0 crypto-header">
                                {item.giftCard}
                                <span className="d-block fs-10">
                                <span className="mobile-gift">  Gift Cards worth</span>
                                  <span className="fs-13"> ₦{item?.total}</span>
                                </span>
                                <p className="fs-12 hide-type-desktop">
                                {item?.cardType ? item.cardType : "Crypto"}
                              </p>
                              <p className="fs-10 hide-date-crypto-desktop">{item?.timestamp}</p>
                              </p>
                            </td>
                            <td>
                              <p className="fs-12 hide-type-transaction">
                                {item?.cardType ? item.cardType : "Crypto"}
                              </p>
                            </td>
                            <td className="dashboard-btn">
                              <Link
                                to=""
                                className={`complete-btn ${getButtonColor(
                                  item?.status
                                )}`}
                              >
                                {/* <span className="dot">.</span> */}
                                {item?.status.toUpperCase()}
                              </Link>
                            </td>
                            <td className="">
                              <p className="fs-10 hide-type-transaction">{item?.timestamp}</p>
                            </td>
                          </tr>
                            </>):("")}
                          </>
                          
                        ))
                      ) : (
                        <>
                          <tr className="text-center not-ransctionbg">
                            <td colSpan={4}>
                              {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                              <div>
                                <img
                                  src="img/EmptyState.png"
                                  alt=""
                                 
                                  className="transaction-empty"
                                />
      
                                <h4 className="not-transction-transaction">
                                  Transaction
                                </h4>

                                <h5 className="not-transction-transaction-p text-center">
                                  You don't have any pending or completed
                                  transction.
                                </h5>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </>):(<>
                  </>)}

                  {selectedButton == 'Gift Cards'?(<>
                    <tbody id="mobile">
                      {transaction.length > 0 ? (
                        transaction.map((item, index) => (
                          <>
                            {item.transactionType == 'giftcard'?(<>
                              <tr onClick={() => getDetails(item.id)}>
                            <td className="d-flex align-items-center">
                              <p className="gift-icon">
                              <img src="/img/Withdrawal1.svg" alt=''  />
                              </p>
                              <p className="fs-12 mb-0 ms-2 crypto-header">
                                {item.giftCard}
                                <span className="d-block fs-10">
                                <span className="mobile-gift">  Gift Cards worth</span>
                                  <span className="fs-13"> ₦{item?.total}</span>
                                </span>
                                <p className="fs-12 hide-type-desktop">
                                {item?.cardType ? item.cardType : "Crypto"}
                              </p>
                              <p className="fs-10 hide-date-crypto-desktop">{item?.timestamp}</p>
                              </p>
                            </td>
                            <td>
                              <p className="fs-12 hide-type-transaction">
                                {item?.cardType ? item.cardType : "Crypto"}
                              </p>
                            </td>
                            <td className="dashboard-btn">
                              <Link
                                to=""
                                className={`complete-btn ${getButtonColor(
                                  item?.status
                                )}`}
                              >
                                {/* <span className="dot">.</span> */}
                                {item?.status.toUpperCase()}
                              </Link>
                            </td>
                            <td className="">
                              <p className="fs-10 hide-type-transaction">{item?.timestamp}</p>
                            </td>
                          </tr>
                            </>):("")}
                          </>
                          
                        ))
                      ) : (
                        <>
                          <tr className="text-center not-ransctionbg">
                            <td colSpan={4}>
                              {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                              <div>
                                <img
                                  src="img/EmptyState.png"
                                  alt=""
                                 
                                  className="transaction-empty"
                                />
                                <h4 className="not-transction-transaction">
                                  Transaction
                                </h4>

                                <h5 className="not-transction-transaction-p text-center">
                                  You don't have any pending or completed
                                  transction.
                                </h5>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </>):(<>
                  </>)}

                  {/* 1411 */} 

                    <div></div>
                  </table>
                  <Stack spacing={2} className="d-none">
                    <Pagination
                      count={10}
                      variant="outlined"
                      color="secondary"
                    />
                  </Stack>
                </div>
                <div className="col-md-4 col-lg-4">
                  {loader ? (
                    <>
                      <div className="col-md-4 text-center m-4">
                        {/* <span
                          className="text-white spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        <span className="text-white">Loading...</span> */}
                      </div>
                    </>
                  ) : (
                    <>
                      {tranDetails.transactionType == "crypto" ? (
                        <Card
                          className="transcation-card"
                          sx={{ maxWidth: 300 }}
                        >
                          <div className="back-icon">
                            {/* <img src="/img/CaretLeft.png" onClick={closeBtn} /> */}
                            <a href="/Transcation"><img src="/img/CaretLeft1.svg" /></a>
                          </div>
                          <span>
                            <img
                              src="/img/printer.png"
                              alt=""
                              className="detals-icon"
                            />
                          </span>
                          <CardContent className="content">
                            <div className="d-flex justify-content-between mb-4">
                              <div className="">
                                <h5
                                  className="mb-2  h4 transaction-heading"
                                  style={{ color: "#e9e9ef" }}
                                >
                                  Transaction
                                </h5>
                                <p className="mb-4 p ml-2">
                                  {" "}
                                  {tranDetails.timestamp}
                                </p>
                              </div>
                              <div className="text-white d-none">
                                <i class="bi bi-printer h4"></i>
                              </div>
                            </div>
                            <div className="amazon-card">
                              <p className="d-flex">
                                <span>
                                  <Avatar className="right-icon">
                                    <img src="/img/righttick.svg" alt="" />
                                  </Avatar>
                                </span>

                                <span className="h4 text-white ms-2 gift-card-details">
                                  {tranDetails.giftCard}
                                </span>
                              </p>
                              <button
                                className={`pending-btn mb-3 ${getButtonColor(
                                  tranDetails.status
                                )}`}
                                // variant="outlined"
                              >
                                {/* <i className="bi bi-dot"></i>{" "} */}
                                {/* <span className="dot-deails">.</span> */}
                                {tranDetails.status.toUpperCase()}
                              </button>
                              <p className=" p-details">
                              Details of transactions are shown below
                              </p>
                            </div>
                            <div className="receipt-card mb-3">
                              <h5 className="">
                                Rate
                                <span className="d-block mt-1">
                                  {" "}
                                  N{tranDetails.rate}
                                </span>
                              </h5>
                              <h5 className=" ">
                                Amount
                                <span className="d-block mt-1">
                                  {" "}
                                  {tranDetails.amount}
                                </span>
                              </h5>
                              <h5 className="details-address">
                                Address
                                <span
                                  className="d-block mt-1"
                                  style={{ fontSize: "14px" }}
                                >
                                  {" "}
                                  {tranDetails.address}
                                </span>
                              </h5>
                              <h5 className="">
                                Reasons
                                <span className="d-block mt-1">
                                  {" "}
                                  {tranDetails.reason}
                                </span>
                              </h5>
                              <h5 className="mt-1">
                                TOTAL
                                <span className="d-block mt-1">
                                  N {tranDetails.total}
                                </span>
                              </h5>
                              <div className="accounts-btn d-none ">
                                <Button className="name-btn" variant="outlined">
                                  N25 × 2
                                </Button>
                                <Button className="name-btn" variant="outlined">
                                  N25 × 2
                                </Button>
                                <Button className="name-btn" variant="outlined">
                                  N25 × 2
                                </Button>
                              </div>
                            </div>
                            <div className="image-card ">
                              <h4 style={{ marginTop: "-7px" }}>IMAGES</h4>
                              <div
                                className="img-card"
                                style={{
                                  overflowX: "scroll",
                                  overflowY: "hidden",
                                }}
                              >
                                {tranDetails.transaction_Image ? (
                                  <>
                                    {tranDetails.transaction_Image.map(
                                      (items) => (
                                        <img
                                          className="w-50"
                                          src={`https://secureapi.thequchangeapp.xyz/${items.images}`}
                                        />
                                      )
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <Imagemodal /> */}
                            </div>
                          </CardContent>

                          <CardActions
                            className={`${tranDetails ? "" : "d-none"}`}
                          >
                            {/* <Button className="btn-proceed mb-2">
                              Proceed
                            </Button> */}
                          </CardActions>
                        </Card>
                      ) : (
                        <>
                          <Card
                            className={`transcation-card ${
                              tranDetails ? "" : "d-none"
                            }`}
                            sx={{ maxWidth: 300 }}
                          >
                           <div className="back-icon">
                            {/* <img src="/img/CaretLeft.png" onClick={closeBtn} /> */}
                            <a href="/Transcation"><img src="/img/CaretLeft1.svg" /></a>
                          </div>
                            <span>
                              <img
                                src="/img/printer.png"
                                className="detals-icon"
                              />
                            </span>
                            <CardContent className="content">
                              <div className="justify-content-between mb-4">
                                <div className="">
                                  <h5
                                    className="mb-0 h4 transaction-heading"
                                    style={{ color: "#e9e9ef" }}
                                  >
                                    Transaction
                                  </h5>
                                  <h5 className="gift-h ">Gift Card </h5>
                                  <p className="mb-4 p ml-2">
                                    {" "}
                                    {tranDetails.timestamp}
                                  </p>
                                </div>
                                <div className="text-white d-none">
                                  <i class="bi bi-printer h4"></i>
                                </div>
                              </div>
                              <div className="amazon-card">
                                <p className="">
                                  <span className="icon-text">
                                    <Avatar className="right-icon">
                                      {/* <CheckIcon /> */}
                                      <img src="/img/righttick.svg" alt="" />
                                    </Avatar>
                                  </span>

                                  <span className="h4 text-white ms-2 gift-card-details-ecard text-fonts">
                                    {tranDetails.giftCard}
                                  </span>
                                </p>
                                <button
                                  className={`pending-btn  mb-3 ${getButtonColor(
                                    tranDetails.status
                                  )}`}
                                  // variant="outlined"
                                >
                                  {/* <i className="bi bi-dot"></i>{" "} */}
                                  {/* <span className="dot-deails">.</span> */}
                                  {tranDetails.status}
                                </button>
                                <p className="p-details">
                                Details of transactions are shown below
                                </p>
                              </div>
                              <div className="receipt-card ">
                                <h5 className="">
                                  CURRENCY
                                  <span className="d-block mt-1">
                                    {" "}
                                    {tranDetails.currency}
                                  </span>
                                </h5>
                                <h5 className="">
                                  CARD TYPE
                                  <span className="d-block mt-1">
                                    {" "}
                                    {tranDetails.cardType}
                                  </span>
                                </h5>

                               
                                <h5 className="">
                                  {" "}
                                  Value
                                </h5>
                                <div className="disVal">
                                  {tranV ?(<>
                                    {tranV.map((item)=>(
                                      <>
                                       <span
                                    className="text-white d-block  mt-2 value-box"
                                  >
                                    {item}
                                  </span>
                                      </>
                                    ))}
                                  </>):("")}
                                </div>
                                <h5>
                                  Reasons
                                  <span className="d-block">
                                    {" "}
                                    {tranDetails.reason}
                                  </span>
                                </h5>
                                <h5>
                                  TOTAL
                                  <span className="d-block mt-1">
                                    N {tranDetails.total}
                                  </span>
                                </h5>

                                <div className="accounts-btn d-none">
                                  <Button
                                    className="name-btn"
                                    variant="outlined"
                                  >
                                    N25 × 2
                                  </Button>
                                  <Button
                                    className="name-btn"
                                    variant="outlined"
                                  >
                                    N25 × 2
                                  </Button>
                                  <Button
                                    className="name-btn"
                                    variant="outlined"
                                  >
                                    N25 × 2
                                  </Button>
                                </div>
                              </div>
                              <div className="image-card">
                                <h5>IMAGES</h5>
                                <div
                                  className="img-card"
                                  style={{
                                    overflowX: "scroll",
                                    overflowY: "hidden",
                                  }}
                                >
                                  {/* 1411 */}
                                  {tranDetails.transaction_Image ? (
                                    <>
                                      {tranDetails.transaction_Image.map(
                                        (items) => (
                                          <img
                                            className="w-50"
                                            src={`${items.images}`}
                                          />
                                        )
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                   {/* 1411 */}
                                </div>
                                {/* <Imagemodal /> */}
                              </div>
                            </CardContent>

                            <CardActions className="d-none">
                              {/* <Button className="btn-proceed mb-2">
                                Proceed
                              </Button> */}
                            </CardActions>
                          </Card>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </div>
  );
}

export default Transcation;

// ok code

// import React, { useState, useEffect } from "react";
// import "./transcation.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import CheckIcon from "@mui/icons-material/Check";
// import Avatar from "@mui/material/Avatar";
// import { Typography } from "@mui/material";
// import Imagemodal from "./imagemodal";
// import { useNavigate } from "react-router";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import { Link } from "react-router-dom";
// import "./ransaction.css";
// function Transcation() {
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const navigate = useNavigate();
//   const [transaction, setTransaction] = useState([]);
//   const [tranDetails, setTransactionDetail] = useState("");
//   const [gettingWallet, setGettingWallet] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [id, setId] = useState(0);

//   useEffect(() => {
//     if (!auth) {
//       navigate("/");
//     }
//     setGettingWallet(true);
//     getTransaction();
//     getDetails(id);
//   }, []);

//   const getTransaction = async () => {
//     const apicall = await AfterLoginService.GetTransaction(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setTransaction(resp.data);
//           if (!resp.data) {
//             getDetails(resp.data[0].id);
//           }

//           setGettingWallet(false);
//           setLoader(true);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   const getDetails = async (transaction_id) => {
//     setLoader(true);
//     const apicall = await AfterLoginService.GetTransactionDetails(
//       transaction_id,
//       auth
//     );
//     if (apicall?.state === true) {
//       const resp = await apicall.result.json();
//       if (resp?.status === 200) {
//         setTransactionDetail(resp?.data);

//         if (window.innerWidth > 768) {
//           document.getElementById("mobile").style.display = "block";
//           document.getElementById("mobile-1").style.display = "block";
//         } else {
//           document.getElementById("mobile").style.display = "none";
//           document.getElementById("mobile-1").style.display = "none";
//         }

//         setLoader(false);
//       } else {
//         console.log(resp.message);
//         setLoader(false);
//       }
//     } else {
//     }
//   };

//   // select button

//   const [selectedButton, setSelectedButton] = useState(null);

//   const handleButtonClick = (buttonName) => {
//     setSelectedButton(buttonName);
//   };

//   const getButtonStyle = (buttonName) => {
//     return {
//       marginRight: "10px",
//       backgroundColor: selectedButton === buttonName ? " #27ee91" : "",
//     };
//   };

//   // colse transaction detals

//   function closeBtn() {
//     window.location.href = window.location.href;
//   }

//   // for  status button
//   // function getButtonColor(status) {
//   //   switch (status.toUpperCase()) {
//   //     case "PENDING":
//   //       return "button-pending";
//   //     case "DECLINED":
//   //       return "button-declined";
//   //     case "APPROVED":
//   //       return "button-approved";
//   //     case "PROCESSING":
//   //       return "button-processing";
//   //     default:
//   //       return "";
//   //   }
//   // }

//   function getButtonColor(status) {
//     if (status && typeof status === "string") {
//       switch (status.toUpperCase()) {
//         case "PENDING":
//           return "button-pending";
//         case "DECLINED":
//           return "button-declined";
//         case "APPROVED":
//           return "button-approved";
//         case "PROCESSING":
//           return "button-processing";
//         default:
//           return "";
//       }
//     } else {
//       return "";
//     }
//   }

//   return (
//     <div className="container-fluid position-relative d-flex p-0 transcation-container">
//       <Header />
//       <div className="content">
//         <Subheader />
//         <div className="container-fluid pt-4 px-4 transcation-table">
//           {gettingWallet ? (
//             <>
//               <div className="col-md-12 text-center m-4">
//                 <span
//                   className="text-white spinner-border spinner-border-sm"
//                   role="status"
//                   aria-hidden="true"
//                 ></span>{" "}
//                 <span className="text-white">Loading...</span>
//               </div>
//             </>
//           ) : (
//             <div className="row header-2">
//               <h3 className="heading-transaction mb-0">Transaction</h3>
//               <p className="para-transaction ">
//                 Molestie et amet cursus vestibulum quam commodo.
//               </p>
//               {/* <Box className="d-none">
//                 <Tabs className="tab-box" aria-label="">
//                   <Tab className="tab" label="All" />
//                   <Tab className="tab" label="Crypto" />
//                   <Tab className="tab" label="Gift Cards" />
//                   <Tab className="tab" label="Naira" />
//                 </Tabs>
//               </Box> */}
//               <div className="row mt-2 ">
//                 <div className="col-lg-4 col-md-4 col-sm-12 col-12 col-xs-12">
//                   <button
//                     type="button"
//                     className="btn btn-secondary btn-tansaction"
//                     style={getButtonStyle("All")}
//                     onClick={() => handleButtonClick("All")}
//                   >
//                     All
//                   </button>

//                   <button
//                     type="button"
//                     className="btn btn-secondary btn-tansaction"
//                     style={getButtonStyle("Crypto")}
//                     onClick={() => handleButtonClick("Crypto")}
//                   >
//                     Crypto
//                   </button>

//                   <button
//                     type="button"
//                     className="btn btn-secondary btn-tansaction"
//                     style={getButtonStyle("Gift Cards")}
//                     onClick={() => handleButtonClick("Gift Cards")}
//                   >
//                     Gift Cards
//                   </button>
//                 </div>

//                 <div className="col-lg-4 col-md-4 col-sm-12 col-12 "> </div>
//                 <div className="col-lg-4 col-md-4 col-sm-12 col-12 "> </div>
//               </div>
//               <div className="transcation-div mt-2 mx-1 ">
//                 <div className="col-md-8 col-lg-8 col-sm-8 col-8 col-xs-8 table-transcation   order-sm-1 order-2">
//                   {/* <div className="table-responsive"> */}
//                   <table
//                     className="table table-dark table-border "
//                     id="mobile-1"
//                     style={{ tableLayout: "fixed" }}
//                   >
//                     <thead>
//                       <tr className="fs-13 " style={{ tableLayout: "fixed" }}>
//                         <th>DETAILS</th>
//                         <th>TYPE</th>
//                         <th>STATUS</th>
//                         <th>DATE</th>
//                       </tr>
//                     </thead>
//                     {/* </table>

//                   <table
//                     class="table table-dark table-border table-1"
//                     style={{ textAlign: "center" }}
//                   > */}
//                     <tbody id="mobile">
//                       {transaction.length > 0 ? (
//                         transaction.map((item, index) => (
//                           <tr onClick={() => getDetails(item?.id)}>
//                             <td className="d-flex align-items-center">
//                               <p className="gift-icon">
//                                 <i class="bi bi-gift"></i>
//                               </p>
//                               <p className="fs-12 ms-2">
//                                 {item?.giftCard}
//                                 <span className="d-block fs-10">
//                                   Gift Cards worth
//                                   <span className="fs-13"> N{item?.total}</span>
//                                 </span>
//                               </p>
//                             </td>
//                             <td>
//                               <p className="fs-12">
//                                 {item?.cardType ? item?.cardType : "Crypto"}
//                               </p>
//                             </td>
//                             {/* <td>
//                               <a href="" className="complete-btn">
//                                 {item?.status}
//                               </a>
//                             </td> */}

//                             <td>
//                               <a
//                                 href=""
//                                 className={`complete-btn ${getButtonColor(
//                                   item?.status
//                                 )}`}
//                               >
//                                 {item?.status}
//                               </a>
//                             </td>
//                             <td className="">
//                               <p className="fs-10">{item?.timestamp}</p>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <>
//                           <tr className="text-center not-ransctionbg">
//                             <div>
//                               <img
//                                 src="img/EmptyState.png"
//                                 alt=""
//                                 height="500px"
//                                 style={{ margin: "auto", marginTop: "60px" }}
//                               />
//                               <h4 className="not-transction">Transaction</h4>
//                               <p className="not-transction-p">
//                                 You don't have any pending or completed
//                                 transction.
//                               </p>
//                             </div>
//                           </tr>
//                         </>
//                       )}
//                     </tbody>
//                     <div></div>
//                   </table>
//                   {/* </div> */}

//                   <Stack spacing={2} className="d-none">
//                     <Pagination
//                       count={10}
//                       variant="outlined"
//                       color="secondary"
//                     />
//                   </Stack>
//                 </div>

//                 <div className="col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12 order-sm-2 order-1">
//                   {loader ? (
//                     <>
//                       <div className="col-md-4 text-center m-4">
//                         <span
//                           className="text-white spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         <span className="text-white">Loading...</span>
//                       </div>
//                     </>
//                   ) : (
//                     <>
//                       {tranDetails &&
//                       tranDetails.transactionType == "crypto" ? (
//                         <Card
//                           className="transcation-card"
//                           sx={{ maxWidth: 300 }}
//                         >
//                           <CardContent className="content">
//                             <span
//                               className="material-symbols-outlined mt-1"
//                               style={{ float: "right", color: "#ebe1e1" }}
//                               onClick={closeBtn}
//                             >
//                               cancel
//                             </span>
//                             <div className="d-flex justify-content-between mb-4">
//                               <div className="">
//                                 <h5 className="mb-0 h4">
//                                   Transaction Details{" "}
//                                 </h5>
//                               </div>
//                               <div className="text-white d-none">
//                                 <i class="bi bi-printer h4"></i>
//                               </div>
//                             </div>
//                             <div className="amazon-card">
//                               {/* <Button
//                                 className="pending-btn mb-3"
//                                 variant="outlined"
//                               >
//                                 <i class="bi bi-dot"></i> {tranDetails.status}
//                               </Button>  */}

//                               <Button
//                                 className={`pending-btn mb-3 ${getButtonColor(
//                                   tranDetails.status
//                                 )}`}
//                                 variant="outlined"
//                               >
//                                 <i class="bi bi-dot"></i> {tranDetails.status}
//                               </Button>

//                               <p className="d-flex">
//                                 <span>
//                                   <Avatar className="right-icon">
//                                     <CheckIcon />
//                                   </Avatar>
//                                 </span>

//                                 <span className="h4 text-white ms-2">
//                                   {tranDetails.giftCard}
//                                 </span>
//                               </p>
//                               <p className="mb-0 p"> {tranDetails.timestamp}</p>
//                             </div>
//                             <div className="receipt-card mb-3">
//                               <h5 className="">
//                                 Rate
//                                 <span className="d-block">
//                                   {" "}
//                                   N{tranDetails.rate}
//                                 </span>
//                               </h5>
//                               <h5 className="">
//                                 Amount
//                                 <span className="d-block">
//                                   {" "}
//                                   {tranDetails.amount}
//                                 </span>
//                               </h5>
//                               <h5>
//                                 Address
//                                 <span className="d-block">
//                                   {" "}
//                                   {tranDetails.address}
//                                 </span>
//                               </h5>
//                               <h5>
//                                 Reasons
//                                 <span className="d-block">
//                                   {" "}
//                                   {tranDetails.reason}
//                                 </span>
//                               </h5>
//                               <h5>
//                                 TOTAL
//                                 <span className="d-block">
//                                   N {tranDetails.total}
//                                 </span>
//                               </h5>
//                               <div className="accounts-btn d-none">
//                                 <Button className="name-btn" variant="outlined">
//                                   N25 × 2
//                                 </Button>
//                                 <Button className="name-btn" variant="outlined">
//                                   N25 × 2
//                                 </Button>
//                                 <Button className="name-btn" variant="outlined">
//                                   N25 × 2
//                                 </Button>
//                               </div>
//                             </div>
//                             <div className="image-card">
//                               <h4 className="">IMAGES</h4>
//                               <div className="img-card">
//                                 {tranDetails.transaction_Image ? (
//                                   <>
//                                     {tranDetails.transaction_Image.map(
//                                       (items) => (
//                                         <img
//                                           className="w-50"
//                                           src={`https://secureapi.thequchangeapp.xyz/${items.images}`}
//                                         />
//                                       )
//                                     )}
//                                   </>
//                                 ) : (
//                                   ""
//                                 )}
//                               </div>
//                               {/* <Imagemodal /> */}
//                             </div>
//                           </CardContent>

//                           <CardActions
//                             className={`${tranDetails ? "" : "d-none"}`}
//                           >
//                             {/* <Button className="btn-proceed mb-2">
//                               Proceed
//                             </Button> */}
//                           </CardActions>
//                         </Card>
//                       ) : (
//                         <>
//                           <Card
//                             className={`transcation-card ${
//                               tranDetails ? "" : "d-none"
//                             }`}
//                             sx={{ maxWidth: 300 }}
//                           >
//                             <CardContent className="content">
//                               <span
//                                 className="material-symbols-outlined mt-1"
//                                 style={{ float: "right", color: "#ebe1e1" }}
//                                 onClick={closeBtn}
//                               >
//                                 cancel
//                               </span>
//                               <div className="d-flex justify-content-between mb-4">
//                                 <div className="">
//                                   <h5 className="mb-0 h4">
//                                     Transaction Details
//                                   </h5>
//                                 </div>
//                                 <div className="text-white d-none">
//                                   <i class="bi bi-printer h4"></i>
//                                 </div>
//                               </div>
//                               <div className="amazon-card">
//                                 {/* <Button
//                                   className="pending-btn mb-3"
//                                   variant="outlined"
//                                 >
//                                   <i class="bi bi-dot"></i> {tranDetails.status}
//                                 </Button> */}

//                                 <Button
//                                   className={`pending-btn mb-3 ${getButtonColor(
//                                     tranDetails.status
//                                   )}`}
//                                   variant="outlined"
//                                 >
//                                   <i class="bi bi-dot"></i> {tranDetails.status}
//                                 </Button>

//                                 <p className="d-flex">
//                                   <span>
//                                     <Avatar className="right-icon">
//                                       <CheckIcon />
//                                     </Avatar>
//                                   </span>

//                                   <span className="h4 text-white ms-2">
//                                     {tranDetails.giftCard}
//                                   </span>
//                                 </p>
//                                 <p className="mb-0 p">
//                                   {" "}
//                                   {tranDetails.timestamp}
//                                 </p>
//                               </div>
//                               <div className="receipt-card mb-3">
//                                 <h5 className="">
//                                   CURRENCY
//                                   <span className="d-block">
//                                     {" "}
//                                     {tranDetails.currency}
//                                   </span>
//                                 </h5>
//                                 <h5 className="">
//                                   CARD TYPE
//                                   <span className="d-block">
//                                     {" "}
//                                     {tranDetails.cardType}
//                                   </span>
//                                 </h5>
//                                 <h5>
//                                   Values
//                                   <span className="text-white d-block">
//                                     {" "}
//                                     {tranDetails.values}
//                                   </span>
//                                 </h5>
//                                 <h5>
//                                   Reasons
//                                   <span className="d-block">
//                                     {" "}
//                                     {tranDetails.reason}
//                                   </span>
//                                 </h5>
//                                 <h5>
//                                   TOTAL
//                                   <span className="d-block">
//                                     N {tranDetails.total}
//                                   </span>
//                                 </h5>

//                                 <div className="accounts-btn d-none">
//                                   <Button
//                                     className="name-btn"
//                                     variant="outlined"
//                                   >
//                                     N25 × 2
//                                   </Button>
//                                   <Button
//                                     className="name-btn"
//                                     variant="outlined"
//                                   >
//                                     N25 × 2
//                                   </Button>
//                                   <Button
//                                     className="name-btn"
//                                     variant="outlined"
//                                   >
//                                     N25 × 2
//                                   </Button>
//                                 </div>
//                               </div>
//                               <div className="image-card ">
//                                 <h4 className="">IMAGES</h4>
//                                 <div className="img-card ">
//                                   {tranDetails.transaction_Image ? (
//                                     <>
//                                       {tranDetails.transaction_Image.map(
//                                         (items) => (
//                                           <img
//                                             className="w-50 text-center"
//                                             src={`https://secureapi.thequchangeapp.xyz/${items.images}`}
//                                           />
//                                         )
//                                       )}
//                                     </>
//                                   ) : (
//                                     ""
//                                   )}
//                                 </div>
//                                 {/* <Imagemodal /> */}
//                               </div>
//                             </CardContent>
//                             {/*
//                             <CardActions className="d-none">
//                               <Button className="btn-proceed mb-2">
//                                 Proceed
//                               </Button>
//                             </CardActions> */}
//                           </Card>
//                         </>
//                       )}
//                     </>
//                   )}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Transcation;

//good

// import React, { useState, useEffect } from "react";
// import "./transcation.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import CheckIcon from "@mui/icons-material/Check";
// import Avatar from "@mui/material/Avatar";
// import { Typography } from "@mui/material";
// import Imagemodal from "./imagemodal";
// import { useNavigate } from "react-router";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import { Link } from "react-router-dom";
// import "./ransaction.css";
// function Transcation() {
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const navigate = useNavigate();
//   const [transaction, setTransaction] = useState([]);
//   const [tranDetails, setTransactionDetail] = useState("");
//   const [gettingWallet, setGettingWallet] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [id, setId] = useState(0);

//   useEffect(() => {
//     if (!auth) {
//       navigate("/");
//     }
//     setGettingWallet(true);
//     getTransaction();
//     getDetails(id);
//   }, []);

//   const getTransaction = async () => {
//     const apicall = await AfterLoginService.GetTransaction(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setTransaction(resp.data);
//           if (!resp.data) {
//             getDetails(resp.data[0].id);
//           }

//           setGettingWallet(false);
//           setLoader(true);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   const getDetails = async (transaction_id) => {
//     setLoader(true);
//     const apicall = await AfterLoginService.GetTransactionDetails(
//       transaction_id,
//       auth
//     );
//     if (apicall?.state === true) {
//       const resp = await apicall.result.json();
//       if (resp?.status === 200) {
//         setTransactionDetail(resp?.data);

//         if (window.innerWidth > 768) {

//           document.getElementById("mobile").style.display = "block";
//           document.getElementById("mobile-1").style.display = "block";
//         } else {

//           document.getElementById("mobile").style.display = "none";
//           document.getElementById("mobile-1").style.display = "none";
//         }

//         setLoader(false);
//       } else {
//         console.log(resp.message);
//         setLoader(false);
//       }
//     } else {

//     }
//   };

//   return (
//     <div className="container-fluid position-relative d-flex p-0 transcation-container">
//       <Header />
//       <div className="content">
//         <Subheader />
//         <div className="container-fluid pt-4 px-4 transcation-table">
//           {gettingWallet ? (
//             <>
//               <div className="col-md-12 text-center m-4">
//                 <span
//                   className="text-white spinner-border spinner-border-sm"
//                   role="status"
//                   aria-hidden="true"
//                 ></span>{" "}
//                 <span className="text-white">Loading...</span>
//               </div>
//             </>
//           ) : (
//             <div className="row">
//               <h3 className="heading-transaction mb-0">Transaction</h3>
//               <p className="para-transaction ">
//                 Molestie et amet cursus vestibulum quam commodo.
//               </p>
//               {/* <Box className="d-none">
//                 <Tabs className="tab-box" aria-label="">
//                   <Tab className="tab" label="All" />
//                   <Tab className="tab" label="Crypto" />
//                   <Tab className="tab" label="Gift Cards" />
//                   <Tab className="tab" label="Naira" />
//                 </Tabs>
//               </Box> */}
//               <div className="row mt-2 ">
//                 <div className="col-lg-4 col-md-4 col-sm-12 col-12 col-xs-12 ">
//                   <button
//                     type="button"
//                     className="btn btn-info btn-transaction1"
//                     style={{ marginRight: "10px" }}
//                   >
//                     All
//                   </button>

//                   <button
//                     type="button"
//                     className="btn btn-secondary btn-tansaction"
//                     style={{ marginRight: "10px" }}
//                   >
//                     Crypto
//                   </button>

//                   <button
//                     type="button"
//                     className="btn btn-secondary btn-tansaction"
//                     style={{ marginRight: "10px" }}
//                   >
//                     Gift Cards
//                   </button>
//                 </div>

//                 <div className="col-lg-4 col-md-4 col-sm-12 col-12 "> </div>
//                 <div className="col-lg-4 col-md-4 col-sm-12 col-12 "> </div>
//               </div>
//               <div className="transcation-div mt-2 mx-1 ">
//                 <div className="col-md-8 col-lg-8 col-sm-8 col-8 col-xs-8 table-transcation   order-sm-1 order-2">
//                   {/* <div className="table-responsive"> */}
//                   <table className="table-border  table-transcation1"  id="mobile-1">
//                     <thead className="table-header1">
//                       <tr className="fs-13" style={{ marginLeft: "20px" }}>
//                         <th>DETAILS</th>
//                         <th>TYPE</th>
//                         <th>STATUS</th>
//                         <th>DATE</th>
//                       </tr>
//                     </thead>
//                   </table>

//                   <table
//                     class="table table-dark table-border"
//                     style={{ textAlign: "center" }}
//                   >
//                     <tbody id="mobile">
//                       {transaction.length > 0 ? (
//                         transaction.map((item, index) => (
//                           <tr  onClick={() => getDetails(item?.id)} >
//                             <td  className="d-flex align-items-center">
//                               <p className="gift-icon">
//                                 <i class="bi bi-gift"></i>
//                               </p>
//                               <p className="fs-12 ms-2">
//                                 {item?.giftCard}
//                                 <span className="d-block fs-10">
//                                   Gift Cards worth
//                                   <span className="fs-13"> N{item?.total}</span>
//                                 </span>
//                               </p>
//                             </td>
//                             <td>
//                               <p className="fs-12">
//                                 {item?.cardType ? item?.cardType : "Crypto"}
//                               </p>
//                             </td>
//                             <td>
//                               <a href="" className="complete-btn">
//                                 {item?.status}
//                               </a>
//                             </td>
//                             <td className="">
//                               <p className="fs-10">{item?.timestamp}</p>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <>
//                           <tr className="text-center not-ransctionbg">
//                             <div>
//                               <img
//                                 src="img/EmptyState.png"
//                                 alt=""
//                                 height="500px"
//                                 style={{ margin: "auto", marginTop: "60px" }}
//                               />
//                               <h4 className="not-transction">Transaction</h4>
//                               <p className="not-transction-p">
//                                 You don't have any pending or completed
//                                 transction.
//                               </p>
//                             </div>
//                           </tr>
//                         </>
//                       )}
//                     </tbody>
//                     <div></div>
//                   </table>
//                   {/* </div> */}

//                   <Stack spacing={2} className="d-none">
//                     <Pagination
//                       count={10}
//                       variant="outlined"
//                       color="secondary"
//                     />
//                   </Stack>
//                 </div>

//                 <div className="col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12 order-sm-2 order-1">
//                   {loader ? (
//                     <>
//                       <div className="col-md-4 text-center m-4">
//                         <span
//                           className="text-white spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         <span className="text-white">Loading...</span>
//                       </div>
//                     </>
//                   ) : (
//                     <>

//                       {tranDetails && tranDetails.transactionType == "crypto" ? (
//                         <Card
//                           className="transcation-card"
//                           sx={{ maxWidth: 300 }}
//                         >
//                           <CardContent className="content">
//                             <div className="d-flex justify-content-between mb-4">
//                               <div className="">
//                                 <h5 className="mb-0 h4">
//                                   Transaction Details{" "}
//                                 </h5>
//                               </div>
//                               <div className="text-white d-none">
//                                 <i class="bi bi-printer h4"></i>
//                               </div>
//                             </div>
//                             <div className="amazon-card">
//                               <Button
//                                 className="pending-btn mb-3"
//                                 variant="outlined"
//                               >
//                                 <i class="bi bi-dot"></i> {tranDetails.status}
//                               </Button>

//                               <p className="d-flex">
//                                 <span>
//                                   <Avatar className="right-icon">
//                                     <CheckIcon />
//                                   </Avatar>
//                                 </span>

//                                 <span className="h4 text-white ms-2">
//                                   {tranDetails.giftCard}
//                                 </span>
//                               </p>
//                               <p className="mb-0 p"> {tranDetails.timestamp}</p>
//                             </div>
//                             <div className="receipt-card mb-3">
//                               <h5 className="">
//                                 Rate
//                                 <span className="d-block">
//                                   {" "}
//                                   N{tranDetails.rate}
//                                 </span>
//                               </h5>
//                               <h5 className="">
//                                 Amount
//                                 <span className="d-block">
//                                   {" "}
//                                   {tranDetails.amount}
//                                 </span>
//                               </h5>
//                               <h5>
//                                 Address
//                                 <span className="d-block">
//                                   {" "}
//                                   {tranDetails.address}
//                                 </span>
//                               </h5>
//                               <h5>
//                                 Reasons
//                                 <span className="d-block">
//                                   {" "}
//                                   {tranDetails.reason}
//                                 </span>
//                               </h5>
//                               <h5>
//                                 TOTAL
//                                 <span className="d-block">
//                                   N {tranDetails.total}
//                                 </span>
//                               </h5>
//                               <div className="accounts-btn d-none">
//                                 <Button className="name-btn" variant="outlined">
//                                   N25 × 2
//                                 </Button>
//                                 <Button className="name-btn" variant="outlined">
//                                   N25 × 2
//                                 </Button>
//                                 <Button className="name-btn" variant="outlined">
//                                   N25 × 2
//                                 </Button>
//                               </div>
//                             </div>
//                             <div className="image-card">
//                               <h4 className="">IMAGES</h4>
//                               <div className="img-card">
//                                 {tranDetails.transaction_Image ? (
//                                   <>
//                                     {tranDetails.transaction_Image.map(
//                                       (items) => (
//                                         <img
//                                           className="w-50"
//                                           src={`https://secureapi.thequchangeapp.xyz/${items.images}`}
//                                         />
//                                       )
//                                     )}
//                                   </>
//                                 ) : (
//                                   ""
//                                 )}
//                               </div>
//                               <Imagemodal />
//                             </div>
//                           </CardContent>

//                           <CardActions
//                             className={`${tranDetails ? "" : "d-none"}`}
//                           >
//                             <Button className="btn-proceed mb-2">
//                               Proceed
//                             </Button>
//                           </CardActions>
//                         </Card>
//                       ) : (
//                         <>
//                           <Card
//                             className={`transcation-card ${
//                               tranDetails ? "" : "d-none"
//                             }`}
//                             sx={{ maxWidth: 300 }}
//                           >
//                             <CardContent className="content">
//                               <div className="d-flex justify-content-between mb-4">
//                                 <div className="">
//                                   <h5 className="mb-0 h4">
//                                     Transaction Details
//                                   </h5>
//                                 </div>
//                                 <div className="text-white d-none">
//                                   <i class="bi bi-printer h4"></i>
//                                 </div>
//                               </div>
//                               <div className="amazon-card">
//                                 <Button
//                                   className="pending-btn mb-3"
//                                   variant="outlined"
//                                 >
//                                   <i class="bi bi-dot"></i> {tranDetails.status}
//                                 </Button>

//                                 <p className="d-flex">
//                                   <span>
//                                     <Avatar className="right-icon">
//                                       <CheckIcon />
//                                     </Avatar>
//                                   </span>

//                                   <span className="h4 text-white ms-2">
//                                     {tranDetails.giftCard}
//                                   </span>
//                                 </p>
//                                 <p className="mb-0 p">
//                                   {" "}
//                                   {tranDetails.timestamp}
//                                 </p>
//                               </div>
//                               <div className="receipt-card mb-3">
//                                 <h5 className="">
//                                   CURRENCY
//                                   <span className="d-block">
//                                     {" "}
//                                     {tranDetails.currency}
//                                   </span>
//                                 </h5>
//                                 <h5 className="">
//                                   CARD TYPE
//                                   <span className="d-block">
//                                     {" "}
//                                     {tranDetails.cardType}
//                                   </span>
//                                 </h5>
//                                 <h5>
//                                   Values
//                                   <span className="text-white d-block">
//                                     {" "}
//                                     {tranDetails.values}
//                                   </span>
//                                 </h5>
//                                 <h5>
//                                   Reasons
//                                   <span className="d-block">
//                                     {" "}
//                                     {tranDetails.reason}
//                                   </span>
//                                 </h5>
//                                 <h5>
//                                   TOTAL
//                                   <span className="d-block">
//                                     N {tranDetails.total}
//                                   </span>
//                                 </h5>

//                                 <div className="accounts-btn d-none">
//                                   <Button
//                                     className="name-btn"
//                                     variant="outlined"
//                                   >
//                                     N25 × 2
//                                   </Button>
//                                   <Button
//                                     className="name-btn"
//                                     variant="outlined"
//                                   >
//                                     N25 × 2
//                                   </Button>
//                                   <Button
//                                     className="name-btn"
//                                     variant="outlined"
//                                   >
//                                     N25 × 2
//                                   </Button>
//                                 </div>
//                               </div>
//                               <div className="image-card">
//                                 <h4 className="">IMAGES</h4>
//                                 <div className="img-card">
//                                   {tranDetails.transaction_Image ? (
//                                     <>
//                                       {tranDetails.transaction_Image.map(
//                                         (items) => (
//                                           <img
//                                             className="w-50"
//                                             src={`https://secureapi.thequchangeapp.xyz/${items.images}`}
//                                           />
//                                         )
//                                       )}
//                                     </>
//                                   ) : (
//                                     ""
//                                   )}
//                                 </div>
//                                 <Imagemodal />
//                               </div>
//                             </CardContent>

//                             <CardActions className="d-none">
//                               <Button className="btn-proceed mb-2">
//                                 Proceed
//                               </Button>
//                             </CardActions>
//                           </Card>
//                         </>
//                       )}
//                     </>
//                   )}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Transcation;
