export const useSelect = () => {
    let styles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "black" : "black",
            color: state.isFocused ? "white" : "white",
            fontSize: "14px",
            border: "0",
            borderRadius: "0px",
            "&:hover": {
                border: "0",
                backgroundColor: state.isDisabled ? "#252731" : "#252731",
                color: 'white',
                borderRadius: "0px"
            }
        }),
        // option: (provided, state) => ({
        //     ...provided,
        //     fontSize: "15px",
        //     fontWeight: "400",
        //     color: state.isFocused ? "#686868" : "#686868",
        //     backgroundColor:  state.isFocused ? "black" : "#fff",
        //     border: state.isFocused && "0.5px solid  #85aeff",
        //     borderRadius: state.isFocused && "10px",
        //     "&:hover": {
        //         backgroundColor: state.isDisabled ? "normal" : "#edf4ff",
        //         border: "0.5px solid  #85aeff",
        //         borderRadius: "10px",
        //         color: state.isDisabled ? "#686868" : "#686868",
        //     },
        // }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: "20",
        }),
        menuList:(provided, state) => ({
            ...provided,
            padding: '0',
            border: '1px solid #363636',
            borderRadius: "5px",
            // paddingLeft: '10px',
            backgroundColor:'black'
        }) ,
        singleValue: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            fontSize: "14px",
            fontWeight: "300",
            letterSpacing:'0.025em'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? "#ffffff" : "#ffffff",
            fontSize: "15px",
            fontWeight: "400",
            "&:hover": {
                color: state.isFocused ? "#ffffff" : "#ffffff",
            },
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? "0" : "0",
            backgroundColor: state.isFocused ? "#252731" : "#252731",
            // borderRadius: state.isFocused ? "5px" : "5px",
            boxShadow: state.isFocused ? null : null,
        }),
        input: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            fontSize: "13px",
            fontWeight: "300",
            letterSpacing:'0.025em'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            fontSize: "14px",
            fontWeight: "300",
            letterSpacing:'0.025em'
        }),
    };

    return styles;
};

export default useSelect;