let Trc20Services = {
  adminApi: "https://admin.thequchangeapp.xyz",
  localAuthApi: "https://secureapi.thequchangeapp.xyz/api/auth",
  tronApi: "http://54.172.240.49:3000",
  trongridAPI: "https://api.trongrid.io",
  usdtPrice: 1,

  requestApi: async function (url, auth, data = {}, method = "GET") {
    let reqData = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + auth.api_access_token,
      },
    };

    if (Object.keys(data).length !== 0 || method === "POST") {
      reqData.body = JSON.stringify(data);
    }

    return await fetch(url, reqData)
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: await result.json(), state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  GetTronUserWallet: function (auth) {
    let url = this.localAuthApi + "/get-tron-user-wallet";
    return this.requestApi(url, auth);
  },

  CreateJwtTronToken: async function (auth, body) {
    let apiStatus = "";
    let url = this.adminApi + "/jwt/index2.php";

    //================================//
    // first create jwt token
    //================================//

    let getToken = await this.requestApi(url, auth, body, "POST");
    if (getToken?.result?.data?.token) {
      //================================//
      // create tron wallet
      let url = this.tronApi + "/tron-wallet";
      let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      let getWalletAddress = await this.requestApi(url, jwtAuth, {}, "POST");

      //================================ end//
      if (getWalletAddress.result.statusCode === 400) {
        let recheckIfaccountExist = this.tronApi + "/tron-wallet";
        let get = await this.requestApi(
          recheckIfaccountExist,
          jwtAuth,
          {},
          "GET"
        );
        let getWalt = {
          email: get.result.email,
          phone: get.result.phone,
          tronaddress: get.result.address,
          tronid: get.result._id,
        };
        let url = this.localAuthApi + "/save-trx-usdt-wallet";
        let saveWalletAddress = await this.requestApi(
          url,
          auth,
          getWalt,
          "POST"
        );
        apiStatus = saveWalletAddress;
      } else {
        //================================//
        // save wallet
        let saveWallet = {
          email: getWalletAddress.result.email,
          phone: getWalletAddress.result.phone,
          tronaddress: getWalletAddress.result.address,
          tronid: getWalletAddress.result._id,
        };
        let url = this.localAuthApi + "/save-trx-usdt-wallet";
        let saveWalletAddress = await this.requestApi(
          url,
          auth,
          saveWallet,
          "POST"
        );
        apiStatus = saveWalletAddress;
        //================================//
      }
    }

    return apiStatus;
  },

  tapToRefreshTRC20: async function (auth, body) {
    let url = this.adminApi + "/jwt/index2.php";
    let apiStatus;
    //================================//
    // first create jwt token
    let getToken = await this.requestApi(url, auth, body, "POST");

    //================================//

    if (getToken?.result?.data?.token) {
      let url = this.tronApi + "/tron-wallet/balance";
      let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      let getBlances = await this.requestApi(url, jwtAuth, {}); // get form tron api
      if (getBlances.result) {
        let url =
          this.localAuthApi +
          `/update-trx-usdt-wallet?trx_bal=${getBlances?.result?.formatted_balance?.trx}&usdt_bal=${getBlances?.result?.formatted_balance?.usdt}`;
        let updateBlances = await this.requestApi(url, auth, {}, "POST"); // get form tron api
        apiStatus = updateBlances;
      }
    }
    return apiStatus;
  },

  GetAdminRate: async function (auth) {
    let url = this.localAuthApi + "/get-tron-admin";
    return await this.requestApi(url, auth);
  },

  GetTrxTransaction: async function (auth, type) {
    // =========================================//
    // Get TRX/USDT sell/send transaction
    // =========================================//
    let trnsactionType =
      type === true
        ? "get-usdt-transaction-history"
        : "get-trx-transaction-history";
    let url = this.localAuthApi + `/${trnsactionType}`;
    return await this.requestApi(url, auth);
  },

  GetTrxReceiveTransaction: async function (auth, body, type) {
    // =========================================//
    // Get Jwt Tron token
    // =========================================//
    let url = this.adminApi + "/jwt/index2.php";
    let getToken = await this.requestApi(url, auth, body, "POST");

    if (getToken?.result?.data?.token) {
      // =========================================//
      // Get Received Transaction history
      // =========================================//
      let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      let url =
        this.tronApi +
        `/tron-wallet/${type === true ? "usdt" : "trx"}/transactions`;
      let historyData = await this.requestApi(url, jwtAuth);

      return historyData;
    }
  },

  ChangeStatusByTransactionId: async function (auth, body, txId) {
    // =========================================//
    // Get Jwt Tron token
    // =========================================//
    let url = this.adminApi + "/jwt/index2.php";
    let getToken = await this.requestApi(url, auth, body, "POST");
    let apiStatus;
    if (getToken?.result?.data?.token) {
      // =========================================//
      // check tron transaction by tx id
      // =========================================//
      let url = this.trongridAPI + "/walletsolidity/gettransactionbyid";
      let body = { value: txId };
      let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      let gettransactionResponse = await this.requestApi(
        url,
        jwtAuth,
        body,
        "POST"
      );

      // =========================================//
      // Update status in local Db
      // =========================================//
      if (Object.keys(gettransactionResponse.result).length !== 0) {
        let status = gettransactionResponse.result.ret[0].contractRet;
        let url =
          this.localAuthApi +
          `/update-trxusdt-tran-status?tran_id=${txId}&status=${status}`;
        let updateResponse = await this.requestApi(url, auth, {}, "POST");
        apiStatus = updateResponse;
      }

      return apiStatus;
    }
  },

  CheckValidTransaction: async function (auth, body, address, amount, token) {
    // =========================================//
    // Get Jwt Tron token
    // =========================================//
    let apiStatus;
    let url = this.adminApi + "/jwt/index2.php";
    let getToken = await this.requestApi(url, auth, body, "POST");

    if (getToken?.result?.data?.token) {
      // =========================================//
      // Get conversion price
      // =========================================//
      let url = this.tronApi + "/tron-wallet/convert?amount=1";
      let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      let conversionResponse = await this.requestApi(url, jwtAuth);
      if (Object.keys(conversionResponse.result).length !== 0) {
        // =========================================//
        // Apply formula to get actual value
        // =========================================//
        let trxAmount =
          parseFloat(amount) *
          (parseFloat(conversionResponse.result?.tron) /
            parseFloat(conversionResponse.result?.usd));
        if (token === "USDT") {
          trxAmount = parseFloat(amount) * this.usdtPrice;
        }
        let feeBody = {
          toAddress: `${address}`,
          amount: (+trxAmount.toFixed(6)).toString(),
        };

        // =========================================//
        // hit tron fee api to check valid
        // =========================================//
        let url =
          this.tronApi + `/tron-wallet/${token.toLowerCase()}/transfer/fee`;
        let validResponse = await this.requestApi(
          url,
          jwtAuth,
          feeBody,
          "POST"
        );
        validResponse.result.amount =
          token === "USDT"
            ? trxAmount
            : parseFloat(conversionResponse.result?.tron) /
            parseFloat(conversionResponse.result?.usd);
        validResponse.result.trxusdtprice =
          token === "USDT" ? this.usdtPrice : conversionResponse.result?.usd;
        apiStatus = validResponse;
      }
      return apiStatus;
    }
  },

  SendTransaction: async function (
    auth,
    body,
    address,
    send_amount,
    token,
    uiamount,
    conversion_rate
  ) {
    // =========================================//
    // Get Jwt Tron token
    // =========================================//
    let apiStatus;
    let url = this.adminApi + "/jwt/index2.php";
    let getToken = await this.requestApi(url, auth, body, "POST");

    if (getToken?.result?.data?.token) {
      let tron_user_wallelt = await this.GetTronUserWallet(auth); ///get user wallet info

      let from_address = "";
      if (tron_user_wallelt.result?.data) {
        from_address = tron_user_wallelt.result?.data.tron_wallet_address;
      }

      // Comment below code if create request close

      // let url = this.tronApi + `/tron-wallet/${token.toLowerCase()}/transfer`;
      // let amountBody = {
      //   "toAddress": `${address}`,
      //   "amount": (+send_amount).toFixed(6).toString()
      // }
      // let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      // let sendResponse = await this.requestApi(url, jwtAuth, amountBody, "POST");

      // if (sendResponse.result?.status === 'completed' || sendResponse.result?.status === 'success') {
      //   let params = `tran_type=send&tran_id=${sendResponse.result?.txID}&naira_sell_rate=0&amount=${uiamount}&from_address=${from_address}&to_address=${address}&naira_amount_sent=0&tron_type=${token}&conversion_rate=${conversion_rate}&value_to_send=${send_amount}`;
      //   let url = this.localAuthApi + `/save-usdt-transaction?${params}`;
      //   let saveLocalResponse = await this.requestApi(url, auth, {}, "POST");
      //   saveLocalResponse.result.txID = sendResponse.result?.txID
      //   apiStatus = saveLocalResponse;
      // }
      // else {
      //   apiStatus = sendResponse.result;
      // }

      // uncomment below code if create request close

      let txId =
        "d6a0184c52cc44ed6c529b0e9dff62630f47e5668334068b8a2d54b02ede1db1";
      let params = `tran_type=send&tran_id=${txId}&naira_sell_rate=0&amount=${uiamount}&from_address=${from_address}&to_address=${address}&naira_amount_sent=0&tron_type=${token}&conversion_rate=${conversion_rate}&value_to_send=${(+send_amount).toFixed(6)}`;
      let url = this.localAuthApi + `/save-usdt-transaction?${params}`;
      let saveLocalResponse = await this.requestApi(url, auth, {}, "POST");
      saveLocalResponse.result.txID = txId;
      apiStatus = saveLocalResponse;
    }
    return apiStatus;
  },

  sellTransactionConversion: async function (auth, amount, token) {
    let apiStatus;
    // =========================================//
    // Get Tron Admin
    // =========================================//
    let url = this.localAuthApi + "/get-tron-admin";
    let adminResponse = await this.requestApi(url, auth);

    if (adminResponse?.result?.status === 200) {
      // =========================================//
      // Get JWT Token
      // =========================================//
      let jwtUrl = this.adminApi + "/jwt/index2.php";
      let body = {
        key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
        email: auth?.user.email,
        phone: auth?.user.phone,
      };
      let getToken = await this.requestApi(jwtUrl, auth, body, "POST");

      if (getToken?.result?.data?.token) {
        let rate =
          token === "trx"
            ? adminResponse.result?.data?.tron_rate
            : adminResponse.result?.data?.usdt_rate; ///get tron admin trx/usdt rate

        let Naira_conversion_amount = +amount * +rate; ///naira conversion using given formula

        // =========================================//
        // Get conversion price for fee api
        // =========================================//
        let url = this.tronApi + "/tron-wallet/convert?amount=1";
        let jwtAuth = { api_access_token: getToken?.result?.data?.token };
        let conversionResponse = await this.requestApi(url, jwtAuth);

        if (Object.keys(conversionResponse.result).length !== 0) {
          // =========================================//
          // Apply formula to get actual amount ui_amount * (conversion trx price)/(conversion usd price)
          // =========================================//
          let trxAmount =
            parseFloat(amount) *
            (parseFloat(conversionResponse.result?.tron) /
              parseFloat(conversionResponse.result?.usd));
          if (token === "usdt") {
            trxAmount = parseFloat(amount) * this.usdtPrice;
          }

          // =========================================//
          // Post api for transaction fee
          // =========================================//
          let feeBody = {
            toAddress: `${adminResponse.result?.data.tron_address}`, ///get tron admin address
            amount: `${(+trxAmount).toFixed(6)}`,
          };
          let url = this.tronApi + `/tron-wallet/${token}/transfer/fee`;
          let validResponse = await this.requestApi(
            url,
            jwtAuth,
            feeBody,
            "POST"
          );

          let tron_user_wallelt = await this.GetTronUserWallet(auth); ///get user wallet info

          let from_address = "";
          if (tron_user_wallelt.result?.data) {
            from_address = tron_user_wallelt.result?.data.tron_wallet_address;
          }

          if (validResponse.result.statusCode === undefined) {
            validResponse.result.conversion_rate =
              token === "usdt"
                ? this.usdtPrice
                : conversionResponse.result?.usd;
            validResponse.result.Naira_conversion_amount =
              Naira_conversion_amount;
            validResponse.result.Naira_sell_rate = rate; ///get tron admin rate
            validResponse.result.send_amount = (+trxAmount).toFixed(6);
            validResponse.result.ui_amount = amount;
            validResponse.result.to_address =
              adminResponse.result?.data.tron_address; ///get tron admin address
            validResponse.result.from_address = from_address; //current user wallet
            validResponse.result.txID =
              "d6a0184c52cc44ed6c529b0e9dff62630f47e5668334068b8a2d54b02ede1db1";
          }

          apiStatus = validResponse?.result;
        }
      }
      return apiStatus;
    }
  },

  sellTransaction: async function (auth, data, token) {
    console.log(data);
    // =========================================//
    // Get Jwt Tron token
    // =========================================//
    let apiStatus;
    let jwtUrl = this.adminApi + "/jwt/index2.php";
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };
    let getToken = await this.requestApi(jwtUrl, auth, body, "POST");

    if (getToken?.result?.data?.token) {
      let createUrl = this.tronApi + `/tron-wallet/${token}/transfer`;
      let amountBody = {
        toAddress: `${data.to_address}`,
        amount: `${+data.send_amount}`,
      };

      // =========================================//
      // Create tron transaction
      // =========================================//

      // Comment below code if create request close

      let jwtAuth = { api_access_token: getToken?.result?.data?.token };
      let sendResponse = await this.requestApi(createUrl, jwtAuth, amountBody, "POST");

      if (sendResponse.result?.status === 'completed' || sendResponse.result?.status === 'success') {
        let params = `tran_type=sell&tran_id=${sendResponse.result?.txID}&naira_sell_rate=${data.Naira_sell_rate
          }&amount=${data?.ui_amount}&from_address=${data?.from_address
          }&to_address=${data?.to_address}&naira_amount_sent=${data?.Naira_conversion_amount
          }&tron_type=${token.toUpperCase()}&conversion_rate=${data?.conversion_rate
          }&value_to_send=${data?.send_amount}`;
        let url = this.localAuthApi + `/save-usdt-transaction?${params}`;
        let saveLocalResponse = await this.requestApi(url, auth, {}, "POST");
        saveLocalResponse.result.txID = sendResponse.result?.txID;
        apiStatus = saveLocalResponse;
      }
      else {
        apiStatus = sendResponse.result;
      }

      // UnComment below code if create request close

      // let params = `tran_type=sell&tran_id=${data?.txID}&naira_sell_rate=${
      //   data.Naira_sell_rate
      // }&amount=${data?.ui_amount}&from_address=${
      //   data?.from_address
      // }&to_address=${data?.to_address}&naira_amount_sent=${
      //   data?.Naira_conversion_amount
      // }&tron_type=${token.toUpperCase()}&conversion_rate=${
      //   data?.conversion_rate
      // }&value_to_send=${data?.send_amount}`;
      // let url = this.localAuthApi + `/save-usdt-transaction?${params}`;
      // let saveLocalResponse = await this.requestApi(url, auth, {}, "POST");
      // saveLocalResponse.result.txID = data?.txID;
      // apiStatus = saveLocalResponse;
    }
    return apiStatus;
  },
};

export default Trc20Services;
