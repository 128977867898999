import React, { useEffect, useState } from "react";
import "./account.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import TextField from "@mui/material/TextField";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal edit-email">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Editmail = (detail) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [current_email, setCurrentEmail] = useState(detail.Editmail.email);
  const [new_email, setNewEmail] = useState("");
  const [confirm_email, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = JSON.parse(localStorage.getItem("user"));
  const [loader, setLoader] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const proceedEmailUpdate = async () => {
    setCurrentEmail(detail.Editmail.email);

    if (validatePassword()) {
      setLoader(true);
      var result = true;
      if (new_email != confirm_email) {
        toast("Confirm email not match");
        setLoader(false);
        return (result = false);
      }
      let item = { password };
      let item1 = { current_email, new_email, confirm_email };
      const apicall = await AfterLoginService.ValidatePassword(auth, item);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            UpdateEmail(item1);
          } else {
            toast(resp.message);
            setLoader(false);
          }
        });
      } else {
      }
    }
  };

  const UpdateEmail = async (item) => {
    const apicall = await AfterLoginService.UpdateUserEmail(auth, item);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status === 200) {
          toast(resp.message);

          setCurrentEmail("");
          setNewEmail("");
          setConfirmEmail("");
          setPassword("");

          setTimeout(() => {
            closeFirstModal();
            setLoader(false);
          }, 2000);
        } else {
          toast(resp.message);
          setLoader(false);
        }
      });
    } else {
    }
  };

  const validatePassword = () => {
    var result = true;
    if (current_email === "" || current_email === null) {
      toast("Please enter current email");
      result = false;
    }
    if (new_email === "" || new_email === null) {
      toast("Please enter new email");
      result = false;
    }
    if (confirm_email === "" || confirm_email === null) {
      toast("Please enter confirm email");
      result = false;
    }
    if (password === "" || password === null) {
      toast("Please enter password");
      result = false;
    }

    return result;
  };

  // mui css
  const textFieldStyles = {
    backgroundColor: "white",
    color: "black",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  return (
    <div>
      <button className="btn btn-edit" onClick={openFirstModal}>
        Edit
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content update-modal">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Update Email
                    {/* <span className="d-block"></span> */}
                    {/* <p className="update-p">
                      Lorem ipsum dolor sit amet consectetur.
                    </p> */}
                  </h5>
                  <button
                    type="button"
                    className=" email-update-close"
                    onClick={closeFirstModal}
                   
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body ">
                  <form _lpchecked="1" className="form-main">
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="text"
                        defaultValue={detail.Editmail.email}
                        onChange={(e) => setCurrentEmail(e.target.value)}
                        id="name"
                        label={
                          <span style={{ color: "gray" }}>Current Email</span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="text"
                        value={new_email}
                        onChange={(e) => setNewEmail(e.target.value)}
                        id="name"
                        label={<span style={{ color: "gray" }}>New Email</span>}
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="email"
                        name="confirm_email"
                        value={confirm_email}
                        onChange={(e) => setConfirmEmail(e.target.value)}
                        id="phone"
                        label={
                          <span style={{ color: "gray" }}>
                            Confirm New Email
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="phone"
                        label={
                          <span style={{ color: "gray" }}>
                            Quchange Password
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    className={`btn btn-update profile-btn ${loader ? "disabled" : ""}`}
                    onClick={() => proceedEmailUpdate()}
                    style={{ width: "100%" }}
                  >
                    {loader ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm "
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Loading...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Editmail;

// new changes

// import React, { useEffect, useState } from "react";
// import "./account.scss";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import TextField from "@mui/material/TextField";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal edit-email">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Editmail = (detail) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [current_email, setCurrentEmail] = useState(detail.Editmail.email);
//   const [new_email, setNewEmail] = useState("");
//   const [confirm_email, setConfirmEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [loader, setLoader] = useState(false);

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   const proceedEmailUpdate = async () => {
//     setCurrentEmail(detail.Editmail.email);

//     if (validatePassword()) {
//       setLoader(true);
//       var result = true;
//       if (new_email != confirm_email) {
//         toast("Confirm email not match");
//         setLoader(false);
//         return (result = false);
//       }
//       let item = { password };
//       let item1 = { current_email, new_email, confirm_email };
//       const apicall = await AfterLoginService.ValidatePassword(auth, item);
//       if (apicall.state === true) {
//         apicall.result.json().then((resp) => {
//           if (resp.status == 200) {
//             UpdateEmail(item1);
//           } else {
//             toast(resp.message);
//             setLoader(false);
//           }
//         });
//       } else {
//       }
//     }
//   };

//   const UpdateEmail = async (item) => {
//     const apicall = await AfterLoginService.UpdateUserEmail(auth, item);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status === 200) {
//           toast(resp.message);

//           setCurrentEmail("");
//           setNewEmail("");
//           setConfirmEmail("");
//           setPassword("");

//           setTimeout(() => {
//             closeFirstModal();
//             setLoader(false);
//           }, 2000);
//         } else {
//           toast(resp.message);
//           setLoader(false);
//         }
//       });
//     } else {
//     }
//   };

//   const validatePassword = () => {
//     var result = true;
//     if (current_email === "" || current_email === null) {
//       toast("Please enter current email");
//       result = false;
//     }
//     if (new_email === "" || new_email === null) {
//       toast("Please enter new email");
//       result = false;
//     }
//     if (confirm_email === "" || confirm_email === null) {
//       toast("Please enter confirm email");
//       result = false;
//     }
//     if (password === "" || password === null) {
//       toast("Please enter password");
//       result = false;
//     }

//     return result;
//   };

//   // mui css
//   const textFieldStyles = {
//     backgroundColor: "white",
//     color: "black",
//     width: "100%",
//     height: "auto",
//     borderRadius: "2px",
//   };

//   return (
//     <div>
//       <button className="btn btn-edit" onClick={openFirstModal}>
//         Edit
//       </button>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className="modal-dialog">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title">
//                     Update Email
//                     <span className="d-block"></span>
//                   </h5>
//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1">
//                     <div className="form-group w-75 mt-2">
//                       <TextField
//                         type="text"
//                         defaultValue={detail.Editmail.email}
//                         onChange={(e) => setCurrentEmail(e.target.value)}
//                         // className="form-control mb-3"
//                         id="name"
//                         label={
//                           <span style={{ color: "gray" }}>Current Email</span>
//                         }
//                         variant="filled"
//                         style={textFieldStyles}
//                       />
//                     </div>
//                     <div className="form-group w-75 mt-2">
//                       <TextField
//                         type="text"
//                         value={new_email}
//                         onChange={(e) => setNewEmail(e.target.value)}
//                         // className="form-control mb-3"
//                         id="name"
//                         label={<span style={{ color: "gray" }}>New Email</span>}
//                         variant="filled"
//                         style={textFieldStyles}
//                       />
//                     </div>
//                     <div className="form-group w-75 mt-2">
//                       <TextField
//                         type="email"
//                         name="confirm_email"
//                         value={confirm_email}
//                         onChange={(e) => setConfirmEmail(e.target.value)}
//                         // className="form-control mb-3"
//                         id="phone"
//                         label={
//                           <span style={{ color: "gray" }}>
//                             Confirm New Email
//                           </span>
//                         }
//                         variant="filled"
//                         style={textFieldStyles}
//                       />
//                     </div>
//                     <div className="form-group w-75 mt-2">
//                       <TextField
//                         type="password"
//                         name="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         // className="form-control mb-3"
//                         id="phone"
//                         label={
//                           <span style={{ color: "gray" }}>
//                             Quchange Password
//                           </span>
//                         }
//                         variant="filled"
//                         style={textFieldStyles}
//                       />
//                     </div>
//                   </form>
//                 </div>
//                 <div className="modal-footer">
//                   <button
//                     className={`btn btn-update  ${loader ? "disabled" : ""}`}
//                     onClick={() => proceedEmailUpdate()}
//                   >
//                     {loader ? (
//                       <>
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         Loading...
//                       </>
//                     ) : (
//                       "Proceed"
//                     )}
//                   </button>
//                   <ToastContainer />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Editmail;

// import React, { useEffect, useState } from 'react';
// import './account.scss';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import AfterLoginService from '../../Services/LoginSignService/AfterLoginService';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal edit-email">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Editmail = (detail) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [current_email,setCurrentEmail] = useState(detail.Editmail.email);
//   const [new_email,setNewEmail] = useState("");
//   const [confirm_email,setConfirmEmail] = useState("");
//   const [password,setPassword] = useState("");
//   const auth = JSON.parse(localStorage.getItem('user'));
//   const [loader,setLoader] = useState(false);

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   const proceedEmailUpdate = async () =>{
//       setCurrentEmail(detail.Editmail.email)

//       if(validatePassword()){
//         setLoader(true)
//             var result = true;
//             if(new_email != confirm_email)
//             {
//                 toast("Confirm email not match");
//                 setLoader(false)
//                 return result =false;
//             }
//             let item = {password}
//             let item1 = {current_email,new_email,confirm_email}
//             const apicall = await AfterLoginService.ValidatePassword(auth,item);
//             if(apicall.state === true){
//                 apicall.result.json().then((resp)=>{
//                   if(resp.status == 200){
//                       UpdateEmail(item1);
//                   }
//                   else{
//                     toast(resp.message);
//                     setLoader(false)
//                   }
//                 })
//             }
//             else{

//             }
//       }
//   }

//   const UpdateEmail = async (item) => {
//     const apicall = await AfterLoginService.UpdateUserEmail(auth,item)
//     if(apicall.state === true){
//       apicall.result.json().then((resp)=>{
//         if(resp.status === 200)
//         {
//           toast(resp.message);

//           setCurrentEmail("");
//           setNewEmail("");
//           setConfirmEmail("");
//           setPassword("");

//           setTimeout(() => {
//             closeFirstModal();
//             setLoader(false)
//           }, 2000);
//         }
//         else{
//           toast(resp.message);
//           setLoader(false)
//         }
//       })
//     }
//     else{

//     }
//   }

//   const validatePassword = () =>{

//     var result = true;
//     if(current_email === '' || current_email === null)
//     {
//         toast("Please enter current email");
//         result = false;
//     }
//     if(new_email === '' || new_email === null)
//     {
//         toast("Please enter new email");
//         result = false;
//     }
//     if(confirm_email === '' || confirm_email === null)
//     {
//         toast("Please enter confirm email");
//         result =false;
//     }
//     if(password === '' || password === null)
//     {
//         toast("Please enter password");
//         result =false;
//     }

//      return  result;

//   }

//   return (
//     <div>
//       <button className='btn btn-edit' onClick={openFirstModal}>Edit</button>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className='modal-dialog'>
//                 <div className="modal-content">
//                 <div className="modal-header">
//                     <h5 className="modal-title">
//                         Update Email
//                         <span className='d-block'>
//                         </span>
//                     </h5>
//                     <button type="button" className="close" onClick={closeFirstModal}>
//                        <img src='img/close-icon.png'/>
//                     </button>
//                 </div>
//                 <div className="modal-body">
//                     <form _lpchecked="1">
//                     <div className="form-group w-75">
//                         <input type="text" defaultValue={detail.Editmail.email}
//                         onChange={(e)=>setCurrentEmail(e.target.value)} className="form-control mb-3" id="name" placeholder="Current Email"/>
//                     </div>
//                     <div className="form-group w-75">
//                         <input type="text" value={new_email} onChange={(e)=>setNewEmail(e.target.value)} className="form-control mb-3" id="name" placeholder="New Email"/>
//                     </div>
//                     <div className="form-group w-75">
//                         <input type="email" name='confirm_email'
//                         value={confirm_email} onChange={(e)=>setConfirmEmail(e.target.value)}
//                         className="form-control mb-3" id="phone" placeholder="Confirm New Email"/>
//                     </div>
//                     <div className="form-group w-75">
//                         <input type="password" name='password'
//                         value={password} onChange={(e)=>setPassword(e.target.value)}
//                         className="form-control mb-3" id="phone" placeholder="Quchange Password"/>
//                     </div>

//                     </form>
//                 </div>
//                 <div className="modal-footer">
//                     <button className={`btn btn-update  ${
//                         loader ? "disabled" : ""
//                       }`}

//                      onClick={()=>proceedEmailUpdate()}>

//                       {loader ? (
//                           <>
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>{" "}
//                             Loading...
//                           </>
//                         ) : (
//                           "Proceed"
//                         )}

//                       </button>
//                     <ToastContainer/>
//                 </div>
//                 </div>
//             </div>
//             </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Editmail;
