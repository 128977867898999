import React, { useEffect, useState } from "react";
import "./account.scss";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal payment-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Paymentmodal = ({
  bank1,
  bankcode1,
  accountname1,
  accountnumber1,
  checkbank,
  paymentHandle
}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [selects, setSelects] = useState();
  const [acNumber, setAccountNumber] = useState("");
  const [acName, setAccountName] = useState('');
  const [password, setPassword] = useState("");
  const [bankCode, setBankCode] = useState("");
  const auth = JSON.parse(localStorage.getItem("user"));
  const [noRedbiller, setWithOutRedbillerBank] = useState([]);
  const [redbiller, setWithRedbillerBank] = useState([]);
  const [checkRed, SetCheckRed] = useState("");
  const [isReadonly, setIsReadonly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inpuloader, setInputLoading] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  useEffect(() => {
    getBankList();
  }, []);

  const getBankList = async () => {
    const apicall = await AfterLoginService.GetBankList(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status === 200) {
          setWithOutRedbillerBank(resp.data);
          setWithRedbillerBank(resp.data1);
          setSelects(bank1);
          setAccountNumber(accountnumber1);
          setAccountName(accountname1);
          setBankCode(bankcode1);
          // if(checkbank == 1){
          //   setIsReadonly(true)
          // }
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const handleV = (event) => {
    setAccountNumber(event.target.value);
    if (acNumber.length == 10 && checkRed == 1) {
      getAccountName();
      setInputLoading(true);
    }
  };

  const getAccountName = async () => {
    let bankcode = bankCode;
    let accountno = acNumber;
    const apicall = await AfterLoginService.GetRedbillerAccountName(
      bankcode,
      accountno
    );
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == "true") {
          toast(resp.message);
          setAccountName(resp.account_name);
          setInputLoading(false);
        } else {
          toast(resp.message);
          setAccountName('');
          setInputLoading(false);
        }
      });
    }
  };

  const UpdateBankDetails = async () => {
    if (validate()) {
      setLoading(true);
      let item = { password };
      let bank = selects;
      let bankcode = bankCode;
      let account_name = acName;
      let account_number = acNumber;
      let item1 = { bank, bankcode, account_name, account_number };
      const apicall = await AfterLoginService.ValidatePassword(auth, item);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status === 200) {
            updateBank(item1);
          } else {
            toast(resp.message);
            setLoading(false);
          }
        });
      } else {
      }
    }
  };

  const handleSelect = (event) => {
    setSelects(event.target.value);
    const checkRedbiller =
      event.target[event.target.selectedIndex].getAttribute("data-red");
    const bcode =
      event.target[event.target.selectedIndex].getAttribute("data-bankCode");
    setBankCode(bcode);
   
    SetCheckRed(checkRedbiller);
    if (checkRedbiller == 0) {
      setIsReadonly(false);
      setAccountNumber('');
      setAccountName('');
    } else {
      setIsReadonly(true);
      setAccountName('');
      setAccountNumber('');
    }
  };
  

  const handleChange = event => {
    setAccountName(event.target.value);
  };

  const handleChange1 = event => {
    setAccountNumber(event.target.value);
  };

  const validate = () => {
    var result = true;
    if (selects === "" || selects === null) {
      toast("The Bank field is required");
      result = false;
    }
    if (acNumber === "" || acNumber === null) {
      toast("The Account number field is required");
      result = false;
    }
    if (acName === "" || acName === null) {
      toast("The Account name field is required");
      result = false;
    }
    if (password === "" || password === null) {
      toast("The password field is required");
      result = false;
    }
    return result;
  };

  const updateBank = async (item1) => {
    const apicall = await AfterLoginService.UpdateBankDetails(auth, item1);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status === 200) {
          toast(resp.message);

          setTimeout(() => {
            closeFirstModal();
            setLoading(false);
            setPassword("");
            paymentHandle();
          }, 2000);
        } else {
          toast(resp.message);
          setLoading(false);
        }
      });
    } else {
    }
  };

  // mui css
  const textFieldStyles = {
    backgroundColor: "white",
    color: "black",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  return (
    <div>
      <button className="btn btn-edit-payment " onClick={openFirstModal} >
        Edit
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content payment-modal">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Payment Details
                    <span className="d-block"></span>
                    <p className="payment-p">
                    Select preferred payment method from the list
                    </p>
                  </h5>
                  <button
                    type="button"
                    className="paymentdetail-close"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop: "-15px",
                    }}
                  >
                    <img src="img/Close.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <form _lpchecked="1" className="payment-form">
                    <div className="form-group w-100 mt-3 ">
                      <select
                        class="form-select "
                        onChange={handleSelect}
                        aria-label="Default select example"
                      >
                        <option selected className="select-option" >Select Option</option>
                        {noRedbiller.map((item) => (
                          <option
                            value={item.bank_name}
                            data-bankCode={item.bank_code}
                            data-red={item.is_redbiller}
                            selected={bank1 == item.bank_name ? "selected" : ""}
                            
                          >
                            {item.bank_name}
                           
                          </option>
                        ))}
                        {redbiller.map((item) => (
                          <option
                            value={item.bank_name}
                            data-bankCode={item.bank_code}
                            data-red={item.is_redbiller}
                            selected={bank1 == item.bank_name ? "selected" : ""}
                          >
                            {item.bank_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group w-100 mt-3">
                      <TextField
                        type="text"
                        defaultValue={accountnumber1}
                        // onChange={(e) => setAccountNumber(e.target.value)}
                        onChange={handleChange1}
                        value={acNumber}
                        onKeyUp={handleV}
                        //  className="form-control mb-4"
                        id="name"
                        readOnly={inpuloader ? "readOnly" : ""}
                        label={
                          <span style={{ color: "gray" }}>
                            Acc Number /BTC Address/Email
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                      {inpuloader ? (
                        <>
                          <div className="text-white">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group w-100 mt-3">
                  
                      <TextField
                        type="text"

                        defaultValue={accountname1}
                        // onChange={(e) => setAccountName(e.target.value)}

                        onChange={handleChange}
                        value={acName}

                        inputProps={
                          { readOnly:isReadonly, }
                        }
                        id="name"
                        label={
                          <span style={{ color: "gray" }}>
                            Acc Name (add Bank Name if self-entry)
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-3">
                      <TextField
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        // className="form-control mb-4"
                        id="name"
                        label={
                          <span style={{ color: "gray" }}>Enter Password</span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    className={`btn btn-update w-100 ${
                      loading ? "disabled" : ""
                    }`}
                    onClick={() => UpdateBankDetails()}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm "
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Paymentmodal;

// company code

// import React, { useEffect, useState } from 'react';
// import './account.scss';
// import AfterLoginService from '../../Services/LoginSignService/AfterLoginService';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal payment-modal">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Paymentmodal = ({bank1,bankcode1,accountname1,accountnumber1,checkbank}) => {

//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [selects , setSelects] = useState();
//   const [acNumber , setAccountNumber] = useState("");
//   const [acName , setAccountName] = useState("");
//   const [password , setPassword] = useState("");
//   const [bankCode, setBankCode] = useState("");
//   const auth = JSON.parse(localStorage.getItem('user'));
//   const [noRedbiller , setWithOutRedbillerBank] = useState([]);
//   const [redbiller , setWithRedbillerBank] = useState([]);
//   const [checkRed,SetCheckRed] = useState("");
//   const [isReadonly, setIsReadonly] = useState(false);
//   const [loading,setLoading] = useState(false);
//   const [inpuloader,setInputLoading] = useState(false);

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   useEffect(()=>{
//         getBankList();
//   },[])

//   const getBankList = async () =>{
//     const apicall = await AfterLoginService.GetBankList(auth);
//     if(apicall.state === true){
//         apicall.result.json().then((resp)=>{

//           if(resp.status === 200){
//               setWithOutRedbillerBank(resp.data)
//               setWithRedbillerBank(resp.data1)

//                 setSelects(bank1);
//                 setAccountNumber(accountnumber1);
//                 setAccountName(accountname1);
//                 setBankCode(bankcode1);
//                 // if(checkbank == 1){
//                 //   setIsReadonly(true)
//                 // }

//           }
//           else{
//             console.log(resp.message);
//           }
//         })
//     }
//     else{

//     }
//   }

//   const handleSelect = (event) =>{
//     setSelects(event.target.value)
//     const checkRedbiller = event.target[event.target.selectedIndex].getAttribute('data-red')
//     const bcode = event.target[event.target.selectedIndex].getAttribute('data-bankCode')

//     setBankCode(bcode);
//     SetCheckRed(checkRedbiller)
//     if(checkRedbiller == 0){
//       setIsReadonly(false)
//     }
//     else{
//       setIsReadonly(true)
//       setAccountName("");
//       setAccountNumber("");
//     }
//   }

//   const handleV = (event) =>{
//     setAccountNumber(event.target.value)

//     if(acNumber.length == 10 && checkRed == 1)
//     {
//         getAccountName();
//         setInputLoading(true)
//     }
//   }

//     const getAccountName = async () =>{
//       let bankcode = bankCode;
//       let accountno = acNumber;
//       const apicall = await AfterLoginService.GetRedbillerAccountName(bankcode,accountno);
//       if(apicall.state === true)
//       {
//         apicall.result.json().then((resp)=>{
//           if(resp.status == 'true')
//           {
//             toast(resp.message);
//             setAccountName(resp.account_name)
//             setInputLoading(false)
//           }
//           else{
//             toast(resp.message);
//             setInputLoading(false)
//           }
//         })
//       }
//     }

//   const UpdateBankDetails = async () =>{

//     if(validate()){
//         setLoading(true)
//         let item = {password}
//         let bank = selects;
//         let bankcode = bankCode;
//         let account_name = acName;
//         let account_number = acNumber;
//         let item1 = {bank,bankcode,account_name,account_number}
//           const apicall = await AfterLoginService.ValidatePassword(auth,item);
//           if(apicall.state === true){
//             apicall.result.json().then((resp)=>{
//               if(resp.status === 200){
//                   updateBank(item1);
//               }
//               else{
//                 toast(resp.message);
//                 setLoading(false)
//               }
//             })
//           }
//           else{

//           }
//       }
//     }

//     const validate = ()=>{
//       var result = true;
//       if(selects === '' || selects === null)
//       {

//           toast('The Bank field is required');
//           result = false;
//       }
//       if(acNumber === '' || acNumber === null)
//       {

//           toast('The Account number field is required');
//           result = false;
//       }
//       if(acName === '' || acName === null)
//       {
//           toast('The Account name field is required');
//           result = false;
//       }
//       if(password === '' || password === null)
//       {

//           toast('The password field is required');
//           result = false;
//       }
//       return result;

//     }

//     const updateBank = async (item1) =>{
//       const apicall = await AfterLoginService.UpdateBankDetails(auth,item1);
//       if(apicall.state === true){
//           apicall.result.json().then((resp)=>{
//             if(resp.status === 200){
//               toast(resp.message);

//               setTimeout(() => {
//                 closeFirstModal();
//                 setLoading(false)
//                 setPassword("")
//               }, 2000);
//             }
//             else{
//               toast(resp.message);
//               setLoading(false)
//             }
//           })
//       }else
//       {

//       }
//     }

//   return (
//     <div>
//       <button className='btn btn-edit' onClick={openFirstModal}>Edit</button>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className='modal-dialog'>
//                 <div className="modal-content">
//                 <div className="modal-header">
//                     <h5 className="modal-title">
//                         Payment Details
//                         <span className='d-block'>
//                         </span>
//                     </h5>
//                     <button type="button" className="close" onClick={closeFirstModal}>
//                        <img src='img/close-icon.png'/>
//                     </button>
//                 </div>
//                 <div className="modal-body">
//                     <form _lpchecked="1">
//                       <div className="form-group w-75 mb-3">

//                         <select class="form-select" onChange={handleSelect}  aria-label="Default select example">
//                           <option selected>Select Option</option>
//                           {
//                             noRedbiller.map((item)=>
//                             <option  value={item.bank_name} data-bankCode={item.bank_code} data-red={item.is_redbiller} selected={bank1 == item.bank_name ? 'selected':''}>{item.bank_name}</option>
//                             )
//                           }
//                           {
//                             redbiller.map((item)=>
//                             <option value={item.bank_name} data-bankCode={item.bank_code} data-red={item.is_redbiller} selected={bank1 == item.bank_name ? 'selected':''}>{item.bank_name}</option>
//                             )
//                           }

//                         </select>
//                       </div>

//                       <div className="form-group w-75">
//                         <input type="text" defaultValue={accountnumber1}
//                          onChange={(e)=>setAccountNumber(e.target.value)}
//                          onKeyUp={handleV}
//                          className="form-control mb-4"
//                          id="name"
//                          placeholder="Acc Number /BTC Address/Email"
//                          readOnly={inpuloader?"readOnly":""}
//                          />
//                         {
//                           inpuloader ? (
//                             <>
//                               <div className='text-white'>
//                               <span
//                                 className="spinner-border spinner-border-sm"
//                                 role="status"
//                                 aria-hidden="true"
//                               ></span>
//                               Loading...
//                               </div>
//                             </>
//                           ):""
//                         }
//                       </div>
//                       <div className="form-group w-75">
//                         <input type="text" defaultValue={accountname1} onChange={(e)=>setAccountName(e.target.value)} readOnly={isReadonly} className="form-control mb-4" id="name" placeholder="Acc Name (add Bank Name if self-entry)"/>
//                       </div>
//                       <div className="form-group w-75">
//                         <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control mb-4" id="name" placeholder="Enter Password"/>
//                       </div>

//                     </form>
//                 </div>
//                 <div className="modal-footer">
//                     <button className={`btn btn-update ${loading ? "disabled":""}`} onClick={()=>UpdateBankDetails()}>

//                        {
//                         loading ? (
//                           <>
//                            <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>
//                             Loading...
//                           </>
//                         ):(
//                           "Update"
//                         )
//                       }

//                     </button>
//                     <ToastContainer/>
//                 </div>
//                 </div>
//             </div>
//             </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Paymentmodal;
