import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Sellbtc from "./sellbtc";
import TextField from "@mui/material/TextField";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sellmodal = ({btcWalletDetails,btcRate}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [requiredError, setRequiredError] = useState(false);
  const [mamount,setMAm] = useState("0");

  const handleAmountChange = (e) => {
    setRequiredError(false);
    const inputAmount = e.target.value;
    const numericInput = inputAmount.replace(/[^0-9.]/g, "");
    setAmount(numericInput);
    setMAm(numericInput*btcRate);
  };

  const openFirstModal = () => setIsFirstModalOpen(true);
  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
    setAmount("");
    setMAm("0");
  };

  // mui css
  const textFieldStyles = {
    background: "#6C6C6C ",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };
  return (
    <div>
      <Avatar className="bg-receive" alt="" onClick={openFirstModal}>
        <img src="img/sell1.svg" />
      </Avatar>
      <p className="text-white small text-center " style={{ fontSize: "18px" }}>
        Sell
      </p>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content sell-modal-btc">
                <div className="modal-header">
                  <div className="row">
                    <div className="col-12 col-md-12 col-sm-12 col-12">
                      <h5 className="modal-title" style={{ textAlign: "left" }}>
                        Sell BTC
                      </h5>
                      <p
                        style={{
                          color: "#7D7DA1",
                          // marginLeft: "55px",
                          fontSize: "11px",
                        }}
                      >
                        Enter amount in $ to sell your bitcoins
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="close-sell-btc"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body" style={{ marginTop: "-25px" }}>
                  <form _lpchecked="1">
                    <div className="form-group w-100  mb-3">
                      <TextField
                        type="text"
                        // className="form-control"
                        id="usdAmount"
                        value={amount}
                        onChange={handleAmountChange}
                        label={
                          <span style={{ color: "#B8BCC3" }}>
                            {" "}
                            I want to sell{" "}
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                      {requiredError && (
                        <p className="text-danger text-xs">Field is Required</p>
                      )}{" "}
                      <div className=" w-75 mt-2">
                        <span style={{ fontSize: "14px", color: "#7D7DA1" }}>
                          Available
                        </span>
                        <sapn
                          style={{
                            color: "#FFFFFF",
                            fontSize: "14px",
                            padding: "5px",
                          }}
                        >
                          {btcWalletDetails.data.blockio_balance}<span style={{ fontSize: "11px" }}>BTC</span>
                        </sapn>
                      </div>
                    </div>

                    <div className="form-group w-100  mb-3">
                      <img src="/img/tipbtc.png" width="100%" />
                    </div>

                    <div className=" w-100 " style={{ marginTop: "-10px" }}>
                      <span style={{ fontSize: "14px", color: "#7D7DA1" }}>
                        You Will receive
                      </span>
                      <p style={{ color: "#FFFFFF", fontSize: "15px" }}>
                      ₦ {mamount}
                      </p>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <div style={{ width: "100%" }}>
                    <Sellbtc
                      amount={amount}
                      closePreviousModal={closeFirstModal}
                      setRequiredError={setRequiredError}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sellmodal;

// company code

// import React, { useState } from "react";
// import Avatar from "@mui/material/Avatar";
// import Sellbtc from "./sellbtc";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-modal">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Sellmodal = () => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [amount, setAmount] = useState("");
//   const [requiredError, setRequiredError] = useState(false);

//   const handleAmountChange = (e) => {
//     setRequiredError(false)
//     const inputAmount = e.target.value;
//     const numericInput = inputAmount.replace(/[^0-9.]/g, "");
//     setAmount(numericInput);
//   };

//   const openFirstModal = () => setIsFirstModalOpen(true);
//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//     setAmount("");
//   };
//   return (
//     <div>
//       <Avatar className="bg-receive" alt="" onClick={openFirstModal}>
//         <img src="img/sell.png" />
//       </Avatar>
//       <p className="text-white small text-center">Sell</p>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className="modal-dialog">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title text-center">
//                     Sell BTC To Quchange
//                     <span className="d-block text-success h5">
//                       $1 BTC = ₦880
//                     </span>
//                   </h5>
//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1">
//                     <div className="form-group w-75  mb-3">
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="usdAmount"
//                         placeholder="Enter Amount in USD"
//                         value={amount}
//                         onChange={handleAmountChange}
//                       />
//                       {requiredError && (
//                         <p className="text-danger text-xs">Field is Required</p>
//                       )}{" "}
//                     </div>
//                   </form>
//                 </div>
//                 <div className="modal-footer">
//                   <Sellbtc
//                     amount={amount}
//                     closePreviousModal={closeFirstModal}
//                     setRequiredError={setRequiredError}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Sellmodal;
