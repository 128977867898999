import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import ViewRedeemModel from "./viewRedeemModel";

const GiftCardTransactionDetails = ({isOpenGTranD,setIsOpenGTranD,gdetails,setIsOpenGTran}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isOpenViewRedeem,setIsOpenViewRedeem] = useState(false);
  const goBack = () => {
    setIsOpenGTranD(false)
    setIsOpenGTran(true)
  };
  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "SUCCESSFUL":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }
  return (
    <div>
                    {isOpenGTranD &&(
                        <>
                        <div className="row pt-4">
                            <div className="back-icon-main d-block">
                                <img src="../img/CaretLeft1.svg" onClick={goBack} />
                            </div>
                            <div className="col-md-12">
                                <div>
                                    <h3 className="heading-transaction mb-0">Transaction Details</h3>
                                    
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="det-1">
                                    <p className={`complete-btn d-inline ${getButtonColor(
                                        gdetails?.status
                                                )}`}>
                                        {gdetails.status}
                                    </p>
                                    <p style={{color:"#939394",marginTop:"5px"}}>Giftcard Purchases Details</p>
                                </div>
                                <table className="table confT">
                                    <tbody>
                                        
                                        <tr>
                                            <td>Transaction Id</td>
                                            <td className="tright">#{gdetails?gdetails.transactionId:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Product Name</td>
                                            <td className="tright">{gdetails?gdetails.productName:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Recipient</td>
                                            <td className="tright">{gdetails?gdetails.recipientEmail:''} </td>
                                        </tr>
                                        <tr>
                                            <td>Sender</td>
                                            <td className="tright">{gdetails?gdetails.recipientName:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Date & Time</td>
                                            <td className="tright">{gdetails?gdetails.created_at1:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Gift Card Amount</td>
                                            <td className="tright">NGN {gdetails?gdetails.fxValue:''}
                                                &nbsp;
                                               ({gdetails?gdetails.currencyCode:''} {gdetails?gdetails.unitPrice:''})</td>
                                        </tr>
                                        <tr>
                                            <td>Quantity</td>
                                            <td className="tright">{gdetails?gdetails.quantity:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Fee</td>
                                            <td className="tright">NGN {gdetails?gdetails.totalFee:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Deducted Amount</td>
                                            <td className="tright">NGN {gdetails?gdetails.ngnValue:''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6"></div>
                            <ViewRedeemModel setIsOpenViewRedeem={setIsOpenViewRedeem} isOpenViewRedeem={isOpenViewRedeem} gdetails={gdetails}/>
                           
                        </div>
                        </>
                    )}
    </div>
  );
};
export default GiftCardTransactionDetails;