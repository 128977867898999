
import React, { useEffect, useState } from "react";
import "./walletdetails.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Requestmodal from "./requestmodal";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Walletdetails() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [balance, setBalance] = useState("");
  const navigate = useNavigate();
  const [walletHis, setHistory] = useState([]);
  const [walletCurrencyList, setWalletCurrencyList] = useState([]);
  const [gettingWallet, setGettingWallet] = useState(false);
  const [lowMsg, setLowMsg] = useState(false);
  const [value, setValue] = useState();
  const [symbol, setSymbol] = useState();

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      navigate("/");
    }
    setGettingWallet(true);
    getWalletBalance();
    getWalletHistory();
    GetWalletCurrency();
  }, []);

  const handleData = () => {
    getWalletBalance();
    getWalletHistory();
  };
  const getWalletBalance = async () => {
    if (auth) {
      const apicall = await AfterLoginService.GetWalletBalance(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setBalance(resp);
            setLowMsg(true);
            setGettingWallet(false);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  };

  const getWalletHistory = async () => {
    if (auth) {
      const apicall = await AfterLoginService.GetWalletHistory(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setHistory(resp.data);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  };

  const GetWalletCurrency = async () => {
    if (auth) {
      const walletCurrencyList = await AfterLoginService.GetWalletCurrency(auth);
      if (walletCurrencyList.state === true) {
        walletCurrencyList.result.json().then((resp) => {
          if (resp.status == 200) {
            setWalletCurrencyList(resp.data);
            console.log(resp.data)
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    setSymbol(e.target[e.target.selectedIndex].getAttribute("data-symbol"));
 };
  // for status button
  function getButtonColor(status) {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "button-pending";
      case "DECLINED":
        return "button-declined";
      case "APPROVED":
        return "button-approved";
      case "PROCESSING":
        return "button-processing";
      case "COMPLETED":
        return "button-completed";
      default:
        return "";
    }
  }

  const goBack = () => navigate(-1);

  return (
    <div className="container-fluid position-relative d-flex p-0 wallet-container">
      <Header />
      <div className="content">
        <Subheader />
        <div className="container-fluid pt-4 px-4 wallet-table ">
          <div className="row">
            {gettingWallet ? (
              <>
                <div className="col-md-12 text-center m-4">
                  <span
                    className="text-white spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="text-white">Loading...</span>
                </div>
              </>
            ) : (
              <div className="col-md-12 col-lg-12">
                <div className="back-icon-main">
                  {/* <a href="#"> */}
                  <img src="/img/back.svg" onClick={goBack} />
                  {/* </a> */}
                </div>

                <h3 className="textWallet">Wallet</h3>
                <p className="textLorem">Balance available for withdrawal</p>

                <div className="row wallet-sc">
                  <div className="col-lg-9 col-md-12 col-sm-12 sm-12 ">
                    <div className="col-md-12 gx-5 wallet-header  ">
                      <div className="row ">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <p className="mt-2 d-flex align-items-center overall ml-4 mt-3">
                            <img src="img/overallicon.svg" />
                            <span className="ms-2 "> Overall Balance</span>
                          </p>
                          <h6
                            className="h1 dolor-wallet-heading ml-4 wallet-1"
                            style={{ fontSize: "40px" }}
                            >
                            <span className="dollor ">{symbol?symbol:'₦'}</span>
                            {value?(balance.wallet/value).toFixed(2):balance.wallet}
                           
                          </h6>
                          <select value={value} onChange={handleChange} className="chooise_type">
                          {walletCurrencyList.length > 0 ? (
                              walletCurrencyList.map((data) => (
                              <>
                                <option data-symbol={data.image} value={data.exchange_rate}>{data.currency}</option>
                              </>
                              ))
                            ) : (
                              <>
                             
                              </>
                            )}
                           
                          </select>
                          <h6
                            className="h1 dolor-wallet-heading ml-4  wallet-2"
                            style={{ fontSize: "20px" }}
                            >
                            <span className="dollor1 ">₦</span>
                            {balance.wallet}
                          </h6>
                        </div>
                      </div>

                      <div className="row mt-5 mx-5 ">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="text-left "></div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="walllet-btn-container ">
                            <div className="btn-container">
                              <Requestmodal
                                balance={balance}
                                handleData={handleData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3"> </div>
                </div>
                <div className="row ">
                  <div className="col-lg-5 col-md-7 col-sm-12 col-12">
                    {lowMsg ? (
                      <>
                        {balance.wallet < 1000 ? (
                          <div className="pops-msg">
                            <p>
                              <img
                                src="/img/info-circle.svg"
                                className="cirle-info"
                              />
                              <span className=" msg-p">
                                Your balance is too low to initiate a withdrawal
                                process
                              </span>
                              {/* </button> */}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-lg-7 col-md-5 col-sm-12 col-12"></div>
                </div>

                <div className="container-fluid pt-4  wallet-table ">
                  <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                      <div className="wallet-scroll">
                        <table class="table table-dark table-border  ">
                          <thead>
                            <tr className="fs-13">
                              <th>DETAILS</th>
                              <th className="d-none d-sm-table-cell">TYPE</th>
                              <th className="wallet-status-mobile">STATUS</th>
                              <th className="d-none d-sm-table-cell">DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {walletHis.length > 0 ? (
                              walletHis.map((data) => (
                                <tr>
                                  <td className="d-flex align-items-center">
                                    <img src="/img/dashbordlist.svg" alt="" />
                                    <p className="fs-12 ms-2">
                                      <span className="d-block fs-10 wallet-list-header">
                                        Wallet withdrawal of
                                        <span className="fs-13 wallet-amount">
                                          {" "}
                                          ₦{data.amount}
                                        </span>
                                      </span>
                                      <p className="fs-12 wallet-mobile-date">
                                        {data.timestamp}
                                      </p>
                                    </p>
                                  </td>
                                  <td className=" Wallet-withdrawal">
                                    <p className="fs-12">Wallet withdrawal</p>
                                  </td>
                                  <td className="cashback-wallet-btn">
                                    <a
                                      className={`complete-btn ${getButtonColor(
                                        data.status
                                      )}`}
                                    >
                                      {/* <span className="dot">.</span>{" "} */}
                                      {data.status.toUpperCase()}
                                    </a>
                                  </td>

                                  <td className="Wallet-date ">
                                    <p className="fs-12">{data.timestamp}</p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={4}>
                                    {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                                    <div className="no-transaction">
                                      <img
                                        src="img/EmptyState.svg"
                                        alt=""
                                        style={{
                                          margin: "auto",
                                          marginTop: "60px",
                                          height: "120px",
                                        }}
                                      />
                                      <h4 className="not-transction-wallat">
                                        Transaction
                                      </h4>

                                      <h5 className="not-transction-wallet-p text-center">
                                        You don't have any pending or completed
                                        transction.
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <div></div>
                        </table>
                        <div className="pagination d-none">
                          <div>
                            <a href="">
                              <i class="bi bi-chevron-left"></i>
                            </a>
                            <ul>
                              <li>1</li>
                              <li>2</li>
                              <li>3</li>
                              <li>4</li>
                              <li>10</li>
                            </ul>
                            <a className="next-btn" href="">
                              <i class="bi bi-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 "></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Walletdetails;

