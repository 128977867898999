import { CircularProgress, Divider } from "@mui/material";
import React from "react";

function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  text,
  loading = false,
  error = null,
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-70"></div>

        <div className="modal-container bg-[#070809] text-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content text-left">
            <div className="modal-header">
              <h3 className="modal-title text-lg font-semibold p-2 ml-4">
                QXchange
              </h3>
            </div>
            <Divider style={{ backgroundColor: "white" }} />
            <div className="modal-body p-4">
              <p>{text}</p>
              <div className="">
                <span className="font-medium">Note:</span>
                <p>Estimated Arrival Time is 10 minutes.</p>
                <p>Standard network fee applies.</p>
              </div>
            </div>
            <Divider style={{ backgroundColor: "white" }} />
            <div className="flex justify-end p-2">
              <button
                onClick={onClose}
                className="bg-gray-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onConfirm();
                }}
                className={`bg-[#331377] text-white font-bold py-2 px-4 rounded`}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <CircularProgress
                      color="secondary"
                      size={20}
                      className="mr-2"
                    />
                    <div>Loading...</div>
                  </div>
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
            <Divider />
            {error && <p className="px-4 text-danger">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
