import React, { useState, useEffect } from "react";
import "./billpurchase.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import { useNavigate } from "react-router";
import GiftCard from "./seeRedeem";
import Chechout from "./checkout";
import TextField from "@mui/material/TextField";

function GiftCardDetails({showlist, setShowList ,pname,isOpen,pimg,brand_Id,productId,setProductId,denominationType,productInfo,virtualAccountInfo,fxRate,setFxRate}) {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [quantity,setQuantity] = useState(1);
  const [from,setFrom] = useState("");
  const [to,setTo] = useState("");
  const [unitPrice,setUnitPrice] = useState("");
  const [unitPriceDisplay,setUnitPriceDisplay] = useState("");
  const [unitPriceDisplayCurrency,setUnitPriceDisplayCurrency] = useState("");
  const [checkBoxChecked,setCheckBoxChecked] = useState(false);
  const [rememberUser, setRememberUser] = useState(false)
  const [minEnterValue, setMinEnterValue] = useState(false)
  const [maxEnterValue, setMaxEnterValue] = useState(false)
  const [selectedButton, setSelectedButton] = useState("giftcard");

  const navigate = useNavigate();
  const goBack = () => {
    setShowList(false)
    isOpen(true)
    setRememberUser(false)
    setCheckBoxChecked(false)
    setUnitPriceDisplay("")
    setUnitPrice("")
    setTo("")
    setFrom("")
    setQuantity(1)
    setUnitPriceDisplayCurrency("")
    setSelectedButton('-1')
    setProductId("")
    setFxRate("")
  };
 
  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
  }, []);

 const choiseAmt = (e)=>{
    setUnitPrice(e.target.value)
    const element = e.target;
    const unitPriceDisplay =
    element.getAttribute("data-display")
    setUnitPriceDisplay(unitPriceDisplay)

    const unitPriceDisplayCurrency =
    element.getAttribute("data-display_currency")
    setUnitPriceDisplayCurrency(unitPriceDisplayCurrency) 
 }

 const incrementCounter = () => {
    setQuantity(quantity + 1);
};
       
const decrementCounter = () => {
 if (quantity !== 1) {
    setQuantity(quantity - 1);
  }
};

const handleAmt = (e)=>{
  const reg = /^[.0-9\b]+$/
  let preval=e.target.value
  if (e.target.value === '' || reg.test(e.target.value)){
    setUnitPriceDisplay(e.target.value);
    setUnitPriceDisplayCurrency(productInfo.recipientCurrencyCode);
    // giftCardFxRate(e.target.value,productInfo.recipientCurrencyCode);
    setMinEnterValue(productInfo.minRecipientDenomination);
    setMaxEnterValue(productInfo.maxRecipientDenomination);
    setUnitPrice(fxRate*e.target.value)
    return true
  }else{
    e.target.value = preval.substring(0,(preval.length-1))
  }
   
  
}

// const giftCardFxRate = async (amount,currencyCode) => {
//   const item = {currencyCode:currencyCode,amount:amount}
//   const apicall = await AfterLoginService.giftCardFxRate(auth,item);
//   if (apicall.state === true) {
//     apicall.result.json().then((resp) => {
//       if (resp.status == 200) {
//         setUnitPrice(resp.data?resp.data['senderAmount']:'')
//       } else {
//         console.log(resp.message);
//       }
//     });
//   } else {
//   }
// };

const getButtonStyle = (buttonName) => {
  return {
    marginRight: "10px",
    backgroundColor: selectedButton === buttonName ? " #3c0d68" : "",
  };
};

const handleButtonClick = (buttonName) => {
  setSelectedButton(buttonName);
  
};
  return (
    <>
        {
            showlist && (
                <>
                    <div className="container-fluid pt-4 px-4 wallet-table dpDesign1  mobile-1">
                        <div className="row">
                          <div className="back-icon-main d-block">
                            <img src="/img/CaretLeft1.svg" onClick={goBack} />
                          </div>
                          <div className="row mt-2 ">
                              <div className="col-md-12">
                                  <div className="row">
                                        <div className="col-md-4 col-12 col-sm-12">
                                            <div className="">
                                              <div className="pm-1">
                                                <img src={pimg}/>
                                              </div>
                                              {/* <span className="see-1" onClick={()=>seeRedeem()}>See Redeem Instructions</span> */}
                                              <GiftCard brandId={brand_Id}/>
                                              
                                            </div>
                                        </div>
                                        <div className="col-md-8 gitf_details">
                                            <h3 className="heading-transaction mb-0">{pname}</h3>
                                            <div className="row mt-4">
                                              <div className="col-md-12">
                                                {productInfo.denominationType == 'RANGE'?(<>
                                                    <span>Select an amount between {productInfo.recipientCurrencyCode} {productInfo.minRecipientDenomination} - {productInfo.recipientCurrencyCode} {productInfo.maxRecipientDenomination}</span>
                                                  </>):<></>}
                                              </div>
                                            </div>
                                            <div className="row mb-2 mt-4">
                                                
                                                <div className="col-md-2">
                                                  <span>Amount</span>
                                                </div>
                                                {
                                                  productInfo.denominationType == 'RANGE'?(
                                                    <>
                                                    <div className="col-md-2">
                                                      <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="amount"  value={unitPriceDisplay}
                                                      onChange={handleAmt}/>
                                                    </div>
                                                    </>
                                                  ):<>
                                                   <div className="col-md-8">
                                                      <div className="amt_choise">
                                                      {productInfo.fixedRecipientDenominations.map((item,index)=>(
                                                        <>
                                                      
                                                      
                                                          <div className="mr-10">
                                                              <label >
                                                            <div className="am-bx" style={getButtonStyle(index)} onClick={() => handleButtonClick(index)}>
                                                              
                                                              <input type="radio" value={productInfo.fixedSenderDenominations[index]} 
                                                              data-display={item}
                                                              data-display_currency={productInfo.recipientCurrencyCode}
                                                              name="amt"
                                                            
                                                              onChange={choiseAmt}
                                                              id={`lb`+index}
                                                              style={{ visibility: 'hidden'}}
                                                              />&nbsp;{productInfo.recipientCurrencyCode} &nbsp;{item}
                                                            
                                                            </div>
                                                              </label>
                                                          </div>
                                                        
                                                        
                                                        </>
                                                      ))}
                                                    </div>
                                                    </div>
                                                  </>
                                                }
                                                
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-2">
                                                  <span>To</span>
                                                </div>
                                                <div className="col-md-5">
                                                    <input type="email" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="email@gmail.com"  value={to}
                                                    onChange={(e) => setTo(e.target.value)} />
                                                  
                                                </div>
                                              
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-2">
                                                  <span>From</span>
                                                </div>
                                                <div className="col-md-5">
                                                    <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="your name" value={from}
                                                    onChange={(e) => setFrom(e.target.value)} />
                                                </div>
                                              
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-2">
                                                  <span>Quantity</span>
                                                </div>
                                                <div className="col-md-2 col-4">
                                                  <div className="qu-1">
                                                      <div
                                                        className="text-white"
                                                        onClick={decrementCounter}
                                                      >
                                                        <i class="bi bi-dash-lg text-white"></i>
                                                      </div>
                                                      <p className="mb-0 !text-base text-white">
                                                      {quantity}
                                                      </p>
                                                      <div
                                                        className="text-white"
                                                        onClick={incrementCounter}
                                                      >
                                                        <i class="bi bi-plus-lg text-white"></i>
                                                      </div>
                                                  </div>
                                                </div>  
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-2">
                                                 
                                                </div>
                                                <div className="col-md-10">
                                                  
                                                <input type="checkbox"
                                                 checked={rememberUser}
                                                 onChange={() => {
                                                    setRememberUser(!rememberUser)
                                                    if(rememberUser){
                                                      setCheckBoxChecked(false)
                                                    }else{
                                                      setCheckBoxChecked(true)
                                                    }
                                                    
                                                 }}
                                                className="checkbox-size"/>
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                  <span>By clicking the 'Buy Now' button , you agree to our <strong>Terms and Conditions,</strong>including our <strong>Cancellation Policy</strong></span>
                                                </div>  
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-md-2">
                                                </div>
                                                <div className="col-md-3">
                                                  <Chechout productInfo={productInfo} pimg={pimg} pname={pname} quantity={quantity} from={from} to={to} unitPrice={unitPrice} unitPriceDisplay={unitPriceDisplay} checkBoxChecked={checkBoxChecked} unitPriceDisplayCurrency={unitPriceDisplayCurrency} virtualAccountInfo={virtualAccountInfo} setShowList={setShowList} isOpen={isOpen} goBack={goBack} minEnterValue={minEnterValue} maxEnterValue={maxEnterValue}/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                  </div>
                              </div>
                            
                          </div>
                        
                        </div>
                    </div> 
                </>
            )
        }
       
    </>
  );
}

export default GiftCardDetails;


