import React, { useState } from "react";
import "./mobilesidebar.scss";
import { Link } from "react-router-dom";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal mobile-sidebar">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Mobilesidebar = () => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
      <button
        className="sidebar-toggler flex-shrink-0"
        onClick={openFirstModal}
      >
        {/* <i class="bi bi-list text-white"></i> */}
        <img src="/img/menu1.svg" />
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal" onClick={closeFirstModal}>
            <div className="modal-dialog">
              <div className="modal-content ">
                <div className="modal-header">
                  <h5 className="modal-title">
                    <div className="mobile-logo ">
                      {/* <img src="img/signup.png" alt="Dashboard Logo" /> */}
                      <img src="img/logo.svg" alt="Dashboard Logo" />
                    </div>
                  </h5>
                  <button
                    type="button"
                    className="close-sidebar-btn"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="">
                    <li>
                      <a className="flex flex-row items-center" href="/Dashboard">
                        <img src="img/shape.svg" />
                        <span className="ms-2">
                          <Link to="/Dashboard"> Dashboard</Link>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="flex flex-row items-center" href="/Giftcard">
                        <img src="img/giftcard.svg" />
                        <span className="ms-2">
                          <Link to="/Giftcard">Sell Gift Card</Link>
                        </span>
                      </a>
                    </li>
                    <li>
                        <a className="flex flex-row items-center" href="/Cryptosell">
                          <img src="img/cashbackicon.svg" />
                          <span className="ms-2">
                            <Link to="/Cryptosell">Crypto Quick Sell</Link>
                          </span>
                        </a>
                      </li>
                    <li>
                      <a className="flex flex-row items-center" href="/Transcation">
                        <img src="img/transctionicon.svg" />
                        <span className="ms-2">
                          <Link to="/Transcation">Transaction</Link>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="flex flex-row items-center" href="/Walletdetails">
                        <img src="img/wallet.svg" />
                        <span className="ms-2">
                          <Link to="/Walletdetails"> Wallet</Link>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="flex flex-row items-center" href="/Cashbackwallet">
                        <img src="img/cashbackicon.svg" />
                        <span className="ms-2">
                          <Link to="/Cashbackwallet">Cashback Wallet</Link>
                        </span>
                      </a>
                    </li>
                  
                      {/* <li>
                        <a className="flex flex-row items-center" href="/instant">
                          <img src="img/crypto.svg" />
                          <span className="ms-2">
                            <Link to="/instant">
                            BTC ( QUICK SELL )
                            </Link>
                          </span>
                        </a>

                      </li> */}

                    <li>
                      {/* <li>
                        <a className="flex flex-row items-center" href="/Logindetails">
                          <img src="img/crypto.svg" />
                          <span className="ms-2">
                            <Link to="/Logindetails">
                              BTC Wallet / Sell BTC
                            </Link>
                          </span>
                        </a>

                      </li> */}
                     
                      <li className="d-none">
                        <a className="flex flex-row items-center" href="/Trc">
                          <img src="img/crypto.svg" />
                          <span className="ms-2">
                            <Link to="/Trc">TRC20(USDT & TRON)</Link>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="flex flex-row items-center" href="/Referral">
                          <img src="img/wallet.svg" />
                          <span className="ms-2">
                            <Link to="/Referral">Referral Wallet</Link>
                          </span>
                        </a>
                      </li>

                      <a className="flex flex-row items-center" href="/Qxchange">
                        <img src="img/crypto.svg" />
                        <span className="ms-2">
                          <Link to="/Qxchange">Swap Crypto</Link>
                        </span>
                      </a>
                    </li>

                    <li>
                      <a className="flex flex-row items-center" href="/Account">
                        <img src="img/users.svg" />
                        <span className="ms-2">
                          <Link to="/Account">Account</Link>
                        </span>
                      </a>
                    </li>
                    {/* <li>
                      <a className="flex flex-row items-center" href="/Giftguide">
                        <img src="img/giftcard.svg" />
                        <span className="ms-2">
                          <Link to="/Giftguide">Gift Card Sell Guide</Link>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="flex flex-row items-center" href="/Bitcoinguide">
                        <img src="img/giftcard.svg" />
                        <span className="ms-2">
                          <Link to="/Bitcoinguide">Bitcoin Sell Guide</Link>
                        </span>
                      </a>
                    </li> */}
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Mobilesidebar;
