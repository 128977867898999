/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Giftvaluemodal from "./giftvaluemodal";
import Giftconfirmmodal from "./giftconfirmmodal";
import "../scss/giftcardmodal.scss";
import { toast } from "react-toastify";
import GiftCardService from "../../Services/LoginSignService/GiftCardService";
import Select, { components } from "react-select";
import { MdClose } from "react-icons/md";
import useSelect from "../../Hook/user-select";
import { RemoveIcon } from "../../Svg/AllSvg";
import TextField from "@mui/material/TextField";

const MultiValue = (props) => (
  <components.MultiValue {...props}>{props.data.code}</components.MultiValue>
);

const Giftcardmodal = ({ gitCardCurrency, open, isOpen }) => {
  const [proceedModal, setProceedModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [giftCartTypeList, setGiftCardTypeList] = useState([]);
  const [giftCardId, setgiftCardId] = useState("");
  const [currencyOption, setOurrencyOption] = useState(null);
  const [typeOption, setTypeOption] = useState(null);
  const [giftCardValue, setGiftCardValue] = useState([]);
  const [currencyInfo, setCurrencyInfo] = useState({});
  const [typeInfo, setTypeInfo] = useState({});
  const [currencyTypeInfo, setCurrencyTypeInfo] = useState({});
  const [inputValues, setInputValues] = useState([""]);
  const [valueModal, setValueModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentVal, setCurrencyVal] = useState("");
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  let styles = useSelect();

  const handleTotal = () => {
    let count = 0;
    if (giftCardValue.length > 0) {
      for (let i = 0; i < giftCardValue.length; i++) {
        const el = giftCardValue[i];
        count = count + el?.price * el?.qty;
      }
      setTotal(count);
    }
  };

  const onImageChange = (e) => {
    setImages([...images, ...e.target.files]);
  };

  const deleteFile = (e) => {
    const s = images.filter((item, index) => index !== e);
    setImages(s);
  };

  const handlesubmit = () => {
    const url =
      currencyTypeInfo?.label === "E Code"
        ? Object.keys(currencyInfo)?.length > 0 &&
          Object.keys(typeInfo)?.length > 0 &&
          giftCardValue?.length > 0 &&
          images?.length > 0 &&
          inputValues[0] !== ""
        : Object.keys(currencyInfo)?.length > 0 &&
          Object.keys(typeInfo)?.length > 0 &&
          giftCardValue?.length > 0 &&
          images?.length > 0;
    if (url) {
      setProceedModal(true);
    } else if (Object.keys(currencyInfo)?.length === 0) {
      toast("Please select atleast one currency!");
    } else if (
      Object.keys(currencyInfo)?.length !== 0 &&
      Object.keys(typeInfo)?.length === 0
    ) {
      toast("Please select atleast one currency type!");
    } else if (
      Object.keys(currencyInfo)?.length !== 0 &&
      Object.keys(typeInfo)?.length !== 0 &&
      giftCardValue?.length === 0
    ) {
      toast("Please select atleast one Gift card value!");
    } else if (
      Object.keys(currencyInfo)?.length !== 0 &&
      Object.keys(typeInfo)?.length !== 0 &&
      giftCardValue?.length !== 0 &&
      images?.length === 0
    ) {
      toast("Please select atleast one image!");
    } else if (currencyTypeInfo?.label === "E Code" && inputValues[0] === "") {
      toast("Please Enter E-code!");
    } else {
      toast("Please select All values!!");
    }
  };

  const closeFirstModal = () => {
    setCurrencyTypeInfo({});
    setImages([]);
    setImageURLs([]);
    setTypeInfo({});
    setTypeList([]);
    setTypeOption(null);
    isOpen(false);
  };
  const auth = JSON.parse(localStorage.getItem('user'));

  const getCurrencyList = async () => {
    const apiCall = await GiftCardService.CurrencyList(gitCardCurrency?.id,auth);
    if (apiCall.state === true) {
      apiCall.result.json().then((resp1) => {
        if (resp1.status === 404) {
          toast(resp1.message);
        }
        if (resp1.status === 400) {
          toast(resp1.message);
        }
        if (resp1.status === 200) {
          let finalArr = [];

          let arr = [{ label: 'Select a currency', value: 'Any', disabled: true }]
          let arr1 = resp1?.data.map((item) => ({
            label: (
              <div className="flex items-center gap-2">
                {/* <div className='w-5 h-5 flex justify-center items-center rounded-full'> */}
                <img src={item?.flag} alt="img" className="w-7 h-7" />
                {/* </div> */}
                <p className="text-sm text-white !mb-0"> {item?.code}</p>
              </div>
            ),
            value: item?.id,
            code: item?.code,
          }));
          finalArr = arr.concat(arr1);
          setCurrency(finalArr);
        }
      });
    } else {
      toast("Something went wrong!");
    }
  };

  const handleSelect = (e) => {
    setOurrencyOption(e);
    setgiftCardId(e?.value);
    getGiftCardTypeList(e?.value);
    setCurrencyInfo(e);
    setTypeInfo({});
    setGiftCardValue([]);
    setTotal(0);
    setImageURLs([]);
    setImages([]);
  };

  const getGiftCardTypeList = async (id) => {
    const apiCall = await GiftCardService.GiftCardType(gitCardCurrency?.id, id,auth);
    if (apiCall.state === true) {
      apiCall.result.json().then((resp1) => {
        if (resp1.status === 404) {
          toast(resp1.message);
        }
        if (resp1.status === 400) {
          toast(resp1.message);
        }
        if (resp1.status === 200) {
          setCurrencyTypeInfo(resp1.data);
          let finalArr = [];
          let arr = [{ label: "Select type", value: "Any", disabled: true }];
          let arr1 = resp1?.data.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
          finalArr = arr.concat(arr1);
          setTypeList(finalArr);
        }
      });
    } else {
      toast("Something went wrong!");
    }
  };

  const handleSelectType = (e) => {
    setTypeOption(e);
    setTypeInfo(e);
    getGiftCardValueList(e?.value);
    setCurrencyTypeInfo(e);
    setGiftCardValue([]);
    setTotal(0);
    setImageURLs([]);
    setImages([]);
  };

  const getGiftCardValueList = async (cart_type_id) => {
    const apiCall = await GiftCardService.GiftCardValueList(
      gitCardCurrency?.id,
      giftCardId,
      cart_type_id,
      auth
    );
    if (apiCall.state === true) {
      apiCall.result.json().then((resp1) => {
        if (resp1.status === 404) {
          setGiftCardTypeList([]);
          // toast(resp1.message)
        }
        if (resp1.status === 400) {
          setGiftCardTypeList([]);
          // toast(resp1.message);
        }
        if (resp1.status === 200) {
          setGiftCardTypeList(resp1?.data);
        }
      });
    } else {
      toast("Something went wrong!");
    }
  };

  const handleAddEcode = () => {
    setInputValues([...inputValues, ""]);
  };

  const handleInputChange = (index, event) => {
    const newValues = [...inputValues];
    newValues[index] = event.target.value;
    setInputValues(newValues);
  };

  const handleProceed = async () => {
    let count = 0;
    if (giftCardValue.length > 0) {
      for (let i = 0; i < giftCardValue.length; i++) {
        const el = giftCardValue[i];
        count = count + el?.price * el?.qty;
      }
    }

    let arr = [];
    for (let i = 0; i < giftCardValue.length; i++) {
      let str;
      const el = giftCardValue[i];
      if (el?.qty > 0) {
        str = currentVal + " " + el?.value + "*" + el?.qty;
        arr.push(str);
      }
    }
    var formdata = new FormData();
    formdata.append("transactionType", "giftcard");
    formdata.append("total", count.toString());
    formdata.append("values[]", arr.toString());
    formdata.append("cardID", gitCardCurrency?.id.toString());
    formdata.append("cardType", currencyTypeInfo?.label);
    formdata.append("currency", currencyInfo?.code.toString());
    formdata.append("platform", "Web");
    formdata.append("ecode[]", inputValues.map((item) => item).toString());
    images.forEach((image) => {
      formdata.append("images[]", image);
    });

    const apiCall = await GiftCardService.SellGiftCardTransaction(formdata,auth);
    if (apiCall.state === true) {
      apiCall.result.json().then((resp1) => {
        if (resp1.status === 404) {
          toast(resp1.message);
        }
        if (resp1.status === 400) {
          toast(resp1.message);
        }
        if (resp1.status === 200) {
          toast(resp1?.message);
          setConfirmModal(true);
        }
      });
    } else {
      toast("Something went wrong!");
    }
  };

  const handleConfirm = () => {
    setConfirmModal(false);
    setProceedModal(false);
    isOpen(false);
    setGiftCardTypeList([]);
    setTypeList([]);
    setCurrency([]);
    setInputValues([""]);
    setCurrencyTypeInfo({});
    setGiftCardValue([]);
    setImages([]);
    setImageURLs([]);
    setTypeInfo({});
    setTypeList([]);
    setTypeOption(null);
  };

  const handleRemoveValue = async (index) => {
    const values = [...giftCardValue];
    values.splice(index, 1);
    setGiftCardValue(values);
  };

  const handleValueModal = async () => {
    if (giftCartTypeList?.length > 0) {
      setValueModal(true);
    } else {
      setValueModal(false);
      toast("Please select another currenct type!");
    }
  };

  const handleRemoveEcode = async (index) => {
    const newValues = [...inputValues];
    newValues.splice(index, 1);
    setInputValues(newValues);
  };

  useEffect(() => {
    if (images.length < 0) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(image));
    // images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setImageURLs(newImageUrls);
  }, [images]);

  useEffect(() => {
    if (giftCardValue?.length > 0) {
      handleTotal();
    }
  }, [giftCardValue]);

  useEffect(() => {
    if (open) {
      getCurrencyList();
    }
  }, [open]);

  useEffect(() => {
    if (currency.length > 0) {
      setOurrencyOption(currency[0]);
    }
  }, [currency]);

  useEffect(() => {
    if (typeList.length > 0) {
      setTypeOption(typeList[0]);
    }
  }, [typeList]);

  // mui css 
  const textFieldStyles = {
    background: "#252731 ",
   width: "95%",
    height: "auto",
   borderRadius: "2px",
 };
  return (
    <>
      {open && (
        <>
          <Giftvaluemodal
            giftCartTypeList={giftCartTypeList}
            setGiftCardValue={setGiftCardValue}
            valueModal={valueModal}
            setValueModal={setValueModal}
            setTotal={setTotal}
            total={total}
            setCurrencyVal={setCurrencyVal}
          />
          <Giftconfirmmodal
            handleProceed={handleProceed}
            handleConfirm={handleConfirm}
            setProceedModal={setProceedModal}
            proceedModal={proceedModal}
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            total={total}
            gitCardCurrency={gitCardCurrency}
          />
          <div className="modal bestbuy-modal !z-[1021]">
            <div className="modal-content">
              <div className="modal">
                <div className="modal-dialog gift-card-modal">
                  <div className="modal-content !max-h-[550px] ">
                    <div
                      className={`modal-header border-bottom-0 `}
                      style={{
                        backgroundColor: `${gitCardCurrency.background}`,
                        padding:`0px`
                      }}
                    >
                      <div className="header-img d-flex justify-center items-center w-100">
                        <img className="!h-20 w-100" src={gitCardCurrency?.image} />
                      </div>
                      <button
                        type="button "
                        className="close-btn "
                        onClick={closeFirstModal}
                        style={{
                          background: "#313131",
                          color: "#FFFFFF",
                          padding: "1px",
                        }}
                      >
                        <img src="img/close-icon.png" />
                      </button>
                    </div>
                    <div className="modal-body no-scrollbar !px-8" >
                      <form _lpchecked="1">
                        <div className="form-group gitcard-form mb-3">
                          <Select
                            onChange={(e) => handleSelect(e)}
                            name="currencyCode"
                            options={currency}
                            value={currencyOption}
                            styles={styles}
                            components={{ MultiValue }}
                            // menuIsOpen={true}
                          />
                        </div>

                        <div className="form-group  mb-3 gitcard-form">
                          <Select
                            onChange={(e) => handleSelectType(e)}
                            placeholder="Select a type"
                            value={typeOption}
                            name="typeOption"
                            options={typeList}
                            styles={styles}
                            className="custom-select"
                          />
                        </div>
                        <div className="address-value  ">
                          <p
                            className="mb-0  small p-2 "
                            style={{
                              color: "#FFFFFF",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            Value
                          </p>
                          {Object.keys(currencyTypeInfo)?.length > 0 &&
                          Object.keys(typeInfo)?.length ? (
                            <>
                              <div className="flex flex-wrap px-2 gap-[10px] pb-3">
                                {giftCardValue?.map((item, index) => (
                                  <>
                                    <div
                                      className="text-white flex border-[1px] border-white py-[6px] px-[10px] rounded-full items-center text-center gap-2 !h-9"
                                      key={index}
                                    >
                                      <p className="mb-0">
                                        ₦{item?.value} x {item?.qty}
                                      </p>
                                      <div
                                        className="bg-red-700 rounded-full h-[16px] w-[16px] text-black flex justify-center items-center"
                                        onClick={() => handleRemoveValue(index)}
                                      >
                                        <MdClose size={13} />
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                              <div
                                className="text-white flex justify-center items-center text-xs pb-2 hover:cursor-pointer"
                                onClick={handleValueModal}
                              >
                                <i
                                  class="bi bi-plus-lg"
                                  style={{ marginTop: "-10px" }}
                                ></i>
                                <span
                                  className="ms-1"
                                  style={{
                                    marginTop: "-10px",
                                    color: "#B8BCC3",
                                    fontSize: "14px",
                                  }}
                                >
                                  Tap to add more value
                                </span>
                              </div>
                            </>
                          ) : (
                            <p className="mb-0 text-white text-center pb-2 text-base">
                              Please select the type first
                            </p>
                          )}
                        </div>

                        <div className="drag-drop mb-4">
                          <form className="!mt-5">
                            <div class="upload_dropZone text-center w-full px-[10px]">
                              {imageURLS.length > 0 ? (
                                <>
                                  {imageURLS.map((imageSrc, index) => (
                                    <div
                                      className="flex justify-between items-center w-full mb-3"
                                      key={index}
                                    >
                                      <img
                                        alt="not found"
                                        width={"82px"}
                                        height={"82px"}
                                        src={URL.createObjectURL(imageSrc)}
                                        className="w-[82px] h-[82px] object-fill"
                                      />
                                      <div className="flex flex-col">
                                        <p className="text-[15px] !mb-0">
                                          Upload Successful
                                        </p>

                                        <p className="text-[13px] !mb-0 flex">
                                          File &nbsp;{" "}
                                          <p className="text-sm truncate w-[75px] !mb-0">
                                            {imageSrc?.name}
                                          </p>{" "}
                                          | {(imageSrc?.size / 1024).toFixed(2)}
                                          KB{" "}
                                        </p>
                                      </div>
                                      <div
                                        className="flex gap-1 items-center cursor-pointer text-xs text-[#EB5757]"
                                        onClick={() => deleteFile(index)}
                                      >
                                        <RemoveIcon />
                                        Remove
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <p class="small my-2 mb-0">
                                  <i class="bi bi-file-image ms-2"></i>
                                  <span className="ms-2">
                                    Drag and Drop file
                                  </span>
                                </p>
                              )}

                              <input
                                id="upload_image_background"
                                data-post-name="image_background"
                                class="position-absolute invisible"
                                type="file"
                                onChange={(event) => {
                                  onImageChange(event);
                                }}
                                multiple
                                accept="image/*"
                              />
                              <label
                                class="btn btn-upload"
                                for="upload_image_background"
                              >
                                Select a file
                              </label>
                              <div class="upload_gallery d-flex flex-wrap justify-content-center mb-0"></div>
                            </div>
                          </form>
                        </div>

                        {currencyTypeInfo?.label === "E Code" && (
                          <div className="e-code !w-[350px]" >
                            {inputValues?.map((item, index) => (
                              <div
                                className="form-group relative w-100"
                                key={index}
                              >
                                {inputValues?.length > 1 &&
                                  inputValues?.length !== index + 1 && (
                                    <div
                                      className="bg-white hover:cursor-pointer  rounded-full h-5 w-5 text-black flex justify-center items-center absolute !top-[-20px] !right-0"
                                      onClick={() => handleRemoveEcode(index)}
                                    >
                                      <MdClose size={18}  />{" "}
                                    </div>
                                  )}
                                 <TextField
                                  type="text"
                                  className="form-control mb-4  text-white add-text-card"
                                  id="name"
                                  value={item}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                  label={<span style={{ color: "#ffffff" }}>Enter E-Code</span>}
                                  variant="filled"
                                  style={textFieldStyles}
                                  InputProps={{ disableUnderline: true }}
                                
                                  sx={{ input : {color : 'white'}}}

                                />
                              </div>
                            ))}
                            <div className="text-white text-decoration-none text-center hover:cursor-pointer ">
                              <span className="ms-1" onClick={handleAddEcode}>
                                Add E-Code
                              </span>
                              <i class="bi bi-plus-lg ms-1"></i>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                    <div className="modal-footer " >
                      <div
                        onClick={handlesubmit}
                        className="btn-proceed-gitft"
                      >
                          {/* My Changes  */}
                          {/* <p className="mt-1 pt-1 ">Proceed</p>
                          {total !== 0 && <p className="n-btn p-1 ml-1">for ₦{total}</p>} */}
                        
                        { total !==0 ?(<>
                          <p className="n-btn">Proceed for ₦{total}</p>
                        </>):(<>
                          <p className="mt-1 pt-1 ">Proceed</p>
                        </>)}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Giftcardmodal;

// company code

// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable jsx-a11y/alt-text */
// import React, { useState, useEffect } from 'react';
// import Giftvaluemodal from './giftvaluemodal';
// import Giftconfirmmodal from './giftconfirmmodal';
// import '../scss/giftcardmodal.scss';
// import { toast } from 'react-toastify';
// import GiftCardService from '../../Services/LoginSignService/GiftCardService';
// import Select, { components } from 'react-select'
// import { MdClose } from 'react-icons/md'
// import useSelect from '../../Hook/user-select';
// import { RemoveIcon } from '../../Svg/AllSvg';

// const MultiValue = props => (
//   <components.MultiValue {...props}>
//     {props.data.code}
//   </components.MultiValue>
// );

// const Giftcardmodal = ({ gitCardCurrency, open, isOpen }) => {
//   const [proceedModal, setProceedModal] = useState(false)
//   const [confirmModal, setConfirmModal] = useState(false)
//   const [currency, setCurrency] = useState([])
//   const [typeList, setTypeList] = useState([])
//   const [giftCartTypeList, setGiftCardTypeList] = useState([])
//   const [giftCardId, setgiftCardId] = useState('')
//   const [currencyOption, setOurrencyOption] = useState(null)
//   const [typeOption, setTypeOption] = useState(null)
//   const [giftCardValue, setGiftCardValue] = useState([])
//   const [currencyInfo, setCurrencyInfo] = useState({})
//   const [typeInfo, setTypeInfo] = useState({})
//   const [currencyTypeInfo, setCurrencyTypeInfo] = useState({})
//   const [inputValues, setInputValues] = useState(['']);
//   const [valueModal, setValueModal] = useState(false)
//   const [total, setTotal] = useState(0)
//   const [currentVal, setCurrencyVal] = useState("")
//   const [images, setImages] = useState([]);
//   const [imageURLS, setImageURLs] = useState([]);
//   let styles = useSelect();

//   const handleTotal = () => {
//     let count = 0
//     if (giftCardValue.length > 0) {
//       for (let i = 0; i < giftCardValue.length; i++) {
//         const el = giftCardValue[i];
//         count = count + (el?.price * el?.qty)
//       }
//       setTotal(count)
//     }
//   }

//   const onImageChange = (e) => {
//     setImages([...images, ...e.target.files]);
//   }

//   const deleteFile = (e) => {
//     const s = images.filter((item, index) => index !== e);
//     setImages(s);
//   }

//   const handlesubmit = () => {
//     const url = currencyTypeInfo?.label === "E Code" ? (Object.keys(currencyInfo)?.length > 0 && Object.keys(typeInfo)?.length > 0 && giftCardValue?.length > 0 && images?.length > 0 && inputValues[0] !== '') : (Object.keys(currencyInfo)?.length > 0 && Object.keys(typeInfo)?.length > 0 && giftCardValue?.length > 0 && images?.length > 0)
//     if (url) {
//       setProceedModal(true)
//     } else if (Object.keys(currencyInfo)?.length === 0) {
//       toast('Please select atleast one currency!')
//     } else if (Object.keys(currencyInfo)?.length !== 0 && Object.keys(typeInfo)?.length === 0) {
//       toast('Please select atleast one currency type!')
//     } else if (Object.keys(currencyInfo)?.length !== 0 && Object.keys(typeInfo)?.length !== 0 && giftCardValue?.length === 0) {
//       toast('Please select atleast one Gift card value!')
//     } else if (Object.keys(currencyInfo)?.length !== 0 && Object.keys(typeInfo)?.length !== 0 && giftCardValue?.length !== 0 && images?.length === 0) {
//       toast('Please select atleast one image!')
//     }
//     else if (currencyTypeInfo?.label === "E Code" && inputValues[0] === "") {
//       toast('Please Enter E-code!')
//     }
//     else {
//       toast('Please select All values!!')
//     }
//   }

//   const closeFirstModal = () => {
//     setCurrencyTypeInfo({})
//     setImages([])
//     setImageURLs([])
//     setTypeInfo({})
//     setTypeList([])
//     setTypeOption(null)
//     isOpen(false);
//   };

//   const getCurrencyList = async () => {
//     const apiCall = await GiftCardService.CurrencyList(gitCardCurrency?.id);
//     if (apiCall.state === true) {
//       apiCall.result.json().then((resp1) => {
//         if (resp1.status === 404) {
//           toast(resp1.message)
//         }
//         if (resp1.status === 400) {
//           toast(resp1.message);
//         }
//         if (resp1.status === 200) {
//           let finalArr = []
//           let arr = [{ label: 'rency', value: 'Any', disabled: true }]
//           let arr1 = resp1?.data.map((item) => ({
//             label: <div className='flex items-center gap-2'>
//               {/* <div className='w-5 h-5 flex justify-center items-center rounded-full'> */}
//               <img src={item?.flag} alt='img' className='w-7 h-7' />
//               {/* </div> */}
//               <p className='text-sm text-white !mb-0'> {item?.code}</p>
//               </div>,
//             value: item?.id,
//             code: item?.code
//           }))
//           finalArr = arr.concat(arr1)
//           setCurrency(finalArr)
//         }
//       })
//     } else {
//       toast("Something went wrong!")
//     }
//   }

//   const handleSelect = (e) => {
//     setOurrencyOption(e)
//     setgiftCardId(e?.value)
//     getGiftCardTypeList(e?.value)
//     setCurrencyInfo(e)
//     setTypeInfo({})
//     setGiftCardValue([])
//     setTotal(0)
//     setImageURLs([])
//     setImages([])
//   }

//   const getGiftCardTypeList = async (id) => {
//     const apiCall = await GiftCardService.GiftCardType(gitCardCurrency?.id, id);
//     if (apiCall.state === true) {
//       apiCall.result.json().then((resp1) => {
//         if (resp1.status === 404) {
//           toast(resp1.message)
//         }
//         if (resp1.status === 400) {
//           toast(resp1.message);
//         }
//         if (resp1.status === 200) {
//           setCurrencyTypeInfo(resp1.data)
//           let finalArr = []
//           let arr = [{ label: 'Select type', value: 'Any', disabled: true }]
//           let arr1 = resp1?.data.map((item) => ({
//             label: item?.name,
//             value: item?.id
//           }))
//           finalArr = arr.concat(arr1)
//           setTypeList(finalArr)
//         }
//       })
//     } else {
//       toast("Something went wrong!")
//     }
//   }

//   const handleSelectType = (e) => {
//     setTypeOption(e)
//     setTypeInfo(e)
//     getGiftCardValueList(e?.value)
//     setCurrencyTypeInfo(e)
//     setGiftCardValue([])
//     setTotal(0)
//     setImageURLs([])
//     setImages([])
//   }

//   const getGiftCardValueList = async (cart_type_id) => {
//     const apiCall = await GiftCardService.GiftCardValueList(gitCardCurrency?.id, giftCardId, cart_type_id);
//     if (apiCall.state === true) {
//       apiCall.result.json().then((resp1) => {
//         if (resp1.status === 404) {
//           setGiftCardTypeList([])
//           // toast(resp1.message)
//         }
//         if (resp1.status === 400) {
//           setGiftCardTypeList([])
//           // toast(resp1.message);
//         }
//         if (resp1.status === 200) {
//           setGiftCardTypeList(resp1?.data)
//         }
//       })
//     } else {
//       toast("Something went wrong!")
//     }
//   }

//   const handleAddEcode = () => {
//     setInputValues([...inputValues, '']);
//   }

//   const handleInputChange = (index, event) => {
//     const newValues = [...inputValues];
//     newValues[index] = event.target.value;
//     setInputValues(newValues);
//   };

//   const handleProceed = async () => {
//     let count = 0
//     if (giftCardValue.length > 0) {
//       for (let i = 0; i < giftCardValue.length; i++) {
//         const el = giftCardValue[i];
//         count = count + (el?.price * el?.qty)
//       }
//     }

//     let arr = []
//     for (let i = 0; i < giftCardValue.length; i++) {
//       let str;
//       const el = giftCardValue[i];
//       if (el?.qty > 0) {
//         str = currentVal + " " + el?.value + "*" + el?.qty
//         arr.push(str)
//       }
//     }
//     var formdata = new FormData();
//     formdata.append("transactionType", "giftcard");
//     formdata.append("total", count.toString());
//     formdata.append("values[]", arr.toString());
//     formdata.append("cardID", gitCardCurrency?.id.toString());
//     formdata.append("cardType", currencyTypeInfo?.label);
//     formdata.append("currency", currencyInfo?.code.toString());
//     formdata.append("platform", "Web");
//     formdata.append("ecode[]", inputValues.map((item) => item).toString());
//     images.forEach(image => {
//       formdata.append("images[]", image);
//     });

//     const apiCall = await GiftCardService.SellGiftCardTransaction(formdata);
//     if (apiCall.state === true) {
//       apiCall.result.json().then((resp1) => {
//         if (resp1.status === 404) {
//           toast(resp1.message)
//         }
//         if (resp1.status === 400) {
//           toast(resp1.message);
//         }
//         if (resp1.status === 200) {
//           toast(resp1?.message)
//           setConfirmModal(true)
//         }
//       })
//     } else {
//       toast("Something went wrong!")
//     }
//   }

//   const handleConfirm = () => {
//     setConfirmModal(false);
//     setProceedModal(false)
//     isOpen(false)
//     setGiftCardTypeList([])
//     setTypeList([])
//     setCurrency([])
//     setInputValues([''])
//     setCurrencyTypeInfo({})
//     setGiftCardValue([])
//     setImages([])
//     setImageURLs([])
//     setTypeInfo({})
//     setTypeList([])
//     setTypeOption(null)
//   }

//   const handleRemoveValue = async (index) => {
//     const values = [...giftCardValue]
//     values.splice(index, 1)
//     setGiftCardValue(values)
//   }

//   const handleValueModal = async () => {
//     if (giftCartTypeList?.length > 0) {
//       setValueModal(true)
//     } else {
//       setValueModal(false)
//       toast("Please select another currenct type!")
//     }
//   }

//   const handleRemoveEcode = async (index) => {
//     const newValues = [...inputValues];
//     newValues.splice(index, 1);
//     setInputValues(newValues);
//   };

//   useEffect(() => {
//     if (images.length < 0) return
//     const newImageUrls = [];
//     images.forEach((image) => newImageUrls.push(image));
//     // images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
//     setImageURLs(newImageUrls);
//   }, [images]);

//   useEffect(() => {
//     if (giftCardValue?.length > 0) {
//       handleTotal()
//     }
//   }, [giftCardValue])

//   useEffect(() => {
//     if (open) {
//       getCurrencyList()
//     }
//   }, [open])

//   useEffect(() => {
//     if (currency.length > 0) {
//       setOurrencyOption(currency[0])
//     }
//   }, [currency])

//   useEffect(() => {
//     if (typeList.length > 0) {
//       setTypeOption(typeList[0])
//     }
//   }, [typeList])

//   return (
//     <>
//       {open && (<>
//         <Giftvaluemodal giftCartTypeList={giftCartTypeList} setGiftCardValue={setGiftCardValue} valueModal={valueModal} setValueModal={setValueModal} setTotal={setTotal} total={total} setCurrencyVal={setCurrencyVal} />
//         <Giftconfirmmodal handleProceed={handleProceed} handleConfirm={handleConfirm} setProceedModal={setProceedModal} proceedModal={proceedModal} confirmModal={confirmModal} setConfirmModal={setConfirmModal} total={total} gitCardCurrency={gitCardCurrency} />
//         <div className="modal bestbuy-modal !z-[1021]">
//           <div className="modal-content">
//             <div className="modal">
//               <div className='modal-dialog !w-auto'>
//                 <div className="modal-content !max-h-[600px]">
//                   <div className={`modal-header border-bottom-0 `} style={{ backgroundColor: `${gitCardCurrency.background}` }}>
//                     <div className='header-img d-flex justify-center items-center w-100'>
//                       <img className='!h-11' src={gitCardCurrency?.image} />
//                     </div>
//                     <button type="button" className="close" onClick={closeFirstModal}>
//                       <img src='img/close-icon.png' />
//                     </button>
//                   </div>
//                   <div className="modal-body no-scrollbar !px-8">
//                     <form _lpchecked="1">
//                       <div className="form-group w-[350px] mb-3">
//                         <Select
//                           onChange={(e) => handleSelect(e)}
//                           name="currencyCode"
//                           options={currency}
//                           value={currencyOption}
//                           styles={styles}
//                           components={{ MultiValue }}
//                           // menuIsOpen={true}
//                         />

//                       </div>
//                       <div className="form-group w-[350px] mb-3">
//                         <Select
//                           onChange={(e) => handleSelectType(e)}
//                           placeholder="Please select the currency first"
//                           value={typeOption}
//                           name="typeOption"
//                           options={typeList}
//                           styles={styles}
//                         />

//                       </div>
//                       <div className='address-value !w-[350px] '>
//                         <p className='mb-0 text-white small p-2'>Value</p>
//                         {(Object.keys(currencyTypeInfo)?.length > 0 && Object.keys(typeInfo)?.length) ? (
//                           <>
//                             <div className='flex flex-wrap px-2 gap-[10px] pb-3'>
//                               {giftCardValue?.map((item, index) => (
//                                 <>
//                                   <div className='text-white flex border-[1px] border-white py-[6px] px-[10px] rounded-full items-center text-center gap-2 !h-9' key={index}>
//                                     <p className="mb-0">₦{item?.value} x {item?.qty}
//                                     </p>
//                                     <div className="bg-red-700 rounded-full h-[16px] w-[16px] text-black flex justify-center items-center" onClick={() => handleRemoveValue(index)} ><MdClose size={13} />
//                                     </div>
//                                   </div>

//                                 </>
//                               ))}

//                             </div>
//                             <div className="text-white flex justify-center items-center text-xs pb-2 hover:cursor-pointer" onClick={handleValueModal}>
//                               <i class="bi bi-plus-lg"></i><span className='ms-1'>Tap to add more value</span>
//                             </div>
//                           </>)
//                           : (
//                             <p className="mb-0 text-white text-center pb-2 text-base">Please select the type first</p>
//                           )}
//                       </div>

//                       <div className='drag-drop mb-4 !w-[350px]'>
//                         <form className='!mt-5'>
//                           <div class="upload_dropZone text-center w-full px-[10px]">
//                             {imageURLS.length > 0 ? (
//                               <>
//                                 {imageURLS.map((imageSrc, index) => (
//                                   <div className='flex justify-between items-center w-full mb-3' key={index}>
//                                     <img
//                                       alt="not found"
//                                       width={"82px"}
//                                       height={"82px"}
//                                       src={URL.createObjectURL(imageSrc)}
//                                       className='w-[82px] h-[82px] object-fill'
//                                     />
//                                     <div className='flex flex-col'>
//                                       <p className='text-[15px] !mb-0'>Upload Successful</p>

//                                       <p className='text-[13px] !mb-0 flex'>File &nbsp;  <p className='text-sm truncate w-[75px] !mb-0'>{imageSrc?.name}</p> | {(imageSrc?.size / 1024).toFixed(2)}KB </p>
//                                     </div>
//                                     <div className='flex gap-1 items-center cursor-pointer text-xs text-[#EB5757]' onClick={() => deleteFile(index)}>

//                                       <RemoveIcon />
//                                       Remove
//                                     </div>
//                                   </div>
//                                 ))}
//                               </>)
//                               : (
//                                 <p class="small my-2 mb-0"><i class="bi bi-file-image ms-2"></i><span className='ms-2'>Drag and Drop file</span></p>
//                               )}

//                             <input id="upload_image_background" data-post-name="image_background" class="position-absolute invisible" type="file"
//                               onChange={(event) => {
//                                 onImageChange(event)
//                               }} multiple accept="image/*" />
//                             <label class="btn btn-upload" for="upload_image_background">Select a file</label>
//                             <div class="upload_gallery d-flex flex-wrap justify-content-center mb-0"></div>
//                           </div>
//                         </form>
//                       </div>

//                       {currencyTypeInfo?.label === "E Code" &&
//                         <div className='e-code w-75'>
//                           {inputValues?.map((item, index) => (
//                             <div className="form-group relative w-100" key={index}>

//                               {((inputValues?.length > 1 && inputValues?.length !== (index + 1))) &&
//                                 <div className="bg-white hover:cursor-pointer  rounded-full h-5 w-5 text-black flex justify-center items-center absolute !top-[-10px] !right-0"
//                                   onClick={() => handleRemoveEcode(index)}
//                                 ><MdClose size={18} /> </div>
//                               }
//                               <input type="text" className="form-control mb-4 w-100 text-white" id="name" placeholder="Enter E-Code" value={item}
//                                 onChange={(event) => handleInputChange(index, event)} />
//                             </div>
//                           ))}
//                           <div className='text-white text-decoration-none text-center hover:cursor-pointer'>
//                             <span className='ms-1' onClick={handleAddEcode}>Add E-Code</span>
//                             <i class="bi bi-plus-lg ms-1"></i>
//                           </div>
//                         </div>
//                       }
//                     </form>
//                   </div>
//                   <div className="modal-footer w-100">
//                     <div onClick={handlesubmit} className="hover:bg-[#146c43] hover:cursor-pointer bg-[#198754] py-[6px] px-3 rounded-md text-white h-[38px] flex gap-1">
//                       <p>
//                         Proceed
//                       </p>
//                       {(total !== 0) &&
//                         <p>
//                           for ₦{total}
//                         </p>}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//       )}
//     </>
//   );
// };

// export default Giftcardmodal;
