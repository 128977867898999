import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal image-modal">
      <div className="modal-content">
        {children}
      </div>
    </div>
  );
};
const auth = JSON.parse(localStorage.getItem("user"));

const SeeRedeem = ({brandId}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [redeemInstructionText, setRedeemInstructionText] = useState("");
  const [loader, setLoader] = useState(true);
  const openFirstModal = () => {
    setIsFirstModalOpen(true);
    redeemInstruction(brandId);
  };
  const redeemInstruction = async (brandId) =>{
    
    const apicall = await AfterLoginService.seeRedeemInstruction(auth,brandId);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if(resp.data){
          setLoader(false)
          setRedeemInstructionText(resp.data.verbose);
        }
        else{
          setLoader(false)
        }
       
      });
    } else {
    }
  }

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
     
    <Button className='btn-open mb-2 w-100' onClick={openFirstModal}>See Redeem Instructions</Button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className='modal-dialog'>
                <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" onClick={closeFirstModal}>
                       <img src='img/close-icon.png'/>
                    </button>
                </div>
                <div className="modal-body">
                    <div className=''>
                        
                          {loader?<>
                            <span
                            className="text-white spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            ></span>{" "}
                            <span className="text-white">Loading...</span>
                          </>:<>
                          <p>
                            {redeemInstructionText}
                          </p>
                          </>}
                        
                    </div>
                </div>
                </div>
            </div>
            </div>
        </Modal>
      )}
    </div>
  );
};

export default SeeRedeem;
