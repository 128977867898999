import React, { useState } from "react";
import "./account.scss";

import { Link } from "react-router-dom";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Retrymodal = () => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
      <button className="retry-btn" onClick={openFirstModal}>
        <img src="/img/retry.png" className="retry-btn" alt="" />
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content  update-name-modal ">
                <div className="modal-header">
                  <div className="row">
                    <div className="col-lg-12 col-ms-12 col-sm-12 col-12">
                      <img src="/img/TICK.png" className=" close-red" alt="" />
                    </div>
                  </div>

                  <h5 className="modal-title  kyc-varification">
                    KYC Verification Successful
                  </h5>

                  <button
                    type="button profile-close"
                    className="close"
                    onClick={closeFirstModal}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="bvn-p-modal">
                  <p>Congrats your identity successfully verified</p>
                </div>

                <div className="modal-footer ">
                  <button className="sucess-continue-btn">Continue</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Retrymodal;

