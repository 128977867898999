import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import "./qxchange.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomDropdown from "./CustomDropDown";
import QxchangeService from "../../Services/QxchangeService/QxchangeService";
import ConfirmationModal from "./confirmationModal";
import moment from "moment";
import TransactionStatusModal from "./TransactionStatusModal";
import SingleTransStatusModal from "./SingleTransStatusModal";
import SuccessTransactionModal from "./SuccessTransactionModal";
import { CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";

function Qxchange() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [gettingTransaction, setGettingTransaction] = useState(true);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [fromCurrency, setFromCurrencny] = useState({});
  const [toCurrency, setToCurrecny] = useState({});
  const [minExchangeAmount, setMinExchangeAmount] = useState(null);
  const [fromInput, setFromInput] = useState("");
  const [minAmountError, setMinAmountError] = useState(null);
  const [xchangeAmount, setXchangeAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [refundWalletAddress, setRefundWalletAddress] = useState("");
  const [requiredWalletAddressError, setRequiredWalletAddressError] =
    useState(false);
  const [apiError, setApiError] = useState(null);
  const [xchangeTransactionData, setXchangeTransactionData] = useState([]);
  const [transactionRecord, setTransactionRecord] = useState({});
  const [isTransactionStatusModalOpen, setIsTransactionstatusModalOpen] =
    useState(false);
  const [viewSingleTransStatusModal, setViewSingleTransStatusModal] =
    useState(false);
  const [gettingSingleTransactionDetail, setGettingSingleTransactionDetail] =
    useState({ uId: "", status: false });
  const backG = {
    background: "black",
  };
  useEffect(() => {
    const fetchTransactions = async () => {
      const auth = JSON.parse(localStorage.getItem("user"));
      const { result } = await QxchangeService.GetQxchangeTransactions(auth);
      setXchangeTransactionData(result.data);
      setGettingTransaction(false);
    };
    fetchTransactions();
  }, []);

  const handleFromInput = (e) => {
    const inputAmount = e.target.value;
    const numericInput = inputAmount.replace(/[^0-9.]/g, "");
    setFromInput(numericInput);
    setMinAmountError(null);
  };

  useEffect(() => {
    const findXchangeAmount = async () => {
      if (fromCurrency?.ticker && toCurrency?.ticker && fromInput) {
        if (fromInput < minExchangeAmount) {
          setMinAmountError("Amount is too small!");
        } else {
          const { result } = await QxchangeService.EstimatedExchangeAmount(
            `${fromCurrency.ticker}_${toCurrency.ticker}`,
            fromInput
          );
          setXchangeAmount(result.estimatedAmount);
        }
      }
    };
    findXchangeAmount();
  }, [fromInput, fromCurrency, toCurrency, minExchangeAmount]);

  const handlePopUp = () => {
    if (!walletAddress) {
      setRequiredWalletAddressError(true);
      return;
    }
    if (
      toCurrency?.ticker &&
      fromCurrency?.ticker &&
      fromInput &&
      xchangeAmount
    )
      setIsOpen(true);
  };
  const handleViewTransaction = async (uId) => {
    setGettingSingleTransactionDetail({
      uId,
      status: true,
    });
    const { result } = await QxchangeService.TransactionStatus(uId);
    setTransactionRecord(result);
    setViewSingleTransStatusModal(true);
    setGettingSingleTransactionDetail(false);
  };
  const resetFields = () => {
    setFromCurrencny("");
    setToCurrecny({});
    setFromInput("");
    setXchangeAmount("");
    setWalletAddress("");
    setRefundWalletAddress("");
    setMinExchangeAmount(null);
  };

  const handleConfirmModal = async () => {
    setLoading(true);
    const body = {
      from: fromCurrency.ticker,
      to: toCurrency.ticker,
      amount: fromInput,
      address: walletAddress,
      flow: "standard",
      refundAddress: refundWalletAddress,
    };
    const { result } = await QxchangeService.CreateExchangeTransaction(body);
    if (result.error) {
      setApiError(result.message);
    } else {
      setIsOpen(false);
      setTransactionRecord({ ...result, payinAmount: body.amount });
      const auth = JSON.parse(localStorage.getItem("user"));
      const { result: saveXchangeTransRecord } =
        await QxchangeService.SaveQxchangeTransaction(
          auth,
          refundWalletAddress,
          toCurrency.ticker,
          fromCurrency.ticker,
          result.payoutAddress,
          result.payinAddress,
          result.id,
          xchangeAmount,
          fromInput
        );

      if (saveXchangeTransRecord.status === 200)
        setIsTransactionstatusModalOpen(true);
      setXchangeTransactionData([
        ...xchangeTransactionData,
        {
          to: toCurrency.ticker,
          from: fromCurrency.ticker,
          payOutAddress: result.payoutAddress,
          payInAddress: result.payinAddress,
          u_id: result.id,
          fromAmount: xchangeAmount,
          toAmount: fromInput,
          converted_timestamp: moment().unix(),
        },
      ]);
      resetFields();
    }
    setLoading(false);
  };

  const textFieldStyles = {
    background: "#252731 ",
    width: "100%",
    height: "50px",
    borderRadius: "2px",
  };
  return (
    <div className="container-fluid position-relative d-flex p-0 qxchange-container">
      <Header />
      <ToastContainer />
      <div className={`content md:w-full lg:w-[80%]`}>
        <Subheader />
        <div className="container-fluid pt-4 px-4 qxchange-table">
          <div className="row">
            <h3 className=" text-light crypt-heading mb-0">Swap Crypto</h3>
            <p className=" fs-13 crypt-heading" style={{ color: "#7d7da1" }}>
              {/* Molestie et amet cursus vestibulum quam commodo. */}
            </p>
            <div className="qxchange">
              <div className="col-md-4 col-lg-4 qxchange-card " style={backG}>
                <Card className="m-auto quchange-card" style={backG} >
                  <CardContent className="content w-full  card-content ">
                    <div className="crypto-back ">
                      <img src="/img/CaretLeft1.svg" onClick={goBack} />
                    </div>

                    <h5 className="mb-0 ">
                      Crypto Exchange
                      </h5>
                    <p className="mb-3 fs-12 ">
                    Swap from one crypto to another.
                    </p>

                    <div className="input-group mb-4  d-flex relative  ">
                      <span
                        className="input-group-text d-block  p-1"
                        id="basic-addon1"
                      >
                        <p>From</p>
                        <CustomDropdown
                          fromCurrency={fromCurrency}
                          setToCurrecny={setToCurrecny}
                          setFromCurrencny={setFromCurrencny}
                          setFromInput={setFromInput}
                          setXchangeAmount={setXchangeAmount}
                          setWalletAddress={setWalletAddress}
                          setRefundWalletAddress={setRefundWalletAddress}
                          setMinExchangeAmount={setMinExchangeAmount}
                        />
                      </span>
                      <TextField
                        type="text"
                        className=" focus:outline-none currency-input px-[15px] rounded-tr-md rounded-br-md "
                        label={
                          <span style={{ color: "#ffffff", fontSize: "12px" }}>
                            Enter From Amount
                          </span>
                        }
                        aria-describedby="basic-addon1"
                        onChange={handleFromInput}
                        value={fromInput}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />

                      <img
                        src="/img/Max1.svg"
                        className="max-img-white"
                        alt="Image"
                      />
                      <img src="/img/Max.svg" className="max-img" alt="Image" />
                      {minExchangeAmount && (
                      <p className="min-p">
                        Min amount to send is {minExchangeAmount} {fromCurrency.ticker.toUpperCase()}
                      </p>
                    )}
                      {/* <p className="avail-p ml-1">
                        Available{" "}
                        <span
                          style={{
                            color: "#FFFFFF",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          1000BTC
                        </span>
                      </p> */}
                    </div>

                    <div className="d-flex justify-content-center align-items-center icon-box">
                      <p className="up-down-icons">
                        <i className="bi bi-arrow-up"></i>
                        <i className="bi bi-arrow-down"></i>
                      </p>
                    </div>

                    <div className="  ArrowUpDowwn">
                      <img src="img/quchange.svg" />
                    </div>

                    <div className="input-group mb-4 ">
                      <span
                        className="input-group-text d-block  p-1 "
                        id="basic-addon1"
                      >
                        <p>To</p>
                        {fromCurrency?.name ? (
                          <CustomDropdown
                            setFromCurrencny={setFromCurrencny}
                            api="to"
                            setToCurrecny={setToCurrecny}
                            ticker={fromCurrency.ticker}
                            setMinExchangeAmount={setMinExchangeAmount}
                            toCurrency={toCurrency}
                          />
                        ) : (
                          <>
                            <span className="text-xs"> Currency </span>
                            <span>{<KeyboardArrowDownIcon />}</span>
                          </>
                        )}
                      </span>
                      <TextField
                        type="text"
                        className=" focus:outline-none currency-input px-[15px] rounded-tr-md rounded-br-md"
                        label={
                          <span style={{ color: "#ffffff", fontSize: "12px" }}>
                            Your Converted Value
                          </span>
                        }
                        aria-describedby="basic-addon1"
                        readOnly
                        value={xchangeAmount}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="input-group mb-4 wallet-input">
                      <TextField
                        type="text"
                        className=" placeholder:text-white "
                        label={
                          <span style={{ color: "#ffffff", fontSize: "12px" }}>
                            To Wallet Address
                          </span>
                        }
                        aria-label="Server"
                        onChange={(e) => {
                          setRequiredWalletAddressError(false);
                          setWalletAddress(e.target.value);
                        }}
                        value={walletAddress}
                        style={textFieldStyles}
                        variant="filled"
                        sx={{ input: { color: "white" } }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div className="input-group mb-4 wallet-input ">
                      <TextField
                        type="text"
                        className=" placeholder:text-white "
                        label={
                          <span style={{ color: "#ffffff", fontSize: "12px" }}>
                            Refund Wallet Address
                          </span>
                        }
                        aria-label="Server"
                        value={refundWalletAddress}
                        onChange={(e) => {
                          setRefundWalletAddress(e.target.value);
                        }}
                        style={textFieldStyles}
                        variant="filled"
                        sx={{ input: { color: "white" } }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div className="input-group mb-4  wallet-input TipsCrypto">
                      <img src="/img/quchangetips.svg" alt="" />
                    </div>

                    <div className=" " style={{ display: "inline" }}>
                      <Button
                        className="btn-proceed "
                        onClick={handlePopUp}
                      >
                        Proceed
                      </Button>
                    </div>
                  </CardContent>
                  {/* <CardActions className="" sx={{ padding: "16px",marginTop:'-15px', marginLeft:'30px' }}> */}
                  {/* <div>
                    <Button className="btn-proceed " onClick={handlePopUp} style={{width:'20%'}}>
                      Proceed
                    </Button>
                    </div> */}
                  {/* </CardActions> */}
                </Card>
              </div>
              <div className="qxchange-scroll w-[650px] ">
                {gettingTransaction ? (
                  <div className="flex items-center justify-center">
                    <CircularProgress
                      color="secondary"
                      size={20}
                      className="mr-2"
                    />
                    <div className="text-white">Loading...</div>
                  </div>
                ) : xchangeTransactionData.length ? (
                  <>
                    <table class="table table-dark table-border mb-4 ">
                      <thead>
                        <tr className="fs-13 ">
                          <th>DETAILS</th>
                          <th className="hide-desktop-quchange"></th>
                          <th className="d-none d-sm-table-cell">TRANSACTION-ID</th>
                          <th className="view-detail-mobile">VIEW DETAIL</th>
                          <th className="d-none d-sm-table-cell">DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {xchangeTransactionData.map((record) => {
                          return (
                            <tr className="">
                              <td className="d-flex align-items-center">
                                <p>

                                </p>
                                <img src="/img/dashbordlist.svg" alt=''  />
                                <p className="fs-12 ms-2  quchange-mobile-header">

                                  {record.from}
                                  {"->"}
                                  {record.to}
                                  <span className="d-block fs-10">

                                    {record.from}  {record.fromAmount} {"->"}{" "}
                                    {record.to}  {record.toAmount}
                                  </span>
                                </p>
                                <p className="fs-12 quchange-mobile-type-id">{record.u_id}</p>
                                <p className="fs-12 quchange-mobile-type-date">
                                  {moment
                                    .unix(record.converted_timestamp)
                                    .format("MMMM D, YYYY HH:mm")}
                                </p>
                              </td>
                              <td>
                                <p className="fs-12 quchange-mobile-type">{record.u_id}</p>
                              </td>
                              <td >
                                <button
                                  className="complete-btn px-4"
                                  onClick={() =>
                                    handleViewTransaction(record.u_id)
                                  }
                                >
                                  {gettingSingleTransactionDetail.status &&
                                  gettingSingleTransactionDetail.uId ===
                                    record.u_id ? (
                                    <div className="flex gap-1 justify-center items-center">
                                      <CircularProgress
                                        size={10}
                                        color="secondary"
                                      />
                                      <div className="text-xs">Loading...</div>
                                    </div>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                              </td>
                              <td className="">
                                <p className="fs-12 quchange-mobile-type">
                                  {moment
                                    .unix(record.converted_timestamp)
                                    .format("MMMM D, YYYY HH:mm")}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* <Stack spacing={2}>
                      <Pagination
                        count={10}
                        variant="outlined"
                        color="secondary"
                      />
                    </Stack> */}
                  </>
                ) : (
                  <div className="text-dangerr text-sm ml-4 ">
                    <div className="empty mx-0">
                      <img
                        src="img/EmptyState.svg"
                        alt=""
                        className="empty-img"
                      />
                      <h4 className="not-transction text-center">
                        Transaction
                      </h4>
                      <p className="not-transction-p">
                        You don't have any pending or completed transction.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <TransactionStatusModal
          transactionDetails={transactionRecord}
          isOpen={isTransactionStatusModalOpen}
          onClose={() => setIsTransactionstatusModalOpen(false)}
          setSuccessModalOpen={setSuccessModalOpen}
        />
        <ConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={handleConfirmModal}
          text={`Proceed to exchanging ${fromInput} ${fromCurrency.ticker} to ${xchangeAmount} ${toCurrency.ticker} into
          specified reciever wallet address address?`}
          loading={loading}
          error={apiError}
        />
        <SingleTransStatusModal
          transactionDetails={transactionRecord}
          isOpen={viewSingleTransStatusModal}
          onClose={() => setViewSingleTransStatusModal(false)}
        />
        <SuccessTransactionModal
          isOpen={successModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          fromCurrency={transactionRecord.fromCurrency}
          toCurrency={transactionRecord.toCurrency}
          toAmount={transactionRecord.amount}
          fromAmount={transactionRecord.payinAmount}
          refunded={!!transactionRecord.refundAddress}
        />
      </div>
    </div>
  );
}

export default Qxchange;

// company code

// import React, { useEffect, useState } from "react";
// import "./qxchange.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Button from "@mui/material/Button";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import CustomDropdown from "./CustomDropDown";
// import QxchangeService from "../../Services/QxchangeService/QxchangeService";
// import ConfirmationModal from "./confirmationModal";
// import moment from "moment";
// import TransactionStatusModal from "./TransactionStatusModal";
// import SingleTransStatusModal from "./SingleTransStatusModal";
// import SuccessTransactionModal from "./SuccessTransactionModal";
// import { CircularProgress } from "@mui/material";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function Qxchange() {
//   const [gettingTransaction, setGettingTransaction] = useState(true);
//   const [successModalOpen, setSuccessModalOpen] = useState(false);
//   const [fromCurrency, setFromCurrencny] = useState({});
//   const [toCurrency, setToCurrecny] = useState({});
//   const [minExchangeAmount, setMinExchangeAmount] = useState(null);
//   const [fromInput, setFromInput] = useState("");
//   const [minAmountError, setMinAmountError] = useState(null);
//   const [xchangeAmount, setXchangeAmount] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [walletAddress, setWalletAddress] = useState("");
//   const [refundWalletAddress, setRefundWalletAddress] = useState("");
//   const [requiredWalletAddressError, setRequiredWalletAddressError] =
//     useState(false);
//   const [apiError, setApiError] = useState(null);
//   const [xchangeTransactionData, setXchangeTransactionData] = useState([]);
//   const [transactionRecord, setTransactionRecord] = useState({});
//   const [isTransactionStatusModalOpen, setIsTransactionstatusModalOpen] =
//     useState(false);
//   const [viewSingleTransStatusModal, setViewSingleTransStatusModal] =
//     useState(false);
//   const [gettingSingleTransactionDetail, setGettingSingleTransactionDetail] =
//     useState({ uId: "", status: false });

//   useEffect(() => {
//     const fetchTransactions = async () => {
//       const auth = JSON.parse(localStorage.getItem("user"));
//       const { result } = await QxchangeService.GetQxchangeTransactions(auth);
//       setXchangeTransactionData(result.data);
//       setGettingTransaction(false);
//     };
//     fetchTransactions();
//   }, []);

//   const handleFromInput = (e) => {
//     const inputAmount = e.target.value;
//     const numericInput = inputAmount.replace(/[^0-9.]/g, "");
//     setFromInput(numericInput);
//     setMinAmountError(null);
//   };

//   useEffect(() => {
//     const findXchangeAmount = async () => {
//       if (fromCurrency?.ticker && toCurrency?.ticker && fromInput) {
//         if (fromInput < minExchangeAmount) {
//           setMinAmountError("Amount is too small!");
//         } else {
//           const { result } = await QxchangeService.EstimatedExchangeAmount(
//             `${fromCurrency.ticker}_${toCurrency.ticker}`,
//             fromInput
//           );
//           setXchangeAmount(result.estimatedAmount);
//         }
//       }
//     };
//     findXchangeAmount();
//   }, [fromInput, fromCurrency, toCurrency, minExchangeAmount]);

//   const handlePopUp = () => {
//     if (!walletAddress) {
//       setRequiredWalletAddressError(true);
//       return;
//     }
//     if (
//       toCurrency?.ticker &&
//       fromCurrency?.ticker &&
//       fromInput &&
//       xchangeAmount
//     )
//       setIsOpen(true);
//   };
//   const handleViewTransaction = async (uId) => {
//     setGettingSingleTransactionDetail({
//       uId,
//       status: true,
//     });
//     const { result } = await QxchangeService.TransactionStatus(uId);
//     setTransactionRecord(result);
//     setViewSingleTransStatusModal(true);
//     setGettingSingleTransactionDetail(false);
//   };
//   const resetFields = () => {
//     setFromCurrencny("");
//     setToCurrecny({});
//     setFromInput("");
//     setXchangeAmount("");
//     setWalletAddress("");
//     setRefundWalletAddress("");
//     setMinExchangeAmount(null);
//   };

//   const handleConfirmModal = async () => {
//     setLoading(true);
//     const body = {
//       from: fromCurrency.ticker,
//       to: toCurrency.ticker,
//       amount: fromInput,
//       address: walletAddress,
//       flow: "standard",
//       refundAddress: refundWalletAddress,
//     };
//     const { result } = await QxchangeService.CreateExchangeTransaction(body);
//     if (result.error) {
//       setApiError(result.message);
//     } else {
//       setIsOpen(false);
//       setTransactionRecord({ ...result, payinAmount: body.amount });
//       const auth = JSON.parse(localStorage.getItem("user"));
//       const { result: saveXchangeTransRecord } =
//         await QxchangeService.SaveQxchangeTransaction(
//           auth,
//           refundWalletAddress,
//           toCurrency.ticker,
//           fromCurrency.ticker,
//           result.payoutAddress,
//           result.payinAddress,
//           result.id,
//           xchangeAmount,
//           fromInput
//         );

//       if (saveXchangeTransRecord.status === 200)
//         setIsTransactionstatusModalOpen(true);
//       setXchangeTransactionData([
//         ...xchangeTransactionData,
//         {
//           to: toCurrency.ticker,
//           from: fromCurrency.ticker,
//           payOutAddress: result.payoutAddress,
//           payInAddress: result.payinAddress,
//           u_id: result.id,
//           fromAmount: xchangeAmount,
//           toAmount: fromInput,
//           converted_timestamp: moment().unix(),
//         },
//       ]);
//       resetFields();
//     }
//     setLoading(false);
//   };

//   return (
//     <div className="container-fluid position-relative d-flex p-0 qxchange-container">
//       <Header />
//       <ToastContainer />
//       <div className={`content md:w-full lg:w-[80%]`}>
//         <Subheader />
//         <div className="container-fluid pt-4 px-4 qxchange-table">
//           <div className="row">
//             <h3 className="text-white mb-0">Qxchange</h3>
//             <p className="text-white fs-13">
//               Molestie et amet cursus vestibulum quam commodo.
//             </p>
//             <div className="qxchange">
//               <div className="col-md-4 col-lg-4 qxchange-card">
//                 <Card className="" sx={{ maxWidth: 300 }}>
//                   <CardContent className="content w-full m-0">
//                     <h5 className="mb-0">Crypto Exchange</h5>
//                     <p className="mb-3 fs-12">
//                       Lorem ipsum dolor sit amet consectetur.
//                     </p>
//                     <div className="input-group mb-3">
//                       <span
//                         className="input-group-text d-block p-1"
//                         id="basic-addon1"
//                       >
//                         <p>From</p>
//                         <CustomDropdown
//                           fromCurrency={fromCurrency}
//                           setToCurrecny={setToCurrecny}
//                           setFromCurrencny={setFromCurrencny}
//                           setFromInput={setFromInput}
//                           setXchangeAmount={setXchangeAmount}
//                           setWalletAddress={setWalletAddress}
//                           setRefundWalletAddress={setRefundWalletAddress}
//                           setMinExchangeAmount={setMinExchangeAmount}
//                         />
//                       </span>
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="Enter From Amount"
//                         aria-label=""
//                         aria-describedby="basic-addon1"
//                         onChange={handleFromInput}
//                         value={fromInput}
//                       />
//                     </div>
//                     {minAmountError && (
//                       <p className="text-danger m-0">{minAmountError}</p>git
//                     )}
//                     {minExchangeAmount && (
//                       <p className="text-success">
//                         Min amount to send is {minExchangeAmount}Btc
//                       </p>
//                     )}

// <div className="d-flex justify-content-center align-items-center icon-box">
//   <p className="up-down-icons">
//     <i className="bi bi-arrow-up"></i>
//     <i className="bi bi-arrow-down"></i>
//   </p>
// </div>
//                     <div className="input-group mb-4">
//                       <span
//                         className="input-group-text d-block  p-1"
//                         id="basic-addon1"
//                       >
//                         <p>To</p>
//                         {fromCurrency?.name ? (
//                           <CustomDropdown
//                             setFromCurrencny={setFromCurrencny}
//                             api="to"
//                             setToCurrecny={setToCurrecny}
//                             ticker={fromCurrency.ticker}
//                             setMinExchangeAmount={setMinExchangeAmount}
//                             toCurrency={toCurrency}
//                           />
//                         ) : (
//                           <>
//                             <span className="text-xs"> Currency </span>
//                             <span>{<KeyboardArrowDownIcon />}</span>
//                           </>
//                         )}
//                       </span>
//                       <input
//                         type="text"
//                         className=" focus:outline-none w-[62.6%] px-[15px] rounded-tr-md rounded-br-md"
//                         placeholder="Your Converted Value.."
//                         aria-label=""
//                         aria-describedby="basic-addon1"
//                         readOnly
//                         value={xchangeAmount}
//                       />
//                     </div>

//                     <div className="mb-3 flex-row ">
//                       <input
//                         type="text"
//                         className=" placeholder:text-white rounded-md"
//                         placeholder="To Wallet Address"
//                         aria-label="Server"
//                         onChange={(e) => {
//                           setRequiredWalletAddressError(false);
//                           setWalletAddress(e.target.value);
//                         }}
//                         value={walletAddress}
//                         style={{
//                           width: "100%",
//                           border: "none",
//                           color: "white",
//                           backgroundColor: "#252731",
//                           fontSize: "13px",
//                           padding: "15px",
//                         }}
//                       />
//                       {requiredWalletAddressError && (
//                         <p className="text-danger d-block">Field is required</p>
//                       )}
//                     </div>
//                     <div className="input-group mb-1">
//                       <input
//                         type="text"
//                         className=" placeholder:text-white rounded-md"
//                         placeholder="Refund Wallet Address"
//                         aria-label="Server"
//                         value={refundWalletAddress}
//                         onChange={(e) => {
//                           setRefundWalletAddress(e.target.value);
//                         }}
//                         style={{
//                           width: "100%",
//                           border: "none",
//                           color: "white",
//                           backgroundColor: "#252731",
//                           fontSize: "13px",
//                           padding: "15px",
//                         }}
//                       />
//                     </div>
//                   </CardContent>
//                   <CardActions sx={{ padding: "16px" }}>
//                     <Button className="btn-proceed " onClick={handlePopUp}>
//                       Proceed
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </div>
//               <div className="qxchange-scroll w-[650px] overflow-x-scroll">
//                 {gettingTransaction ? (
//                   <div className="flex items-center justify-center">
//                     <CircularProgress
//                       color="secondary"
//                       size={20}
//                       className="mr-2"
//                     />
//                     <div className="text-white">Loading...</div>
//                   </div>
//                 ) : xchangeTransactionData.length ? (
//                   <>
//                     <table class="table table-dark table-border mb-4">
//                       <thead>
//                         <tr className="fs-13">
//                           <th>DETAILS</th>
//                           <th>TRANSACTION-ID</th>
//                           <th>VIEW DETAIL</th>
//                           <th>DATE</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {xchangeTransactionData.map((record) => {
//                           return (
//                             <tr className="">
//                               <td className="d-flex align-items-center">
//                                 <p>
//                                   <i class="bi bi-arrow-down-left-circle-fill"></i>
//                                 </p>
//                                 <p className="fs-12 ms-2">
//                                   {record.from}
//                                   {"->"}
//                                   {record.to}
//                                   <span className="d-block fs-10">
//                                     {record.from} & {record.fromAmount} {"->"}{" "}
//                                     {record.to} & {record.toAmount}
//                                   </span>
//                                 </p>
//                               </td>
//                               <td>
//                                 <p className="fs-12">{record.u_id}</p>
//                               </td>
//                               <td>
//                                 <button
//                                   className="complete-btn px-4"
//                                   onClick={() =>
//                                     handleViewTransaction(record.u_id)
//                                   }
//                                 >
//                                   {gettingSingleTransactionDetail.status &&
//                                   gettingSingleTransactionDetail.uId ===
//                                     record.u_id ? (
//                                     <div className="flex gap-1 justify-center items-center">
//                                       <CircularProgress
//                                         size={10}
//                                         color="secondary"
//                                       />
//                                       <div className="text-xs">Loading...</div>
//                                     </div>
//                                   ) : (
//                                     "View"
//                                   )}
//                                 </button>
//                               </td>
//                               <td className="">
//                                 <p className="fs-12">
//                                   {moment
//                                     .unix(record.converted_timestamp)
//                                     .format("MMMM D, YYYY HH:mm")}
//                                 </p>
//                               </td>
//                             </tr>
//                           );
//                         })}
//                       </tbody>
//                     </table>
//                     {/* <Stack spacing={2}>
//                       <Pagination
//                         count={10}
//                         variant="outlined"
//                         color="secondary"
//                       />
//                     </Stack> */}
//                   </>
//                 ) : (
//                   <div className="text-danger text-sm ml-4">
//                     No Transaction Found!
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//         <TransactionStatusModal
//           transactionDetails={transactionRecord}
//           isOpen={isTransactionStatusModalOpen}
//           onClose={() => setIsTransactionstatusModalOpen(false)}
//           setSuccessModalOpen={setSuccessModalOpen}
//         />
//         <ConfirmationModal
//           isOpen={isOpen}
//           onClose={() => setIsOpen(false)}
//           onConfirm={handleConfirmModal}
//           text={`Proceed to exchanging ${fromInput} ${fromCurrency.ticker} to ${xchangeAmount} ${toCurrency.ticker} into
//           specified reciever wallet address address?`}
//           loading={loading}
//           error={apiError}
//         />
//         <SingleTransStatusModal
//           transactionDetails={transactionRecord}
//           isOpen={viewSingleTransStatusModal}
//           onClose={() => setViewSingleTransStatusModal(false)}
//         />
//         <SuccessTransactionModal
//           isOpen={successModalOpen}
//           onClose={() => setSuccessModalOpen(false)}
//           fromCurrency={transactionRecord.fromCurrency}
//           toCurrency={transactionRecord.toCurrency}
//           toAmount={transactionRecord.amount}
//           fromAmount={transactionRecord.payinAmount}
//           refunded={!!transactionRecord.refundAddress}
//         />
//       </div>
//     </div>
//   );
// }

// export default Qxchange;
