import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import useSelect from "../../../Hook/user-select";
import Select from "react-select";

const OperatorList = ({isOpenOperator,setIsOpenMobile,setShowService,countryISOCode,phoneNumber,setIsOpenOperator,opList,setIsOpenOperatorDetails,isOpenOperatorDetails,opDetails,setOpDetails,countryOption,isCountry1}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isDisabled, setIsDisabled] = useState(true)
  let styles = useSelect();
  const goBack = () => {
    setIsOpenMobile(true)
    setShowService(false)
    setIsOpenOperator(false)
  };
  
  const operatorView = async (operatorId)=>{

        const item = {operatorId:operatorId?operatorId:''}
        const apicall = await AfterLoginService.getOperator(auth,item);
        if (apicall.state === true) {
        apicall.result.json().then((resp) => {
            if (resp.status == 200) {
            console.log(resp.data)
            setOpDetails(resp.data);
            setShowService(false)
            setIsOpenOperator(false)
            setIsOpenMobile(false)
            setIsOpenOperatorDetails(true)
            } else {
            console.log(resp.message);
            }
        });
        } else {
        }
  }
 

  return (
    <div className="mobile_response container-fluid">
        
        {isOpenOperator &&(
            <>
            <div className="row pt-4 px-4 wallet-table dpDesign1">
                <div className="col-md-2">
                    <div className="back-icon-main d-block">
                        <img src="../img/CaretLeft1.svg" onClick={goBack} />
                    </div>
                </div>
                <div className="col-md-10"></div>
                <div className="col-md-6">
                    <div>
                        <div>
                            <h3 className="heading-transaction mb-0">Mobile TopUp</h3>
                            <p className="mb-p">Follow three easy steps to top-up in seconds. To do, please set the reciever phone number below:</p>
                        </div> 
                    </div>    
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6 col-12">
                    <div className="row">
                    <div className="col-md-12 col-12 mb-4">
                                    <div className="row">
                                        <div className="col-md-4 col-6 country-pr-0">
                                            <Select
                                                name="currencyCode"
                                                options={countryOption}
                                                value={isCountry1}
                                                styles={styles}
                                                isDisabled={isDisabled}
                                            /> 
                                        </div>
                                        <div className="col-md-6 col-6 input-pl-0">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter Phone Number"    value={phoneNumber}
                                            isDisabled={isDisabled} />
                                           
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="row">        
                         {opList.length>0?(opList.map((item)=>(<>
                            <div className="col-md-3 col-6" onClick={()=>operatorView(item.operatorId)}>
                                <div className="opdesign">
                                    <img src={item.logoUrls[1]}/>
                                    <p>{item?item.name.substring(0,18):''}</p>
                                </div>
                            </div>
                         </>))):(<></>)}
                        
                    </div>
                </div>
            </div>
            </>
        )}
       
    </div>
  );
};

export default OperatorList;
