import React, { useState } from "react";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Createsuccess = ({handleData,isFirstModalOpen,setIsFirstModalOpen,setVirtualTranScreenShow,setVirtualAcScreenShow,createLoader,setCreateLoader,virtualAccountDetails}) => {

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
    setVirtualTranScreenShow(true);
    setVirtualAcScreenShow(false)
    virtualAccountDetails()
  };
  const done = ()=>{
    setIsFirstModalOpen(false)
    setVirtualTranScreenShow(true);
    setVirtualAcScreenShow(false)
    virtualAccountDetails()
  }
  const continueStore = ()=>{
       handleData();
  }
  return (
    <div className="mb-4">
      <button className={`btn btn-primary form-control buy_now ${createLoader?("disabled"):(<></>)}`} onClick={continueStore}>
                        {createLoader ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            "Create"
                          )}
      </button>
      {isFirstModalOpen && (
        <Modal>
        <div className="modal ">
          <div className="modal-dialog">
            <div className="modal-content  update-name-modal ">
              <div className="modal-header">
                <div className="row">
                  <div className="col-lg-12 col-ms-12 col-sm-12 col-12">
                    <img src="/img/righttick.svg" className=" close-red" alt="" />
                  </div>
                </div>
                <ToastContainer />

                <button
                  type="button profile-close"
                  className="close"
                  onClick={closeFirstModal}
                >
                  <img src="img/close-icon.png" />
                </button>
              </div>
              <div className="modal-body ">
                <h5 className="modal-title text-center">Virtual account created successfully.</h5>
              </div>
              <div className="modal-footer  d-flex ">
                  <div className="row">
                    {/* <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="view-btn text-center">
                        <button className="mt-2.5">
                          View Transactions
                        </button>
                      </div>
                    </div> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-done text-center " onClick={done} >
                        <button className="mt-2.5 "  >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      )}
    </div>
  );
};

export default Createsuccess;
