var LoginSignService = {
    GetApiKey : function()
    {
        const password = 'quchange.app.revamp';
           let item1 = {password}
           return fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-api",{
               method:'POST',
               headers:{
                  "Content-Type":"application/json",
                  "Accept":"application/json"
               },
               body:JSON.stringify(item1)
            }).then((result)=>{
                if (result.status === 200) {
                    return { result: result, state: true };
                } else {
                    return { result: result, state: false };
                }
            }).catch((err)=>{
               console.log(err);
            })
    },
    GetCaptcha : function(){
      return  fetch("https://admin.thequchangeapp.xyz/checkApi/generateCaptcha.php",{
            method:'POST', 
         }).then((result)=>{
                if (result.status === 200) {
                    return { result: result, state: true };
                } else {
                    return { result: result, state: false };
                }
            }).catch((err)=>{
               console.log(err);
            });
        
    },
    VerifyCaptcha : function(data){
        return  fetch("https://admin.thequchangeapp.xyz/checkApi/verifyCaptcha.php",{
            method:'POST',
            body:data
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    UserLogin : function(item){
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/login",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
             },
             body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    Signup : function(item){
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/signup",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
             },
             body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GiftCardList : function(auth,page,limit){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-listing?page=${page}&limit=${limit}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    // FetchGiftCardList : function(auth,page,limit){
    //     return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-listing?page=${page}&limit=${limit}`,{
    //         method:'GET',
    //         headers:{
    //         "Content-Type":"application/json",
    //         "Accept":"application/json",
    //         "Authorization":'Bearer'+auth.api_access_token
    //         },
            
    //      }).then((result)=>{
    //               if (result.status === 200) {
    //                   return { result: result, state: true };
    //               } else {
    //                   return { result: result, state: false };
    //               }
    //           }).catch((err)=>{
    //              console.log(err);
    //           });
          
    // },
    cryptoList : function(auth){
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-crypto-listing",{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    SendEmail : function(item1){
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/forgetpassword",{
            method:'POST',
            headers:{
               "Content-Type":"application/json",
               "Accept":"application/json"
            },
            body:JSON.stringify(item1)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    OTPVerify : function(items){    
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/verifyotp",{
            method:'POST',
            headers:{
               "Content-Type":"application/json",
               "Accept":"application/json"
            },
            body:JSON.stringify(items)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    
    
}
export default LoginSignService