import React, { useState, useEffect } from "react";
import "./trc.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Avatar from "@mui/material/Avatar";
import RefreshIcon from "@mui/icons-material/Refresh";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Sendmodal from "./sendmodal";
import Sellmodal from "./sellmodal";
import Receivemodal from "./receivedmodal";
import Trc20Services from "../../Services/LoginSignService/Trc20Service";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import CachedIcon from "@mui/icons-material/Cached";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Trc() {
  const [buttonText, setButtonText] = useState("Received TRX Transactions");
  const [showDetail, setShowDetail] = useState(false);
  const [tronWalletInfo, setTronWalletDetail] = useState({});
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [rate, setRate] = useState({});
  const [history, setHistory] = useState([]);
  const [receivedHistory, setReceivedHistory] = useState([]);
  const [isReceived, setIsReceived] = useState(false);
  const [isUsdt, setIsUsdt] = useState(false);
  const [onLoad, setOnLoad] = useState(true);

  /*******************Get Current Date */
  const today = new Date();
  const date = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = monthNames[today.getMonth()];
  const day = weekday[today.getDay()];
  const currentDate = day + " , " + month + "  " + date + "th";
  /**************************End *******************/

  useEffect(() => {
    getTronWalletDetail();
  }, []);

  const handleClick = async (e) => {
    setIsLoading(true);
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };
    if (auth) {
      const trxTransaction = await Trc20Services.GetTrxReceiveTransaction(
        auth,
        body,
        isUsdt
      );
      let record = trxTransaction?.result;
      record = record.filter((item) => {
        return item.type === "received";
      });
      
      setReceivedHistory(record);
      // setIsReceived(true);
      let type = isUsdt === true ? "usdt" : "trx";
      // setButtonText(`Send/Sell ${type.toUpperCase()} Transaction`);
       setButtonText(`Send/Sell TRX Transactions Send/Sell USDT Transactions`);
      setHistory([]);
      setIsLoading(false);
    }
  };

  const handleSendSellClick = async (e, type) => {
    setIsLoading(true);
    setIsUsdt(type);
    e.preventDefault();
    let checkType = type === true ? "usdt" : "trx";
    const trxTransaction = await Trc20Services.GetTrxTransaction(auth, type);
    setButtonText(`Received ${checkType.toUpperCase()} Transactions`);
    setHistory(trxTransaction?.result?.data);
    setIsReceived(false);
    setReceivedHistory([]);
    setIsLoading(false);
  };

  const Loader = () => {
    return (
      <>
        <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
          <div class="flex items-center">
            <svg
              class="animate-spin h-20 w-20 text-blue-950"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="gray"
                stroke-width="4"
              ></circle>
              <path
                fill="#fff"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </>
    );
  };

  const getTronWalletDetail = async () => {
    try {
      setIsLoading(true);
      if (auth) {
        const apicall = await Trc20Services.GetTronUserWallet(auth);

        if (apicall.result.isdetailadded === 1) {
          setShowDetail(true);
          setTronWalletDetail(apicall.result?.data);
          const adminRate = await Trc20Services.GetAdminRate(auth);
          setRate(adminRate?.result?.data);

          const trxTransaction = await Trc20Services.GetTrxTransaction(
            auth,
            false
          );
          setHistory(trxTransaction?.result?.data);
        }
        setIsLoading(false);
        setOnLoad(false);
      }
    } catch (error) {}
  };

  const createWallet = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };
    if (auth) {
      const tronToken = await Trc20Services.CreateJwtTronToken(auth, body);
      if (tronToken.result.status === 200) {
        getTronWalletDetail();
        setIsLoading(false);
      }
    }
  };

  const refreshBlanceTab = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };
    if (auth) {
      const tronToken = await Trc20Services.tapToRefreshTRC20(auth, body);
      if (tronToken.result.status === 200) {
        getTronWalletDetail();
      }
      setIsLoading(false);
    }
  };

  const RefreshStatus = async (txId) => {
    setIsLoading(true);
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };
    if (auth) {
      const tronToken = await Trc20Services.ChangeStatusByTransactionId(
        auth,
        body,
        txId
      );
      if (tronToken && tronToken.result && tronToken.result.status === 200) {
        const trxTransaction = await Trc20Services.GetTrxTransaction(
          auth,
          isUsdt
        );
        setHistory(trxTransaction?.result?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const openNewTab = (e, transaction_id) => {
    e.preventDefault();

    let url = `https://tronscan.org/#/transaction/${transaction_id}`;
    window.open(url, "_blank");
  };

  const refreshHistory = async () => {
    await getTronWalletDetail();
    setIsLoading(true);
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };
    if (auth) {
      const tronToken = await Trc20Services.tapToRefreshTRC20(auth, body);
      if (tronToken.result.status === 200) {
        getTronWalletDetail();
      }
      setIsLoading(false);
    }
  };

  // copy paste
  {
    /* 1411 */
  }
  const [isCopied, setIsCopied] = useState(false);
  const copyTronAddress = () => {
    const tronAddress = tronWalletInfo.tron_wallet_address;
    navigator.clipboard.writeText(tronAddress);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  {
    /* 1411 */
  }
  return (
    <div className="container-fluid position-relative d-flex p-0 crypto-container">
      <ToastContainer />
      <Header />
      <div className="content">
        <Subheader />
        <div
          className={`container-fluid pt-4 px-4 crypto-table  ${
            showDetail ? "" : "!h-screen"
          } ${
            history.length != 0 || receivedHistory.length != 0
              ? ""
              : "!h-screen"
          }`}
        >
          <div className="row">
            <div
              className={`${
                showDetail === true
                  ? "col-lg-7 col-md-7 col-sm-12 col-12"
                  : "col-lg-12 col-md-12 col-sm-12 col-12"
              }`}
            >
              <div className={`${onLoad ? "!hidden" : "d-flex"}`}>
                <div className="mb-3">
                  <span className=" ">
                    <img src="/img/back.svg" className="right-icon " />
                  </span>
                </div>
                <div className="ms-2  ">
                  <h4 className="h6 trc mb-0">TRC20(USDT & TRON)</h4>
                  <p className="mb-0  fs-12 trc-p">
                    Molestie et amet cursus vestibulum quam commodo.
                  </p>
                </div>
              </div>
              {showDetail === true ? (
                <div className="balance-header d-flex justify-content-between col-md-12 gx-5">
                  <div className="  trc-heading ">
                    <p className=" ">
                      <img
                        src="/img/overallicon.svg"
                        className="trc-heading-img"
                      />
                      <h6
                        className=" total-trx pl-3"
                        style={{ marginTop: "-20px" }}
                      >
                        {" "}
                        Total balance (TRC20)
                      </h6>
                      <span className="rotationTop ">
                        <a
                          onClick={refreshBlanceTab}
                          class="  cursor-pointer text-center text-white h5 text-decoration-none"
                        >
                          <img
                            src="/img/shape1.svg"
                            style={{ height: "14px" }}
                          />
                        </a>
                      </span>
                    </p>
                    <h6 className="h4 ml-3 trc-h6 mb-2">
                      {tronWalletInfo?.tron_bal && tronWalletInfo.tron_bal}
                      <span className="h6-btc">TRX</span>
                      <span className="d-block h4 d-block-usdt-btc mt-1">
                        {tronWalletInfo?.usdt_bal && tronWalletInfo.usdt_bal}{" "}
                        USDT
                      </span>
                    </h6>
                  </div>

                  <div className="rate-heading ">
                    <h5 className="rate">
                      <p>
                        <img
                          src="/img/crypto.svg"
                          className="mb-3 rate-img-trc"
                          alt=""
                        />
                        <h5 className="rate-h5">Rate</h5>
                      </p>
                      <span className="rotation ">
                        <a
                          onClick={refreshBlanceTab}
                          class="  cursor-pointer text-center text-white h5 text-decoration-none"
                        >
                          <img
                            src="/img/shape1.svg"
                            style={{ height: "14px" }}
                          />
                        </a>
                      </span>
                    </h5>

                    <p className=" rate-icon">
                      <span className="p-2">₦</span>
                      {rate?.usdt_rate && rate?.usdt_rate}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  class={`align-items-center text-white  justify-content-center mb-2 border-bottom-0 ${
                    onLoad ? "!hidden" : "d-flex"
                  }`}
                >
                  <div className="flex flex-col items-center mt-5 btc-wallet-img trc-no-bal">
                    <img src="/img/btcgruop.svg" alt="BTC Wallet" />
                    <h6 className="trc-no-bal-heading">
                      TRC20(USDT & TRON) Wallet
                    </h6>
                    <p className="trc-no-bal-p">
                      {" "}
                      You do not have a TRC20(USDT & TRON)
                    </p>
                    <p className="trc-no-bal-p-two">
                      Wallet, click on the link below to create wallet
                    </p>
                    <button
                      type="button"
                      className="btn transaction btn-exchange1"
                      onClick={(e) => createWallet(e)}
                    >
                      <span className="p-1" style={{ fontSize: "14px" }}>
                        +
                      </span>
                      Create Wallet
                    </button>
                  </div>
                </div>
              )}

              {showDetail === true ? (
                <>
                  <div
                    style={{ marginTop: "-80px" }}
                    className="row text-center copy-paste-header "
                  >
                    {/* 1411 */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="trc-block1 mx-3">
                        <p className="tap-usd-trc">
                          Tap to copy USDT(TRC) Address
                        </p>
                        {/* 1411 */}
                        <span className="d-block h6 block-btc-h ">
                          {tronWalletInfo.tron_wallet_address}
                          <ContentCopyIcon
                            className="copy-icon copy-icon-mobile"
                            style={{
                              float: "right",
                              marginTop: "-10px",
                            }}
                            onClick={copyTronAddress}
                          />
                        </span>
                        {isCopied && (
                          <p
                            className="text-light"
                            style={{ textAlign: "right" }}
                          >
                            Copied!
                          </p>
                        )}
                        {/* 1411 */}
                      </div>
                    </div>
                    {/* 1411 */}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* 1411 */}
            {showDetail === true && (
              <>
                <div className="col-lg-5 col-md-5 col-sm-12 col-12 mt-5">
                  <div className="balance-header1">
                    <div className="btn-container mt-5 m-auto">
                      <Sendmodal auth={auth} refreshHistory={refreshHistory} />
                      <Sellmodal refreshHistory={refreshHistory} />
                      <Receivemodal
                        tronAddress={
                          tronWalletInfo?.tron_wallet_address &&
                          tronWalletInfo.tron_wallet_address
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* 1411 */}
          </div>

          <div>
            <div className="row trc-table1">
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                {showDetail === true && (
                  <div>
                    {/* <div class="card-header align-items-center text-white d-flex flex-col justify-content-center mb-2 border-bottom-0">
                    <a
                      onClick={refreshBlanceTab}
                      class="mb-0 cursor-pointer text-center text-white h5 text-decoration-none"
                    >
                      Tap to Refresh Balance
                    </a>
                  </div> */}

                    {/* <div class="card-btn align-items-center text-white d-flex justify-content-center mb-2 border-bottom-0">
                    <button
                      type="button"
                      style={{
                        backgroundColor:
                          isUsdt === false ? "#ffffff" : "#b3acc5",
                      }}
                      className="btn transaction"
                      onClick={(e) => {
                        setButtonText("Received TRX Transaction");
                        handleSendSellClick(e, false);
                      }}
                    >
                      TRX Transactions
                    </button>
                    <button
                      type="button"
                      style={{
                        backgroundColor:
                          isUsdt === true ? "#ffffff" : "#b3acc5",
                      }}
                      className="btn transaction"
                      onClick={(e) => {
                        setButtonText("Received USDT Transaction");
                        handleSendSellClick(e, true);
                      }}
                    >
                      USDT Transactions
                    </button>
                  </div> */}

                    {/* <div class="card-btn align-items-center text-white d-flex justify-content-center mb-2 border-bottom-0">
                    <button
                      type="button"
                      className="btn transaction"
                      onClick={(e) =>
                        isReceived === false
                          ? handleClick(e, isUsdt)
                          : handleSendSellClick(e, isUsdt)
                      }
                    >
                      {buttonText}
                    </button>
                  </div> */}
                    {isReceived === false && (
                      <div className="trc-scroll">
                        {/* <div className="row heading ">
                          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="transaction-Heading">
                              <h4 className="ml-2">Transaction</h4>
                              <p className="heading-p ml-2">
                                Monday October 17th
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="mt-4 ">
                              <button
                                type="button"
                                style={{
                                  backgroundColor:
                                    isUsdt === false ? "#5109ee" : "#b3acc5",
                                }}
                                className="btn trx-button"
                                onClick={(e) => {
                                  setButtonText("Received TRX Transaction");
                                  handleSendSellClick(e, false);
                                }}
                              >
                                TRX Transactions
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="mt-4">
                              <button
                                type="button"
                                style={{
                                  backgroundColor:
                                    isUsdt === true ? "#ffffff" : "#000000",
                                }}
                                className="btn usdt-btn "
                                onClick={(e) => {
                                  setButtonText("Received USDT Transaction");
                                  handleSendSellClick(e, true);
                                }}
                              >
                                USDT Transactions
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="mt-4">
                              <button
                                type="button"
                                className="btn received-btn"
                                onClick={(e) =>
                                  isReceived === false
                                    ? handleClick(e, isUsdt)
                                    : handleSendSellClick(e, isUsdt)
                                }
                              >
                                {buttonText}
                              </button>
                            </div>
                          </div>
                          <hr style={{ color: "gray" }} />
                        </div> */}

                        <div className="row heading">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="transaction-Heading">
                              <h4 className="ml-3">Transaction</h4>
                              <p className="heading-p ml-3">
                                {`${currentDate}`}
                              </p>
                            </div>
                          </div>

                          {/* <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="d-lg-flex d-md-flex d-sm-flex d-flex mx-2 three-btn">
                              <div className="mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor:
                                      isUsdt === false ? "#5109ee" : "#5109ee",
                                  }}
                                  className="btn trx-button"
                                  onClick={(e) => {
                                    setButtonText("Received TRX Transaction");
                                    handleSendSellClick(e, false);
                                  }}
                                >
                                  TRX Transactions
                                </button>
                              </div>
                              <div className="mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor:
                                      isUsdt === true ? "#000000" : "#000000",
                                  }}
                                  className="btn usdt-btn"
                                  onClick={(e) => {
                                    setButtonText("Received USDT Transaction");
                                    handleSendSellClick(e, true);
                                  }}
                                >
                                  USDT Transactions
                                </button>
                              </div>
                              <div className="mt-4">
                                <button
                                  type="button"
                                  className="btn received-btn"
                                  onClick={(e) =>
                                    isReceived === false
                                      ? handleClick(e, isUsdt)
                                      : handleSendSellClick(e, isUsdt)
                                  }
                                >
                                  {buttonText}
                                </button>
                              </div>
                            </div>
                          </div> */}

                          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="d-lg-flex d-md-flex d-sm-flex d-flex mx-2 three-btn">
                              <div className="mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: !isUsdt
                                      ? "#5109ee"
                                      : "#000000",
                                    color: !isUsdt ? "white" : "white",
                                  }}
                                  className="btn trx-button"
                                  onClick={(e) => {
                                    setButtonText("Received TRX Transaction");
                                    handleSendSellClick(e, false);
                                    setIsUsdt(false);
                                  }}
                                >
                                  TRX Transactions
                                </button>
                              </div>
                              <div className="mt-4">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: isUsdt
                                      ? "#5109ee"
                                      : "#000000",
                                    color: isUsdt ? "white" : "white",
                                  }}
                                  className="btn usdt-btn"
                                  onClick={(e) => {
                                    setButtonText("Received USDT Transaction");
                                    handleSendSellClick(e, true);
                                    setIsUsdt(true);
                                  }}
                                >
                                  USDT Transactions
                                </button>
                              </div>
                              <div className="mt-4">
                                <button
                                  type="button"
                                  
                                  className="btn received-btn"
                                  onClick={(e) =>
                                    isReceived === false
                                      ? handleClick(e, isUsdt)
                                      : handleSendSellClick(e, isUsdt)
                                  }
                                >
                                  {buttonText}
                                </button>
                              </div>
                            </div>
                          </div>

                          <hr style={{ color: "gray" }} />
                        </div>

                        <table class="table table-dark table-border first-trc-table ">
                          <thead>
                            <tr className="fs-13">
                              <th>DETAILS</th>
                              <th className="hide-from-desktop"></th>

                              <th className="d-none d-sm-table-cell">TYPE</th>
                              <th>STATUS</th>
                              <th className="d-none d-sm-table-cell">DATE</th>
                              <th className="trc-referesh-mobile">Refresh</th>
                            </tr>
                          </thead>
                          <tbody>
                            {history &&
                              history.length > 0 &&
                              history.map((item) => {
                                return (
                                  <tr
                                    onClick={(e) =>
                                      openNewTab(e, item?.transaction_id)
                                    }
                                  >
                                    <td className="d-flex align-items-center">
                                      <p>
                                        {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
                                      </p>
                                      <img src="/img/dashbordlist.svg" alt="" />
                                      <p className="fs-12 ms-2">
                                        {isUsdt === false
                                          ? item?.trx_value_to_send
                                          : item?.usdt_value_to_send}{" "}
                                        {item?.tron_type}
                                        <span className="d-block fs-10 trc-mobile-header">
                                          sent to : {item?.to}
                                        </span>
                                      </p>
                                      <p className="fs-12 trc-date-mobile">
                                        {item?.timestamp}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="fs-12 hide-mobile-type">
                                        SENT
                                      </p>
                                    </td>
                                    <td className="trc-mobile-btn">
                                      <a
                                        href=""
                                        className={`complete-btn ${
                                          item?.status === "OUT_OF_ENERGY"
                                            ? "!text-[#ff0000] !border-[#ff0000]"
                                            : ""
                                        }`}
                                      >
                                        {item?.status.toUpperCase()}
                                      </a>
                                    </td>
                                    <td className="">
                                      <p className="fs-12 hide-mobile-type">
                                        {item?.timestamp}
                                      </p>
                                    </td>
                                    <td className="mobile-refersh-btn">
                                      {item?.status &&
                                        item?.status.toLowerCase() !==
                                          "success" && (
                                          <RefreshIcon
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              RefreshStatus(
                                                item?.transaction_id
                                              );
                                            }}
                                            className="cursor-pointer mobile-referesh-btn"
                                          />
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                            {history && history.length === 0 && (
                              <tr>
                                <td colSpan={5}>
                                  {/* <p className="text-center pt-[10px]">
                                    No Record Found
                                  </p> */}

                                  <div className="empty ">
                                    <img
                                      src="img/EmptyState.png"
                                      alt=""
                                      style={{
                                        margin: "auto",
                                        marginTop: "60px",
                                        height: "204px",
                                      }}
                                    />
                                    <h4 className="not-transction text-center">
                                      Transaction
                                    </h4>
                                    <p className="not-transction-p">
                                      You don't have any pending or completed
                                      transction.
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* <div className="pagination">
                          <div>
                            <a href="">See More</a>
                          </div>
                        </div> */}
                      </div>
                    )}
                    {isReceived === true && (
                      <div className="trc-scroll">
                        <table class="table table-dark table-border received-trx-table-third">
                          <thead>
                            <tr className="fs-13">
                              <th>DETAILS</th>
                              <th className="received-trx-table"></th>
                              <th className="d-none d-sm-table-cell">TYPE</th>
                              <th>STATUS</th>
                              <th className="d-none d-sm-table-cell">DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {receivedHistory &&
                              receivedHistory.length > 0 &&
                              receivedHistory.map((item) => {
                                return (
                                  <tr
                                    onClick={(e) =>
                                      openNewTab(e, item?.transaction_id)
                                    }
                                  >
                                    <td className="d-flex align-items-center mb-0">
                                      <p>
                                        {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
                                      </p>
                                      <img src="/img/dashbordlist.svg" alt="" />
                                      <p className="fs-12 ms-2 mb-0 received-trx-list">
                                        {isUsdt === false
                                          ? item?.amount / 1000000 + " TRX"
                                          : item?.value / 1000000 + " USDT"}
                                        <span className="d-block fs-10">
                                          Received from : {item?.from}
                                        </span>
                                        <p className="fs-12 received-trc-type-mobile">
                                          <Moment
                                            date={
                                              isUsdt === false
                                                ? item?.timestamp
                                                : item?.block_timestamp
                                            }
                                            format="YYYY-MM-DD HH:MM"
                                          />
                                        </p>
                                      </p>
                                    </td>
                                    <td>
                                      <p className="fs-12 received-trc-type">
                                        RECEIVED
                                      </p>
                                    </td>
                                    <td className="trc-received-mobile-btn">
                                      <a href="" className="complete-btn">
                                        {isUsdt === true
                                          ? "SUCCESS"
                                          : item?.status}
                                      </a>
                                    </td>
                                    <td className="">
                                      <p className="fs-12 received-trc-type">
                                        <Moment
                                          date={
                                            isUsdt === false
                                              ? item?.timestamp
                                              : item?.block_timestamp
                                          }
                                          format="YYYY-MM-DD HH:MM"
                                        />
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}
                            {receivedHistory &&
                              receivedHistory.length === 0 && (
                                <tr>
                                  <td colSpan={5}>
                                    <p className="text-center pt-[10px]">
                                      No Record Found
                                    </p>
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                        {/* <div className="pagination">
                          <div>
                            <a href="">See More</a>
                          </div>
                        </div> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12"> </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading === true && <Loader />}
    </div>
  );
}

export default Trc;

// select button

// import React, { useState, useEffect } from "react";
// import "./trc.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Avatar from "@mui/material/Avatar";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import Sendmodal from "./sendmodal";
// import Sellmodal from "./sellmodal";
// import Receivemodal from "./receivedmodal";
// import Trc20Services from "../../Services/LoginSignService/Trc20Service";
// import Moment from "react-moment";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import JoinFullIcon from "@mui/icons-material/JoinFull";
// import CachedIcon from "@mui/icons-material/Cached";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// function Trc() {
//   const [buttonText, setButtonText] = useState("Received TRX Transactions");
//   const [showDetail, setShowDetail] = useState(false);
//   const [tronWalletInfo, setTronWalletDetail] = useState({});
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [isLoading, setIsLoading] = useState(false);
//   const [rate, setRate] = useState({});
//   const [history, setHistory] = useState([]);
//   const [receivedHistory, setReceivedHistory] = useState([]);
//   const [isReceived, setIsReceived] = useState(false);
//   const [isUsdt, setIsUsdt] = useState(false);
//   const [onLoad, setOnLoad] = useState(true);

//   /*******************Get Current Date */
//   const today = new Date();
//   const date = today.getDate();
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   var weekday = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//   ];
//   const month = monthNames[today.getMonth()];
//   const day = weekday[today.getDay()];
//   const currentDate = day + " , " + month + "  " + date + "th";
//   /**************************End *******************/

//   useEffect(() => {
//     getTronWalletDetail();
//   }, []);

//   const handleClick = async (e) => {
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const trxTransaction = await Trc20Services.GetTrxReceiveTransaction(
//         auth,
//         body,
//         isUsdt
//       );
//       let record = trxTransaction?.result;
//       record = record.filter((item) => {
//         return item.type === "received";
//       });
//       setReceivedHistory(record);
//       setIsReceived(true);
//       let type = isUsdt === true ? "usdt" : "trx";
//       setButtonText(`Send/Sell ${type.toUpperCase()} Transaction`);
//       setHistory([]);
//       setIsLoading(false);
//     }
//   };

//   const handleSendSellClick = async (e, type) => {
//     setIsLoading(true);
//     setIsUsdt(type);
//     e.preventDefault();
//     let checkType = type === true ? "usdt" : "trx";
//     const trxTransaction = await Trc20Services.GetTrxTransaction(auth, type);
//     setButtonText(`Received ${checkType.toUpperCase()} Transactions`);
//     setHistory(trxTransaction?.result?.data);
//     setIsReceived(false);
//     setReceivedHistory([]);
//     setIsLoading(false);
//   };

//   const Loader = () => {
//     return (
//       <>
//         <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
//           <div class="flex items-center">
//             <svg
//               class="animate-spin h-20 w-20 text-blue-950"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//             >
//               <circle
//                 class="opacity-25"
//                 cx="12"
//                 cy="12"
//                 r="10"
//                 stroke="gray"
//                 stroke-width="4"
//               ></circle>
//               <path
//                 fill="#fff"
//                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//               ></path>
//             </svg>
//           </div>
//         </div>
//       </>
//     );
//   };

//   const getTronWalletDetail = async () => {
//     try {
//       setIsLoading(true);
//       if (auth) {
//         const apicall = await Trc20Services.GetTronUserWallet(auth);

//         if (apicall.result.isdetailadded === 1) {
//           setShowDetail(true);
//           setTronWalletDetail(apicall.result?.data);
//           const adminRate = await Trc20Services.GetAdminRate(auth);
//           setRate(adminRate?.result?.data);

//           const trxTransaction = await Trc20Services.GetTrxTransaction(
//             auth,
//             false
//           );
//           setHistory(trxTransaction?.result?.data);
//         }
//         setIsLoading(false);
//         setOnLoad(false);
//       }
//     } catch (error) {}
//   };

//   const createWallet = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.CreateJwtTronToken(auth, body);
//       if (tronToken.result.status === 200) {
//         getTronWalletDetail();
//         setIsLoading(false);
//       }
//     }
//   };

//   const refreshBlanceTab = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.tapToRefreshTRC20(auth, body);
//       if (tronToken.result.status === 200) {
//         getTronWalletDetail();
//       }
//       setIsLoading(false);
//     }
//   };

//   const RefreshStatus = async (txId) => {
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.ChangeStatusByTransactionId(
//         auth,
//         body,
//         txId
//       );
//       if (tronToken && tronToken.result && tronToken.result.status === 200) {
//         const trxTransaction = await Trc20Services.GetTrxTransaction(
//           auth,
//           isUsdt
//         );
//         setHistory(trxTransaction?.result?.data);
//         setIsLoading(false);
//       } else {
//         setIsLoading(false);
//       }
//     }
//   };

//   const openNewTab = (e, transaction_id) => {
//     e.preventDefault();

//     let url = `https://tronscan.org/#/transaction/${transaction_id}`;
//     window.open(url, "_blank");
//   };

//   const refreshHistory = async () => {
//     await getTronWalletDetail();
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.tapToRefreshTRC20(auth, body);
//       if (tronToken.result.status === 200) {
//         getTronWalletDetail();
//       }
//       setIsLoading(false);
//     }
//   };

//   // copy paste
//   {
//     /* 1411 */
//   }
//   const [isCopied, setIsCopied] = useState(false);
//   const copyTronAddress = () => {
//     const tronAddress = tronWalletInfo.tron_wallet_address;
//     navigator.clipboard.writeText(tronAddress);
//     setIsCopied(true);

//     setTimeout(() => {
//       setIsCopied(false);
//     }, 5000);
//   };
//   {
//     /* 1411 */
//   }
//   return (
//     <div className="container-fluid position-relative d-flex p-0 crypto-container">
//       <ToastContainer />
//       <Header />
//       <div className="content">
//         <Subheader />
//         <div
//           className={`container-fluid pt-4 px-4 crypto-table  ${
//             showDetail ? "" : "!h-screen"
//           } ${
//             history.length != 0 || receivedHistory.length != 0
//               ? ""
//               : "!h-screen"
//           }`}
//         >
//           <div className="row">
//             <div
//               className={`${
//                 showDetail === true
//                   ? "col-lg-7 col-md-7 col-sm-12 col-12"
//                   : "col-lg-12 col-md-12 col-sm-12 col-12"
//               }`}
//             >
//               <div className={`${onLoad ? "!hidden" : "d-flex"}`}>
//                 <div className="mb-3">
//                   <span className=" ">
//                     <img src="/img/back.svg" className="right-icon " />
//                   </span>
//                 </div>
//                 <div className="ms-2  ">
//                   <h4 className="h6 trc mb-0">TRC20(USDT & TRON)</h4>
//                   <p className="mb-0  fs-12 trc-p">
//                     Molestie et amet cursus vestibulum quam commodo.
//                   </p>
//                 </div>
//               </div>
//               {showDetail === true ? (
//                 <div className="balance-header d-flex justify-content-between col-md-12 gx-5">
//                   <div className="  trc-heading ">
//                     <p className=" ">
//                       <img
//                         src="/img/overallicon.svg"
//                         className="trc-heading-img"
//                       />
//                       <h6
//                         className=" total-trx pl-3"
//                         style={{ marginTop: "-20px" }}
//                       >
//                         {" "}
//                         Total balance (TRC20)
//                       </h6>
//                       <span className="rotationTop ">
//                         <a
//                           onClick={refreshBlanceTab}
//                           class="  cursor-pointer text-center text-white h5 text-decoration-none"
//                         >
//                           <img
//                             src="/img/shape1.svg"
//                             style={{ height: "14px" }}
//                           />
//                         </a>
//                       </span>
//                     </p>
//                     <h6 className="h4 ml-3 trc-h6 mb-2">
//                       {tronWalletInfo?.tron_bal && tronWalletInfo.tron_bal}
//                       <span className="h6-btc">TRX</span>
//                       <span className="d-block h4 d-block-usdt-btc mt-1">
//                         {tronWalletInfo?.usdt_bal && tronWalletInfo.usdt_bal}{" "}
//                         USDT
//                       </span>
//                     </h6>
//                   </div>

//                   <div className="rate-heading ">
//                     <h5 className="rate">
//                       <p>
//                         <img
//                           src="/img/crypto.svg"
//                           className="mb-3 rate-img-trc"
//                           alt=""
//                         />
//                         <h5 className="rate-h5">Rate</h5>
//                       </p>
//                       <span className="rotation ">
//                         <a
//                           onClick={refreshBlanceTab}
//                           class="  cursor-pointer text-center text-white h5 text-decoration-none"
//                         >
//                           <img
//                             src="/img/shape1.svg"
//                             style={{ height: "14px" }}
//                           />
//                         </a>
//                       </span>
//                     </h5>

//                     <p className=" rate-icon">
//                       <span className="p-2">₦</span>
//                       {rate?.usdt_rate && rate?.usdt_rate}
//                     </p>
//                   </div>
//                 </div>
//               ) : (
//                 <div
//                   class={`align-items-center text-white  justify-content-center mb-2 border-bottom-0 ${
//                     onLoad ? "!hidden" : "d-flex"
//                   }`}
//                 >
//                   <div className="flex flex-col items-center mt-5 btc-wallet-img trc-no-bal">
//                     <img src="/img/btcgruop.svg" alt="BTC Wallet" />
//                     <h6 className="trc-no-bal-heading">
//                       TRC20(USDT & TRON) Wallet
//                     </h6>
//                     <p className="trc-no-bal-p">
//                       {" "}
//                       You do not have a TRC20(USDT & TRON)
//                     </p>
//                     <p className="trc-no-bal-p-two">
//                       Wallet, click on the link below to create wallet
//                     </p>
//                     <button
//                       type="button"
//                       className="btn transaction btn-exchange1"
//                       onClick={(e) => createWallet(e)}
//                     >
//                       <span className="p-1" style={{ fontSize: "14px" }}>
//                         +
//                       </span>
//                       Create Wallet
//                     </button>
//                   </div>
//                 </div>
//               )}

//               {showDetail === true ? (
//                 <>
//                   <div
//                     style={{ marginTop: "-80px" }}
//                     className="row text-center copy-paste-header "
//                   >
//                     {/* 1411 */}
//                     <div className="col-lg-12 col-md-12 col-sm-12 col-12">
//                       <div className="trc-block1 mx-3">
//                         <p className="tap-usd-trc">
//                           Tap to copy USDT(TRC) Address
//                         </p>
//                         {/* 1411 */}
//                         <span className="d-block h6 block-btc-h ">
//                           {tronWalletInfo.tron_wallet_address}
//                           <ContentCopyIcon
//                             className="copy-icon copy-icon-mobile"
//                             style={{
//                               float: "right",
//                               marginTop: "-10px",
//                             }}
//                             onClick={copyTronAddress}
//                           />
//                         </span>
//                         {isCopied && (
//                           <p
//                             className="text-light"
//                             style={{ textAlign: "right" }}
//                           >
//                             Copied!
//                           </p>
//                         )}
//                         {/* 1411 */}
//                       </div>
//                     </div>
//                     {/* 1411 */}
//                   </div>
//                 </>
//               ) : (
//                 <></>
//               )}
//             </div>
//             {/* 1411 */}
//             {showDetail === true && (
//               <>
//                 <div className="col-lg-5 col-md-5 col-sm-12 col-12 mt-5">
//                   <div className="balance-header1">
//                     <div className="btn-container mt-5 m-auto">
//                       <Sendmodal auth={auth} refreshHistory={refreshHistory} />
//                       <Sellmodal refreshHistory={refreshHistory} />
//                       <Receivemodal
//                         tronAddress={
//                           tronWalletInfo?.tron_wallet_address &&
//                           tronWalletInfo.tron_wallet_address
//                         }
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </>
//             )}
//             {/* 1411 */}
//           </div>

//           <div>
//             <div className="row trc-table1">
//               <div className="col-lg-8 col-md-8 col-sm-12 col-12">
//                 {showDetail === true && (
//                   <div>
//                     {/* <div class="card-header align-items-center text-white d-flex flex-col justify-content-center mb-2 border-bottom-0">
//                     <a
//                       onClick={refreshBlanceTab}
//                       class="mb-0 cursor-pointer text-center text-white h5 text-decoration-none"
//                     >
//                       Tap to Refresh Balance
//                     </a>
//                   </div> */}

//                     {/* <div class="card-btn align-items-center text-white d-flex justify-content-center mb-2 border-bottom-0">
//                     <button
//                       type="button"
//                       style={{
//                         backgroundColor:
//                           isUsdt === false ? "#ffffff" : "#b3acc5",
//                       }}
//                       className="btn transaction"
//                       onClick={(e) => {
//                         setButtonText("Received TRX Transaction");
//                         handleSendSellClick(e, false);
//                       }}
//                     >
//                       TRX Transactions
//                     </button>
//                     <button
//                       type="button"
//                       style={{
//                         backgroundColor:
//                           isUsdt === true ? "#ffffff" : "#b3acc5",
//                       }}
//                       className="btn transaction"
//                       onClick={(e) => {
//                         setButtonText("Received USDT Transaction");
//                         handleSendSellClick(e, true);
//                       }}
//                     >
//                       USDT Transactions
//                     </button>
//                   </div> */}

//                     {/* <div class="card-btn align-items-center text-white d-flex justify-content-center mb-2 border-bottom-0">
//                     <button
//                       type="button"
//                       className="btn transaction"
//                       onClick={(e) =>
//                         isReceived === false
//                           ? handleClick(e, isUsdt)
//                           : handleSendSellClick(e, isUsdt)
//                       }
//                     >
//                       {buttonText}
//                     </button>
//                   </div> */}
//                     {isReceived === false && (
//                       <div className="trc-scroll">
//                         {/* <div className="row heading ">
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="transaction-Heading">
//                               <h4 className="ml-2">Transaction</h4>
//                               <p className="heading-p ml-2">
//                                 Monday October 17th
//                               </p>
//                             </div>
//                           </div>
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="mt-4 ">
//                               <button
//                                 type="button"
//                                 style={{
//                                   backgroundColor:
//                                     isUsdt === false ? "#5109ee" : "#b3acc5",
//                                 }}
//                                 className="btn trx-button"
//                                 onClick={(e) => {
//                                   setButtonText("Received TRX Transaction");
//                                   handleSendSellClick(e, false);
//                                 }}
//                               >
//                                 TRX Transactions
//                               </button>
//                             </div>
//                           </div>
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="mt-4">
//                               <button
//                                 type="button"
//                                 style={{
//                                   backgroundColor:
//                                     isUsdt === true ? "#ffffff" : "#000000",
//                                 }}
//                                 className="btn usdt-btn "
//                                 onClick={(e) => {
//                                   setButtonText("Received USDT Transaction");
//                                   handleSendSellClick(e, true);
//                                 }}
//                               >
//                                 USDT Transactions
//                               </button>
//                             </div>
//                           </div>
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="mt-4">
//                               <button
//                                 type="button"
//                                 className="btn received-btn"
//                                 onClick={(e) =>
//                                   isReceived === false
//                                     ? handleClick(e, isUsdt)
//                                     : handleSendSellClick(e, isUsdt)
//                                 }
//                               >
//                                 {buttonText}
//                               </button>
//                             </div>
//                           </div>
//                           <hr style={{ color: "gray" }} />
//                         </div> */}

//                         <div className="row heading">
//                           <div className="col-lg-4 col-md-4 col-sm-12 col-12">
//                             <div className="transaction-Heading">
//                               <h4 className="ml-3">Transaction</h4>
//                               <p className="heading-p ml-3">
//                                 {`${currentDate}`}
//                               </p>
//                             </div>
//                           </div>

//                           {/* <div className="col-lg-8 col-md-8 col-sm-12 col-12">
//                             <div className="d-lg-flex d-md-flex d-sm-flex d-flex mx-2 three-btn">
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   style={{
//                                     backgroundColor:
//                                       isUsdt === false ? "#5109ee" : "#5109ee",
//                                   }}
//                                   className="btn trx-button"
//                                   onClick={(e) => {
//                                     setButtonText("Received TRX Transaction");
//                                     handleSendSellClick(e, false);
//                                   }}
//                                 >
//                                   TRX Transactions
//                                 </button>
//                               </div>
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   style={{
//                                     backgroundColor:
//                                       isUsdt === true ? "#000000" : "#000000",
//                                   }}
//                                   className="btn usdt-btn"
//                                   onClick={(e) => {
//                                     setButtonText("Received USDT Transaction");
//                                     handleSendSellClick(e, true);
//                                   }}
//                                 >
//                                   USDT Transactions
//                                 </button>
//                               </div>
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   className="btn received-btn"
//                                   onClick={(e) =>
//                                     isReceived === false
//                                       ? handleClick(e, isUsdt)
//                                       : handleSendSellClick(e, isUsdt)
//                                   }
//                                 >
//                                   {buttonText}
//                                 </button>
//                               </div>
//                             </div>
//                           </div> */}

//                           <div className="col-lg-8 col-md-8 col-sm-12 col-12">
//                             <div className="d-lg-flex d-md-flex d-sm-flex d-flex mx-2 three-btn">
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   style={{
//                                     backgroundColor: !isUsdt
//                                       ? "#5109ee"
//                                       : "#000000",
//                                     color: !isUsdt ? "white" : "white",
//                                   }}
//                                   className="btn trx-button"
//                                   onClick={(e) => {
//                                     setButtonText("Received TRX Transaction");
//                                     handleSendSellClick(e, false);
//                                     setIsUsdt(false);
//                                   }}
//                                 >
//                                   TRX Transactions
//                                 </button>
//                               </div>
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   style={{
//                                     backgroundColor: isUsdt
//                                       ? "#5109ee"
//                                       : "#000000",
//                                     color: isUsdt ? "white" : "white",
//                                   }}
//                                   className="btn usdt-btn"
//                                   onClick={(e) => {
//                                     setButtonText("Received USDT Transaction");
//                                     handleSendSellClick(e, true);
//                                     setIsUsdt(true);
//                                   }}
//                                 >
//                                   USDT Transactions
//                                 </button>
//                               </div>
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   className="btn received-btn"
//                                   onClick={(e) =>
//                                     isReceived === false
//                                       ? handleClick(e, isUsdt)
//                                       : handleSendSellClick(e, isUsdt)
//                                   }
//                                 >
//                                   {buttonText}
//                                 </button>
//                               </div>
//                             </div>
//                           </div>

//                           <hr style={{ color: "gray" }} />
//                         </div>

//                         <table class="table table-dark table-border first-trc-table ">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th className="hide-from-desktop"></th>

//                               <th className="d-none d-sm-table-cell">TYPE</th>
//                               <th>STATUS</th>
//                               <th className="d-none d-sm-table-cell">DATE</th>
//                               <th className="trc-referesh-mobile">Refresh</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {history &&
//                               history.length > 0 &&
//                               history.map((item) => {
//                                 return (
//                                   <tr
//                                     onClick={(e) =>
//                                       openNewTab(e, item?.transaction_id)
//                                     }
//                                   >
//                                     <td className="d-flex align-items-center">
//                                       <p>
//                                         {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
//                                       </p>
//                                       <img src="/img/dashbordlist.svg" alt="" />
//                                       <p className="fs-12 ms-2">
//                                         {isUsdt === false
//                                           ? item?.trx_value_to_send
//                                           : item?.usdt_value_to_send}{" "}
//                                         {item?.tron_type}
//                                         <span className="d-block fs-10 trc-mobile-header">
//                                           sent to : {item?.to}
//                                         </span>
//                                       </p>
//                                       <p className="fs-12 trc-date-mobile">
//                                         {item?.timestamp}
//                                       </p>
//                                     </td>
//                                     <td>
//                                       <p className="fs-12 hide-mobile-type">
//                                         SENT
//                                       </p>
//                                     </td>
//                                     <td className="trc-mobile-btn">
//                                       <a
//                                         href=""
//                                         className={`complete-btn ${
//                                           item?.status === "OUT_OF_ENERGY"
//                                             ? "!text-[#ff0000] !border-[#ff0000]"
//                                             : ""
//                                         }`}
//                                       >
//                                         {item?.status.toUpperCase()}
//                                       </a>
//                                     </td>
//                                     <td className="">
//                                       <p className="fs-12 hide-mobile-type">
//                                         {item?.timestamp}
//                                       </p>
//                                     </td>
//                                     <td className="mobile-refersh-btn">
//                                       {item?.status &&
//                                         item?.status.toLowerCase() !==
//                                           "success" && (
//                                           <RefreshIcon
//                                             onClick={(e) => {
//                                               e.stopPropagation();
//                                               RefreshStatus(
//                                                 item?.transaction_id
//                                               );
//                                             }}
//                                             className="cursor-pointer mobile-referesh-btn"
//                                           />
//                                         )}
//                                     </td>
//                                   </tr>
//                                 );
//                               })}
//                             {history && history.length === 0 && (
//                               <tr>
//                                 <td colSpan={5}>
//                                   {/* <p className="text-center pt-[10px]">
//                                     No Record Found
//                                   </p> */}

//                                   <div className="empty ">
//                                     <img
//                                       src="img/EmptyState.png"
//                                       alt=""
//                                       style={{
//                                         margin: "auto",
//                                         marginTop: "60px",
//                                         height: "204px",
//                                       }}
//                                     />
//                                     <h4 className="not-transction text-center">
//                                       Transaction
//                                     </h4>
//                                     <p className="not-transction-p">
//                                       You don't have any pending or completed
//                                       transction.
//                                     </p>
//                                   </div>
//                                 </td>
//                               </tr>
//                             )}
//                           </tbody>
//                         </table>
//                         {/* <div className="pagination">
//                           <div>
//                             <a href="">See More</a>
//                           </div>
//                         </div> */}
//                       </div>
//                     )}
//                     {isReceived === true && (
//                       <div className="trc-scroll">
//                         <table class="table table-dark table-border received-trx-table-third">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th className="received-trx-table"></th>
//                               <th className="d-none d-sm-table-cell">TYPE</th>
//                               <th>STATUS</th>
//                               <th className="d-none d-sm-table-cell">DATE</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {receivedHistory &&
//                               receivedHistory.length > 0 &&
//                               receivedHistory.map((item) => {
//                                 return (
//                                   <tr
//                                     onClick={(e) =>
//                                       openNewTab(e, item?.transaction_id)
//                                     }
//                                   >
//                                     <td className="d-flex align-items-center mb-0">
//                                       <p>
//                                         {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
//                                       </p>
//                                       <img src="/img/dashbordlist.svg" alt="" />
//                                       <p className="fs-12 ms-2 mb-0 received-trx-list">
//                                         {isUsdt === false
//                                           ? item?.amount / 1000000 + " TRX"
//                                           : item?.value / 1000000 + " USDT"}
//                                         <span className="d-block fs-10">
//                                           Received from : {item?.from}
//                                         </span>
//                                         <p className="fs-12 received-trc-type-mobile">
//                                           <Moment
//                                             date={
//                                               isUsdt === false
//                                                 ? item?.timestamp
//                                                 : item?.block_timestamp
//                                             }
//                                             format="YYYY-MM-DD HH:MM"
//                                           />
//                                         </p>
//                                       </p>
//                                     </td>
//                                     <td>
//                                       <p className="fs-12 received-trc-type">
//                                         RECEIVED
//                                       </p>
//                                     </td>
//                                     <td className="trc-received-mobile-btn">
//                                       <a href="" className="complete-btn">
//                                         {isUsdt === true
//                                           ? "SUCCESS"
//                                           : item?.status}
//                                       </a>
//                                     </td>
//                                     <td className="">
//                                       <p className="fs-12 received-trc-type">
//                                         <Moment
//                                           date={
//                                             isUsdt === false
//                                               ? item?.timestamp
//                                               : item?.block_timestamp
//                                           }
//                                           format="YYYY-MM-DD HH:MM"
//                                         />
//                                       </p>
//                                     </td>
//                                   </tr>
//                                 );
//                               })}
//                             {receivedHistory &&
//                               receivedHistory.length === 0 && (
//                                 <tr>
//                                   <td colSpan={5}>
//                                     <p className="text-center pt-[10px]">
//                                       No Record Found
//                                     </p>
//                                   </td>
//                                 </tr>
//                               )}
//                           </tbody>
//                         </table>
//                         {/* <div className="pagination">
//                           <div>
//                             <a href="">See More</a>
//                           </div>
//                         </div> */}
//                       </div>
//                     )}
//                   </div>
//                 )}
//               </div>
//               <div className="col-lg-4 col-md-4 col-sm-12 col-12"> </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {isLoading === true && <Loader />}
//     </div>
//   );
// }

// export default Trc;

// 20/12/23

// import React, { useState, useEffect } from "react";
// import "./trc.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Avatar from "@mui/material/Avatar";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import Sendmodal from "./sendmodal";
// import Sellmodal from "./sellmodal";
// import Receivemodal from "./receivedmodal";
// import Trc20Services from "../../Services/LoginSignService/Trc20Service";
// import Moment from "react-moment";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import JoinFullIcon from "@mui/icons-material/JoinFull";
// import CachedIcon from "@mui/icons-material/Cached";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// function Trc() {
//   const [buttonText, setButtonText] = useState("Received TRX Transactions");
//   const [showDetail, setShowDetail] = useState(false);
//   const [tronWalletInfo, setTronWalletDetail] = useState({});
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [isLoading, setIsLoading] = useState(false);
//   const [rate, setRate] = useState({});
//   const [history, setHistory] = useState([]);
//   const [receivedHistory, setReceivedHistory] = useState([]);
//   const [isReceived, setIsReceived] = useState(false);
//   const [isUsdt, setIsUsdt] = useState(false);
//   const [onLoad, setOnLoad] = useState(true);

//   /*******************Get Current Date */
//   const today = new Date();
//   const date = today.getDate();
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   var weekday = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//   ];
//   const month = monthNames[today.getMonth()];
//   const day = weekday[today.getDay()];
//   const currentDate = day + " , " + month + "  " + date + "th";
//   /**************************End *******************/

//   useEffect(() => {
//     getTronWalletDetail();
//   }, []);

//   const handleClick = async (e) => {
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const trxTransaction = await Trc20Services.GetTrxReceiveTransaction(
//         auth,
//         body,
//         isUsdt
//       );
//       let record = trxTransaction?.result;
//       record = record.filter((item) => {
//         return item.type === "received";
//       });
//       setReceivedHistory(record);
//       setIsReceived(true);
//       let type = isUsdt === true ? "usdt" : "trx";
//       setButtonText(`Send/Sell ${type.toUpperCase()} Transaction`);
//       setHistory([]);
//       setIsLoading(false);
//     }
//   };

//   const handleSendSellClick = async (e, type) => {
//     setIsLoading(true);
//     setIsUsdt(type);
//     e.preventDefault();
//     let checkType = type === true ? "usdt" : "trx";
//     const trxTransaction = await Trc20Services.GetTrxTransaction(auth, type);
//     setButtonText(`Received ${checkType.toUpperCase()} Transactions`);
//     setHistory(trxTransaction?.result?.data);
//     setIsReceived(false);
//     setReceivedHistory([]);
//     setIsLoading(false);
//   };

//   const Loader = () => {
//     return (
//       <>
//         <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
//           <div class="flex items-center">
//             <svg
//               class="animate-spin h-20 w-20 text-blue-950"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//             >
//               <circle
//                 class="opacity-25"
//                 cx="12"
//                 cy="12"
//                 r="10"
//                 stroke="gray"
//                 stroke-width="4"
//               ></circle>
//               <path
//                 fill="#fff"
//                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//               ></path>
//             </svg>
//           </div>
//         </div>
//       </>
//     );
//   };

//   const getTronWalletDetail = async () => {
//     try {
//       setIsLoading(true);
//       if (auth) {
//         const apicall = await Trc20Services.GetTronUserWallet(auth);

//         if (apicall.result.isdetailadded === 1) {
//           setShowDetail(true);
//           setTronWalletDetail(apicall.result?.data);
//           const adminRate = await Trc20Services.GetAdminRate(auth);
//           setRate(adminRate?.result?.data);

//           const trxTransaction = await Trc20Services.GetTrxTransaction(
//             auth,
//             false
//           );
//           setHistory(trxTransaction?.result?.data);
//         }
//         setIsLoading(false);
//         setOnLoad(false);
//       }
//     } catch (error) {}
//   };

//   const createWallet = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.CreateJwtTronToken(auth, body);
//       if (tronToken.result.status === 200) {
//         getTronWalletDetail();
//         setIsLoading(false);
//       }
//     }
//   };

//   const refreshBlanceTab = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.tapToRefreshTRC20(auth, body);
//       if (tronToken.result.status === 200) {
//         getTronWalletDetail();
//       }
//       setIsLoading(false);
//     }
//   };

//   const RefreshStatus = async (txId) => {
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.ChangeStatusByTransactionId(
//         auth,
//         body,
//         txId
//       );
//       if (tronToken && tronToken.result && tronToken.result.status === 200) {
//         const trxTransaction = await Trc20Services.GetTrxTransaction(
//           auth,
//           isUsdt
//         );
//         setHistory(trxTransaction?.result?.data);
//         setIsLoading(false);
//       } else {
//         setIsLoading(false);
//       }
//     }
//   };

//   const openNewTab = (e, transaction_id) => {
//     e.preventDefault();

//     let url = `https://tronscan.org/#/transaction/${transaction_id}`;
//     window.open(url, "_blank");
//   };

//   const refreshHistory = async () => {
//     await getTronWalletDetail();
//     setIsLoading(true);
//     let body = {
//       key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
//       email: auth?.user.email,
//       phone: auth?.user.phone,
//     };
//     if (auth) {
//       const tronToken = await Trc20Services.tapToRefreshTRC20(auth, body);
//       if (tronToken.result.status === 200) {
//         getTronWalletDetail();
//       }
//       setIsLoading(false);
//     }
//   };

//   // copy paste
//   {
//     /* 1411 */
//   }
//   const [isCopied, setIsCopied] = useState(false);
//   const copyTronAddress = () => {
//     const tronAddress = tronWalletInfo.tron_wallet_address;
//     navigator.clipboard.writeText(tronAddress);
//     setIsCopied(true);

//     setTimeout(() => {
//       setIsCopied(false);
//     }, 5000);
//   };
//   {
//     /* 1411 */
//   }
//   return (
//     <div className="container-fluid position-relative d-flex p-0 crypto-container">
//       <ToastContainer />
//       <Header />
//       <div className="content">
//         <Subheader />
//         <div
//           className={`container-fluid pt-4 px-4 crypto-table  ${
//             showDetail ? "" : "!h-screen"
//           } ${
//             history.length != 0 || receivedHistory.length != 0
//               ? ""
//               : "!h-screen"
//           }`}
//         >
//           <div className="row">
//             <div className={`${showDetail === true ? "col-lg-7 col-md-7 col-sm-12 col-12":"col-lg-12 col-md-12 col-sm-12 col-12"}`}>
//               <div className={`${onLoad ? "!hidden" : "d-flex"}`}>
//                 <div className="mb-3">
//                   <span className=" ">
//                     <img src="/img/back.svg" className="right-icon " />
//                   </span>
//                 </div>
//                 <div className="ms-2  ">
//                   <h4 className="h6 trc mb-0">TRC20(USDT & TRON)</h4>
//                   <p className="mb-0  fs-12 trc-p">
//                     Molestie et amet cursus vestibulum quam commodo.
//                   </p>
//                 </div>
//               </div>
//               {showDetail === true ? (
//                 <div className="balance-header d-flex justify-content-between col-md-12 gx-5">
//                   <div className="  trc-heading ">
//                     <p className=" ">
//                       <img src="/img/overallicon.svg"  className="trc-heading-img" />
//                       <h6 className=" total-trx pl-3" style={{ marginTop: "-20px" }}>
//                         {" "}
//                         Total balance (TRC20)
//                       </h6>
//                       <span className="rotationTop ">
//                         <a
//                           onClick={refreshBlanceTab}
//                           class="  cursor-pointer text-center text-white h5 text-decoration-none"
//                         >
//                           <img
//                             src="/img/shape1.svg"
//                             style={{ height: "14px" }}
//                           />
//                         </a>
//                       </span>
//                     </p>
//                     <h6 className="h4 ml-3 trc-h6 mb-2">
//                       {tronWalletInfo?.tron_bal && tronWalletInfo.tron_bal}
//                       <span className="h6-btc">TRX</span>
//                       <span className="d-block h4 d-block-usdt-btc mt-1">
//                         {tronWalletInfo?.usdt_bal && tronWalletInfo.usdt_bal}{" "}
//                         USDT
//                       </span>
//                     </h6>
//                   </div>

//                   <div className="rate-heading ">
//                     <h5 className="rate">
//                       <p>
//                         <img
//                           src="/img/crypto.svg"
//                           className="mb-3 rate-img-trc"
//                           alt=""
//                         />
//                         <h5 className="rate-h5">Rate</h5>
//                       </p>
//                       <span className="rotation ">
//                         <a
//                           onClick={refreshBlanceTab}
//                           class="  cursor-pointer text-center text-white h5 text-decoration-none"
//                         >
//                           <img
//                             src="/img/shape1.svg"
//                             style={{ height: "14px" }}
//                           />
//                         </a>
//                       </span>
//                     </h5>

//                     <p className=" rate-icon">
//                       <span className="p-2">₦</span>
//                       {rate?.usdt_rate && rate?.usdt_rate}
//                     </p>
//                   </div>
//                 </div>
//               ) : (
//                 <div
//                   class={`align-items-center text-white  justify-content-center mb-2 border-bottom-0 ${
//                     onLoad ? "!hidden" : "d-flex"
//                   }`}
//                 >
//                   <div className="flex flex-col items-center mt-5 btc-wallet-img trc-no-bal">
//                     <img src="/img/btcgruop.svg" alt="BTC Wallet" />
//                     <h6 className="trc-no-bal-heading">
//                       TRC20(USDT & TRON) Wallet
//                     </h6>
//                     <p
//                      className="trc-no-bal-p"
//                     >
//                       {" "}
//                       You do not have a TRC20(USDT & TRON)
//                     </p>
//                     <p
//                       className="trc-no-bal-p-two"
//                     >
//                       Wallet, click on the link below to create wallet
//                     </p>
//                     <button
//                       type="button"
//                       className="btn transaction btn-exchange1"
//                       onClick={(e) => createWallet(e)}
//                     >
//                       <span className="p-1" style={{ fontSize: "14px" }}>
//                         +
//                       </span>
//                       Create Wallet
//                     </button>
//                   </div>
//                 </div>
//               )}

//             {showDetail === true ? (<>
//               <div
//                 style={{ marginTop: "-80px" }}
//                 className="row text-center copy-paste-header "
//                  >
//                 {/* 1411 */}
//                 <div className="col-lg-12 col-md-12 col-sm-12 col-12">
//                   <div className="trc-block1 mx-3">
//                     <p className="tap-usd-trc">Tap to copy USDT(TRC) Address</p>
//                     {/* 1411 */}
//                     <span className="d-block h6 block-btc-h ">
//                       {tronWalletInfo.tron_wallet_address}
//                       <ContentCopyIcon
//                         className="copy-icon copy-icon-mobile"
//                         style={{
//                           float: "right",
//                           marginTop: "-10px",
//                         }}
//                         onClick={copyTronAddress}
//                       />
//                     </span>
//                     {isCopied && (
//                       <p className="text-light" style={{ textAlign: "right" }}>
//                         Copied!
//                       </p>
//                     )}
//                     {/* 1411 */}
//                   </div>
//                 </div>
//                 {/* 1411 */}
//               </div>
//             </>):(<></>)}

//             </div>
//             {/* 1411 */}
//             {showDetail === true && (
//               <>

//                 <div className="col-lg-5 col-md-5 col-sm-12 col-12 mt-5">
//                   <div className="balance-header1">
//                     <div className="btn-container mt-5 m-auto">
//                       <Sendmodal auth={auth} refreshHistory={refreshHistory} />
//                       <Sellmodal refreshHistory={refreshHistory} />
//                       <Receivemodal
//                         tronAddress={
//                           tronWalletInfo?.tron_wallet_address &&
//                           tronWalletInfo.tron_wallet_address
//                         }
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </>
//             )}
//             {/* 1411 */}
//           </div>

//           <div >
//             <div className="row trc-table1">
//               <div className="col-lg-8 col-md-8 col-sm-12 col-12">
//                 {showDetail === true && (
//                   <div>
//                     {/* <div class="card-header align-items-center text-white d-flex flex-col justify-content-center mb-2 border-bottom-0">
//                     <a
//                       onClick={refreshBlanceTab}
//                       class="mb-0 cursor-pointer text-center text-white h5 text-decoration-none"
//                     >
//                       Tap to Refresh Balance
//                     </a>
//                   </div> */}

//                     {/* <div class="card-btn align-items-center text-white d-flex justify-content-center mb-2 border-bottom-0">
//                     <button
//                       type="button"
//                       style={{
//                         backgroundColor:
//                           isUsdt === false ? "#ffffff" : "#b3acc5",
//                       }}
//                       className="btn transaction"
//                       onClick={(e) => {
//                         setButtonText("Received TRX Transaction");
//                         handleSendSellClick(e, false);
//                       }}
//                     >
//                       TRX Transactions
//                     </button>
//                     <button
//                       type="button"
//                       style={{
//                         backgroundColor:
//                           isUsdt === true ? "#ffffff" : "#b3acc5",
//                       }}
//                       className="btn transaction"
//                       onClick={(e) => {
//                         setButtonText("Received USDT Transaction");
//                         handleSendSellClick(e, true);
//                       }}
//                     >
//                       USDT Transactions
//                     </button>
//                   </div> */}

//                     {/* <div class="card-btn align-items-center text-white d-flex justify-content-center mb-2 border-bottom-0">
//                     <button
//                       type="button"
//                       className="btn transaction"
//                       onClick={(e) =>
//                         isReceived === false
//                           ? handleClick(e, isUsdt)
//                           : handleSendSellClick(e, isUsdt)
//                       }
//                     >
//                       {buttonText}
//                     </button>
//                   </div> */}
//                     {isReceived === false && (
//                       <div className="trc-scroll">
//                         {/* <div className="row heading ">
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="transaction-Heading">
//                               <h4 className="ml-2">Transaction</h4>
//                               <p className="heading-p ml-2">
//                                 Monday October 17th
//                               </p>
//                             </div>
//                           </div>
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="mt-4 ">
//                               <button
//                                 type="button"
//                                 style={{
//                                   backgroundColor:
//                                     isUsdt === false ? "#5109ee" : "#b3acc5",
//                                 }}
//                                 className="btn trx-button"
//                                 onClick={(e) => {
//                                   setButtonText("Received TRX Transaction");
//                                   handleSendSellClick(e, false);
//                                 }}
//                               >
//                                 TRX Transactions
//                               </button>
//                             </div>
//                           </div>
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="mt-4">
//                               <button
//                                 type="button"
//                                 style={{
//                                   backgroundColor:
//                                     isUsdt === true ? "#ffffff" : "#000000",
//                                 }}
//                                 className="btn usdt-btn "
//                                 onClick={(e) => {
//                                   setButtonText("Received USDT Transaction");
//                                   handleSendSellClick(e, true);
//                                 }}
//                               >
//                                 USDT Transactions
//                               </button>
//                             </div>
//                           </div>
//                           <div className="col-lg-3 col-md-3 col-sm-6 col-6">
//                             <div className="mt-4">
//                               <button
//                                 type="button"
//                                 className="btn received-btn"
//                                 onClick={(e) =>
//                                   isReceived === false
//                                     ? handleClick(e, isUsdt)
//                                     : handleSendSellClick(e, isUsdt)
//                                 }
//                               >
//                                 {buttonText}
//                               </button>
//                             </div>
//                           </div>
//                           <hr style={{ color: "gray" }} />
//                         </div> */}

//                         <div className="row heading">
//                           <div className="col-lg-4 col-md-4 col-sm-12 col-12">
//                             <div className="transaction-Heading">
//                               <h4 className="ml-3">Transaction</h4>
//                               <p className="heading-p ml-3">
//                                 {`${currentDate}`}
//                               </p>
//                             </div>
//                           </div>

//                           <div className="col-lg-8 col-md-8 col-sm-12 col-12">
//                             <div className="d-lg-flex d-md-flex d-sm-flex d-flex mx-2 three-btn">
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   style={{
//                                     backgroundColor:
//                                       isUsdt === false ? "#5109ee" : "#5109ee",
//                                   }}
//                                   className="btn trx-button"
//                                   onClick={(e) => {
//                                     setButtonText("Received TRX Transaction");
//                                     handleSendSellClick(e, false);
//                                   }}
//                                 >
//                                   TRX Transactions
//                                 </button>
//                               </div>
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   style={{
//                                     backgroundColor:
//                                       isUsdt === true ? "#000000" : "#000000",
//                                   }}
//                                   className="btn usdt-btn"
//                                   onClick={(e) => {
//                                     setButtonText("Received USDT Transaction");
//                                     handleSendSellClick(e, true);
//                                   }}
//                                 >
//                                   USDT Transactions
//                                 </button>
//                               </div>
//                               <div className="mt-4">
//                                 <button
//                                   type="button"
//                                   className="btn received-btn"
//                                   onClick={(e) =>
//                                     isReceived === false
//                                       ? handleClick(e, isUsdt)
//                                       : handleSendSellClick(e, isUsdt)
//                                   }
//                                 >
//                                   {buttonText}
//                                 </button>
//                               </div>
//                             </div>
//                           </div>

//                           <hr style={{ color: "gray" }} />
//                         </div>

//                         <table class="table table-dark table-border first-trc-table ">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th className="hide-from-desktop"></th>

//                               <th className="d-none d-sm-table-cell">TYPE</th>
//                               <th>STATUS</th>
//                               <th className="d-none d-sm-table-cell">DATE</th>
//                               <th className="trc-referesh-mobile" >Refresh</th>

//                             </tr>
//                           </thead>
//                           <tbody>
//                             {history &&
//                               history.length > 0 &&
//                               history.map((item) => {
//                                 return (
//                                   <tr
//                                     onClick={(e) =>
//                                       openNewTab(e, item?.transaction_id)
//                                     }
//                                   >
//                                     <td className="d-flex align-items-center">
//                                       <p>
//                                       {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
//                                       </p>
//                                       <img src="/img/dashbordlist.svg" alt=''  />
//                                       <p className="fs-12 ms-2">
//                                         {isUsdt === false
//                                           ? item?.trx_value_to_send
//                                           : item?.usdt_value_to_send}{" "}
//                                         {item?.tron_type}
//                                         <span className="d-block fs-10 trc-mobile-header">
//                                           sent to : {item?.to}
//                                         </span>
//                                       </p>
//                                       <p className="fs-12 trc-date-mobile">
//                                         {item?.timestamp}

//                                       </p>
//                                     </td>
//                                     <td>
//                                       <p className="fs-12 hide-mobile-type">SENT</p>
//                                     </td>
//                                     <td className="trc-mobile-btn">
//                                       <a
//                                         href=""
//                                         className={`complete-btn ${
//                                           item?.status === "OUT_OF_ENERGY"
//                                             ? "!text-[#ff0000] !border-[#ff0000]"
//                                             : ""
//                                         }`}
//                                       >
//                                         {item?.status.toUpperCase()}
//                                       </a>
//                                     </td>
//                                     <td className="">
//                                       <p className="fs-12 hide-mobile-type">
//                                         {item?.timestamp}

//                                       </p>
//                                     </td>
//                                     <td className="mobile-refersh-btn">
//                                       {item?.status &&
//                                         item?.status.toLowerCase() !==
//                                           "success" && (
//                                           <RefreshIcon
//                                             onClick={(e) => {
//                                               e.stopPropagation();
//                                               RefreshStatus(
//                                                 item?.transaction_id
//                                               );
//                                             }}
//                                             className="cursor-pointer mobile-referesh-btn"
//                                           />
//                                         )}
//                                     </td>
//                                   </tr>
//                                 );
//                               })}
//                             {history && history.length === 0 && (
//                               <tr>
//                                 <td colSpan={5}>
//                                   {/* <p className="text-center pt-[10px]">
//                                     No Record Found
//                                   </p> */}

//                                   <div className="empty ">
//                                     <img
//                                       src="img/EmptyState.png"
//                                       alt=""
//                                       style={{
//                                         margin: "auto",
//                                         marginTop: "60px",
//                                         height: "204px",
//                                       }}
//                                     />
//                                     <h4 className="not-transction text-center">
//                                       Transaction
//                                     </h4>
//                                     <p className="not-transction-p">
//                                       You don't have any pending or completed
//                                       transction.
//                                     </p>
//                                   </div>
//                                 </td>
//                               </tr>
//                             )}
//                           </tbody>
//                         </table>
//                         {/* <div className="pagination">
//                           <div>
//                             <a href="">See More</a>
//                           </div>
//                         </div> */}
//                       </div>
//                     )}
//                     {isReceived === true && (
//                       <div className="trc-scroll">
//                         <table class="table table-dark table-border received-trx-table-third">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th className="received-trx-table"></th>
//                               <th className="d-none d-sm-table-cell">TYPE</th>
//                               <th>STATUS</th>
//                               <th className="d-none d-sm-table-cell">DATE</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {receivedHistory &&
//                               receivedHistory.length > 0 &&
//                               receivedHistory.map((item) => {
//                                 return (
//                                   <tr
//                                     onClick={(e) =>
//                                       openNewTab(e, item?.transaction_id)
//                                     }
//                                   >
//                                     <td className="d-flex align-items-center mb-0">
//                                       <p>
//                                       {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
//                                       </p>
//                                       <img src="/img/dashbordlist.svg" alt=''  />
//                                       <p className="fs-12 ms-2 mb-0 received-trx-list">
//                                         {isUsdt === false
//                                           ? item?.amount / 1000000 + " TRX"
//                                           : item?.value / 1000000 + " USDT"}
//                                         <span className="d-block fs-10">
//                                           Received from : {item?.from}
//                                         </span>
//                                         <p className="fs-12 received-trc-type-mobile">
//                                         <Moment
//                                           date={
//                                             isUsdt === false
//                                               ? item?.timestamp
//                                               : item?.block_timestamp
//                                           }
//                                           format="YYYY-MM-DD HH:MM"
//                                         />
//                                       </p>
//                                       </p>
//                                     </td>
//                                     <td>
//                                       <p className="fs-12 received-trc-type">RECEIVED</p>
//                                     </td>
//                                     <td className="trc-received-mobile-btn">
//                                       <a href="" className="complete-btn">
//                                         {isUsdt === true
//                                           ? "SUCCESS"
//                                           : item?.status}
//                                       </a>
//                                     </td>
//                                     <td className="">
//                                       <p className="fs-12 received-trc-type">
//                                         <Moment
//                                           date={
//                                             isUsdt === false
//                                               ? item?.timestamp
//                                               : item?.block_timestamp
//                                           }
//                                           format="YYYY-MM-DD HH:MM"
//                                         />
//                                       </p>
//                                     </td>
//                                   </tr>
//                                 );
//                               })}
//                             {receivedHistory &&
//                               receivedHistory.length === 0 && (
//                                 <tr>
//                                   <td colSpan={5}>
//                                     <p className="text-center pt-[10px]">
//                                       No Record Found
//                                     </p>
//                                   </td>
//                                 </tr>
//                               )}
//                           </tbody>
//                         </table>
//                         {/* <div className="pagination">
//                           <div>
//                             <a href="">See More</a>
//                           </div>
//                         </div> */}
//                       </div>
//                     )}
//                   </div>
//                 )}
//               </div>
//               <div className="col-lg-4 col-md-4 col-sm-12 col-12"> </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {isLoading === true && <Loader />}
//     </div>
//   );
// }

// export default Trc;
