import React, { useEffect, useState } from "react";
import "./password.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Password() {
  const [modal, setmodal] = useState(false);
  const [email, setEmail] = useState("");
  const [get_email, setEmails] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirm] = useState("");
  const [otp, setOtp] = useState("");
  const [apiKeys, setApiKey] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchImg, setCaptchaImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  useEffect(() => {
    getAPI();
    getCaptch();
  }, []);

  const getAPI = async () => {
    const apicall = await LoginSignService.GetApiKey();
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        setApiKey(resp);
      });
    } else {
    }
  };

  const getCaptch = async () => {
    const apicall = await LoginSignService.GetCaptcha();

    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        setCaptchaImg(resp1);
      });
    } else {
    }
  };

  function emailSend() {
    if (validate()) {
      vCaptcha();
    }
  }

  const vCaptcha = async () => {
    setLoading(true);
    let captchakeyid = captchImg ? captchImg.data.captchakeyid : "";
    let captchaValue = captcha;
    var formData = new FormData();
    formData.append("captchakeyid", captchakeyid);
    formData.append("captchaValue", captchaValue);
    const apicall = await LoginSignService.VerifyCaptcha(formData);
    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        if (resp1.statusCode == 400) {
          setLoading(false);
          getCaptch();
          toast(resp1.message);
        }
        if (resp1.statusCode == 200) {
          emailSendUser();
        }
      });
    } else {
    }
  };
  const emailSendUser = async () => {
    let api_key = apiKeys.data.api_key;
    let item1 = { email, api_key };

    const apicall = await LoginSignService.SendEmail(item1);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status === 200) {
          setEmails(resp);
          setmodal(true);
        } else {
          setLoading(false);
          toast(resp.message);
        }
      });
    } else {
    }
  };

  const otpVerify = async (e) => {
    e.preventDefault();
    if (validateOtp()) {
      setLoading1(true);
      let email = get_email.data.email;
      let items = { password, password_confirmation, otp, email };
      const apicall = await LoginSignService.OTPVerify(items);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status === 200) {
            navigate("/");
          } else {
            toast(resp.message);
            if (resp.data) {
              setLoading1(false);
              toast(resp.data.password[0]);
            }
            setLoading1(false);
          }
        });
      } else {
      }
    }
  };

  const validateOtp = () => {
    let result1 = true;
    if (password === "" || password === null) {
      result1 = false;
      toast("The new password field is required");
    }
    if (password_confirmation === "" || password_confirmation === null) {
      result1 = false;
      toast("The confirm password field is required");
    }
    if (otp === "" || otp === null) {
      result1 = false;
      toast("The otp field is required");
    }
    return result1;
  };

  const validate = () => {
    let result = true;
    if (email === "" || email === null) {
      result = false;
      toast("The email field is required");
    }
    if (captcha === "" || captcha === null) {
      result = false;
      toast("The captcha field is required");
    }
    return result;
  };

  // mui css
  const textFieldStyles = {
    background: "#252731 ",
    width: "300px",
    height: "auto",
    borderRadius: "2px",
  };

  
  return (
    <div className="password-container ">
      <div className="password-form " style={{ marginTop: "80px" }}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="card form-container">
                <div class="card-body py-">
                  <h3 style={{color:"#FFFFFF"}}>Forgot Password</h3>
                  <p className="fs-14 " style={{ color: "gray" }}>
                    Please enter the email you register{" "}
                  </p>

                  <form _lpchecked="1">
                    <div class="form-group mt-2 w-100">
                      <TextField
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        //  class="form-control"
                        id="email"
                        label={<span style={{ color: "#ffffff" }}>Email</span>}
                        variant="filled"
                        style={textFieldStyles}
                        sx={{ input : {color : 'white'}}}
                      />
                    </div>
                    <div
                      className="form-group mt-3"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        type="text"
                        value={captcha}
                        onChange={(e) => setCaptcha(e.target.value)}
                        //  className="form-control mb-1"
                        id="password"
                        style={{ flex: 1, ...textFieldStyles }}
                        label={<span style={{ color: "#ffffff" }}>Captcha</span>}
                        variant="filled"
                        sx={{ input : {color : 'white'}}}
                      />
                      <div
                        className="captchaimage"
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "74%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <img
                          src={captchImg ? captchImg.data.captchaimage : ""}
                          alt="Captcha"
                          style={{
                            width: "100%",
                            height: "58px",
                            marginTop: "1px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className='mb-5 text-white'>
                   <a className='text-success text-decoration-none' href='' >
                     Checking
                   </a>
                  </div> */}

                    <div
                      class="d-flex flex-row align-items-center justify-content-center mt-3"
                      style={{ marginBottom: "40px" }}
                    >
                      <a
                        class={`btn btn-proceed w-100 ${
                          loading ? "disabled" : ""
                        }`}
                        onClick={emailSend}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>{" "}
                            Loading...
                          </>
                        ) : (
                          "Proceed"
                        )}
                      </a>
                    </div>

                    <ToastContainer />
                    <Modal
                      className="forget-password "
                      size="md"
                      isOpen={modal}
                      toggle={() => setmodal(!modal)}
                    >
                      <ModalHeader
                        className="bg-slate-50"
                        toggle={() => setmodal(!modal)}
                      >
                        <h3 className="text-white">Reset Password</h3>
                      </ModalHeader>
                      <ModalBody className="justify-content-center align-items-center d-flex flex-col w-100  modal-body ">
                        <Card
                          className="pt-2  card w-100"
                          sx={{ maxWidth: 500 }}
                        >
                          <CardContent className="w-100 text-center">
                            <Box className="w-100">
                              <form
                                _lpchecked="1"
                                onSubmit={otpVerify}
                                className="d-flex flex-col justify-content-cneter align-items-center"
                              >
                                <div className="form-group w-100 ">
                                  <TextField
                                    type="password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    // className="form-control mb-4"
                                    id="name"
                                    label={
                                      <span style={{ color: "#ffffff" }}>
                                        Password
                                      </span>
                                    }
                                    variant="filled"
                                    style={textFieldStyles}
                                    sx={{ input : {color : 'white'}}}
                                  />
                                </div>
                                <div className="form-group w-100 mt-2">
                                  <TextField
                                    type="password"
                                    value={password_confirmation}
                                    onChange={(e) =>
                                      setPasswordConfirm(e.target.value)
                                    }
                                    // className="form-control mb-4"
                                    id="name"
                                    label={
                                      <span style={{ color: "#ffffff" }}>
                                        Confirm New Password
                                      </span>
                                    }
                                    variant="filled"
                                    style={textFieldStyles}
                                    sx={{ input : {color : 'white'}}}
                                  />
                                </div>
                                <div className="form-group w-100 mt-2">
                                  <TextField
                                    type="text"
                                    // className="form-control mb-4"
                                    id="email"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    label={
                                      <span style={{ color: "#ffffff" }}>
                                        Enter OTP
                                      </span>
                                    }
                                    variant="filled"
                                    style={textFieldStyles}
                                    sx={{ input : {color : 'white'}}}
                                  />
                                </div>

                                <div
                                  className=" d-flex flex-row align-items-center justify-content-center form-group  mt-2 w-100 "
                                  
                                >
                                  <button
                                    type="submit"


                                    class={`btn btn-exchange  mx-1
                            ${loading1 ? "disabled" : ""}`}
                            style={{width:'70%'}}
                                  >
                                    {loading1 ? (
                                      <>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>{" "}
                                        Loading...
                                      </>
                                    ) : (
                                      "Update"
                                    )}
                                  </button>
                                </div>
                              </form>
                            </Box>
                          </CardContent>
                        </Card>
                      </ModalBody>
                    </Modal>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;




// old code

// import React, {useEffect, useState}  from 'react';
// import './password.scss';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import { Modal , ModalHeader ,ModalBody } from 'reactstrap';
// import LoginSignService from '../../Services/LoginSignService/LoginSignService';
// import { useNavigate } from 'react-router';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function Password() {
//    const [modal , setmodal] = useState(false)
//    const [email , setEmail] = useState("")
//    const [get_email,setEmails] = useState("")
//    const navigate = useNavigate();
//    const [password,setPassword] = useState("");
//    const [password_confirmation,setPasswordConfirm] = useState("");
//    const [otp,setOtp] = useState("");
//    const [apiKeys ,setApiKey] = useState("");
//    const [captcha ,setCaptcha] = useState("");
//    const [captchImg,setCaptchaImg] = useState("")
//    const [loading, setLoading] = useState(false);
//    const [loading1, setLoading1] = useState(false);

//    useEffect(()=>{
//       getAPI();
//       getCaptch();
//     },[])

//     const getAPI = async () => {
//       const apicall = await LoginSignService.GetApiKey();
//       if (apicall.state === true) {
//           apicall.result.json().then((resp) => {
//             setApiKey(resp);
//         })
//       }
//       else {
//       }
//     }

//     const getCaptch = async ()=>{
//       const apicall = await LoginSignService.GetCaptcha();

//       if (apicall.state === true) {
//          apicall.result.json().then((resp1) => {
//             setCaptchaImg(resp1);
//        })
//      }
//      else {
//      }

//     }

//     function emailSend()
//     {
//          if(validate()){
//             vCaptcha();
//          }

//     }

//     const vCaptcha = async () =>{
//       setLoading(true);
//       let captchakeyid = captchImg? captchImg.data.captchakeyid:'';
//       let captchaValue = captcha;
//       var formData = new FormData();
//       formData.append("captchakeyid",captchakeyid);
//       formData.append("captchaValue",captchaValue);
//       const apicall = await LoginSignService.VerifyCaptcha(formData);
//       if (apicall.state === true) {
//          apicall.result.json().then((resp1) => {
//             if(resp1.statusCode == 400)
//             {
//                setLoading(false);
//                getCaptch();
//                toast(resp1.message)

//             }
//             if(resp1.statusCode == 200)
//             {
//                emailSendUser();

//             }
//        })
//      }
//      else {
//      }
//     }
//     const emailSendUser = async () =>{
//       let api_key = apiKeys.data.api_key;
//       let item1 = {email,api_key}

//       const apicall = await LoginSignService.SendEmail(item1);
//       if(apicall.state === true)
//       {
//          apicall.result.json().then((resp)=>{
//             if(resp.status === 200)
//             {
//                setEmails(resp);
//                setmodal(true)
//             }
//             else
//             {
//                setLoading(false);
//                toast(resp.message);
//             }
//          })
//       }
//       else
//       {

//       }
//     }

//     const otpVerify = async (e) =>{
//       e.preventDefault();
//       if(validateOtp())
//       {
//          setLoading1(true)
//           let email = get_email.data.email;
//           let items = {password,password_confirmation,otp,email}
//           const apicall = await LoginSignService.OTPVerify(items);
//           if(apicall.state === true)
//           {
//             apicall.result.json().then((resp)=>{
//                if(resp.status === 200)
//                {
//                   navigate('/');
//                }
//                else{
//                   toast(resp.message);
//                   if(resp.data)
//                   {
//                      setLoading1(false)
//                      toast(resp.data.password[0]);
//                   }
//                   setLoading1(false)
//                }
//             })
//           }
//           else
//           {

//           }

//       }
//     }

//     const validateOtp = ()=>{
//      let result1 = true;
//       if(password === '' || password === null)
//       {
//          result1 = false;
//          toast("The new password field is required");
//       }
//       if(password_confirmation === '' || password_confirmation === null)
//       {
//          result1 = false;
//          toast("The confirm password field is required");
//       }
//       if(otp === '' || otp === null)
//       {
//          result1 = false;
//          toast("The otp field is required");
//       }
//       return result1;
//     }

//     const validate = ()=>{
//       let result = true;
//        if(email === '' || email === null)
//        {
//           result = false;
//           toast('The email field is required');
//        }
//        if(captcha === '' || captcha === null)
//        {
//          result = false;
//          toast("The captcha field is required");
//        }
//        return result;
//     }

//     return (
//       <div className='password-container'>
//          <div className='password-form'>
//             <div class="container">
//             <div class="row justify-content-center">
//             <div class="col-md-12">
//                <div class="card form-container">
//                   <div class="card-body py-md-4">
//                   <h3>Forgot Password</h3>
//                   <p className='fs-14 text-white'>Please enter the email you register </p>
//                   <form _lpchecked="1">
//                   <div class="form-group">
//                      <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} class="form-control" id="email" placeholder="Email"/>
//                   </div>
//                   <div className="form-group">
//                         <input type="text" value={captcha} onChange={(e)=>setCaptcha(e.target.value)} class="form-control mb-3" id="password" placeholder="Captcha"/>
//                   </div>
//                   <div className="form-group">
//                         <img src={captchImg? captchImg.data.captchaimage:'' }/>
//                   </div>

//                   <div className='mb-5 text-white'>
//                    <a className='text-white text-decoration-none' href=''>
//                      Checking
//                    </a>
//                   </div>

//                   <div class="d-flex flex-row align-items-center justify-content-center">
//                      <a class={`btn btn-proceed w-100 ${
//                         loading ? "disabled" : ""
//                       }`}
//                      onClick={emailSend}>
//                         {loading ? (
//                           <>
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>{" "}
//                             Loading...
//                           </>
//                         ) : (
//                           "Proceed"
//                         )}

//                         </a>
//                   </div>

//                   <ToastContainer />
//                   <Modal
//                      className='forget-password'
//                      size='md'
//                      isOpen={modal}
//                      toggle={() => setmodal(!modal)}
//                      >
//                   <ModalHeader className='bg-slate-50' toggle={() => setmodal(!modal)}>
//                            <h3 className='text-white'>Reset Password</h3>
//                   </ModalHeader>
//                   <ModalBody className='justify-content-center align-items-center d-flex flex-col w-100 modal-body'>

//                      <Card className='pt-2 card w-100' sx={{ maxWidth: 500 }}>
//                      <CardContent className='w-100'>
//                      <Box className='w-100' >
//                      <form _lpchecked="1" onSubmit={otpVerify} className='d-flex flex-col justify-content-cneter align-items-center' >
//                         <div className="form-group w-75">
//                            <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control mb-4" id="name" placeholder="New Password"/>
//                         </div>
//                         <div className="form-group w-75">
//                            <input type="password" value={password_confirmation} onChange={(e)=>setPasswordConfirm(e.target.value)} className="form-control mb-4" id="name" placeholder="Confirm New Password"/>
//                         </div>
//                         <div className="form-group w-75">
//                            <input type="text" className="form-control mb-4" id="email" value={otp} onChange={(e)=>setOtp(e.target.value)} placeholder="Enter OTP"/>
//                         </div>

//                         <div class="d-flex flex-row align-items-center justify-content-center form-group w-100">
//                             <button type='submit' class={`btn btn-exchange w-75
//                             ${
//                               loading1 ? "disabled" : ""
//                             }`}
//                             >

//                               {loading1 ? (
//                           <>
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>{" "}
//                             Loading...
//                           </>
//                         ) : (
//                           "Update"
//                         )}

//                               </button>
//                         </div>
//                      </form>
//                      </Box>
//                      </CardContent>

//                      </Card>
//                      </ModalBody>
//                   </Modal>
//                </form>
//                </div>
//             </div>
//             </div>
//             </div>
//             </div>
//          </div>
//      </div>
//     );
//   }

//   export default Password;
