/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "../scss/valuemodal.scss";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";

const Giftvaluemodal = ({
  giftCartTypeList,
  setGiftCardValue,
  setValueModal,
  valueModal,
  setCurrencyVal,
}) => {
  const [inputFields, setInputFields] = useState([
    { value: "", price: 0, qty: 0 },
  ]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const giftCartTypeListData = () => {
    const res = giftCartTypeList.map((element) =>
      Object.assign(element, { qty: element?.qty ? element?.qty : 0 })
    );
    setData(res);
  };

  useEffect(() => {
    if (giftCartTypeList.length > 0) {
      giftCartTypeListData();
    }
  }, [giftCartTypeList]);

  const closeFirstModal = () => {
    setValueModal(false);
    setInputFields([{ value: "", price: 0, qty: 0 }]);
    setData([]);
  };

  const GiftCardIncrement = (index, item) => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      if (el.id === item?.id) {
        el.qty = item?.qty + 1;
        arr.push(el);
      } else {
        arr.push(el);
      }
      setData(arr);
    }
  };

  const giftCardDecrement = (index, item) => {
    let arr = [];
    let arr1 = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      if (el.id === item?.id && item?.qty !== 0) {
        el.qty = item?.qty - 1;
        arr.push(el);
        arr1.push({
          value: item?.value,
          price: item?.amount,
          qty: item?.amount,
        });
      } else {
        arr.push(el);
      }
      setData(arr);
    }
  };

  const increment = (index) => {
    const values = [...inputFields];
    if (values[0]?.value !== "") {
      values[index].qty = values[index].qty + 1;
      setInputFields(values);
    } else {
      values[index].qty = 0;
      setInputFields(values);
      toast("Please Add value!");
    }
  };

  const decrement = (index) => {
    const values = [...inputFields];
    if (values[0]?.value !== "" && values[index].qty !== 0) {
      values[index].qty = values[index].qty - 1;
      setInputFields(values);
    } else {
      values[index].qty = 0;
      setInputFields(values);
    }
  };

  const handleAddFields = () => {
    setLoading(true);
    const values = [...inputFields];
    values.push({ value: "", price: 0, qty: 0 });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    if (values[0]?.value !== "") {
      let total = 0;
      for (let i = 0; i < giftCartTypeList.length; i++) {
        const el = giftCartTypeList[0];
        total =
          (parseInt(el?.amount) * parseInt(values[index]?.value)) /
          parseInt(el?.value);
        values[index].price = total;
        setInputFields(values);
      }
    } else {
      const total = 0;
      values[index].price = total;
      setInputFields(values);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "-") e.preventDefault();
  };

  const handleSubmit = () => {
    const arr = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        if (el.qty !== 0) {
          setCurrencyVal(el?.currency_symbol);
          arr.push({
            value: el?.value,
            qty: el?.qty,
            price: parseInt(el?.amount),
          });
        }
      }
    }

    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        const el = inputFields[i];
        if (el.qty !== 0) {
          arr.push({
            value: parseInt(el?.value),
            qty: el?.qty,
            price: parseInt(el?.price),
          });
        }
      }
    }

    if (arr.length > 0) {
      setGiftCardValue(arr);
      setValueModal(false);
      const values = [...inputFields];
      // values.push({ value: '', price: 0, qty: 0 });
      setInputFields(values);
      setData([]);
      toast("Add GiftCard value Successfully!");
    } else {
      toast("Please Add atleast one value");
    }
  };

  useEffect(() => {
    if (valueModal) {
      // setInputFields([{ value: '', price: 0, qty: 0 }])
      giftCartTypeListData();
    }
  }, [valueModal]);

  return (
    <>
      {valueModal && (
        <div className="modal value-modal !z-[1023]">
          <div className="modal-content">
            <div className="modal">
              <div className="modal-dialog gift-value-modal">
                <div className="modal-content  modal-card-header">
                  <div className="modal-header d-flex justify-content-end align-items-end">
                    <button type="button" className="close" onClick={closeFirstModal}  style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop: "-60px",
                    }}>
                      <img src='img/close-icon.png' />
                    </button>
                  </div>
                  <div className="modal-body no-scrollbar !px-8">
                    <form _lpchecked="1" className="!mt-2">
                      {data?.map((item, index) => (
                        <div
                          className=" text-white bg-[#1B1818] w-full flex justify-between gap-3 h-full rounded-lg items-center mb-3"
                          key={index}
                        >
                          <div className="w-full flex flex-col p-3 gap-y-2">
                            <p className="text-xs mb-0">
                              {item?.currency_symbol} {item?.value}{" "}
                              {item?.gift_card_name}
                            </p>
                            <span className="text-sm">₦{item?.amount}</span>
                          </div>
                          <div className="bg-[#070809] add-btn flex justify-center px-2 h-full items-center rounded-lg py-4 gap-3">
                            <div
                              className="text-white"
                              onClick={(e) => giftCardDecrement(index, item)}
                            >
                              <i class="bi bi-dash-lg"></i>
                            </div>

                            <p className="mb-0 !text-base">{item?.qty}</p>
                            <div
                              className="text-white"
                              onClick={(e) => GiftCardIncrement(index, item)}
                            >
                              <i class="bi bi-plus-lg"></i>
                            </div>
                          </div>
                        </div>
                      ))}
                    </form>

                    <div className="pt-3">
                      {loading ? (<>
                        {inputFields.map((inputField, index) => 
                        (
                          <div key={index}>
                            <div
                              className="bg-[#1b1818] h-full rounded-md text-white w-full flex justify-between gap-2 items-center mb-4 relative"
                              key={index}
                            >
                              <div
                                className=" rounded-full hover:cursor-pointer h-6 w-6  flex justify-center items-center absolute !top-[-17px] !right-0"
                                onClick={() => handleRemoveFields(index)}
                                style={{
                                  background: "#313131",
                                  color: "#FFFFFF",
                                  padding: "1px",
                                  border: "2px solid #FFFFFF",
                                  // marginTop: "-60px",
                                }}
                              >
                                <MdClose size={18} />
                              </div>
                              <div className="w-full flex flex-col gap-y-2 p-3 ">
                                <p className="text-xs mb-0  ">
                                  <span className=" m-0 dollor-p">
                                    {" "}
                                    {data[0]?.currency_symbol}{" "}
                                  </span>
                                  <input
                                    type="number"
                                    className="bg-[#33353F] text-white placeholder:text-gray-400 !h-8 rounded-[2px] outline-none pl-[4px] max-w-[90px] mr-[7px] enter-text"
                                    placeholder="Enter Value"
                                    value={inputField.value}
                                    onChange={(event) =>
                                      handleChangeInput(index, event)
                                    }
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    min="0"
                                    step="1"
                                  />
                                  {data[0]?.gift_card_name}
                                </p>
                                <span className="text-sm">
                                  ₦{inputField?.price}
                                </span>
                              </div>
                              <div className="add-btn flex justify-center items-center h-full bg-[#070809] gap-3 rounded-lg py-4 px-2">
                                <div
                                  className="text-white"
                                  onClick={() => decrement(index)}
                                >
                                  <i class="bi bi-dash-lg"></i>
                                </div>

                                <p className="mb-0 !text-base">
                                  {inputField?.qty}
                                </p>
                                <div
                                  className="text-white"
                                  onClick={(e) => increment(index)}
                                >
                                  <i class="bi bi-plus-lg"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      </>):(<></>)}                     
                    </div>
                    <div
                      className=" text-center fs-7 tap-btn"
                      onClick={handleAddFields}
                    >
                      TAP TO ADD OTHER VALUES{" "}
                      <span style={{ fontSize: "18px" }}>+</span>
                    </div>

                    <div className="modal-footer1 mt-2 ">
                      {/* <div className=' text-center fs-7' onClick={handleAddFields} style={{fontSize:'13px',color:'#B8BCC3'}}>TAP TO ADD OTHER VALUES +</div> */}
                      <div className="m">
                        {/* <div className=' text-center fs-7' onClick={handleAddFields} style={{fontSize:'13px',color:'#B8BCC3'}}>TAP TO ADD OTHER VALUES +</div> */}
                      </div>
                    </div>
                    <div
                      className="bg-[#331377] p-2 text-white  rounded-md text-center hover:cursor-pointer w-100"
                      onClick={handleSubmit}
                    >
                      Add
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Giftvaluemodal;
