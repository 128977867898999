import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./login.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";



function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchImg, setCaptchaImg] = useState("");
  const [apiKeys, setApiKey] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {

   
    

    const auth = localStorage.getItem("user");
    if (auth) {
      navigate("/dashboard");
    }
    getAPI();
    getCaptch();
    const script = document.createElement('script');
    script.src = "https://cdn1.intent.ai/pixel/6666c68a09c2f4040ea6c98b/intent.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const getAPI = async () => {
    const apicall = await LoginSignService.GetApiKey();
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        setApiKey(resp);
      });
    } else {
    }
  };

  const getCaptch = async () => {
    const apicall = await LoginSignService.GetCaptcha();

    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        setCaptchaImg(resp1);
      });
    } else {
    }
  };

  const ProceedLogin = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      let captchakeyid = captchImg ? captchImg.data.captchakeyid : "";
      let captchaValue = captcha;
      let captc = { captchaValue, captchakeyid };
      var formData = new FormData();
      formData.append("captchakeyid", captchakeyid);
      formData.append("captchaValue", captchaValue);
      const apicall = await LoginSignService.VerifyCaptcha(formData);
      if (apicall.state === true) {
        apicall.result.json().then((resp1) => {
          if (resp1.statusCode == 400) {
            toast(resp1.message);
            setLoading(false);
            getCaptch();
          }
          if (resp1.statusCode == 200) {
            userLogin();
          }
        });
      } else {
      }
    }
  };

  const validate = () => {
    let result = true;
    if (email === "" || email === null) {
      result = false;
      toast("Please Enter Your Email!");
    }
    if (password === "" || password === null) {
      result = false;
      toast("Please Enter Your Password!");
    }
    if (captcha === "" || captcha === null) {
      result = false;
      toast("Please Enter Captcha");
    }
    return result;
  };

  const userLogin = async () => {
    let api_key = apiKeys.data.api_key;
    let item = { email, password, api_key };
    const apicall = await LoginSignService.UserLogin(item);
    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        if (resp1.status == 404) {
          toast(resp1.message);
          setLoading(false);
        }
        if (resp1.status == 400) {
          toast(resp1.message);
          const interval = setInterval(() => {
            navigate("/Password");
          }, 4000);
        }
        if (resp1.status == 200) {
          localStorage.setItem("user", JSON.stringify(resp1));
          navigate("/dashboard");
        }
      });
    } else {
    }
  };

  // mui css
  const textFieldStyles = {
    background: "#252731",
    width: "95%",
    height: "auto",
    borderRadius: "2px",
  };

  return (
    <>
      <div className="login-container" style={{ overflow: "hidden" }}>
        <div className="container">
          <div className="row  mt-5  ">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12">
              <div className=" row ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 ">
                  <div className=" logo ">
                    {/* <img src="img/signup.png" alt="singup" /> */}
                    <img src="img/logo.svg" alt="Dashboard Logo" />
                  </div>
                </div>
              </div>
              <div className="login-content">
                <div className="login-heading ">
                  <h1 className="">
                    Manage Your Account <span >With Confidence</span>
                  </h1>
                  <p className="">
                  Experience a faster, easier and more reliable way to sell
                    <span>
                    your gift cards for Naira with Quchange
                    </span>
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 ">
                  <div className="footer-img ">
                    <img src="img/loginpic.svg"  alt="footer" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12 login-form1 ">
              <div className="login-form ">
                <div class="card form-container">
                  <div class="card-body py-md-2">
                    <h3 style={{ color: "#FFFFFF",fontSize:'20px' }}>Welcome back</h3>
                    <div className="">
                      <h2
                        className="text-[11px] font-[400] mb-4"
                        style={{ color: "#7D7DA1" }}
                      >
                        Already have a Quchange Account?
                        <span className="">
                          <Link
                            className="text-[white] btn-loginInstead"
                            to="/Signup"
                          >
                            <button
                              type="submit"
                              className="btn btn-proceed1 ml-2"
                            >
                              {" "}
                              Create Account
                            </button>
                          </Link>
                        </span>
                      </h2>
                    </div>
                    <form _lpchecked="1" onSubmit={ProceedLogin}>
                      <div class="form-group ">
                        <TextField
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          id="email"
                          label={
                            <span style={{ color: "#ffffff" }}>Email</span>
                          }
                          variant="filled"
                          style={textFieldStyles}
                          sx={{ input: { color: "white" } }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <TextField
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          id="password"
                          label={
                            <span style={{ color: "#ffffff" }}>Password</span>
                          }
                          variant="filled"
                          style={textFieldStyles}
                          sx={{ input: { color: "white" } }}
                          InputProps={{ disableUnderline: true }}
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="password-toggle-button p-3 mx-3 text-light"
                          style={{ fontSize: "20px" }}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </button>
                      </div>

                      <div
                        className="form-group  mt-3"
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-10px",
                        }}
                      >
                        <TextField
                          type="text"
                          value={captcha}
                          onChange={(e) => setCaptcha(e.target.value)}
                          id="password"
                          style={{ flex: 1, ...textFieldStyles }}
                          label={
                            <span style={{ color: "#ffffff" }}>Captcha</span>
                          }
                          variant="filled"
                          sx={{ input: { color: "white" } }}
                          InputProps={{ disableUnderline: true }}
                        />

                        <div
                          className="captchaimage"
                          style={{
                            position: "absolute",
                            right: "0",
                            top: "73%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <img
                            src={captchImg ? captchImg.data.captchaimage : ""}
                            alt="Captcha"
                            style={{
                              width: "100%",
                              height: "63px",
                              marginTop: "-2px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>

                      <div className=" text-white">
                        <Link
                          to="/Password"
                          style={{ color: "#FFFFFF", textDecoration: "none" }}
                        >
                          Forgot Password
                        </Link>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                        <button
                          type="submit"
                          className={`btn btn-proceed1 w-100
                ${loading ? "disabled" : ""}`}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>
                      <div className="mt-3">
                        <h2
                          className=" text-[12px] font-[400] mb-4"
                          style={{ color: "#7D7DA1" }}
                        >
                          Already have a Quchange Account?
                          <span className="">
                            <Link
                              className="text-[white] btn-loginInstead"
                              to="/Signup"
                            >
                              <button
                                type="submit"
                                className="btn btn-proceed1 ml-2"
                              >
                                {" "}
                                Create Account
                              </button>
                            </Link>
                          </span>
                        </h2>
                      </div>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

// my code
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import "./login.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import LoginSignService from "../../Services/LoginSignService/LoginSignService";
// import { json, useNavigate } from "react-router";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [captcha, setCaptcha] = useState("");
//   const [captchImg, setCaptchaImg] = useState("");
//   const [apiKeys, setApiKey] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const auth = localStorage.getItem("user");
//     if (auth) {
//       navigate("/dashboard");
//     }
//     getAPI();
//     getCaptch();
//   }, []);

//   const getAPI = async () => {
//     const apicall = await LoginSignService.GetApiKey();
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         setApiKey(resp);
//       });
//     } else {
//     }
//   };

//   const getCaptch = async () => {
//     const apicall = await LoginSignService.GetCaptcha();

//     if (apicall.state === true) {
//       apicall.result.json().then((resp1) => {
//         setCaptchaImg(resp1);
//       });
//     } else {
//     }
//   };

//   const ProceedLogin = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       let captchakeyid = captchImg ? captchImg.data.captchakeyid : "";
//       let captchaValue = captcha;
//       let captc = { captchaValue, captchakeyid };
//       var formData = new FormData();
//       formData.append("captchakeyid", captchakeyid);
//       formData.append("captchaValue", captchaValue);
//       const apicall = await LoginSignService.VerifyCaptcha(formData);
//       if (apicall.state === true) {
//         apicall.result.json().then((resp1) => {
//           if (resp1.statusCode == 400) {
//             toast(resp1.message);
//             setLoading(false);
//             getCaptch();
//           }
//           if (resp1.statusCode == 200) {
//             userLogin();
//           }
//         });
//       } else {
//       }
//     }
//   };

//   const validate = () => {
//     let result = true;
//     if (email === "" || email === null) {
//       result = false;
//       toast("Please Enter Your Email!");
//     }
//     if (password === "" || password === null) {
//       result = false;
//       toast("Please Enter Your Password!");
//     }
//     if (captcha === "" || captcha === null) {
//       result = false;
//       toast("Please Enter Captcha");
//     }
//     return result;
//   };

//   const userLogin = async () => {
//     let api_key = apiKeys.data.api_key;
//     let item = { email, password, api_key };
//     const apicall = await LoginSignService.UserLogin(item);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp1) => {
//         if (resp1.status == 404) {
//           toast(resp1.message);
//           setLoading(false);
//         }
//         if (resp1.status == 400) {
//           toast(resp1.message);
//           const interval = setInterval(() => {
//             navigate("/Password");
//           }, 4000);
//         }
//         if (resp1.status == 200) {
//           localStorage.setItem("user", JSON.stringify(resp1));
//           navigate("/dashboard");
//         }
//       });
//     } else {
//     }
//   };

//   return (
//     <>
//       <div className="login-container" style={{ overflow: "hidden" }}>
//         <div className="container">
//           <div className="row  mt-5  ">
//             <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12">
//               <div className=" row ">
//                 <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 ">
//                   <div
//                     className=" logo "
//                     style={{ marginLeft: "55px", marginTop: "30px" }}
//                   >
//                     <img src="img/signup.png" alt="singup" />
//                   </div>
//                 </div>
//               </div>
//               <div className="login-content">
//                 <div className="login-heading ">
//                   <h1 className="text-light">
//                     Manage Your Account <span>With Confidence</span>
//                   </h1>
//                   <p className="text-white">
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
//                     mi tellus{" "}
//                     <span>
//                       dolor,commodo erat felis nulla. Elementum, suspendisse
//                       amet id quam.{" "}
//                     </span>
//                   </p>
//                 </div>
//               </div>
//               <div className="row ">
//                 <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 ">
//                   <div className="footer-img ">
//                     <img src="img/tech.png" alt="footer" />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12 login-form1 ">
//               <div className="login-form ">
//                 <div class="card form-container">
//                   <div class="card-body py-md-2">
//                     <h3>Welcome back</h3>
//                     <div className="">
//                       <h2 className="text-[white] text-[12px] font-[400] mb-4">
//                         Already have a Quchange Account?
//                         <span className="">
//                           <Link
//                             className="text-[white] btn-loginInstead"
//                             to="/Signup"
//                           >
//                             <button
//                               type="submit"
//                               className="btn btn-proceed1 ml-2"
//                             >
//                               {" "}
//                               Create Account
//                             </button>
//                           </Link>
//                         </span>
//                       </h2>
//                     </div>
//                     <form _lpchecked="1" onSubmit={ProceedLogin}>
//                       <div class="form-group ">
//                         <input
//                           type="email"
//                           value={email}
//                           onChange={(e) => setEmail(e.target.value)}
//                           class="form-control "
//                           id="email"
//                           placeholder="Email"
//                         />
//                       </div>

//                       <div className="form-group">
//                         <input
//                           type={showPassword ? "text" : "password"}
//                           value={password}
//                           onChange={(e) => setPassword(e.target.value)}
//                           className="form-control"
//                           id="password"
//                           placeholder="Password"
//                         />
//                         <button
//                           type="button"
//                           onClick={() => setShowPassword(!showPassword)}
//                           className="password-toggle-button p-3"
//                           style={{ fontSize: "20px" }}
//                         >
//                           {showPassword ? (
//                             <FontAwesomeIcon icon={faEye} />
//                           ) : (
//                             <FontAwesomeIcon icon={faEyeSlash} />
//                           )}
//                         </button>
//                       </div>

//                       <div
//                         className="form-group"
//                         style={{
//                           position: "relative",
//                           display: "flex",
//                           alignItems: "center",
//                         }}
//                       >
//                         <input
//                           type="text"
//                           value={captcha}
//                           onChange={(e) => setCaptcha(e.target.value)}
//                           className="form-control mb-1"
//                           id="password"
//                           style={{ flex: 1 }}
//                           placeholder="Captcha"
//                         />
//                         <div
//                           className="captchaimage"
//                           style={{
//                             position: "absolute",
//                             right: "0",
//                             top: "74%",
//                             transform: "translateY(-50%)",
//                           }}
//                         >
//                           <img
//                             src={captchImg ? captchImg.data.captchaimage : ""}
//                             alt="Captcha"
//                             style={{
//                               width: "100%",
//                               height: "100%",
//                               objectFit: "cover",
//                             }}
//                           />
//                         </div>
//                       </div>

//                       <div className=" text-white mt-2">
//                         <Link
//                           to="/Password"
//                           style={{ color: "white", textDecoration: "none" }}
//                         >
//                           Forgot Password
//                         </Link>
//                       </div>
//                       <div className="d-flex flex-row align-items-center justify-content-center mt-3">
//                         <button
//                           type="submit"
//                           className={`btn btn-proceed w-100
//                 ${loading ? "disabled" : ""}`}
//                         >
//                           {loading ? (
//                             <>
//                               <span
//                                 className="spinner-border spinner-border-sm"
//                                 role="status"
//                                 aria-hidden="true"
//                               ></span>{" "}
//                               Loading...
//                             </>
//                           ) : (
//                             "Login"
//                           )}
//                         </button>
//                       </div>
//                       <div className="mt-3">
//                         <h2 className="text-[white] text-[12px] font-[400] mb-4">
//                           Already have a Quchange Account?
//                           <span className="">
//                             <Link
//                               className="text-[white] btn-loginInstead"
//                               to="/Signup"
//                             >
//                               <button
//                                 type="submit"
//                                 className="btn btn-proceed1 ml-2"
//                               >
//                                 {" "}
//                                 Create Account
//                               </button>
//                             </Link>
//                           </span>
//                         </h2>
//                       </div>
//                       <ToastContainer />
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Login;

// company code

// import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
// import "./login.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import LoginSignService from "../../Services/LoginSignService/LoginSignService";
// import { json, useNavigate } from "react-router";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [captcha, setCaptcha] = useState("");
//   const [captchImg, setCaptchaImg] = useState("");
//   const [apiKeys, setApiKey] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const auth = localStorage.getItem("user");
//     if (auth) {
//       navigate("/dashboard");
//     }
//     getAPI();
//     getCaptch();
//   }, []);

//   const getAPI = async () => {
//     const apicall = await LoginSignService.GetApiKey();
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         setApiKey(resp);
//       });
//     } else {
//     }
//   };

//   const getCaptch = async () => {
//     const apicall = await LoginSignService.GetCaptcha();

//     if (apicall.state === true) {
//       apicall.result.json().then((resp1) => {
//         setCaptchaImg(resp1);
//       });
//     } else {
//     }
//   };

//   const ProceedLogin = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setLoading(true);
//       let captchakeyid = captchImg ? captchImg.data.captchakeyid : "";
//       let captchaValue = captcha;
//       let captc = { captchaValue, captchakeyid };
//       var formData = new FormData();
//       formData.append("captchakeyid", captchakeyid);
//       formData.append("captchaValue", captchaValue);
//       const apicall = await LoginSignService.VerifyCaptcha(formData);
//       if (apicall.state === true) {
//         apicall.result.json().then((resp1) => {
//           if (resp1.statusCode == 400) {
//             toast(resp1.message);
//             setLoading(false);
//             getCaptch();
//           }
//           if (resp1.statusCode == 200) {
//             userLogin();

//           }
//         });
//       } else {
//       }
//     }
//   };

//   const validate = () => {
//     let result = true;
//     if (email === "" || email === null) {
//       result = false;
//       toast("Please Enter Your Email!");
//     }
//     if (password === "" || password === null) {
//       result = false;
//       toast("Please Enter Your Password!");
//     }
//     if (captcha === "" || captcha === null) {
//       result = false;
//       toast("Please Enter Captcha");
//     }
//     return result;
//   };

//   const userLogin = async () => {
//     let api_key = apiKeys.data.api_key;
//     let item = { email, password, api_key };
//     const apicall = await LoginSignService.UserLogin(item);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp1) => {
//         if (resp1.status == 404) {
//           toast(resp1.message);
//           setLoading(false)
//         }
//         if (resp1.status == 400) {
//           toast(resp1.message);
//           const interval = setInterval(() => {
//             navigate("/Password");
//           }, 4000);
//         }
//         if (resp1.status == 200) {
//           localStorage.setItem("user", JSON.stringify(resp1));
//           navigate("/dashboard");
//         }
//       });
//     } else {
//     }
//   };

//   return (
//     <div className="login-container">
//       <div className="login-content">
//         <div className="logo mb-5">
//           <img src="img/signup.png" alt="singup" />
//         </div>
//         <div className="login-heading">
//           <h1>
//             Manage Your Account <span>With Confidence</span>
//           </h1>
//           <p className="text-white">
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mi
//             tellus{" "}
//             <span>
//               dolor,commodo erat felis nulla. Elementum, suspendisse amet id
//               quam.{" "}
//             </span>
//           </p>
//         </div>
//       </div>
//       <div className="login-form">
//         <div class="container">
//           <div class="row justify-content-center">
//             <div class="col-md-12">
//               <div class="card form-container">
//                 <div class="card-body py-md-2">
//                   <h3>Welcome back</h3>

//                   <div className="">
//                     <h2 className="text-[white] text-[12px] font-[400] mb-4">
//                       Already have a Quchange Account?
//                       <span className="">
//                       <Link className="text-[white] btn-loginInstead" to="/Signup">
//                         <button type="submit" className="btn btn-proceed ml-2">
//                           {" "}

//                             {" "}
//                             Create Account

//                         </button>
//                         </Link>
//                       </span>
//                     </h2>
//                   </div>
//                   <form _lpchecked="1" onSubmit={ProceedLogin}>
//                     <div class="form-group ">
//                       <input
//                         type="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         class="form-control "
//                         id="email"
//                         placeholder="Email"
//                       />
//                     </div>

//                     <div className="form-group">
//                       <input
//                         type={showPassword ? "text" : "password"}
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         className="form-control"
//                         id="password"
//                         placeholder="Password"
//                       />
//                       <button
//                         type="button"
//                         onClick={() => setShowPassword(!showPassword)}
//                         className="password-toggle-button p-3"
//                         style={{ fontSize: "20px" }}
//                       >
//                         {showPassword ? (
//                           <FontAwesomeIcon icon={faEye} />
//                         ) : (
//                           <FontAwesomeIcon icon={faEyeSlash} />
//                         )}
//                       </button>
//                     </div>

//                     <div
//                       className="form-group"
//                       style={{
//                         position: "relative",
//                         display: "flex",
//                         alignItems: "center",
//                       }}
//                     >
//                       <input
//                         type="text"
//                         value={captcha}
//                         onChange={(e) => setCaptcha(e.target.value)}
//                         className="form-control mb-1"
//                         id="password"
//                         style={{ flex: 1 }}
//                         placeholder="Captcha"
//                       />
//                       <div
//                         className="captchaimage"
//                         style={{
//                           position: "absolute",
//                           right: "0",
//                           top: "74%",
//                           transform: "translateY(-50%)",
//                         }}
//                       >
//                         <img
//                           src={captchImg ? captchImg.data.captchaimage : ""}
//                           alt="Captcha"
//                           style={{
//                             width: "100%",
//                             height: "100%",
//                             objectFit: "cover",
//                           }}
//                         />
//                       </div>
//                     </div>

//                     <div className=" text-white mt-2">
//                       <a href="/Password" className="text-white">
//                         Forgot Password
//                       </a>
//                     </div>
//                     <div className="d-flex flex-row align-items-center justify-content-center mt-3">
//                       <button type="submit" className={`btn btn-proceed w-100
//                       ${
//                         loading ? "disabled" : ""
//                       }`}
//                       >
//                         {loading ? (
//                           <>
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>{" "}
//                             Loading...
//                           </>
//                         ) : (
//                           "Login"
//                         )}
//                       </button>
//                     </div>
//                     <div className="mt-3">
//                       <h2 className="text-[white] text-[12px] font-[400] mb-4">
//                         Already have a Quchange Account?
//                         <span className="">
//                         <Link
//                               className="text-[white] btn-loginInstead"
//                               to="/Signup"
//                             >
//                           <button
//                             type="submit"
//                             className="btn btn-proceed ml-2"
//                           >
//                             {" "}

//                               {" "}
//                               Create Account

//                           </button>
//                           </Link>
//                         </span>
//                       </h2>
//                     </div>
//                     <ToastContainer />
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="footer-img">
//         <img src="img/tech.png" />
//       </div>
//     </div>
//   );
// }

// export default Login;
