import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Example({notice,cryptoid,showWalletAdd,setTransactionLoading1,transactionLoading1}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const acceptAddress = async ()=>{
    showWalletAdd(cryptoid);
    setTransactionLoading1(false)
  }
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Get Wallet Address
      </Button>

     <div className='notice_mod'>
     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        
      >
        <Modal.Header closeButton>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src='./img/error.png' className='notice_error'/>
         {notice}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='crypto_d_ac' onClick={handleClose}>
            I do not accept
          </Button>
          <Button variant="primary"
           className={`crypto_d_ac1 ${
            transactionLoading1 ? "disabled" : ""
          }`}
            onClick={()=>acceptAddress()}>I Accept</Button>
        </Modal.Footer>
      </Modal>
     </div>
    </>
  );
}

export default Example;