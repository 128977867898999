export default {
  GetBTCWallet: function (auth) {
    return fetch(
      "https://secureapi.thequchangeapp.xyz/api/auth/get/instant/btc/wallet",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + auth.api_access_token,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetBTCAddressThirdParty: (token) => {
    return fetch("https://instbtc.qxchange.app/wallet", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GenerateThirdPartyToken: (auth) => {
    const credentials = `quchangeauthadmin@gmail.com:z!2U3z2*Lh&CP1L0zzyV&`;

    const base64Credentials = btoa(credentials);
    return fetch("https://admin.thequchangeapp.xyz/jwt/index2.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${base64Credentials}`,
      },
      body: JSON.stringify({
        key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
        email: auth.user.email,
        phone: auth.user.phone,
      }),
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  CreateWallet: (token) => {
    return fetch("https://instbtc.qxchange.app/wallet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  SaveBTCWallet: (address, label, userId, auth) => {
    return fetch(
      `https://secureapi.thequchangeapp.xyz/api/auth/create/instant/btc/wallet?address=${address}&label=${label}&blockiouserId=${userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${auth.api_access_token}`,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetBlockIOAdmin: (auth) => {
    return fetch(
      "https://secureapi.thequchangeapp.xyz/api/auth/get/instant/btc/rate",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${auth.api_access_token}`,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetBTCRate: () => {
    return fetch("https://secureapi.thequchangeapp.xyz/api/auth/redbiller/getBitcoinRate.php", {
      method: "GET",
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetTransactions: (token,auth) => {
    let au = auth?auth.api_access_token:'';   
    return fetch("https://secureapi.thequchangeapp.xyz/api/auth/get/instant/btc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + au,
      },
      body:JSON.stringify(token)
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetWalletBalance: (token) => {
    return fetch("https://xchangeapi.qxchange.app/wallet/balance", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  UpdateBtcBalance: (balance, auth) => {
    return fetch(
      `https://secureapi.thequchangeapp.xyz/api/auth/update-btc-balance?btc_balance=${balance}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + auth.api_access_token,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: await result.json(), state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  sendBTC: (token, body) => {
    return fetch(`https://xchangeapi.qxchange.app/wallet/create-transaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
  return { result: await result.json() || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  checkTransactionValidity: (token, body) => {
    return fetch(`https://xchangeapi.qxchange.app/wallet/check-fee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    })
      .then(async (result) => {
        if (result.status === 200 || result.status === 201) {
          return { result: await result.json(), state: true };
        } else {
          return { result: await result.json(), state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  saveTransaction: (
    auth,
    calcAmount,
    transId,
    rate,
    fromAddress,
    toAddress,
    btcToUsd,
    transtype = "send_btc",
    plateform = "web"
  ) => {
    return fetch(
      `https://secureapi.thequchangeapp.xyz/api/auth/save-transaction?transactiontype=${transtype}&amount=${calcAmount}&transactionid=${transId}&rate=${rate}&from_address=${fromAddress}&to_address=${toAddress}&btc_to_usd=${btcToUsd}&platform=${plateform}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + auth.api_access_token,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
