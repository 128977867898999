import React, { useState } from "react";
import Sendusd from "../trc/sendusd";
import Sendtron from "./sendtron";
import Tronquchange from "./tronquchange";
import Usdquchange from "./usdquchange";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal token-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Tokenmodal = ({
  address,
  setAddressMessage,
  setIsFirstModalOpen,
  refreshHistory,
}) => {
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [amount, setAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const openFirstModal = () => {
    if (address === "") {
      setAddressMessage("Please enter address");
      return;
    } else {
      setIsSecondModalOpen(true);
    }
  };

  const closeFirstModal = () => {
    setIsSecondModalOpen(false);
    setIsFirstModalOpen(false);
  };

  // mui css
  const textFieldStyles = {
    background: "#6C6C6C ",
    width: "100%",
    height: "30px",
    borderRadius: "2px",
  };
  return (
    <div>
      <button className="btn btn-update w-100" onClick={openFirstModal}>
        Proceed
      </button>
      {isSecondModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content trc-token-modal" style={{ backgroundColor: "transparent" }}>
                <div className="modal-header">
                  <h5 className="modal-title" style={{marginTop:'-15px'}}>
                    Send (USDT & TRON)
                    <span
                      className="d-block"
                      style={{
                        color: "#7D7DA1",
                        fontSize: "12px",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur.
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop:'-30px'
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="modal-body" style={{marginTop:'-20px'}}>
                  <form _lpchecked="1">
                    <div className="form-group w-100 mt-1">
                      <select
                        class="form-select"
                        label="Default select example"
                        onChange={(e)=> setToken(e.target.value)}
                        style={{
                          height: "80px",
                          fontSize: "17px",
                          fontWeight: "400",
                        }}
                      >
                        <option selected style={{ fontSize: "14px" }}>
                          Select Token
                        </option>
                        <option value="trx" style={{ fontSize: "14px" }}>
                          TRX
                        </option>
                        <option value="usdt" style={{ fontSize: "14px" }}>
                          USDT
                        </option>
                      </select>
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="number"
                        // className="form-control mb-3"
                        id="name"
                        onChange={(e) => setAmount(e.target.value)}
                        label={
                          <span style={{ color: "#ffffff" }}>
                            {" "}
                            Enter Amount{" "}
                          </span>
                        }
                        variant="filled"
                        style={{
                          ...textFieldStyles,
                        }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer " >
                  <div style={{width:'100%'}}>
                  <Sendtron
                    token={token}
                    amount={amount}
                    address={address}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    setIsFirstModalOpen={setIsFirstModalOpen}
                    refreshHistory={refreshHistory}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Tokenmodal;







// company code

// import React, { useState } from 'react';
// import Sendusd from '../trc/sendusd';
// import Sendtron from './sendtron';
// import Tronquchange from './tronquchange';
// import Usdquchange from './usdquchange';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal token-modal">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Tokenmodal = ({ address, setAddressMessage, setIsFirstModalOpen,refreshHistory }) => {
//   const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
//   const [token, setToken] = useState('');
//   const [amount, setAmount] = useState(0);
//   const [errorMessage, setErrorMessage] = useState('');

//   const openFirstModal = () => {
//     if (address === '') {
//       setAddressMessage('Please enter address');
//       return;
//     }
//     else {
//       setIsSecondModalOpen(true);
//     }

//   };

//   const closeFirstModal = () => {
//     setIsSecondModalOpen(false);
//     setIsFirstModalOpen(false);
//   };

//   return (
//     <div>
//       <button className='btn btn-update w-100' onClick={openFirstModal}>Proceed</button>
//       {isSecondModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className='modal-dialog'>
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title">
//                     Send (USDT & TRON)
//                     <span className='d-block'>
//                       Lorem ipsum dolor sit amet consectetur.
//                     </span>
//                   </h5>
//                   <button type="button" className="close" onClick={closeFirstModal}>
//                     <img src='img/close-icon.png' />
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1">
//                     <div className="form-group w-75 mb-3">
//                       <select class="form-select" aria-label="Default select example" onChange={(e)=> setToken(e.target.value)}>
//                         <option selected>Select Token</option>
//                         <option value="TRX">TRX</option>
//                         <option value="USDT">USDT</option>
//                       </select>
//                     </div>
//                     <div className="form-group w-75">
//                       <input type="number" className="form-control mb-3" id="name" placeholder="Enter Amount" onChange={(e)=> setAmount(e.target.value)}/>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="modal-footer">
                  
//                   <Sendtron token={token} amount={amount} address={address} setErrorMessage={setErrorMessage} errorMessage={errorMessage} setIsFirstModalOpen={setIsFirstModalOpen} refreshHistory={refreshHistory}/>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Tokenmodal;
