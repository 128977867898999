import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import SuccessTopup from "./successTopup";
import { useNavigate } from "react-router";

const Confirmation = ({opDetails,phoneNumber,amount,senderCurrencyCode,destinationCurrencyCode,fxRate,feeLocal,feeInternational,samount,virtualAccountInfo,setIsOpenOperatorDetails,minamount,maxamount}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isConfirmation,setIsConfirmation] = useState(false);
  const [loading,setLoading] = useState(false)
  const [successTop,setSuccessTop] = useState(false)
  const [successMes,setSuccessMes] = useState("")
  const navigate = useNavigate();

  const goBack = () => {
  };
  if(senderCurrencyCode == destinationCurrencyCode){
    var fee = feeLocal;
    var totalPrice = Number(fee)+Number(amount);
  }else{
    var fee = feeInternational
    var totalPrice = Number(fee)+Number(amount);
  }
  const closeFirstModal = () => {
    setIsConfirmation(false)
    setLoading(false);
  };

  const nextConfirmation = async ()=>{
    console.log(opDetails);
    if(amount == ''){
      toast("Select Plan / Enter Amount");
      return false;
    }
    if(opDetails.minAmount == null){

    }else{
    if(amount < minamount){
      toast("Select an amount between "+senderCurrencyCode+" "+minamount+" to "+destinationCurrencyCode+" "+maxamount+"");
      return false;
    }
    if(maxamount < amount){
      toast("Select an amount between "+senderCurrencyCode+" "+minamount+" to "+destinationCurrencyCode+" "+maxamount+"");
      return false;
    }}
    setIsConfirmation(true)
  }
  
  const payTopup =async () =>{
    setLoading(true);
    const itemBody = {operatorId:opDetails.operatorId,recipientCountryCode:opDetails.country.isoName,recipientPhone:phoneNumber,amount:amount,planDescription:'test',transactionFee:fee}
    const apicall = await AfterLoginService.createTopupOrder(auth,itemBody);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setSuccessMes(resp.data)
          setSuccessTop(true);
          setLoading(false)
          console.log(resp.data);

        } else {
          console.log(resp.message);
          setLoading(false)
        }
      });
    } else {
      apicall.result.json().then((resp) => {
        if (resp.status == 400) {
          toast(resp.data.message);
          setLoading(false)
        } 
      });
     
    }
    
  }
  const handleSuccess = ()=>{
    setIsConfirmation(false)
    setIsOpenOperatorDetails(true)
  }

  const virtualAccountScreen =()=>{
    navigate('/virtualaccount');
  }
  
  return (
      <div>
        <div className="col-md-10 mt-2 mb-4">
          <button className="buy_now form-control" onClick={nextConfirmation}>Next</button>
        </div>
          {isConfirmation &&(
                  <>
                      <div className="modal gift-success !z-[1027]">
                        <div className="modal-content">
                          <div className="modal">
                            <div className="modal-dialog">
                              <div className="modal-content">
                                
                                <div className="modal-header ">
                                    <h4>Confirmation</h4>
                                    <button type="button" className="close" onClick={closeFirstModal}>
                                      <img src='img/close-icon.png'/>
                                    </button>
                                </div>
                                <div className="modal-body text-white text-center ">
                                    <div className="row">
                                      <div className="col-md-12">
                                          <div className="">
                                             <table className="table confT">
                                                <tbody>
                                                  <tr>
                                                    <td>Operator Name</td>
                                                    <td className="tright">{opDetails?opDetails.name:''}</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Phone</td>
                                                    <td className="tright">{phoneNumber?phoneNumber:''}</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Amount</td>
                                                    <td className="tright">
                                                      {amount?senderCurrencyCode+' '+amount:''}&nbsp;
                                                      ({destinationCurrencyCode+' '+samount})
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Total Fee</td>
                                                    <td className="tright">
                                                      {senderCurrencyCode+' '+fee}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Total Payable Amount</td>
                                                    <td className="tright">{senderCurrencyCode+' '+totalPrice}</td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={2} className="termCondition">By continuing you agree with terms & conditions.</td>
                                                   
                                                  </tr>
                                                </tbody>
                                             </table>
                                             {
                                              virtualAccountInfo.isAccountCreated == 1?(<>
                                              
                                              {
                                                virtualAccountInfo. walletBalance >= totalPrice?(<>
                                               
                                                 <button
                                                    className={`buy_now form-control`}
                                                    onClick={payTopup}
                                                    disabled={`${
                                                      loading ? "disabled" : ""
                                                    }`}
                                                >
                                                  {loading ? (
                                                    <>
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>{" "}
                                                      Loading...
                                                    </>
                                                  ) : (
                                                    "Confirm"
                                                  )}
                                                </button>

                                                </>):(<>
                                                <button className="buy_now form-control addfund" onClick={virtualAccountScreen}>Add Fund</button>
                                                </>)
                                              }
                                              </>):(<>
                                               <button className="buy_now form-control cvr" onClick={virtualAccountScreen}>Create Virtual Account</button>
                                              </>)
                                             }

                                          </div>
                                      </div>
                                     
                                    </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </>
          )}
          <SuccessTopup successTop={successTop} setSuccessTop={setSuccessTop} 
          successMes={successMes} setSuccessMes={setSuccessMes}
          handleSuccess={handleSuccess}/>
    </div>
  );
};
 
export default Confirmation;