
import React, { useEffect, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../../header/header";
import Subheader from "../../subheader/subheader";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GiftCardTransactionDetails from "./giftCardTransactionDetails";
import UtilityTransactionDetails from "./utilityTransactionDetails";
import MobileTransactionDetails from "./mobileTransactionDetails";


function TransactionList() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState("giftcard");
  const [isOpenGTran,setIsOpenGTran] = useState(true);
  const [isOpenGTranD,setIsOpenGTranD] = useState(false);
  const [isOpenMTran,setIsOpenMTran] = useState(false);
  const [isOpenUTran,setIsOpenUTran] = useState(false);
  const [storeGiftTran,setStoreGiftTran] = useState([])
  const [storeMobileTran,setStoreMobileTran] = useState([])
  const [storeUtilityTran,setStoreUtilityTran] = useState([])
  const [gdetails,setGDetails] = useState("");
  const [isLoader,setIsLoader] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      navigate("/");
    }
    giftTransaction();
  }, []);

  const getButtonStyle = (buttonName) => {
    return {
      marginRight: "10px",
      backgroundColor: selectedButton === buttonName ? " #3c0d68" : "",
    };
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    if(buttonName == "giftcard"){
      giftTransaction()
    }
    else if(buttonName == "mobiletopup")
    {
      mobileTransaction()
    }
    else if(buttonName == "billutility"){
      utilityTransaction()
    }
  };
 
  const giftTransaction = async () => {
    setIsLoader(true)
    const apicall = await AfterLoginService.getGiftCardTransaction(auth);
    if(apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setStoreGiftTran(resp.data)
          console.log(storeGiftTran)
          setIsLoader(false)

        } else {
          console.log(resp.message);
          setIsLoader(false)

        }
      });
    } else {
      apicall.result.json().then((resp)=>{})
      setIsLoader(false)

    }
  };
  const mobileTransaction = async () => {
    setIsLoader(true)
    const apicall = await AfterLoginService.getMobileTopUpTransaction(auth);
    if(apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setStoreMobileTran(resp.data)
          setIsLoader(false)
        } else {
          console.log(resp.message);
          setIsLoader(false)
        }
      });
    } else {
      apicall.result.json().then((resp)=>{})
      setIsLoader(false)

    }
  };
  const utilityTransaction = async () => {
    setIsLoader(true)
    const apicall = await AfterLoginService.getUtilityTransaction(auth);
    if(apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          console.log(resp.data)
          setStoreUtilityTran(resp.data)
          setIsLoader(false)
        } else {
          console.log(resp.message);
          setIsLoader(false)
        }
      });
    } else {
      apicall.result.json().then((resp)=>{})
      setIsLoader(false)

    }
  };

  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "SUCCESSFUL":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  const viewTrans = (item)=>{
    setIsOpenGTranD(true)
    setGDetails(item)
    setIsOpenGTran(false)
  }
  const viewTransMobile = (item)=>{
    setIsOpenMTran(true)
    setGDetails(item)
    setIsOpenGTran(false)
  }
  const viewTransUtility = async (item)=>{
    const apicall = await AfterLoginService.getUtilityTransaction(auth,item.transactionId);
    if(apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          console.log(resp.data)
          setIsOpenUTran(true)
          setGDetails(resp.data)
          setIsOpenGTran(false)
        } else {
          console.log(resp.message);
         
        }
      });
    } else {
      apicall.result.json().then((resp)=>{})
    

    }
  }
  const goBack = ()=>{
    navigate('/billpurchase')
  }
  return (
    <div className="position-relative d-flex p-0  dpDesign">
      <Header />
      <div className="content">
        <Subheader />
        <div className="container-fluid pt-4 px-4 wallet-table dpDesign1 ">
           
           {
             isOpenGTran && (<>
               <div className="back-icon-main d-block">
                 <img src="../../img/CaretLeft1.svg" onClick={goBack} />
               </div>
                 <div className="row">
                     <div className="col-md-12 mb-4">
                         <h3 className="heading-transaction mb-0">Transactions</h3>
                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12">
                       <div style={{ display: 'flex' }} >
                       <button
                         type="button"
                         className="btn  btn-tansaction"
                         style={getButtonStyle("giftcard")}
                         onClick={() => handleButtonClick("giftcard")}
                       >
                         Gift Cards
                       </button>

                       <button
                         type="button"
                         className="btn  btn-tansaction"
                         style={getButtonStyle("mobiletopup")}
                         onClick={() => handleButtonClick("mobiletopup")}
                       >
                         Mobile Topup
                       </button>

                       <button
                         type="button"
                         className="btn  btn-tansaction "
                         style={getButtonStyle("billutility")}
                         onClick={() => handleButtonClick("billutility")}
                       >
                         Bill Utility
                       </button>
                         </div>
                     </div>
                     <div className="col-lg-6 col-md-6 col-sm-12 col-12 "> </div>
                 </div>
                 <div className="transcation-div mt-2 ">
                     <div className="col-md-8 col-lg-8 table-transcation table_mobile_responsive">
                     {selectedButton == 'giftcard'?(
                         <>
                             <table class="table table-dark table-border transaction_table_head" id="mobile-1">
                                 <thead>
                                 <tr className="fs-13 d-none-mobile">
                                     <th>Name</th>
                                     <th className="trasaction-heading-mobile">&nbsp;</th>
                                     <th className="d-none d-sm-table-cell">Amount</th>
                                     <th >STATUS</th>
                                     <th className="d-none d-sm-table-cell">DATE</th>
                                 </tr>
                                 </thead>
                                 <tbody className="trn-1">
                                     {isLoader?(<>
                                       <tr>
                                         <td colSpan="4" className="text-center"> 
                                           <span
                                             className="text-white spinner-border spinner-border-sm"
                                             role="status"
                                             aria-hidden="true"
                                           ></span>{" "}
                                           <span className="text-white">Loading...</span></td>
                                       </tr>
                                     </>):(<>
                                       {
                                       storeGiftTran.length>0?(<>
                                       {
                                         storeGiftTran.map((item,index)=>(
                                         <>
                                           <tr onClick={()=>viewTrans(item)} className="d-none-mobile">
                                               <td>
                                                 <img src="../img/utility/giftcard.svg" />
                                                  &nbsp;
                                                  &nbsp;
                                                 {item.productName}</td>
                                               <td>{'NGN'} {item.deductedAmount}</td>
                                               <td>
                                                 <p className={`complete-btn ${getButtonColor(
                                                 item?.status
                                               )}`}>
                                                     {item.status}
                                                   </p>
                                                 </td>
                                               <td>{item.created_at1}</td>
                                           </tr>
                                           <tr onClick={()=>viewTrans(item)} className="d-show-mobile">
                                               <td className="td-mobile">
                                                 <div className="mb-a-1">
                                                   <div className="sc-1">
                                                     <img src="../img/utility/giftcard.svg" />
                                                     &nbsp;
                                                     &nbsp;
                                                   
                                                   </div>
                                                   <div className="sc-2">
                                                     <p> {item.productName}</p><br/>
                                                     <p>{item.quantity}<span className="mobile-gift"> Gift Cards worth </span>NGN {item.deductedAmount}</p><br/>
                                                     <p><span className="mobile-gift">{item.created_at1}</span></p><br/>
                                                   </div>
                                                   <div className="mb-success sc-3">
                                                       <p className={`complete-btn ${getButtonColor(
                                                       item?.status
                                                     )}`}>
                                                       {item.status}
                                                     </p><br/>
                                                   </div>
                                                 </div>
                                               </td>
                                               
                                           </tr>
                                         </>
                                         ))
                                       }
                                       </>):(<>
                                         <tr>
                                           <td colSpan={4} className="text-center">
                                             <img src="../../img/EmptyState.svg" />
                                             <br/>
                                             <p>Transaction not found</p>
                                           </td>
                                         </tr>
                                       
                                       </>)
                                     }
                                     </>)}
                                     <tr>

                                     </tr>
                                 </tbody>
                             </table>
                         </>
                     ):(<></>)}

                     {selectedButton == 'mobiletopup'?(
                         <>
                             <table class="table table-dark table-border transaction_table_head" id="mobile-1">
                                 <thead className="d-none-mobile">
                                   <tr className="fs-13">
                                       <th>Name</th>
                                       <th className="trasaction-heading-mobile">&nbsp;</th>
                                       <th className="d-none d-sm-table-cell">Amount</th>
                                       <th >STATUS</th>
                                       <th className="d-none d-sm-table-cell">DATE</th>
                                   </tr>
                                 </thead>
                                 <tbody className="trn-1">
                                     {isLoader?(<>
                                       <tr>
                                         <td colSpan="4" className="text-center"> 
                                           <span
                                             className="text-white spinner-border spinner-border-sm"
                                             role="status"
                                             aria-hidden="true"
                                           ></span>{" "}
                                           <span className="text-white">Loading...</span></td>
                                       </tr>
                                     </>):(<>
                                       {
                                       storeMobileTran.length>0?(<>
                                       {
                                         storeMobileTran.map((item,index)=>(
                                         <>
                                           <tr onClick={()=>viewTransMobile(item)} className="d-none-mobile">
                                               <td>
                                                  <img src="../img/utility/moblerecharge.svg" />
                                                  &nbsp;
                                                  &nbsp;
                                                 {item.operatorName}</td>
                                               <td>{'NGN'}  {item.totalWalletDeduct}</td>
                                               <td>
                                               <p className={`complete-btn ${getButtonColor(
                                                 item?.status
                                               )}`}>
                                                     {item.status}
                                                   </p>
                                               </td>
                                               <td>{item.created_at1}</td>
                                           </tr>
                                           <tr onClick={()=>viewTransMobile(item)} className="d-show-mobile">
                                               <td className="td-mobile">
                                               <div className="mb-a-1">
                                                 <div className="sc-1">
                                                   <img src="../img/utility/moblerecharge.svg" />
                                                   &nbsp;
                                                   &nbsp;
                                                
                                                 </div>
                                                 <div className="sc-2">
                                                   <p>{item.operatorName}</p><br/>
                                                   <p>{item.totalWalletDeduct} {item.deliveredAmountCurrencyCode} <span className="mobile-gift">on </span> {item.recipientPhone} <span className="mobile-gift"> for </span> NGN {item.deductedAmount}</p><br/>
                                                   <p><span className="mobile-gift">{item.created_at1}</span></p><br/>
                                                 </div>
                                                 <div className="sc-3 mb-success">
                                                   <p className={`complete-btn ${getButtonColor(
                                                     item?.status
                                                     )}`}>
                                                     {item.status}
                                                   </p>
                                                 </div>
                                               </div>
                                               </td>
                                              
                                           </tr>
                                         </>
                                         ))
                                       }
                                       </>):(<>
                                         <tr>
                                           <td colSpan={4} className="text-center">
                                             <img src="../../img/EmptyState.svg" />
                                             <br/>
                                             <p>Transaction not found</p>
                                           </td>
                                         </tr>
                                       
                                       </>)
                                     }
                                     </>)}
                                     <tr>

                                     </tr>
                                 </tbody>
                             </table>
                         </>
                     ):(<></>)}

                     {selectedButton == 'billutility'?(
                         <>
                             <table class="table table-dark table-border transaction_table_head" id="mobile-1">
                                 <thead className="d-none-mobile">
                                 <tr className="fs-13">
                                     <th>NAME</th>
                                     <th className="trasaction-heading-mobile">&nbsp;</th>
                                     <th className="d-none d-sm-table-cell">Amount</th>
                                     <th >STATUS</th>
                                     <th className="d-none d-sm-table-cell">DATE</th>
                                 </tr>
                                 </thead>
                                 <tbody className="trn-1">
                                     {isLoader?(<>
                                       <tr>
                                         <td colSpan="4" className="text-center"> 
                                           <span
                                             className="text-white spinner-border spinner-border-sm"
                                             role="status"
                                             aria-hidden="true"
                                           ></span>{" "}
                                           <span className="text-white">Loading...</span></td>
                                       </tr>
                                     </>):(<>
                                       {
                                       storeUtilityTran.length>0?(<>
                                       {
                                         storeUtilityTran.map((item,index)=>(
                                         <>
                                           <tr onClick={()=>viewTransUtility(item)} className="d-none-mobile">
                                               <td>
                                                 
                                                  &nbsp;
                                                  {
                                                     item.billerType == 'ELECTRICITY_BILL_PAYMENT'?(<>
                                                     <img src="../img/utility/bolt.svg"/>
                                                     </>):("")
                                                   }
                                                   {
                                                     item.billerType == 'WATER_BILL_PAYMENT'?(<>
                                                     <img src="../img/utility/Waterdrop.svg"/>
                                                     </>):("")
                                                   }
                                                   {
                                                     item.billerType == 'TV_BILL_PAYMENT'?(<>
                                                     <img src="../img/utility/TVbill.svg"/>
                                                     </>):("")
                                                   }
                                                   {
                                                     item.billerType == 'INTERNET_BILL_PAYMENT'?(<>
                                                     <img src="../img/utility/internetbill.svg"/>
                                                     </>):("")
                                                   }
                                                  &nbsp;
                                                
                                                 {item.billerName}
                                               </td>
                                               <td>{'NGN'}  {item.totalWalletDeduct?item.totalWalletDeduct:0}</td>
                                               <td>
                                               <p className={`complete-btn ${getButtonColor(
                                                 item?.status
                                               )}`}>
                                                     {item.status}
                                                   </p>
                                               </td>
                                               <td>{item.created_at1}</td>
                                           </tr>
                                           <tr onClick={()=>viewTransUtility(item)} className="d-show-mobile">
                                               <td className="td-mobile">
                                                 <div className="mb-a-1">
                                                   <div className="sc-1">
                                                      &nbsp;
                                                       {
                                                           item.billerType == 'ELECTRICITY_BILL_PAYMENT'?(<>
                                                           <img src="../img/utility/bolt.svg"/>
                                                           </>):("")
                                                         }
                                                         {
                                                           item.billerType == 'WATER_BILL_PAYMENT'?(<>
                                                           <img src="../img/utility/Waterdrop.svg"/>
                                                           </>):("")
                                                         }
                                                         {
                                                           item.billerType == 'TV_BILL_PAYMENT'?(<>
                                                           <img src="../img/utility/TVbill.svg"/>
                                                           </>):("")
                                                         }
                                                         {
                                                           item.billerType == 'INTERNET_BILL_PAYMENT'?(<>
                                                           <img src="../img/utility/internetbill.svg"/>
                                                           </>):("")
                                                         }
                                                       &nbsp;
                                                   </div>
                                                   <div className="sc-2">
                                                     <p>{item.billerName}</p><br/>
                                                     <p>{item.billerType	}<span className="mobile-gift"> for</span>{' NGN '}  {item.totalWalletDeduct?item.totalWalletDeduct:0}</p><br/>
                                                     <p><span className="mobile-gift">{item.created_at1}</span></p><br/>
                                                   </div>
                                                   <div className="sc-3 mb-success">
                                                         <p className={`complete-btn ${getButtonColor(
                                                       item?.status
                                                     )}`}>
                                                     {item.status}
                                                   </p>
                                                   </div>
                                                 </div>
                                                  
                                                
                                                 
                                               </td>
                                              
                                           </tr>
                                         </>
                                         ))
                                       }
                                       </>):(<>
                                         <tr>
                                           <td colSpan={4} className="text-center">
                                             <img src="../../img/EmptyState.svg" />
                                             <br/>
                                             <p>Transaction not found</p>
                                           </td>
                                         </tr>
                                       
                                       </>)
                                     }
                                     </>)}
                                     <tr>

                                     </tr>
                                 </tbody>
                             </table>
                         </>
                     ):(<></>)}
                     
                     </div>
                 </div>
             </>)
           }
           <GiftCardTransactionDetails isOpenGTranD={isOpenGTranD} setIsOpenGTranD={setIsOpenGTranD} gdetails={gdetails} setIsOpenGTran={setIsOpenGTran} />
           <MobileTransactionDetails isOpenMTran={isOpenMTran} setIsOpenMTran={setIsOpenMTran} gdetails={gdetails} setIsOpenGTran={setIsOpenGTran} />
           <UtilityTransactionDetails isOpenUTran={isOpenUTran} setIsOpenUTran={setIsOpenUTran} gdetails={gdetails} setIsOpenGTran={setIsOpenGTran} />
       </div>
      </div>
    </div>
    
  );
}

export default TransactionList;

