import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const BVNsuccess = ({bvnNumber,setBvnNumber,hander}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [nextLoader, setNextLoader] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const continueStore = async ()=>{
        setNextLoader(true)
        if(bvnNumber == '' || bvnNumber == null)
        {
          toast('The bnv field is required');
          setNextLoader(false)
          return false;
        }
        if(bvnNumber.length < 11)
          {
            toast('The bnv number 11 digit required');
            setNextLoader(false)
            return false;
          }
          if(bvnNumber.length > 11)
            {
              toast('The bnv number 11 digit required');
              setNextLoader(false)
              return false;
            }
        // setIsFirstModalOpen(true); 
        const apicall = await AfterLoginService.verifyBvn(auth,bvnNumber);
        if (apicall.state === true) {
          apicall.result.json().then((resp) => {
            if (resp.status == 200) {
              setIsFirstModalOpen(true); 
              setNextLoader(false)
              setBvnNumber("");
            }
            else if(resp.status==400){
              toast(resp.message?resp.message:resp.errors['bvnNumber'][0])
              setNextLoader(false)
            } else {
              setNextLoader(false)
            }
          });
        } 
        else
        {
        }
       
  }
  const done = ()=>{
    setIsFirstModalOpen(false); 
    hander();
  }
  return (
    <div>
      <button className="btn btn-primary form-control buy_now "
       disabled={`${
        nextLoader ? "disabled" : ""
      }`}
      onClick={continueStore}>
                  {nextLoader ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            "Next"
                          )}
        
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content  update-name-modal ">
                <div className="modal-header">
                  <div className="row">
                    <div className="col-lg-12 col-ms-12 col-sm-12 col-12">
                      <img src="/img/righttick.svg" className=" close-red" alt="" />
                    </div>
                  </div>
                  <ToastContainer />

                  <button
                    type="button profile-close"
                    className="close"
                    onClick={closeFirstModal}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="modal-body ">
                  <h5 className="modal-title text-center">BVN Verify</h5>
                </div>
                <div className="modal-footer  d-flex ">
                    <div className="row">
                      {/* <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="view-btn text-center">
                          <button className="mt-2.5">
                            View Transactions
                          </button>
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="btn-done text-center " onClick={done} >
                          <button className="mt-2.5 "  >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BVNsuccess;
