import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./signup.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";

function Signup() {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [referral, setReferral] = useState("");
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState("");
  const [captchImg, setCaptchaImg] = useState("");
  const [apiKeys, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAPI();
    getCaptch();
    const script = document.createElement('script');
    script.src = "https://cdn1.intent.ai/pixel/6666c68a09c2f4040ea6c98b/intent.js";
    script.async = true;
    document.body.appendChild(script);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const getAPI = async () => {
    const apicall = await LoginSignService.GetApiKey();
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        setApiKey(resp);
      });
    } else {
    }
  };

  const getCaptch = async () => {
    const apicall = await LoginSignService.GetCaptcha();

    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        setCaptchaImg(resp1);
      });
    } else {
    }
  };

  const signupProceed = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      let captchakeyid = captchImg ? captchImg.data.captchakeyid : "";
      let captchaValue = captcha;
      let captc = { captchaValue, captchakeyid };
      var formData = new FormData();
      formData.append("captchakeyid", captchakeyid);
      formData.append("captchaValue", captchaValue);

      const apicall = await LoginSignService.VerifyCaptcha(formData);
      if (apicall.state === true) {
        apicall.result.json().then((resp1) => {
          if (resp1.statusCode == 400) {
            toast(resp1.message);
            getCaptch();
            setLoading(false);
          }
          if (resp1.statusCode == 200) {
            userSignUp();
          }
        });
      } else {
      }
    }
  };

  const userSignUp = async () => {
    let api_key = apiKeys.data.api_key;
    let user_plateform = 2;
    let item = {
      first_name,
      last_name,
      email,
      password,
      phone,
      referral,
      api_key,
      user_plateform,
    };
    let item1 = { email, password, api_key };

    const apicall = await LoginSignService.Signup(item);
    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        if (resp1.status == "200") {
          loginAfterSignup(item1);
        } else {
          toast(resp1.message);
          setLoading(false);
        }
      });
    } else {
    }
  };

  const validate = () => {
    let result = true;
    if (first_name === "" || first_name === null) {
      result = false;
      toast("The first name field is required");
    }
    if (last_name === "" || last_name === null) {
      result = false;
      toast("The last name field is required");
    }
    if (email === "" || email === null) {
      result = false;
      toast("The email  field is required");
    }
    if (phone === "" || phone === null) {
      result = false;
      toast("The phone field is required");
    }
    if (password === "" || password === null) {
      result = false;
      toast("The password field is required");
    }
    if (captcha === "" || captcha === null) {
      result = false;
      toast("The captcha field is required");
    }
    return result;
  };

  const loginAfterSignup = async (item1) => {
    const apicall = await LoginSignService.UserLogin(item1);
    if (apicall.state === true) {
      apicall.result.json().then((resp1) => {
        if (resp1.status == 404) {
          toast(resp1.message);
        }
        if (resp1.status == 400) {
          toast(resp1.message);
          const interval = setInterval(() => {
            navigate("/Password");
          }, 4000);
        }
        if (resp1.status == 200) {
          localStorage.setItem("user", JSON.stringify(resp1));
          navigate("/dashboard");
        }
      });
    } else {
    }
  };

  // mui css
  const textFieldStyles = {
    background: "#252731 ",
    width: "100%",
     height: "48px",
    borderRadius: "2px",
    fontSize:"12px"
  };

  return (
    <>
      <div className="signup-container">
        <div className="container">
          <div className="row  mt-5  ">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12">
              <div className=" row ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 ">
                  <div className=" logo-signup mb-5 " >
                    {/* <img src="img/signup.png" alt="singup" /> */}
                    <img src="img/logo.svg" alt="Dashboard Logo" />
                  </div>
                </div>
              </div>
              <div className="signup-content ">
                <div className="signup-heading ">
                  <h1 className="text-light">
                    Manage Your Account <span>With Confidence</span>
                  </h1>
                  <p style={{color:" #9b9999"}}>
                  Experience a faster, easier and more reliable way to sell
                    <span style={{ color: '#9b9999'}}>
                    your gift cards for Naira with Quchange
                    </span>
                  </p>
                </div>
              </div>
              <div className="row  ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 ">
                  <div className="footer-img " >
                    <img src="img/loginpic.svg" alt="footer" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 col-xs-12 signup-form1  " >
              <div className="signup-form ">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="card form-container" >
                        <div className="card-body ">
                          <h3 style={{marginTop:"-10px",color:"#FFFFFF"}}>Create Account</h3>
                          <div>
                            <h2 className=" text-[12px] font-[400] " style={{color:"#7D7DA1"}}>
                              Already have a Quchange Account?
                              <span className="">
                                <Link
                                  className="text-[white] btn-loginInstead"
                                  to="/"
                                >
                                  <button className="btn btn-next ml-1">
                                    {" "}
                                    Login Instead
                                  </button>
                                </Link>
                              </span>
                            </h2>
                          </div>
                          <form _lpchecked="1" onSubmit={signupProceed}>
                            <div className="form-group  ">
                              <TextField
                                type="text"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                // className="form-control mb-3"
                                id="name"
                                label={<span style={{ color: "#ffffff" }}>First Name</span>}
                                variant="filled"
                                style={textFieldStyles}
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                            </div>
                            <div className="form-group mt-2">
                              <TextField
                                type="text"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                // className="form-control mb-3"
                                id="name"
                                label={<span style={{ color: "#ffffff" }}>Last Name</span>}
                                variant="filled"
                                style={textFieldStyles}
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                            </div>
                            <div className="form-group mt-2">
                              <TextField
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // className="form-control mb-3"
                                id="email"
                                label={<span style={{ color: "#ffffff" }}>Email</span>}
                                variant="filled"
                                style={textFieldStyles}
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                            </div>
                            <div className="form-group mt-2">
                              <TextField
                                type="number"
                                min="1"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                name="phone number"
                                // className="form-control mb-3"
                                id="phone"
                                label={<span style={{ color: "#ffffff" }}>Phone Number</span>}
                                variant="filled"
                                style={textFieldStyles}
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                            </div>

                            <div className="form-group mt-2">
                              <TextField
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                // className="form-control"
                                id="password"
                                label={
                                  <span style={{ color: "#ffffff" }}>Password</span>
                                }
                                variant="filled"
                                style={textFieldStyles}
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                              <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="password-toggle-button p-3 text-light"
                                style={{ fontSize: "20px"  }}
                                
                              >
                                {showPassword ? (
                                  <FontAwesomeIcon icon={faEye} />
                                ) : (
                                  <FontAwesomeIcon icon={faEyeSlash} />
                                )}
                              </button>
                            </div>

                            <div
                              className="form-group mt-2"
                            >
                              <TextField
                                type="text"
                                value={referral}
                                onChange={(e) => setReferral(e.target.value)}
                                // className="form-control mb-3"
                                id="password"
                                label={
                                  <span style={{ color: "#ffffff" }}>Referral Code (Optional)</span>
                                }
                                variant="filled"
                                style={textFieldStyles}
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                            </div>
                            <div
                              className="form-group mt-2"
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TextField
                                type="text"
                                value={captcha}
                                onChange={(e) => setCaptcha(e.target.value)}
                                // className="form-control mb-3"
                                id="password"
                                style={{ flex: 1, ...textFieldStyles }} 
                                label={<span style={{ color: "#ffffff" }}>Captcha</span>}
                                variant="filled"
                                sx={{ input : {color : 'white'}}}
                                InputProps={{ disableUnderline: true }}
                              />
                              <div
                                className="captchaimage"
                                style={{
                                  position: "absolute",
                                  right: "0",
                                  top: "60%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <img
                                  src={
                                    captchImg ? captchImg.data.captchaimage : ""
                                  }
                                  alt="Captcha"
                                  
                                  id="Captcha"
                                  style={{
                                    width: "100%",
                                    height: "55px",
                                       marginTop:'14px',
                                      fontSize:'55px',
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-center form-group mt-2">
                              <button
                                type="submit"
                                className={`btn btn-next w-100 ${
                                  loading ? "disabled" : ""
                                }`}
                              >
                                {loading ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    {""}
                                    Loading....
                                  </>
                                ) : (
                                  "Create Account"
                                )}
                              </button>
                            </div>
                            <div className="mt-2">
                              <p className="term-text">
                                By clicking continue to create an account, you
                                agree
                                <span className="d-block">
                                  to our{" "}
                                  <span className="term">
                                    
                                    <a href='https://www.thequchange.com/terms-of-service' target="_blank" style={{color:'#FFFFFF', textDecoration:'none'}}> Term of Conditions</a> 
                                  </span>{" "}
                                  and
                                  <span className="term">
                                    
                                     <a href='https://www.thequchange.com/privacypolicy' target="_blank" style={{color:'#FFFFFF', textDecoration:'none'}}>Privacy policy </a>
                                     </span>
                                </span>
                              </p>
                            </div>
                            <div className="" style={{ marginTop: "-15px" }}>
                              <h2 className=" text-[12px] font-[400] " style={{color:"#7D7DA1"}}>
                                Already have a Quchange Account?
                                <span className="">
                                  <Link
                                    className="text-[white] btn-loginInstead"
                                    to="/"
                                  >
                                    <button className="btn btn-next ml-1 ">
                                      {" "}
                                      Login Instead
                                    </button>
                                  </Link>
                                </span>
                              </h2>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;






