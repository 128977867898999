import React, { useState } from "react";
import "./notification.scss";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal notification-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Notificationmodal = () => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [notifcation , setNotification] = useState([]);
  const [loader, setloader] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
    getNotification();
  };

  
  const getNotification = async () => {
    if (auth) {
      setloader(true);
      const apicall = await AfterLoginService.GetNotification(auth);
     
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setNotification(resp.data);
            setloader(false);
          } else {
            console.log(resp.message);
            setloader(false);
          }
        });
      } else {
      }
    }
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
      <button className="notification-icon" onClick={openFirstModal}>
        {/* <i class="bi bi-bell-fill text-white"></i> */}
        <img src="/img/Notifcation.svg"  /> 
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal" onClick={closeFirstModal}>
          
            <div className="modal-dialog">
            <div className="image-container   ">
                  <img src="/img/CaretLeft.png" />
                </div> 
              <div className="modal-content">
              
                <div className="modal-header">
               
                  <h5 className="modal-title">
                    
                    Notification
                    <span className="d-block">
                      {/* Lorem ipsum dolor sit amet consectetur. */}
                    </span>
                  </h5>
                </div>
                {loader ? (
                  <>
                      

                    <div className="modal-body">
                      <div className="msg-section">
                      <span
                        className="text-white spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      <span className="text-white">Loading...</span>
                      </div>
                    </div>
                  </>
                ):(
                
                  <div className="modal-body">
                  {
                    notifcation.map((item)=>
                      <div className="msg-section">
                        
                    <div className="msg-icon">
                      <img src="img/Withdrawal.svg" />
                    </div>
                    <div className="msg-text">
                      <h5>
                        {item.title}
                        <span>
                          {item.message}
                        </span>
                      </h5>
                      <p>{item.timestamp}</p>
                    </div>
                  </div>
                    )
                  }
                  
                  
                 
                </div>
                )}
                
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Notificationmodal;
