/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Giftconfirmmodal from '../giftcard/giftconfirmmodal';
import GiftCardService from '../../Services/LoginSignService/GiftCardService';
import { RemoveIcon } from '../../Svg/AllSvg';

const CryptoModal = ({ setOpenCrypto, openCrypto, cryptoInfo }) => {
    const [images, setImages] = useState([])
    const [imageURLS, setImageURLs] = useState([])
    const [total, setTotal] = useState(0)
    const [open, setOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [qty, setQty] = useState(0)
    const [address, setAddress] = useState("")
    const auth = JSON.parse(localStorage.getItem('user'));
    const handleClose = () => {
        setImages([])
        setImageURLs([])
        setTotal(0)
        setQty(0)
        setOpenCrypto(false);
    };

    const handlesubmit = () => {
        if (total !== 0 && images?.length > 0) {
            setOpen(true)
        } else
            if (total === 0) {
                toast('Please enter the amount to sell')
            } else
                if (images?.length === 0) {
                    toast('Please select atleast one image!')
                }
                else {
                    toast('Please select All values!!')
                }
    }

    const onImageChange = (e) => {
        setImages([...images, ...e.target.files]);
    }

    const deleteFile = (e) => {
        const s = images.filter((item, index) => index !== e);
        setImages(s);
    }

    const handleonInput = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
    const handleonKeyDown = (event) => {
        if (event.key === '.') {
            event.preventDefault();
        }
    }

    const handleChange = (e) => {
        let count = 0
        setQty(e.target.value)
        count = e.target.value * cryptoInfo?.rate
        setTotal(count)
    }

    const copyToClipboard = (e) => {
        // console.log(e?.target?.value,'e?.target?.value');
        navigator.clipboard.writeText(address)
        // document.execCommand('copy');
        // e.target.focus();
        toast('Address copy to clipboard')
    };

    const handleProceed = async () => {
        var formdata = new FormData();
        formdata.append("transactionType", "crypto");
        formdata.append("address", cryptoInfo?.address?.toString());
        formdata.append("amount", qty.toString());
        formdata.append("cardID", cryptoInfo?.id.toString());
        formdata.append("platform", "Web");
        formdata.append("rate", cryptoInfo?.rate.toString());
        formdata.append("total", total.toString());
        images.forEach(image => {
            formdata.append("images[]", image);
        });

        const apiCall = await GiftCardService.SellCryptoTransaction(formdata,auth);
        if (apiCall.state === true) {
            apiCall.result.json().then((resp1) => {
                if (resp1.status === 404) {
                    toast(resp1.message)
                }
                if (resp1.status === 400) {
                    toast(resp1.message);
                }
                if (resp1.status === 200) {
                    toast(resp1?.message)
                    setIsOpen(true)
                }
            })
        } else {
            toast("Something went wrong!")
        }
    }

    const handleConfirm = () => {
        setOpenCrypto(false)
        setIsOpen(false)
        setOpen(false)
        setTotal(0)
        setQty(0)
        setImages([])
        setImageURLs([])
    }

    useEffect(() => {
        setAddress(cryptoInfo?.address)
    }, [cryptoInfo])

    useEffect(() => {
        if (images.length < 0) return
        const newImageUrls = [];
        images.forEach((image) => newImageUrls.push(image));
        // images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
        setImageURLs(newImageUrls);
    }, [images]);

    return (
        <>
            <Giftconfirmmodal qty={qty} handleProceed={handleProceed} handleConfirm={handleConfirm} setProceedModal={setOpen} proceedModal={open} confirmModal={isOpen} setConfirmModal={setIsOpen} total={total} gitCardCurrency={cryptoInfo} />
            {openCrypto && (
                <div className="modal bestbuy-modal !z-[1021] !bg-[#00000073]">
                    <div className="modal-content">
                        <div className="modal">
                            <div className='modal-dialog crypto-modal'>
                                <div className="modal-content !max-h-[500px] mt-5">
                                    <div className={`modal-header border-bottom-0`} style={{ backgroundColor: `${cryptoInfo.background}` }}>
                                        <div className='header-img d-flex justify-center items-center w-100'>
                                            <img className='!h-11 ' src={cryptoInfo?.image} alt='img' />
                                        </div>
                                        <button type="button" className=" gift-close-btn" onClick={handleClose}>
                                            <img src='img/close-icon.png' alt='img' />
                                        </button>
                                    </div>
                                    <div className="modal-body no-scrollbar !px-8">
                                        <form _lpchecked="1" className='!mt-4'>
                                            <div className="form-group w-[350px]" >
                                                <p className='mb-0 text-white small p-2'>Rate</p>
                                                <input type="text" value={`$1 ${cryptoInfo?.name} = ₦${cryptoInfo?.rate}`} className="form-control mb-[10px] tracking-wide w-100 input__cursor text-white" id="name"
                                                    style={{ color: "transparent" }} />
                                            </div>
                                            <div className="form-group w-[350px]" >
                                                <p className='mb-0 text-white small p-2'>Amount to sell</p>
                                                <input type="number" className="form-control mb-[10px] w-100 text-white" min="1" pattern="[0-9]" onChange={(e) => handleChange(e)}
                                                    step="1" onInput={(e) => handleonInput(e)} onKeyDown={(e) => handleonKeyDown(e)} id="name" placeholder="Enter amount to sell"
                                                />
                                            </div>
                                            <div className="form-group w-[350px]" >
                                                <p className='mb-0 text-white small p-2'>Pay to the address below</p>
                                                <input type="text" value={address} className="form-control mb-2 w-100 input__cursor text-white" id="name" style={{ color: "transparent" }} />
                                                <p className='hover:underline hover:cursor-pointer text-center text-white text-[14px] mb-[14px]' onClick={(e) => copyToClipboard(e)}>Copy to clipboard</p>

                                            </div>
                                            <div className='drag-drop mb-4 !w-[350px]'>
                                                <form className='!mt-5'>
                                                    <div class="upload_dropZone text-center w-full px-[10px]">
                                                        {imageURLS.length > 0 ? (
                                                            <>
                                                                {imageURLS.map((imageSrc, index) => (
                                                                    <div className='flex justify-between items-center w-full mb-3' key={index}>
                                                                        <img
                                                                            alt="not found"
                                                                            width={"82px"}
                                                                            height={"82px"}
                                                                            src={URL.createObjectURL(imageSrc)}
                                                                            className='w-[82px] h-[82px] object-fill'
                                                                        />
                                                                        <div className='flex flex-col'>
                                                                            <p className='text-[15px] !mb-0'>Upload Successful</p>

                                                                            <p className='text-[13px] !mb-0 flex'>File &nbsp;  <p className='text-sm truncate w-[75px] !mb-0'>{imageSrc?.name}</p> | {(imageSrc?.size / 1024).toFixed(2)}KB </p>
                                                                        </div>
                                                                        <div className='flex gap-1 items-center cursor-pointer text-xs text-[#EB5757]' onClick={() => deleteFile(index)}>

                                                                            <RemoveIcon />
                                                                            Remove
                                                                        </div>
                                                                    </div>

                                                                ))}
                                                            </>)
                                                            : (
                                                                <p class="small my-2 mb-0"><i class="bi bi-file-image ms-2"></i><span className='ms-2'>Drag and Drop file</span></p>
                                                            )}

                                                        <input id="upload_image_background" data-post-name="image_background" class="position-absolute invisible" type="file"
                                                            onChange={(event) => {
                                                                onImageChange(event)
                                                            }} multiple accept="image/*" />
                                                        <label class="btn btn-upload" for="upload_image_background">Select a file</label>
                                                        <div class="upload_gallery d-flex flex-wrap justify-content-center mb-0"></div>
                                                    </div>
                                                </form>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer ">
                                        <div onClick={handlesubmit} className="btn-proceed-crypto">
                                            <p className='mt-1 pt-1 '>
                                                Proceed
                                            </p>
                                            {(total !== 0) &&
                                                <p className="n-btn-crypto  " >
                                                    for ₦{total}
                                                </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CryptoModal





// company code

// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable jsx-a11y/alt-text */
// import React, { useEffect, useState } from 'react'
// import { toast } from 'react-toastify';
// import Giftconfirmmodal from '../giftcard/giftconfirmmodal';
// import GiftCardService from '../../Services/LoginSignService/GiftCardService';
// import { RemoveIcon } from '../../Svg/AllSvg';

// const CryptoModal = ({ setOpenCrypto, openCrypto, cryptoInfo }) => {
//     const [images, setImages] = useState([])
//     const [imageURLS, setImageURLs] = useState([])
//     const [total, setTotal] = useState(0)
//     const [open, setOpen] = useState(false)
//     const [isOpen, setIsOpen] = useState(false)
//     const [qty, setQty] = useState(0)
//     const [address, setAddress] = useState("")

//     const handleClose = () => {
//         setImages([])
//         setImageURLs([])
//         setTotal(0)
//         setQty(0)
//         setOpenCrypto(false);
//     };

//     const handlesubmit = () => {
//         if (total !== 0 && images?.length > 0) {
//             setOpen(true)
//         } else
//             if (total === 0) {
//                 toast('Please enter the amount to sell')
//             } else
//                 if (images?.length === 0) {
//                     toast('Please select atleast one image!')
//                 }
//                 else {
//                     toast('Please select All values!!')
//                 }
//     }

//     const onImageChange = (e) => {
//         setImages([...images, ...e.target.files]);
//     }

//     const deleteFile = (e) => {
//         const s = images.filter((item, index) => index !== e);
//         setImages(s);
//     }

//     const handleonInput = (event) => {
//         event.target.value = event.target.value.replace(/[^0-9]*/g, '');
//     }
//     const handleonKeyDown = (event) => {
//         if (event.key === '.') {
//             event.preventDefault();
//         }
//     }

//     const handleChange = (e) => {
//         let count = 0
//         setQty(e.target.value)
//         count = e.target.value * cryptoInfo?.rate
//         setTotal(count)
//     }

//     const copyToClipboard = (e) => {
//         // console.log(e?.target?.value,'e?.target?.value');
//         navigator.clipboard.writeText(address)
//         // document.execCommand('copy');
//         // e.target.focus();
//         toast('Address copy to clipboard')
//     };

//     const handleProceed = async () => {
//         var formdata = new FormData();
//         formdata.append("transactionType", "crypto");
//         formdata.append("address", cryptoInfo?.address?.toString());
//         formdata.append("amount", qty.toString());
//         formdata.append("cardID", cryptoInfo?.id.toString());
//         formdata.append("platform", "Web");
//         formdata.append("rate", cryptoInfo?.rate.toString());
//         formdata.append("total", total.toString());
//         images.forEach(image => {
//             formdata.append("images[]", image);
//         });

//         const apiCall = await GiftCardService.SellCryptoTransaction(formdata);
//         if (apiCall.state === true) {
//             apiCall.result.json().then((resp1) => {
//                 if (resp1.status === 404) {
//                     toast(resp1.message)
//                 }
//                 if (resp1.status === 400) {
//                     toast(resp1.message);
//                 }
//                 if (resp1.status === 200) {
//                     toast(resp1?.message)
//                     setIsOpen(true)
//                 }
//             })
//         } else {
//             toast("Something went wrong!")
//         }
//     }

//     const handleConfirm = () => {
//         setOpenCrypto(false)
//         setIsOpen(false)
//         setOpen(false)
//         setTotal(0)
//         setQty(0)
//         setImages([])
//         setImageURLs([])
//     }

//     useEffect(() => {
//         setAddress(cryptoInfo?.address)
//     }, [cryptoInfo])

//     useEffect(() => {
//         if (images.length < 0) return
//         const newImageUrls = [];
//         images.forEach((image) => newImageUrls.push(image));
//         // images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
//         setImageURLs(newImageUrls);
//     }, [images]);

//     return (
//         <>
//             <Giftconfirmmodal qty={qty} handleProceed={handleProceed} handleConfirm={handleConfirm} setProceedModal={setOpen} proceedModal={open} confirmModal={isOpen} setConfirmModal={setIsOpen} total={total} gitCardCurrency={cryptoInfo} />
//             {openCrypto && (
//                 <div className="modal bestbuy-modal !z-[1021] !bg-[#00000073]">
//                     <div className="modal-content">
//                         <div className="modal">
//                             <div className='modal-dialog !w-auto'>
//                                 <div className="modal-content !max-h-[600px]">
//                                     <div className={`modal-header border-bottom-0 `} style={{ backgroundColor: `${cryptoInfo.background}` }}>
//                                         <div className='header-img d-flex justify-center items-center w-100'>
//                                             <img className='!h-11' src={cryptoInfo?.image} alt='img' />
//                                         </div>
//                                         <button type="button" className="close" onClick={handleClose}>
//                                             <img src='img/close-icon.png' alt='img' />
//                                         </button>
//                                     </div>
//                                     <div className="modal-body no-scrollbar !px-8">
//                                         <form _lpchecked="1" className='!mt-4'>
//                                             <div className="form-group w-[350px]" >
//                                                 <p className='mb-0 text-white small p-2'>Rate</p>
//                                                 <input type="text" value={`$1 ${cryptoInfo?.name} = ₦${cryptoInfo?.rate}`} className="form-control mb-[10px] tracking-wide w-100 input__cursor text-white" id="name"
//                                                     style={{ color: "transparent" }} />
//                                             </div>
//                                             <div className="form-group w-[350px]" >
//                                                 <p className='mb-0 text-white small p-2'>Amount to sell</p>
//                                                 <input type="number" className="form-control mb-[10px] w-100 text-white" min="1" pattern="[0-9]" onChange={(e) => handleChange(e)}
//                                                     step="1" onInput={(e) => handleonInput(e)} onKeyDown={(e) => handleonKeyDown(e)} id="name" placeholder="Enter amount to sell"
//                                                 />
//                                             </div>
//                                             <div className="form-group w-[350px]" >
//                                                 <p className='mb-0 text-white small p-2'>Pay to the address below</p>
//                                                 <input type="text" value={address} className="form-control mb-2 w-100 input__cursor text-white" id="name" style={{ color: "transparent" }} />
//                                                 <p className='hover:underline hover:cursor-pointer text-center text-white text-[14px] mb-[14px]' onClick={(e) => copyToClipboard(e)}>Copy to clipboard</p>

//                                             </div>
//                                             <div className='drag-drop mb-4 !w-[350px]'>
//                                                 <form className='!mt-5'>
//                                                     <div class="upload_dropZone text-center w-full px-[10px]">
//                                                         {imageURLS.length > 0 ? (
//                                                             <>
//                                                                 {imageURLS.map((imageSrc, index) => (
//                                                                     <div className='flex justify-between items-center w-full mb-3' key={index}>
//                                                                         <img
//                                                                             alt="not found"
//                                                                             width={"82px"}
//                                                                             height={"82px"}
//                                                                             src={URL.createObjectURL(imageSrc)}
//                                                                             className='w-[82px] h-[82px] object-fill'
//                                                                         />
//                                                                         <div className='flex flex-col'>
//                                                                             <p className='text-[15px] !mb-0'>Upload Successful</p>

//                                                                             <p className='text-[13px] !mb-0 flex'>File &nbsp;  <p className='text-sm truncate w-[75px] !mb-0'>{imageSrc?.name}</p> | {(imageSrc?.size / 1024).toFixed(2)}KB </p>
//                                                                         </div>
//                                                                         <div className='flex gap-1 items-center cursor-pointer text-xs text-[#EB5757]' onClick={() => deleteFile(index)}>

//                                                                             <RemoveIcon />
//                                                                             Remove
//                                                                         </div>
//                                                                     </div>

//                                                                 ))}
//                                                             </>)
//                                                             : (
//                                                                 <p class="small my-2 mb-0"><i class="bi bi-file-image ms-2"></i><span className='ms-2'>Drag and Drop file</span></p>
//                                                             )}

//                                                         <input id="upload_image_background" data-post-name="image_background" class="position-absolute invisible" type="file"
//                                                             onChange={(event) => {
//                                                                 onImageChange(event)
//                                                             }} multiple accept="image/*" />
//                                                         <label class="btn btn-upload" for="upload_image_background">Select a file</label>
//                                                         <div class="upload_gallery d-flex flex-wrap justify-content-center mb-0"></div>
//                                                     </div>
//                                                 </form>
//                                             </div>
//                                         </form>
//                                     </div>
//                                     <div className="modal-footer w-100">
//                                         <div onClick={handlesubmit} className="hover:bg-[#146c43] hover:cursor-pointer bg-[#198754] py-[6px] px-3 rounded-md text-white h-[38px] flex gap-1">
//                                             <p>
//                                                 Proceed
//                                             </p>
//                                             {(total !== 0) &&
//                                                 <p>
//                                                     for ₦{total}
//                                                 </p>}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     )
// }

// export default CryptoModal