import React, { useEffect, useRef, useState } from "react";
import "./account.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Avatar from "@mui/material/Avatar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TabPane } from "react-bootstrap";
import Paymentmodal from "./paymentmodal";
import Paymentaddmodal from "./paymentaddmodal";
import Editmodal from "./editnamemodal";
import Editmail from "./editemailmodal";
import Withdrawmodal from "./withdrawmodal";
import { useNavigate } from "react-router";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import KYCmodal from "./kycmodal";
import KYCSubmit from "./kycsubmit";
import BVNmodal from "./bvnmodal";
import InternationalPassport from "./internationalpassportModal";

function Account() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [detail, setDetails] = useState("");
  const [current_password, setPassword] = useState("");
  const [new_password, setNewPass] = useState("");
  const [confirm_password, setConfirmPass] = useState("");
  const [bank, setBankDetail] = useState("");
  const [bankIsval, setBankTrue] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [isHide, setIsHide] = useState(false);
  const [isKycScreen, setIsKycScreen] = useState(false);
  const [isKycStatus, setIsKycStatus] = useState("");
  const [isKycData, setKycData] = useState("");
  const [checkIconShow,setCheckIconShow] = useState(false);
  const [checkBvnIconShow,setBvnCheckIconShow] = useState(false);
  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
    setLoader(true);
    getDetails();
    getPaymentDetails();
    getKycLists();
  }, []);

  // Copy Paste

  const [copied, setCopied] = useState(false);

  const copyReferralCode = () => {
    const referralCode = auth ? auth.user.referral_code : "";
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy referral code: ", error);
      });
  };

  //
  const getDetails = async () => {
    if (auth) {
      const apicall = await AfterLoginService.GetDetails(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setLoader(false);
            setDetails(resp.data);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (validatePass()) {
      setLoading(true);
      const item = { current_password, new_password, confirm_password };
      const apicall = await AfterLoginService.UpdatePassword(item, auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            toast(resp.message);
            setPassword("");
            setNewPass("");
            setConfirmPass("");
            setLoading(false);
          } else {
            setLoading(false);

            if (resp.data.confirm_password[0]) {
              toast(resp.data.confirm_password[0]);
            }
          }
        });
      } else {
      }
    }
  };

  const againKyc = () => {
    setIsKycScreen(true);
  };

  const validatePass = () => {
    var result = true;
    if (current_password === "" || current_password === null) {
      toast("Please enter current password");
      result = false;
    }
    if (new_password === "" || new_password === null) {
      toast("Please enter new password");
      result = false;
    }
    if (confirm_password === "" || confirm_password === null) {
      toast("Please enter confirm password");
      result = false;
    }
    return result;
  };

  const handleDatas = () => {
    getKycLists();
  };

  const getKycLists = async () => {
    if (auth) {
      const apicall = await AfterLoginService.GetKycList(auth);

      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setKycData(resp.data);
            setIsKycStatus(resp.data.status);
            setIsKycScreen(false);
          } else if (resp.status == 404 && resp.message == "Kyc not verified") {
            setIsKycScreen(true);
          }
        });
      } else {
      }
    }
  };

  const getPaymentDetails = async () => {
    const apicall = await AfterLoginService.CheckBankDetail(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          if (resp.isdetailadded == 1) {
            setBankDetail(resp.data);
            setBankTrue(resp);
          } else {
            // toast(resp.message);
          }
        } else {
          // toast(resp.message);
        }
      });
    } else {
    }
  };

  const paymentHandle = () => {
    getPaymentDetails();
  };
  const profileHandle = () => {
    getDetails();
  };
  // mui css
  const textFieldStyles = {
    // backgroundColor: "white",
    color: "black",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [profileMenuVisible, setProfileMenuVisible] = useState(true);
  const [profileDataVisible, setProfileDataVisible] = useState(false);

  const toggleProfileData = (arg) => {
    if (arg == "eight") {
      getKycLists();
    }
    if (window.innerWidth <= 768) {
      setProfileMenuVisible(false);
      setProfileDataVisible(true);
    }
  };

  useEffect(() => {
    // Check if the screen width is less than or equal to 768px (mobile)
    if (window.innerWidth <= 768) {
      setProfileMenuVisible(true); // Show the profile menu on mobile
      setProfileDataVisible(false); // Hide the profile data on mobile
    } else {
      setProfileMenuVisible(true); // Show the profile menu by default on laptops
      setProfileDataVisible(true); // Show the profile data by default on laptops
    }
  }, []);

  const handleTermsClick = (event) => {
    event.preventDefault(); // Prevents the default behavior of the link click
    window.open("https://www.thequchange.com/terms-of-service", "_blank");
    // Opens the link in a new tab/window
   
    window.location.reload();
    
  };

  return (
    <div className="container-fluid position-relative d-flex p-0 account-container">
      <Header />
      <div className="content">
        <Subheader />

        <div className="container-fluid pt-4 px-4 account-table">
          <div className="row">
            {loader ? (
              <>
                <div className="col-md-12 text-center m-4">
                  <span
                    className="text-white spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="text-white">Loading...</span>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between accountF">
                  <div className="back-icon-account">
                    <img src="/img/CaretLeft1.svg" onClick={goBack} />
                  </div>
                  <div className="account-head">
                    <h3 className="text-white account-header ">Account</h3>
                    <p className="account-p fs-13">
                      {/* Lorem ipsum dolor sit amet consectetur. */}
                    </p>
                  </div>
                  <div className="setting-icon">
                    <span className="pb-1">
                      <i class="bi bi-gear"></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-12 col-xs-12 profile-container">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col className="profile-menu" sm={3}>
                        <div className="referral-account mb-4">
                          <div className=" ">
                            <p className="ml-2 referal-code-p">Referral Code</p>
                            <h5 className="ml-2 referral-code-h5 ">
                              {auth ? auth.user.referral_code : ""}{" "}
                              <div className="" onClick={copyReferralCode}>
                                {/* <ContentCopyIcon className="copy-icon mx-1" /> */}
                                <img
                                  src="/img/copyicon.svg"
                                  className="copy-icon mx-1"
                                  alt=""
                                />
                                {copied && (
                                  <div className="copied-msg">Copied!</div>
                                )}
                              </div>
                            </h5>
                          </div>
                        </div>

                        <Nav variant="pills" className="flex-column">
                          <Nav.Item className="">
                            <Nav.Link>
                              <span className=" mb-2 more">
                                ACCOUNT SETTINGS
                              </span>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item className="nav-item-horizontal-line">
                            <Nav.Link
                              eventKey="first"
                              onClick={() => toggleProfileData(true)}
                            >
                              <i class="bi bi-person-circle mb-3 account-menu-icon"></i>
                              {/* <img src="/img/profile2.svg" alt=""/> */}
                              <span className="ms-3 mb-3 account-menu">
                                Profile Settings
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className="right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link className="d-none " eventKey="second">
                              <i class="bi bi-people account-menu-icon"></i>
                              <span className="ms-3 account-menu">
                                Referral
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              onClick={() => toggleProfileData(true)}
                            >
                              <i class="bi bi-lock mb-3 account-menu-icon"></i>
                              {/* <img src="/img/password.svg" alt="" className="mb-2" /> */}
                              <span className="ms-3 mb-3 account-menu">
                                Password Reset
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>

                          <div className="mobile-line"></div>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="fourth"
                              onClick={() => toggleProfileData(true)}
                            >
                              <i class="bi bi-bank mb-3 account-menu-icon"></i>
                              <span className="ms-3 mb-3 account-menu ">
                                Payment Details
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <div className="mobile-line"></div>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="eight"
                              onClick={() => toggleProfileData("eight")}
                            >
                              <span class="material-symbols-outlined kyc-icon mb-3 account-menu-icon">
                                person_check
                              </span>
                              <span className="ms-3 kyc-text">Kyc</span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <div className="mobile-line"></div>
                          <Nav.Item className="">
                            <Nav.Link>
                              <span className=" mb-1 more">MORE</span>
                            </Nav.Link>
                          </Nav.Item>

                          <div className="mobile-line"></div>
                          <Nav.Item></Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="six"
                              onClick={() => toggleProfileData(true)}
                            >
                              <i class="bi bi-shield mb-3 account-menu-icon"></i>
                              <span className="ms-3 mb-3 account-menu">
                                Privacy policy
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <div className="mobile-line"></div>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="nine"
                              onClick={() => toggleProfileData(true)}
                            >
                              <i class="bi bi-shield mb-3 account-menu-icon"></i>
                              <span className="ms-3 mb-3 account-menu">
                                <a
                                  href="https://www.thequchange.com/terms-of-service"
                                  target="_blank"
                                  rel="noopener noreferrer" 
                                  onClick={handleTermsClick}
                                  className="term-service"
                                >
                                  Terms of Services
                                </a>
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <div className="mobile-line"></div>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="seven"
                              onClick={() => toggleProfileData(true)}
                            >
                              <i class="bi bi-chat-text mb-3 account-menu-icon"></i>
                              <span className="ms-3 mb-3 account-menu">
                                Help & FAQ
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <div className="mobile-line"></div>

                          
                          <Nav.Item>
                            <Nav.Link
                              eventKey=""
                              onClick={() => toggleProfileData(true)}
                            >
                              <img
                                src="/img/logout1.svg"
                                className="text-danger mb-3 account-menu-icon"
                                alt=""
                              />
                              <span
                                className="ms-3 mb-4 text-danger account-menu logout-btn-account"
                                onClick={logout}
                              >
                                <span className="account-menu1"> Logout</span>
                              </span>
                              <img
                                src="/img/CaretLeft.svg"
                                className=" right-arrow mobile-only"
                              />
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>

                      <Col
                        sm={9}
                        className={`profile-data  ${
                          profileDataVisible ? "visible" : ""
                        }`}
                      >
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="profile-details">
                              <div className="back-icon-main">
                                <a href="/Account">
                                  <img src="/img/CaretLeft1.svg" />
                                </a>
                              </div>
                              <div className="setting-icon-profile">
                                <img src="/img/setting.png" alt="" />
                              </div>
                              <h3 className="text-white">Profile</h3>
                              {/* <p className="profle-p">
                                Lorem ipsum dolor sit amet consectetur.
                              </p> */}
                              <p className="text-white"></p>

                              <div className="name-details mb-3">
                                <div className="d-flex ">
                                  <img
                                    className="rounded-circle me-lg-2 ml-2"
                                    src="img/profile.svg"
                                  />

                                  <h4 className="ms-3 ">
                                    {detail.firstname}
                                    &nbsp;
                                    {detail.lastname}
                                    <span className="d-block h6 pt-1 edit-phone">
                                      {detail.phone}
                                    </span>
                                  </h4>
                                </div>
                                <div className="">
                                  <Editmodal profileHandle={profileHandle} />
                                </div>
                              </div>
                              <div className="email-details">
                                <div className="d-flex">
                                  <h4 className="ms-3">
                                    Email
                                    <span className="d-block h5 pt-1">
                                      {detail.email}
                                    </span>
                                  </h4>
                                </div>
                                <div>
                                  <Editmail Editmail={detail} />
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="third">
                            <div className="reset-container">
                              <h3 className="text-white">Password Reset</h3>
                              <p className="reset-password-p">
                                complete below to reset password
                              </p>
                              <p className="text-white"></p>

                              <button
                                type="button"
                                className="close-reset-password"
                              >
                                <a href="/Account">
                                  <img src="img/Close1.svg" />
                                </a>
                              </button>
                              <form
                                _lpchecked="1"
                                onSubmit={updatePassword}
                                className="password-reset"
                              >
                                <div className="form-group  w-100">
                                  <TextField
                                    type="password"
                                    value={current_password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    // className="form-control mb-4"
                                    id="name"
                                    label={
                                      <span style={{ color: "gray" }}>
                                        Current Password
                                      </span>
                                    }
                                    variant="filled"
                                    style={textFieldStyles}
                                    InputProps={{ disableUnderline: true }}
                                  />
                                </div>
                                <div className="form-group mt-2 w-100">
                                  <TextField
                                    type="password"
                                    value={new_password}
                                    onChange={(e) => setNewPass(e.target.value)}
                                    // className="form-control mb-4"
                                    id="name"
                                    label={
                                      <span style={{ color: "gray" }}>
                                        New Password
                                      </span>
                                    }
                                    variant="filled"
                                    style={textFieldStyles}
                                    InputProps={{ disableUnderline: true }}
                                  />
                                </div>
                                <div className="form-group mt-2 w-100">
                                  <TextField
                                    type="password"
                                    // className="form-control mb-4"
                                    id="email"
                                    value={confirm_password}
                                    onChange={(e) =>
                                      setConfirmPass(e.target.value)
                                    }
                                    label={
                                      <span style={{ color: "gray" }}>
                                        Confirm Password
                                      </span>
                                    }
                                    variant="filled"
                                    style={textFieldStyles}
                                    InputProps={{ disableUnderline: true }}
                                  />
                                </div>

                                <div class="form-group mt-2 ">
                                  <button
                                    type="submit"
                                    className={`btn btn-exchange reset-password ${
                                      loading ? "disabled" : ""
                                    }`}
                                  >
                                    {loading ? (
                                      <>
                                        <span
                                          className="spinner-border spinner-border-sm "
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </>
                                    ) : (
                                      "Update"
                                    )}
                                  </button>
                                </div>
                              </form>

                              <ToastContainer />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth" className="mt-4">
                            <div className="profile-details">
                              <div className="back-icon-main">
                                <a href="/Account">
                                  <img src="/img/CaretLeft1.svg" />
                                </a>
                              </div>
                              <h3 className="text-white">Payment Details</h3>
                              <p className="text-white"></p>
                              {bankIsval.isdetailadded === 1 ? (
                                <div className="referral-details mb-3">
                                  <div className="bank-box mt-3">
                                    <div className=" mx-3">
                                      {/* <Avatar >
                                        <img src="/img/bank.svg"  />
                                      </Avatar> */}
                                      <img
                                        src="/img/bank.svg"
                                        style={{ marginTop: "-2rem" }}
                                      />
                                      <h4 className="ms-3">
                                        {bank.bank}
                                        <span className="d-block h6">
                                          {bank.accountName}
                                        </span>
                                      </h4>
                                    </div>
                                    <button className="btn btn-pass ms-4">
                                      {bank.accountNumber}
                                    </button>
                                  </div>
                                  <div className="mx-3">
                                    <Paymentmodal
                                      bank1={bank.bank}
                                      bankcode1={bank.bank_code}
                                      accountname1={bank.accountName}
                                      accountnumber1={bank.accountNumber}
                                      checkbank={bankIsval.isdetailadded}
                                      paymentHandle={paymentHandle}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="referral-details mb-3">
                                  <div className="">
                                    <div className="row ">
                                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-xs-12 d-flex justify-content-center mb-4">
                                        <div className="">
                                          <img
                                            src="img/EmptyState.svg"
                                            alt=""
                                            style={{ marginLeft: "60px" }}
                                            className="EmptyState-no"
                                          />
                                          <h4 className="not-transction">
                                            Payment Details
                                          </h4>
                                          <p className="not-transction-p">
                                            You have no payment details, click
                                            on the <br /> link below to add a
                                            new payment method
                                          </p>

                                          <Paymentaddmodal
                                            paymentHandle={paymentHandle}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="six">
                            <div
                              className="back-icon-main mt-5 "
                              style={{ paddingLeft: "1.5rem" }}
                            >
                              <a href="/Account">
                                <img src="/img/CaretLeft1.svg" />
                              </a>
                            </div>
                            <div className="policy-details text-white">
                              <h3 className="">Privacy Policy Terms</h3>
                              <p className="fs-13">
                                This Privacy Policy applies to all of the
                                products, services and websites offered by
                                QUCHANGE In addition, where more detailed
                                information is needed to explain our privacy
                                practices, we post supplementary privacy notices
                                to describe how particular services process
                                personal information.{" "}
                              </p>
                              <h6 className="fs-13 font-normal">
                                Information we collect and how we use them{" "}
                              </h6>
                              <p className="fs-13 fs-13-1">
                                We understand that in the course of delivering
                                our numerous products/ services to you via the
                                net, we will come across certain information
                                pertaining to your good self which, to our mind,
                                are purely personal and may even be classified
                                information but for the existing relationship
                                which dictates that you shall either fill them
                                in and/or divulge same in order to be linked to
                                our site. GCBUYING will handle the information
                                in this manner:
                              </p>
                              <h6 className="fs-13 font-normal">
                                • Information you provide{" "}
                              </h6>
                              <p className="fs-13 fs-13-1">
                                When you sign up for any QUCHANGE Service or
                                promotion that requires registration, we ask you
                                for personal information (such as your name,
                                email address and an account password). We
                                typically do not store this information and when
                                we do, we maintain the data in encrypted form on
                                secure servers. We may combine the information
                                you submit under your account with information
                                from other third parties in order to provide you
                                with a better experience and to improve the
                                quality of our services. For certain services,
                                we may give you the opportunity to opt out of
                                combining such information.
                              </p>
                              <h6 className="fs-13 font-normal">
                                • Log Information{" "}
                              </h6>
                              <p className="fs-13 fs-13-1">
                                When you access QUCHANGE, our servers
                                automatically record information that your
                                browser sends whenever you visit a website.
                              </p>
                              <h6 className="fs-13 font-normal">
                                • User communications
                              </h6>
                              <p className="fs-13 fs-13-1">
                                When you send email or other communications to
                                QUCHANGE we may retain those communications in
                                order to process your inquiries, respond to your
                                requests and improve our services.
                              </p>
                              <h6 className="fs-13 font-normal">
                                Other sites{" "}
                              </h6>
                              <p className="fs-13 fs-13-1">
                                This Privacy Policy applies to QUCHANGE only. We
                                do not exercise control over the sites displayed
                                or linked from within our various services.
                                These other sites may place their own cookies or
                                other files on your computer, collect data or
                                solicit personal information from you. QUCHANGE
                                processes personal information for the purposes
                                described.
                              </p>
                              <h6 className="fs-13 font-normal">
                                Data policy{" "}
                              </h6>
                              <p className="fs-13 fs-13-1">
                                QUCHANGE Data Protection Policy refers to our
                                commitment to treat information of employees,
                                customers, stakeholders and other interested
                                parties with the utmost care and
                                confidentiality. With this policy, we ensure
                                that we gather, store and handle data fairly,
                                transparently and with respect towards
                                individual rights.
                              </p>
                              <h6 className="fs-13 font-normal">Scope</h6>
                              <p className="fs-13 fs-13-1">
                                This policy refers to all parties (employees,
                                customers, suppliers, Merchants etc.) who
                                provide any amount of information to us. Who is
                                covered under the Data Protection Policy?
                                Employees of our company and its subsidiaries
                                must follow this policy. Contractors,
                                consultants, partners and any other external
                                entity are also covered. Generally, our policy
                                refers to anyone we collaborate with or acts on
                                our behalf and may need occasional access to
                                data.
                              </p>
                              <h6 className="fs-13 font-normal">Terms</h6>
                              <p className="fs-13 fs-13-1">
                                When a gift card is sent on the app, you have
                                agreed to sell the gift card and if we deem it
                                fit that this gift card satisfies the exchange
                                then your wallet is credited for withdrawal upon
                                discretion. Most withdrawals are instant but
                                some may be subject to further checks and
                                verifications. Most gift card trades are instant
                                but occasionally very few gift cards may require
                                a bit extra time. Gift cards are not 1:1 and the
                                best price is always given at the time. Parallel
                                rate is used and every sale is completed as
                                quickly as possible. Gift cards are given the
                                best rate possible depending on the gift card,
                                some have better prices than others.
                              </p>
                              <p className="fs-13 fs-13-1">
                                We maintain this app as a service to our
                                customers, and by using our app you are agreeing
                                to comply with and be bound by the following
                                terms of use. Please review the following terms
                                and conditions carefully. If you do not agree to
                                the terms and conditions, you should not obtain
                                goods services or products from this site or
                                utilize this site to sell gift cards, trade gift
                                cards, or create an account.
                              </p>
                              <p className="fs-13 fs-13-1">
                                Editing, Deleting and Modification. We reserve
                                the right in our sole discretion to edit or
                                delete any documents, information or other
                                content appearing on the Site, including this
                                Agreement, without further notice to users of
                                the Site.
                              </p>
                              <p className="fs-13 fs-13-1">
                                The term "gift card(s)" or "card(s)" when used
                                on this site refers to physical picture of the
                                cards, paper gift certificates, gift card
                                numbers with or without pin numbers in a digital
                                form, whether originally purchased for personal
                                use or for gifts, issued as a refund for
                                returned merchandise (or for any reason) in the
                                form of a merchandise credit, or any combination
                                of these options, which at the time of sale, are
                                accepted by the issuing retailer in the form
                                they are sold by Quchange.
                              </p>
                              <p className="fs-13 fs-13-1">
                                Use of Information. We reserve the right, and
                                you authorize us, to the use and assignment of
                                all information regarding Site uses by you and
                                all information provided by you in any manner
                                consistent with our Privacy Policy.
                              </p>
                              <h6 className="fs-13">Privacy Policy</h6>
                              <p className="fs-13 fs-13-1">
                                Our Privacy Policy may change from time to time
                                and is a part of this Agreement
                              </p>
                              <h6 className="fs-13">Refund Policy</h6>
                              <p className="fs-13 fs-13-1">
                                Due to the nature of our online platform, and
                                the products listed, we Quchange have a strict
                                no refund policy for any product. No product
                                shall be able to be returned for a like item, or
                                for a cash refund. Additionally, orders cannot
                                be canceled once the order has been placed.
                              </p>
                              <h6 className="fs-13 font-normal">
                                Right To Refuse An Order{" "}
                              </h6>
                              <p className="fs-13 fs-13-1">
                                We reserves the right to refuse any order
                                placed. Additionally, if there was a mistake or
                                change in the price or rate of an order or gift
                                card applied, we reserves the right to update to
                                the current rate without prior notice as prices
                                on the app are subject to fluctuations hence any
                                needed rate update would be done affecting the
                                final price.
                              </p>
                              <p className="fs-13 fs-13-1">
                                Market rate for currencies used will be parallel
                                market rate as opposed to the standard rate as
                                this is the rate at which forex is obtained.
                                Quchange is not liable in the event that the
                                issuing merchant of the gift card declines to
                                accept it upon declaring bankruptcy, or because
                                of changes to its gift card acceptance policy.
                              </p>
                              <p className="fs-13 fs-13-1">
                                You shall use no less than reasonable efforts to
                                maintain the security of your Service
                                credentials. You agree not to transfer your
                                account to an third-party. You shall be solely
                                responsible for use of your credentials and/or
                                your account by any third-party. You must notify
                                Quchange upon becoming aware of any breach or
                                suspected breach of the security of your
                                account.
                              </p>
                              <p className="fs-13 fs-13-1">
                                You are solely responsible for any data, text,
                                information, graphics, photos, profiles, audio
                                clips, video clips, links, or other content that
                                you submit, post, display, or otherwise make
                                available on the application.
                              </p>
                              <p className="fs-13 fs-13-1">
                                Quchange reserves the right to amend this
                                Agreement at any time. If amendments constitute
                                a material change to the Agreement, to be
                                determined at the sole discretion of Quchange,
                                Quchange will notify you via e-mail or via a
                                conspicuous notice on the Website.
                              </p>
                              <p className="fs-13 fs-13-1">
                                In order to ensure the integrity of our Service,
                                we reserve the right in our sole discretion to
                                decline to accept a Gift Card at any time, for
                                any or no reason. If the Gift Card is provided
                                to us electronically, we will do nothing.
                              </p>
                              <h6 className="fs-13">Policy elements</h6>
                              <p className="fs-13 fs-13-1">
                                As part of our operations, we need to obtain and
                                process information. This information includes
                                any offline or online data that makes a person
                                identifiable such as names, addresses, usernames
                                and passwords, digital footprints, photographs,
                                social security numbers, financial data etc. Our
                                company collects this information in a
                                transparent way and only with the full
                                cooperation and knowledge of interested parties.
                                Once this information is available to us, the
                                following rules apply.
                              </p>
                              <h6 className="fs-13">Our data will be:</h6>
                              <ul className="fs-13-1">
                                <li>Accurate and kept up-to-date </li>
                                <li>
                                  Collected fairly and for lawful purposes only.{" "}
                                </li>
                                <li>
                                  Processed by the company within its legal and
                                  moral boundaries
                                </li>
                              </ul>
                              <h6 className="fs-13">Our data will not be: </h6>
                              <ul className="fs-13-1">
                                <li>Communicated informally</li>
                                <li>
                                  {" "}
                                  Stored for more than a specified amount of
                                  time{" "}
                                </li>
                                <li>
                                  Transferred to organizations, states or
                                  countries that do not have adequate data
                                  protection policies{" "}
                                </li>
                                <li>
                                  {" "}
                                  Distributed to any party other than the ones
                                  agreed upon by the data's owner (exempting
                                  legitimate requests from law enforcement
                                  authorities){" "}
                                </li>
                              </ul>
                              <p className="fs-13 fs-13-1">
                                In addition to ways of handling the data the
                                company has direct obligations towards people to
                                whom the data belongs. Specifically, we must:
                              </p>
                              <ul className="fs-13-1">
                                <li>
                                  Let people know which of their data is
                                  collected{" "}
                                </li>
                                <li>
                                  Inform people about how we'll process their
                                  data{" "}
                                </li>
                                <li>
                                  Inform people about who has access to their
                                  information{" "}
                                </li>
                                <li>
                                  {" "}
                                  Have provisions in cases of lost, corrupted or
                                  compromised data{" "}
                                </li>
                                <li>
                                  Allow people to request that we modify, erase,
                                  reduce or correct data contained in our
                                  databases{" "}
                                </li>
                              </ul>
                              <p className="fs-13">Action</p>
                              <p className="fs-13-1">
                                To exercise data protection, we're committed to:{" "}
                              </p>
                              <ul className="fs-13-1">
                                <li>
                                  Restrict and monitor access to sensitive data
                                </li>
                                <li>
                                  Establish data protection practices (document
                                  shredding, secure locks, data encryption,
                                  frequent backups, access authorization etc.){" "}
                                </li>
                              </ul>
                            </div>
                          </Tab.Pane>

                          {/* <Tab.Pane eventKey="nine">
                            <a href="https://www.thequchange.com/terms-of-service" target="_blank" >

                            </a>
                          </Tab.Pane> */}

                          <TabPane eventKey="seven">
                            <div className="faq-details text-white">
                              <div className="back-icon-main">
                                <a href="/Account">
                                  <img src="/img/CaretLeft1.svg" />
                                </a>
                              </div>
                              <h3 className="">Help & FAQ</h3>
                              <p className="fs-13">
                                1. What cards can be sold here?
                              </p>
                              <p className="fs-13 fs-13-1">
                                Every gift card can be sold including ebay,
                                steam, sephora, american express, nordstrom,
                                sephora, vanilla visa etc. Simply click on sell
                                and select the cards you wish to trade from list
                                of options available on the app.
                              </p>
                              <p className="fs-13">2. Is this app reliable?</p>
                              <p className="fs-13 fs-13-1">
                                We are 100% reliable. Your money is 100%
                                guaranteed.
                              </p>
                              <p className="fs-13">3. Where are you based?</p>
                              <p className="fs-13 fs-13-1">
                                We are based in Lekki, Lagos, Nigeria.
                              </p>
                              <p className="fs-13">
                                4. How fast are the transactions?
                              </p>
                              <p className="fs-13 fs-13-1">
                                Depending on the card, the time may vary but
                                most trades are completed almost instantly.{" "}
                              </p>
                              <p className="fs-13">
                                5. How can I make queries?
                              </p>
                              <p className="fs-13 fs-13-1">
                                Please click on the profile icon on the app and
                                select "Customer Support", you will be
                                redirected to a support channel where you can
                                make inquiries.{" "}
                              </p>
                            </div>
                          </TabPane>

                          <TabPane eventKey="eight">
                            {isKycScreen ? (
                              <>
                                <div className=" text-white text-center mt-5">
                                  <div className="back-icon-main">
                                    <a href="/Account">
                                      <img src="/img/CaretLeft1.svg" />
                                    </a>
                                  </div>
                                  {/* <button type="button " className="close-kyc">
                                <img src="img/close-icon.png" />
                              </button> */}
                                  <h3 className="kyc-header">KYC</h3>
                                  {/* <p className="kyc-p ">
                                    Molestie et amet cursus vestibulum quam
                                    commodo.
                                  </p> */}
                                  <hr className="kyc-hr " />

                                  <div className="kyc-box ">
                                    {/* <img src="/img/kyc.png" alt="" /> */}
                                    <div className="row">
                                      <div className="col-lg-2 col-md-2 col-sm-12 ol-12">
                                        <img
                                          src="/img/danger.svg"
                                          className="mt-3 ml-5 text-dark"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                                        <p className="kyc-card-box-p mx-3">
                                          Upload the required documents to
                                          become KYC VERIFIED
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <Row>
                                    <Col className="kyc-menu m-auto" sm={8}>
                                      <Nav
                                        variant="pill"
                                        className="flex-column"
                                      >
                                        <a
                                          href="#seven"
                                          className="nav-link position-relative"
                                          onClick={() =>
                                            toggleProfileData(true)
                                          }
                                        >
                                          <span
                                            className=" mt-3 pb-3"
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "inherit",
                                            }}
                                          >
                                            {/* International Passport Or  National Identity card(NIMC)*  */}
                                            <InternationalPassport setCheckIconShow={setCheckIconShow}/>
                                          </span>
                                         {checkIconShow?<>
                                         <img className="checkIcon" src='/img/icons8-tick-100.png' alt=""/>
                                         </>:""}
                                          


                                          <img
                                            src="/img/CaretLeft.svg"
                                            className="right-arrow kyc-mobile-only"
                                            alt="Right Arrow"
                                          />
                                        </a>

                                        <div>
                                          {/* <button onClick={clearPicture}>Clear Selfe</button> */}
                                        </div>
                                        {/* <div
                                      className="kyc-mobile-line"
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "inherit",
                                      }}
                                    ></div>
                                    <a
                                      href="#eight"
                                      className="nav-link"
                                      onClick={() => toggleProfileData(true)}
                                    >
                                      <span
                                        className="ms-2 mt-3 pb-3 kyc-text"
                                        style={{
                                          backgroundColor: "transparent",
                                          color: "inherit",
                                        }}
                                      >
                                        National Identity card(NIMC)
                                      </span>
                                      <img
                                        src="/img/rightarrow.png"
                                        className="right-arrow kyc-mobile-only"
                                        alt="Right Arrow"
                                      />
                                    </a> */}
                                        <div
                                          className="kyc-mobile-line"
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "inherit",
                                          }}
                                        ></div>

                                        <a href="#" className="nav-link position-relative">
                                          <span
                                            className=" mt-3 pb-3"
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "inherit",
                                            }}
                                          >
                                            <BVNmodal setBvnCheckIconShow={setBvnCheckIconShow}/>
                                          </span>
                                          {checkBvnIconShow?<><img className="checkIcon1" src='/img/icons8-tick-100.png' alt=""/>
                                            </>:""
                                          }

                                          <img
                                            src="/img/CaretLeft.svg"
                                            className="right-arrow kyc-mobile-only"
                                            alt="Right Arrow"
                                          />
                                        </a>

                                        {/* <button onClick={kycSubmit}>
                                      Submit
                                      
                                      </button> */}

                                        <KYCSubmit handleDatas={handleDatas} />
                                      </Nav>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            ) : (
                              <>
                                {isKycStatus === 1 && (
                                  <div>
                                    <div className="row pendingKyc">
                                      <div className="col-lg-12 col-md-12 col-ms-12 col-sm-12 col-12 text-center">
                                        <img
                                          src="/img/TICK.png"
                                          className=" close-red2"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        <h4 className="text-white text-center">
                                          KYC is pending
                                        </h4>
                                        <div className="msgText">
                                          <p>
                                            Admins are verifying you KYC details
                                            , you will be notified once you KYC
                                            status is updated. This may take
                                            upto 24 hours.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isKycStatus === 2 && (
                                  <div>
                                    <div className="row pendingKyc">
                                      <div className="col-lg-12 col-md-12 col-ms-12 col-sm-12 col-12 text-center">
                                        <img
                                          src="/img/TICK.png"
                                          className=" close-red2"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        <h4 className="text-white text-center">
                                          KYC is verify
                                        </h4>
                                        <div className="msgText"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isKycStatus === 3 && (
                                  <div>
                                    <div className="row pendingKyc">
                                      <div className="col-lg-12 col-md-12 col-ms-12 col-sm-12 col-12 text-center">
                                        <img
                                          src="/img/close.png"
                                          className="close-red2"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        <h4 className="text-white text-center">
                                          KYC Verification failed
                                        </h4>
                                        <div className="msgText">
                                          <p>{isKycData.reason}</p>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <button
                                          className="retry-btn retrybtn"
                                          onClick={againKyc}
                                        >
                                          Retry
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </TabPane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
