import React, { useState, useEffect } from "react";
import "./virtualaccount.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import { useNavigate } from "react-router";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import TextField from "@mui/material/TextField";
import BVNsuccess from "./bvnsuccess";
import AccountCreate from "./accountcreate";
import Transaction from "./transaction";

function VirtualAccount() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [loader, setLoader] = useState(false);
  const [bvnScreenShow, setBvnScreenShow] = useState(false);
  const [virtualAcScreenShow, setVirtualAcScreenShow] = useState(false);
  const [virtualTranScreenShow, setVirtualTranScreenShow] = useState(false);
  const [bvnNumber,setBvnNumber] = useState("");
  const [virtualInfo,setVirtualInfo] = useState("");
  const [virtualBank,setVirtualBank] = useState([]);
  const [fname, setFName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [bvnNum,setBvnNum] = useState("")
  const [referenceCode,setReferenceCode] = useState("")

  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
    virtualAccountDetails();
    virtualBankList();
  }, []);

  const textFieldStyles = {
    // backgroundColor: "white",
    color: "white",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "APPROVED":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  const virtualAccountDetails = async ()=>{
    const apicall = await AfterLoginService.virtualDetails(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          console.log(resp.data)
          setVirtualInfo(resp.data)
          setFName(resp.data?.firstName)
          setSurname(resp.data?.surname)
          setPhone(resp.data?.phoneNo)
          setDob(resp.data?.dob)
          setBvnNum(resp.data?.bvnNumber)
          setReferenceCode(resp.data?.referenceCode)
          if(resp.data.isBvnVerified == 1){
            if(resp.data.isAccountCreated == 1){
              setVirtualTranScreenShow(true)
            }
            else{
              setVirtualAcScreenShow(true)
            }
          }
          else{
            setBvnScreenShow(true)
          }
         
        }
        else
        {
          
        }
      });
    } 
    else
    {
    }
   
  }

  const virtualBankList = async ()=>{
    const apicall = await AfterLoginService.virtualBankList(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setVirtualBank(resp.data);
        }
        else
        {
          
        }
      });
    } 
    else
    {
    }
  }

  const hander = ()=>{
    setBvnScreenShow(false);
    setVirtualAcScreenShow(true);
    virtualAccountDetails()
  }



  return (
    <div className="position-relative d-flex p-0  dpDesign">
      <Header />
      <div className="content">
        <Subheader />
        <div className="container-fluid pt-4 px-4 wallet-table dpDesign1">
        {
          loader?
          (<> <div className="row">
                <div className="col-md-12 text-center">
                    <span
                      className="text-white spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span className="text-white">Loading...</span>
                </div>
              </div>
          </>)
          :<>
              {bvnScreenShow && (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <h3 className="heading-transaction mb-0">Create New Wallet</h3>
                            </div>
                            <div className="row virtual-bvn">
                                <div className="col-md-4">
                                    <p>Verify BVN</p>
                                    <small>Please input your BVN in the section below</small>
                                    <div className="">
                                    <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter BVN"  value={bvnNumber}
                                        onChange={(e) => setBvnNumber(e.target.value)} />
                                   
                                    </div>
                                </div>
                                <div className="col-md-8"></div>
                                <div className="col-md-4 mt-4">
                                    <BVNsuccess bvnNumber={bvnNumber} setBvnNumber={setBvnNumber} hander={hander}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
              )}  
          </>
        }
          {virtualAcScreenShow && (<>
            <AccountCreate virtualInfo={virtualInfo} virtualBank={virtualBank} setVirtualAcScreenShow={setVirtualAcScreenShow} setVirtualTranScreenShow={setVirtualTranScreenShow}
            virtualAccountDetails={virtualAccountDetails}
            fname={fname}
            setFName={setFName}
            surname={surname}
            setSurname={setSurname}
            phone={phone}
            setPhone={setPhone}
            dob={dob}
            setDob={setDob}
            bvnNum={bvnNum}
            setBvnNum={setBvnNum}
            referenceCode={referenceCode}
            setReferenceCode={setReferenceCode}
            />
          </>)}
          {
            virtualTranScreenShow && (<>
             <Transaction virtualInfo={virtualInfo} />
            </>)
          }
         </div>
      </div>
    </div>
  );
}
export default VirtualAccount;
