import React, { useState } from "react";
import Trc20Services from "../../Services/LoginSignService/Trc20Service";
import { toast } from "react-toastify";
import SellSuccessfullmodal from "./sellSuccessModel";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-usd">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Selltron = (props) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isSecondModelOpen, setIsSecondModelOpen] = useState(false);
  const [validResponse, setValidResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const Loader = () => {
    return (
      <>
        <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
          <div class="flex items-center">
            <svg
              class="animate-spin h-20 w-20 text-blue-950"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="gray"
                stroke-width="4"
              ></circle>
              <path
                fill="#fff"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </>
    );
  };

  const sellTransactionNairaConversion = async () => {
    setIsLoading(true);
    const sellValidResponse = await Trc20Services.sellTransactionConversion(
      auth,
      props.uiAmount,
      props.token
    );
    console.log(sellValidResponse);
    if (
      sellValidResponse?.statusCode !== undefined &&
      Object.keys(sellValidResponse?.statusCode).length === 0
    ) {
      toast.error(sellValidResponse?.message);
      setIsLoading(false);
      return;
    } else {
      setValidResponse(sellValidResponse);
      setIsSecondModelOpen(true);
    }
    setIsLoading(false);

    setIsSecondModelOpen(true);
  };

  return (
    <div>

<div className="btn-proceed ">
<button
        onClick={sellTransactionNairaConversion}
        
      >
        Proceed
      </button>
     </div>

      {isSecondModelOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title trc-trx-heading">
                    Confirm Sell ({props.token.toUpperCase()}) Transaction
                    <p className="sell-trx-p">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    {/* <span
                      className="d-block text-success fw-bold "
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        marginTop: "-25px",
                      }}
                    >
                      1 {props.token} = $
                      {validResponse && validResponse?.conversion_rate}
                    </span> */}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => props.setIsFirstModalOpen(false)}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop: "-60px",
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>

                <div className="modal-body text-white text-center">
                  {/* <p>
                    Procced to send {props.token}{" "}
                    {validResponse && validResponse?.send_amount}
                    <span className="d-block">
                      worth ${validResponse && validResponse?.ui_amount} to
                      Quchange{" "}
                    </span>
                    <span className="d-block">
                      {" "}
                      for N
                      {validResponse &&
                        (+validResponse?.Naira_conversion_amount).toFixed(
                          2
                        )}{" "}
                    </span>
                  </p>
                  <p>Note : Standard network fee applies.</p> */}

                  <div className="row text-center" style={{marginTop:'-35px'}}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <p
                        style={{
                          color: "#7D7DA1",
                          fontSize: "12px",
                          marginTop: "-15px",
                        }}
                      >
                        You are Selling
                      </p>
                      <h3 style={{ color: "#FFFFFF", marginTop: "-10px" }}>
                      {validResponse && validResponse?.send_amount}
                        <span style={{ fontSize: "15px",padding:'3px' }}>
                        {props.token.toUpperCase()}{" "}
                        </span>
                      </h3>
                      <p className="trc-sell-paragraph">Rate = 985</p>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="/img/vertical.png"
                          style={{ display: "inline-block" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <p style={{ color: "#7D7DA1", fontSize: "12px" }}>
                        You would receive
                      </p>
                      <h3 style={{ color: "#FFFFFF", marginTop: "-10px" }}>
                        <span className="d-block"> ₦   {validResponse &&
                        (+validResponse?.Naira_conversion_amount).toFixed(
                          2
                        )}{" "}</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex">
                  <div className="row mx-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-cancle1">
                        <button
                          type="button"
                          className=""
                          onClick={() => props.setIsFirstModalOpen(false)}
                        >
                          <img src="/img/cancel.png" />
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-update1">
                        <SellSuccessfullmodal
                          amount={validResponse && validResponse?.send_amount}
                          validResponse={validResponse}
                          token={props.token}
                          setIsFirstModalOpen={props.setIsFirstModalOpen}
                          refreshHistory={props.refreshHistory}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isLoading === true && <Loader />}
    </div>
  );
};

export default Selltron;

// company code

// import React, { useState } from 'react';
// import Trc20Services from "../../Services/LoginSignService/Trc20Service";
// import { toast } from 'react-toastify';
// import SellSuccessfullmodal from './sellSuccessModel';

// const Modal = ({ onClose, children }) => {
//     return (
//         <div className="modal send-usd">
//             <div className="modal-content">
//                 {children}
//             </div>
//         </div>
//     );
// };

// const Selltron = (props) => {

//     const auth = JSON.parse(localStorage.getItem("user"));
//     const [isSecondModelOpen, setIsSecondModelOpen] = useState(false);
//     const [validResponse, setValidResponse] = useState({});
//     const [isLoading, setIsLoading] = useState(false);

//     const Loader = () => {
//         return (
//             <>
//                 <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
//                     <div class="flex items-center">
//                         <svg
//                             class="animate-spin h-20 w-20 text-blue-950"
//                             xmlns="http://www.w3.org/2000/svg"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                         >
//                             <circle
//                                 class="opacity-25"
//                                 cx="12"
//                                 cy="12"
//                                 r="10"
//                                 stroke="gray"
//                                 stroke-width="4"
//                             ></circle>
//                             <path
//                                 fill="#fff"
//                                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                             ></path>
//                         </svg>
//                     </div>
//                 </div>
//             </>
//         )
//     }

//     const sellTransactionNairaConversion = async () => {
//         setIsLoading(true);
//         const sellValidResponse = await Trc20Services.sellTransactionConversion(auth, props.uiAmount, props.token);
//         console.log(sellValidResponse);
//         if (sellValidResponse?.statusCode !== undefined && Object.keys(sellValidResponse?.statusCode).length === 0) {
//             toast.error(sellValidResponse?.message);
//             setIsLoading(false);
//             return
//         }
//         else {
//             setValidResponse(sellValidResponse);
//             setIsSecondModelOpen(true);
//         }
//         setIsLoading(false);

//         setIsSecondModelOpen(true);
//     }

//     return (
//         <div>
//             <button className='btn btn-update  w-100 ' style={{marginTop:'25px'}} onClick={sellTransactionNairaConversion} >Proceed</button>
//             {isSecondModelOpen && (
//                 <Modal>
//                     <div className="modal">
//                         <div className='modal-dialog'>
//                             <div className="modal-content">
//                                 <div className="modal-header">
//                                     <h5 className="modal-title m-auto">
//                                         Sell ({props.token.toUpperCase()}) to Quchange
//                                         <span className='d-block text-success fw-bold ' style={{marginLeft:'70px',fontSize:'18px'}}>1 {props.token} = ${validResponse && validResponse?.conversion_rate}</span>
//                                     </h5>
//                                     <button type="button" className="close" onClick={()=>props.setIsFirstModalOpen(false)}>
//                                         <img src='img/close-icon.png' />
//                                     </button>
//                                 </div>
//                                 <div className="modal-body text-white text-center" style={{marginTop:'-15px'}}>
//                                     <p>
//                                         Procced to send {props.token} {validResponse && validResponse?.send_amount}
//                                         <span className='d-block'>worth ${validResponse && validResponse?.ui_amount} to Quchange </span>
//                                         <span className='d-block'>  for N{validResponse && (+validResponse?.Naira_conversion_amount).toFixed(2)} </span>
//                                     </p>
//                                     <p>Note : Standard network fee applies.</p>
//                                 </div>
//                                 <div className="modal-footer d-flex">
//                                     <button type="button" className="btn btn-cancel w-25 bg-danger text-white" onClick={()=>props.setIsFirstModalOpen(false)}>Cancel</button>

//                                     <SellSuccessfullmodal amount={validResponse && validResponse?.send_amount} validResponse={validResponse} token={props.token} setIsFirstModalOpen={props.setIsFirstModalOpen} refreshHistory={props.refreshHistory}/>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal>
//             )}
//             {isLoading === true && (
//                 <Loader />
//             )}
//         </div>
//     );
// };

// export default Selltron;
