import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import './bitcoinguide.scss';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from '../header/header';
import Subheader from '../subheader/subheader';

function Bitcoinguide(){
    const navigate = useNavigate();
  const goBack = () => navigate(-1);
    return(
           <div className="container-fluid position-relative d-flex p-0 gift-container">
            <Header/>
            <div className="content">
            <Subheader/>
            <div className='container-fluid pt-4 px-4 bitcoin-guide bg-zinc-900'>
                <div className='row'>
                <div className="back-icon-main">
              <a href="#">
                <img src="/img/CaretLeft1.svg" onClick={goBack}/>
              </a>
            </div>
                    <div className='col-md-12 col-lg-12 text-white'>
                    <h3>HOW TO SELL BITCOIN CARD</h3>
                        <ul>
                            <li>Tap on the orange icon that says BITCOIN or open the sidebar menu and click on “BITCOIN WALLET”.</li>
                            <li>Every user is given a unique bitcoin address, meaning the address you see on your Quchange bitcoin wallet is yours and yours alone. Your BTC wallet balance will be updated soon as any bitcoin is sent to your Quchange BTC address.</li>
                            <li> Tap to copy your bitcoin address to be used at anytime whenever you wish.</li>
                            <li>To sell bitcoin, send bitcoin into your unique Quchange bitcoin address, after the bitcoin transaction hits 3/3 confirmations on the blockchain network, your BTC wallet balance will be updated by the amount you have sent. You can then click on sell, you will be asked to input the dollar value of the bitcoin you wish to sell from your balance. Make sure to allow $0.5 for blockchain transaction fees, when you hit the sell button, your Wallet is instantly credited in seconds, and you can then proceed to request a withdrawal. </li>
                        </ul>
                    </div>
                </div>
            </div>   
            </div>
            </div>       
    );
}

export default Bitcoinguide;
