import React, { useState, useEffect } from "react";
import "./cryptosell.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import CryptosellDetails from "./CryptosellDetails";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import QRCode from "qrcode.react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


function Cryptosell() {
const auth = JSON.parse(localStorage.getItem("user"));
const navigate = useNavigate();
const [loader, setLoader] = useState(false);
const [cryptoSellList, setCryptoSellList] = useState([]);
const [transactionHistory, setTransactionHistory] = useState([]);
const [showDetails, setShowDetails] = useState(false);
const [showlist, setShowList] = useState(true);
const [cryptoid, setCryptoid] = useState('');
const [currencyName, setCurrencyName] = useState('');
const [cryptoImg, setCryptoImg] = useState('');
const [nairaRate, setNairaRate] = useState('');
const [rateText, setRateText] = useState('');
const [description, setDescription] = useState('');
const [cryptoCode, setCryptoCode] = useState('');
const [walletFlug, setWalletFlug] = useState('');
const [disclaimer,setDisclaimer] = useState('');
const [cryptoAddress,setCryptoAddress] = useState('');
const [notice, setNotice] = useState('');
const [thrcm,setThrcm] = useState('');
const [thcc,setThcc] = useState('');
const [ths,setThs] = useState('');
const [thd,setd] = useState('');
const [thti,setTi] = useState('');
const [thnt,setNt] = useState('');
const [thtr,setTr] = useState('');
const [thnv,setNv] = useState('');
const [thuv,setUv] = useState('');
const [thtm,setTm] = useState('');
const [transaction, setTransaction] = useState(false);
const [showWalletAddress, setShowWalletAddress] = useState(false);
const [transactionDetails,setTransactionDetails] = useState(false);

const [transactionLoading, setTransactionLoading] = useState(true);
const [transactionLoading1, setTransactionLoading1] = useState(false);


useEffect(() => {
if(!auth) {
navigate("/");
}
getCryptoList();
setLoader(true);
}, []);
const getCryptoList = async () => {
   const lr = [];
const apicall = await AfterLoginService.GetCryptoSellList(auth);
if (apicall.state === true) {
apicall.result.json().then((resp) => {
if (resp.status == 200) {
resp.data.map((item,index) => {
   if(item.id != 6)
   {
     lr.push(item);
   }
});
setCryptoSellList(lr)
setLoader(false);
} else {
console.log(resp.message);
setLoader(false);
}
});
} else {
}
};
const getDetails = async (listid,name,icon,code,rate,rateText,description,walletFlug,notice) => {
setCryptoid(listid)
setCurrencyName(name)
setCryptoImg(icon)
setNairaRate(rate)
setCryptoCode(code)
setRateText(rateText)
setDescription(description)
setWalletFlug(walletFlug)
setNotice(notice)
setShowList(false);
setShowDetails(true);
};
const showls = async () => {
setShowList(true);
setShowDetails(false);
};
const back = async (flag='') => {
if(flag == '1')
{
   setWalletFlug(1)
}
setLoader(false);
setShowList(false);
setShowDetails(true);
setTransaction(false)
setShowWalletAddress(false)
setTransactionDetails(false)
getCryptoList();
};
const back2 = async (flag='') => {
if(flag == '1')
{
setWalletFlug(1)
}
setShowList(false);
setShowDetails(false);
setTransaction(true)
setShowWalletAddress(false)
setTransactionDetails(false)
getCryptoList();
};
const showTransactionHistory = async (cryptoid,confirm='1') =>{
  setShowList(false);
  setShowDetails(false);
  setShowWalletAddress(false)
  setTransactionDetails(false)
  setTransactionHistory([]);
  setTransaction(true)
  setLoader(true);
  setTransactionLoading(true);

   let items = {crypto_id:cryptoid,fetch_confirmed_transactions:confirm}
   const apicall = await AfterLoginService.GetInstantCryptoTransaction(auth,items);
   if (apicall.state === true) {
   apicall.result.json().then((resp) => {
   if (resp.status == 200) {
      setTransactionHistory(resp.data);
      setLoader(false);
      setTransactionLoading(false);

   } else {
      console.log(resp.message);
      setLoader(false);
      setTransactionLoading(false);

}
});
} else {
}
}
const showWalletAdd = async (cryptoid)=>{
   getCryptoList();
setTransaction(false)
setShowList(false);
setShowDetails(false);
setTransactionDetails(false)
setLoader(true)
setShowWalletAddress(true)
setTransactionLoading1(true)
let item = {crypto_id:cryptoid};
const apicall = await AfterLoginService.SaveBtcDepositAddress(auth,item);
if (apicall.state === true) {
apicall.result.json().then((resp) => {
if (resp.status == 200) {
   setLoader(false)
   setDisclaimer(resp.disclaimer);
   setCryptoAddress(resp.data);
   setTransactionLoading1(false)

} else {
console.log(resp.message);
setTransactionLoading1(false)

}
});
} else {
}
}
const tranHis = async (rcm,c_currency,status,date,transactionid,network,nairaRate,nairaCreditAm,usdExchange,time) =>
{
setThrcm(rcm);
setThcc(c_currency);
setThs(status);
setd(date);
setTi(transactionid);
setNt(network);
setTr(nairaRate);
setNv(nairaCreditAm);
setUv(usdExchange);
setTm(time);
setShowList(false);
setShowDetails(false);
setTransaction(false)
setShowWalletAddress(false)
setTransactionDetails(true)
}
const [selectedButton, setSelectedButton] = useState("Confirmed");
const handleButtonClickConfirmed = (buttonName,flg) => {
setSelectedButton(buttonName);
showTransactionHistory(cryptoid,flg)
setTransactionLoading(true);
};

   const [copied, setCopied] = useState(false);

  function copyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }


  const getButtonStyle = (buttonName) => {
   return {
     marginRight: "10px",
     backgroundColor: selectedButton === buttonName ? " #27ee91" : "",
   };
 };
return (
<div className="container-fluid position-relative d-flex p-0 dashboard-container">
   <Header />
   <div className="content cont">
      <Subheader />
      <div
      className="container container-fluid pt-4 "
      style={{ overflowX: "scroll", overflowY: "hidden" }}
      >
      
      {
      showlist?(
      <> 
      <div className="row">
         <div className="col-md-4 col-lg-4 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
            <div className="mt-2">
               <h5 className="mb-0 h3 ms-2 text-heading">
                  Crypto Quick Sell
               </h5>
               <p className="small ms-2 " style={{ marginTop: "10px" }}>
               Select a crypto to generate your dedicated address
               </p>
            </div>
         </div>
      </div>
      {loader?(<> <div className="row">
        <div className="col-md-12 text-center">
                      <span
                        className="text-white spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      <span className="text-white">Loading...</span>
        </div>
      </div>
                      </>):( <div className="row">
         <div className="col-md-12 mb-m">
            {cryptoSellList.length>0?(
            cryptoSellList.map((item, index) =>(
            
            <div className="card mb-2 list_card" onClick={() =>
               getDetails(item.id,item.crypto_name,item.crypto_icon,item.crypto_code,item.naira_rate,item.rateText,item.crypto_description,item.is_wallet_created,item.crypto_notice)}>
               <div className="row">
                  <div className="col-md-2 col-3 cr-1">
                     <img src={item.crypto_icon} className="cr-2" />
                  </div>
                  <div className="col-md-8 col-7 ">
                     <div className="cr-3">
                        <strong>{item.crypto_code}</strong><br/>
                        <b>NGN {item.naira_rate}</b>
                        <p>{item.crypto_name}</p>
                     </div>
                  </div>
                  <div className="col-md-2 col-3 ">
                     <img src="./img/arrow-right.png" className="arrow_icon"/>
                  </div>
               </div>
            </div>
            
            ))
            ):(<>
            <h1>Data not found</h1>
            </>)}
         </div>
      </div>)}
     
      </> 
      ):(<></>)
      }
      {/* Details         */}
      <div>
         {
         showDetails?(
         <>
         <div className="row">
            <div className="col-md-4 col-4 col-lg-4 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
               <span onClick={()=>showls()} className="back_btn">Back</span>
            </div>
            <div className="col-md-8 col-8 col-lg-8 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
               <div className="mt-2">
                  <h5 className="mb-0 h3 ms-2 text-heading text-center">
                     Crypto Quick Sell
                  </h5>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-md-12">
               <div className="card listD">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="crypto_d_1"> 
                           <h1 className="crypto_d_2">{currencyName} ({cryptoCode})</h1>
                           <div className="crypto_d_3">
                              <img src={cryptoImg} className="crypto_d_4"/>
                              <p className="crypto_d_5">NGN {nairaRate}</p>
                           </div>
                           <div className="crypto_d_6">
                              <p>
                                 {rateText}
                              </p>
                           </div>
                           <div className="crypto_d_7">
                              <p>
                                 {description}
                              </p>
                           </div>
                        </div>
                     </div>
                     {
                     walletFlug === 1?(<>
                     <div className="col-md-6 col-12 crypto_d_8">
                        <button className="btn" onClick={()=>showTransactionHistory(cryptoid)}>View Transaction History</button>
                     </div>
                     <div className="col-md-6 col-12 crypto_d_9">
                        <button className="btn" onClick={()=>showWalletAdd(cryptoid)}>Show Wallet Address</button>
                     </div>
                     </>):(<>
                     <div className="col-md-6 col-12">
                        <div className="crypto_d_9 crypto_getAd">
                           <CryptosellDetails notice={notice} cryptoid={cryptoid} showWalletAdd={showWalletAdd} setTransactionLoading1={setTransactionLoading1} transactionLoading1={transactionLoading1} class="btn" />
                        </div>
                     </div>
                     </>)
                     }
                  </div>
               </div>
            </div>
         </div>
         </>
         ):(<></>)
         }
      </div>
      {/* end Details  */}
      {/* Transaction History */}
      <div>
         {transaction?(<>
         <div className="row">
            <div className="col-md-4 col-4 col-lg-4 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
               <span onClick={()=>back()} className="back_btn">Back</span>
            </div>
            <div className="col-md-8 col-8 col-lg-8 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
               <div className="mt-2">
                  <h5 className="mb-0 h3 ms-2 text-heading text-center">
                     All Transactions
                  </h5>
               </div>
            </div>
            <div className="col-md-6 col-12 tabbtn">
               <div  className="btntab" >

             

               <button
               className={`btn  btn-tansaction ${
                  transactionLoading ? "disabled" : ""
                }`}
                  type="button"
                  style={getButtonStyle("Confirmed")}
                  onClick={() => handleButtonClickConfirmed("Confirmed",1)}
               >
               Confirmed
               </button>


               <button
                  type="button"
                  className={`btn  btn-tansaction ${
                     transactionLoading ? "disabled" : ""
                   }`}
                  style={getButtonStyle("Un-Confirmed")}
                  onClick={() => handleButtonClickConfirmed("Un-Confirmed",0)}
               >
               Un-Confirmed
               </button>
            </div>
         </div>
         <div className="col-md-12">
            {selectedButton == 'Confirmed'?(
            <>
            {loader?(<>
              <div className="row">
                  <div className="col-md-12 text-center">
                                 <span
                                    className="text-white spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                 ></span>{" "}
                                 <span className="text-white">Loading...</span>
                  </div>
                  </div>
            </>):(<div>
              {transactionHistory.length>0?(transactionHistory.map((item,index)=>(
            <div className="card mb-3 tran_card" onClick={()=>
               tranHis(item.received_crypto_amount,item.crypto_currency,item.status,item.date,item.transaction_id,item.network,item.nairaRate,item.Naira_credited_amount,item.usd_exchange,item.time)}>
               <div className="row">
                  <div className="col-md-1 col-2">
                     <img src="./img/Frame.png"/>
                  </div>
                  <div className="col-md-7 col-6">
                     <div>
                        <strong>{item.received_crypto_amount} {item.crypto_currency}</strong><br/>
                        <b>Deposit</b><br/>
                        <span>{item.crypto_currency} Transaction</span><br/>
                     </div>
                  </div>
                  <div className="col-md-4 col-4">
                     <div>
                        <span className="succ">{item.status}</span>
                        <br/> 
                        <span class="t_date">{item.date}</span>
                     </div>
                  </div>
               </div>
            </div>
            ))):(<><>
            <div className="card data_not">Data not found</div>
            </></>)}
            </div>)}
            </>
            ):(<></>)}


            {selectedButton == 'Un-Confirmed'?(
            <>
            {loader?(<>
               <div className="row">
                  <div className="col-md-12 text-center">
                                 <span
                                    className="text-white spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                 ></span>{" "}
                                 <span className="text-white">Loading...</span>
                  </div>
                  </div>
            </>):(<div>

               {transactionHistory.length>0?(transactionHistory.map((item,index)=>(
                   <div className="card mb-3 tran_card" onClick={()=>
                     tranHis(item.received_crypto_amount,item.crypto_currency,item.status,item.date,item.transaction_id,item.network,item.nairaRate,item.Naira_credited_amount,item.usd_exchange,item.time)}>
                     <div className="row">
                        <div className="col-md-1">
                           <img src="./img/Frame.png"/>
                        </div>
                        <div className="col-md-7">
                           <div>
                              <strong>{item.received_crypto_amount} {item.crypto_currency}</strong><br/>
                              <b>Deposit</b><br/>
                              <span>{item.crypto_currency} Transaction</span><br/>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div>
                              <span className="succ">{item.status}</span>
                              <br/> 
                              <span class="t_date">{item.date}</span>
                           </div>
                        </div>
                     </div>
                  </div>
            ))):(<>
            <div className="card data_not">Data not found</div>
            </>)}

            </div>)}
           

            </>
            ):(<></>)}


         </div>
      </div>
      </>):(<></>)}
   </div>
   {/* End Transaction History */}
   {/* Show Wallet Address  */}
   <div>
      {showWalletAddress?(<>
      <div className="row">
         <div className="col-md-4 col-4 col-lg-4 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
            <span onClick={()=>back(1)} className="back_btn">Back</span>
         </div>
         <div className="col-md-8 col-lg-8 col-8 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
            <div className="mt-2">
               <h5 className="mb-0 h3 ms-2 text-heading text-center">
                  Crypto Quick Sell
               </h5>
            </div>
         </div>


         {loader?(<>
               <div className="row">
                  <div className="col-md-12 text-center">
                                 <span
                                    className="text-white spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                 ></span>{" "}
                                 <span className="text-white">Loading...</span>
                  </div>
                  </div>
            </>):(<>
            <div className="col-md-12">
            <div className="card wallet_ads">
               <div className="mt-2">
                  <h5 className="mb-0 h3 ms-2 text-heading">
                     Wallet Address
                  </h5>
                  <p className="small ms-2 " style={{ marginTop: "10px" }}>
                  Copy your assigned wallet address
                  </p>
               </div>
               <div className="discl">
                  <img src="./img/info-circle1.svg" />
                  <p>{disclaimer}</p>
               </div>
               <div>
               </div>
               <div className="form-group qrc-instant-code1 mb-3 flex justify-center items-center bg-white py-8">
                  <QRCode value={cryptoAddress} />
               </div>
               <div className="wallet_address">
                  <span>Wallet Address</span>
                  <div>
                     <p>{cryptoAddress}</p>
                  </div>
               </div>
               <div className="copy_address"  onClick={() => copyTextToClipboard(cryptoAddress)}>
                  <span>Copy Wallet Address</span>
                 
               </div>
                     {copied ? (
                      <div
                        className="fixed bottom-0 z-50 pointer-events-none"
                        style={{ background: "rgba(0, 0, 0, 0.6)" }}
                      >
                        <div className="shadow-md">
                          <p className="bg-green-200  rounded-lg   p-2 text-center">
                            Wallet address is copied to clipboard
                          </p>
                        </div>
                      </div>
                    ) : null}
            </div>
         </div>
         </>)}


        
      </div>
      </>):(<></>)}
   </div>
   {/* End Show Wallet Address  */}
   {/* Transaction Details  */}
   <div>
      {transactionDetails?(<>
      <div className="row">
         <div className="col-md-4 col-4 col-lg-4 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
            <span onClick={()=>back2(1)} className="back_btn">Back</span>
         </div>
         <div className="col-md-8 col-8 col-lg-8 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
            <div className="mt-2">
               <h5 className="mb-0 h3 ms-2 text-heading text-center">
                  Transaction Details
               </h5>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card trandet">
               <div className="mt-2">
                  <div className="details_card">
                     <div className="dc-1"><img src="./img/Frame.png"></img></div>
                     <div className="dc-2">
                        <strong>{thrcm} {thcc}</strong>
                        <p>Deposit</p>
                        <span>{thcc} Transaction</span>
                     </div>
                     <div className="dc-3">
                        <p className="succ">{ths}</p>
                        <p>{thd}</p>
                     </div>
                  </div>
               </div>
               <div className="cardlist">
                   <div className="row">
                     <div className="col-md-4 col-4">Transaction ID</div>
                     <div className="col-md-7 text-right col-6">{thti.substring(0,10)}...
                              
                     </div>
                     <div className="col-md-1 col-2">
                     <span className="btc-hs ">
                                  
                                  <ContentCopyIcon
                                    className="copy-icon-btc "
                                    onClick={() => copyTextToClipboard(thti)}
                                  />
                                </span>
                                {copied && (
                                  <p
                                    className="text-light "
                                    style={{ textAlign: "right" }}
                                  >
                                    Copied!
                                  </p>
                                )}
                     </div>
                  </div>
                  <div className="row">
                  <div className="col-md-3 col-5">Currency</div>
                  <div className="col-md-9 col-6">{thcc}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">Network</div>
                  <div className="col-md-9 col-6">{thnt}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">Recieved</div>
                  <div className="col-md-9 col-6">{thrcm}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">Trading Rate</div>
                  <div className="col-md-9 col-6">NGN {thtr}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">Naira Value</div>
                  <div className="col-md-9 col-6">NGN {thnv}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">USD Value</div>
                  <div className="col-md-9 col-6">${thuv}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">Date</div>
                  <div className="col-md-9 col-6">{thd}</div>
               </div>
               <div className="row">
                  <div className="col-md-3 col-5">Time</div>
                  <div className="col-md-9 col-6">{thtm}</div>
               </div>
               </div>
               
               <div>
               </div>
            </div>
         </div>
      </div>
      </>):(<></>)}
   </div>
   {/* End Transaction Details  */}
</div>
{/* 
</div>
*/}
</div>
</div>
);
}
export default Cryptosell;