import React from "react";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";

const SuccessTopup = ({ successTop, setSuccessTop ,handleSuccess,successMes,setSuccessMes}) => {
  const navigate = useNavigate();

  const closeFirstModal = () => {
    setSuccessTop();
    handleSuccess();
  };


  const handleView = () => {
    navigate("/billTransaction");
  };

  return (
    <>
      {successTop && (
        <div className="modal gift-success !z-[1027]">
          <div className="modal-content">
            <div className="modal">
              <div className="modal-dialog">
                <div className="modal-content sucessful-card-modal">
                  <div className="modal-header d-flex justify-content-end align-items-end w-100">
                    <button
                      type="button"
                      className="close-btn"
                      onClick={closeFirstModal}
                      style={{
                        background: "#313131",
                        color: "#FFFFFF",
                        padding: "1px",
                      }}
                    >
                      <img src="img/close-icon.png" alt="" />
                    </button>
                  </div>
                  <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col">
                    <img src="/img/righttick.svg" className="mb-5"/>
                    <p className="h4 text-white mb-5 tx-f">
                     {successMes}
                    </p>
                  </div>
                  <div className="modal-footer  d-flex ">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="view-btn text-center">
                          <button onClick={handleView} className="mt-2.5">
                            View Transactions
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="btn-done text-center " onClick={closeFirstModal}>
                          <button className="mt-2.5 " >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessTopup;
