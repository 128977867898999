import React, { useState, useEffect } from "react";
import "./dashboard.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";

function Dashboard() {
  const auth = JSON.parse(localStorage.getItem("user"));

  const [balance, setBalance] = useState("");
  const [tronBalance, setTronBalance] = useState("");
  const [btcBalance, setBtcBalance] = useState("");
  const [btccoinRate, setBtccoinRate] = useState("");
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState([]);
  const [tranDetails, setTransactionDetail] = useState("");
  const [gettingWallet, setGettingWallet] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tloader,setTLoader] = useState(false);
  const [id, setId] = useState(0);

  /*******************Get Current Date */
  const today = new Date();
  const date = today. getDate();
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  var weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const month = (monthNames[today.getMonth()]);
  const day = (weekday[today.getDay()]);
  const currentDate =day+ ' , '+ month + "  " + date+'th';
/**************************End *******************/

  useEffect(() => {
    if (!auth) {
      navigate("/");
    }

    setGettingWallet(true);
    getTransaction();
    getDetails(id);
    getWalletBalance();
    GetTronUserWallet();
    GetBtcWallet();
    GetBtccoinRate();
    setTLoader(true);
    setLoader(true);

    const script = document.createElement('script');
    script.src = "https://cdn1.intent.ai/pixel/6666c68a09c2f4040ea6c98b/intent.js";
    script.async = true;
    document.body.appendChild(script);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const getTransaction = async () => {
    const apicall = await AfterLoginService.GetTransaction(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setTransaction(resp.data);
          if (!resp.data) {
            getDetails(resp.data[0].id);
          }
          setGettingWallet(false);
          setTLoader(false);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const getDetails = async (transaction_id) => {
    setLoader(true);
    const apicall = await AfterLoginService.GetTransactionDetails(
      transaction_id,
      auth
    );
    if (apicall?.state === true) {
      const resp = await apicall.result.json();
      if (resp?.status === 200) {
        setTransactionDetail(resp?.data);
      } else {
        console.log(resp.message);
      }
    } else {
      // Handle API call error
    }
  };


  const getWalletBalance = async () => {
    if (auth) {
      const apicall = await AfterLoginService.GetWalletBalance(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setBalance(resp);
            setGettingWallet(false);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  };

  const GetTronUserWallet = async () =>{
    if(auth)
    {
      
      const apicall = await AfterLoginService.GetTronUserWallet(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setTronBalance(resp);
            setGettingWallet(false);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  }

  const GetBtcWallet = async () =>{
    if(auth)
    {
      
      const apicall = await AfterLoginService.GetBtcWallet(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setBtcBalance(resp);
            setGettingWallet(false);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  }

  const GetBtccoinRate = async () =>{
    if(auth)
    {
      
      const apicall = await AfterLoginService.GetBitcoinRate();
      if (apicall.state === true) {
         apicall.result.json().then((resp) => {
          if (resp) {
            setBtccoinRate(resp);
            setGettingWallet(false);
          } else {
            toast(resp.message);
          }
        });
      } else {
      }
    }
  }
  // for status button
  function getButtonColor(status) {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "button-pending-dashboard";
      case "DECLINED":
        return "button-declined-dashboard";
      case "APPROVED":
        return "button-approved-dashboard";
      case "PROCESSING":
        return "button-processing-dashboard";
        case "COMPLETED":
          return "button-completed-dashboard";
      default:
        return "";
    }
  }


  return (
    <div className="container-fluid position-relative d-flex p-0 dashboard-container">
      <Header />

      <div className="content cont">
        <Subheader />

        {/* <div className="container-fluid pt-4  ">
          <div className="row g-4">
            <div className="col-sm-6 col-xl-4">
              <div className="box-balance rounded p-4">
                <div className="ms-3 text-white mb-5">
                  <p className="mb-2 wallet">
                    <i className="bi bi-wallet"></i>
                    <span className="ms-2 "> Overall Balance</span>
                  </p>
                  <h6 className="mb-0 h2">
                    <span className="overalldollar">$</span>
                    20,670.08
                  </h6>
                </div>
                <div className="d-flex justify-content-center requestbtn">
                  <Link to="/Walletdetails">
                    <button className="btn btn-request mb-5">
                      REQUEST WITHDRAW
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4">
              <div className="bg-black rounded p-4">
                <div className="d-flex mb-5">
                  <Avatar alt="" className="btc-icon">
                    <img
                      src="/img/icons8-tron-64.png "
                      alt="tron"
                      className="tron-icon"
                    />
                  </Avatar>
                  <div className="ms-3 text-white">
                    <p className="mb-0 h6">TRC20(USDT & TRON)</p>
                    <h6 className="mb-0 p tron">Tether & Tron</h6>
                  </div>
                </div>
                <div className="btc-container mb-1">
                  <Avatar className="bg-success" alt="">
                    <i class="bi bi-arrow-up-right"></i>
                  </Avatar>
                  <span className="btc-span">+98.89%</span>
                </div>
                <div className="text-white mb-3">
                  <p className="mb-0 h2">
                    102,61<span className="btc">BTC</span>
                    <span className="d-block h6 tron">$203,018.92</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4">
              <div className="bg-black rounded p-4">
                <div className="d-flex mb-5">
                  <Avatar className="btc-icon" alt="">
                    <i class="bi bi-currency-bitcoin"></i>
                  </Avatar>
                  <div className="ms-3 text-white">
                    <p className="mb-0 h6">BTC</p>
                    <h6 className="mb-0 p tron">Bitcoin</h6>
                  </div>
                </div>
                <div className="btc-container mb-1">
                  <Avatar className="bg-success" alt="">
                    <i class="bi bi-arrow-up-right"></i>
                  </Avatar>
                  <span className="btc-span">+98.89%</span>
                </div>
                <div className="text-white mb-3">
                  <p className="mb-0 h2">
                    102,61<span className="btc">BTC</span>
                    <span className="d-block h6 tron">$203,018.92</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div
          className="container container-fluid pt-4 "
          style={{ overflowX: "scroll", overflowY: "hidden" }}
        >
          <div className=" d-flex  g-4">
            <div className="col-12 col-md-6 col-xl-4">
              <div className="box-balance rounded p-4 mx-1">
                <div className="ms-3 text-white mb-5">
                  <p className="mb-2 wallet">
                    {/* <i className="bi bi-wallet"></i> */}
                    <img src="/img/overallicon.svg" alt="" />
                    <span className="ms-2 dashboard-header"> WALLET BALANCE</span>
                  </p>
                  {tloader?(<>
                    <span
                        className="text-white spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      <span className="text-white">Loading...</span>
                  </>):(
                    <h6 className="mb-0 h2">
                    <span className="dollor">₦</span>
                    {balance.wallet}
                    </h6>
                  )}
                 
                </div>
                <div className="d-flex justify-content-center requestbtn">
                  <Link to="/Walletdetails">
                    <button className="btn btn-request mb-5">
                      REQUEST WITHDRAW
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/* 1411 */}
            {tronBalance.isdetailadded == 1 ? (
              <>
                 <div className="col-12 col-md-6 col-xl-4 d-none">
                    <div className="bg-black rounded p-4 mx-1 trc-card">
                      <div className="d-flex mb-5">
                        <Avatar alt="" className="btc-icon">
                          <img
                            src="/img/icons8-tron-64.png "
                            alt="tron"
                            className="tron-icon"
                          />
                        </Avatar>
                        <div className="ms-3 text-white">
                          <p className="mb-0 h6">TRC20(USDT & TRON)</p>
                          <h6 className="mb-0 p tron">Tether & Tron</h6>
                        </div>
                      </div>
                      <div className="btc-container mb-1">
                        <Avatar className="bg-success" alt="">
                          <i class="bi bi-arrow-up-right"></i>
                        </Avatar>
                        <span className="btc-span">+98.89%</span>
                      </div>
                      
                      <div className="text-white mb-3">
                        <p className="mb-0 h2">
                        {tronBalance.data['tron_bal']}<span className="btc">TRX</span>
                          <span className="d-block h6 tron">{tronBalance.data['usdt_bal']} USDT</span>
                        </p>
                      </div>
                      
                    </div>
                  </div>
              </>
            ):(<>
                  <div className="col-12 col-md-6 col-xl-4 d-none ">
                    <div className="bg-black rounded p-4 mx-1 ">
                      <div className="d-flex mb-5">
                        <img
                            src="/img/Group.png "
                            alt="tron"
                            className="tron-icon"
                          />
                       
                      </div>
                     
                      
                    </div>
                  </div>
            </>)}
           {/* 1411     */}

           {/* 1411 */}
           {/* {btcBalance.isdetailadded == 1?(<>
           
            <div className="col-12 col-md-6 col-xl-4">
              <div className="bg-black rounded p-4 mx-1 btc-card">
                <div className="d-flex mb-5">
                  <Avatar className="btc-icon" alt="">
                    <i class="bi bi-currency-bitcoin"></i>
                    
                    
                  </Avatar>
                
                  <div className="ms-3 text-white">
                    <p className="mb-0 h6">BTC</p>
                    <h6 className="mb-0 p tron">Bitcoin</h6>
                  </div>
                </div>
                <div className="btc-container mb-1">
                 

                  <img src="/img/export.svg" alt=""  />
                  <span className="btc-span">+98.89%</span>
                </div>
                <div className="text-white mb-3">
                  <p className="mb-0 h2">
                    {btcBalance.data['blockio_balance']}<span className="btc">BTC </span>
                    <span className="d-block h6 tron">${(btccoinRate * btcBalance.data['blockio_balance']).toFixed(2)}</span>
                  </p>
                </div>
              </div>
            </div>
           </>):(<>
                  <div className="col-12 col-md-6 col-xl-4 ">
                    <div className="bg-black rounded p-4 mx-1  ">
                      <div className="d-flex mb-2 justify-content-center mt-2">
                        <img
                            src="/img/btcgruop.svg "
                            alt="tron"
                            className="tron-icon "
                          />
                      </div>
                      <div>
                      <h6 className="dashboard-btc">
                          BTC Wallet
                        </h6>
                      </div>
                      <div className="dashboard-btc-main-p">
                      <p className="dashboard-btc-p">
                          {" "}
                          You do not have a BTC Wallet,
                        </p>
                        <p className="dashboard-btc-two" >
                          Click on the link below to create a wallet
                        </p>
                      </div>
                    <div className="text-center ">
                    <button className="btn dashboard-create-btn">
                     <Link to='/Logindetails' style={{textDecoration:'none',color:'#ffffff'}}>
                     + Create  Wallet
                     </Link>
                      </button>
                    </div>
                    </div>
                  </div>
           </>)} */}
            
           {/* 1411 */}
          </div>
        </div>
        {/* </div> */}

        <div className="container-fluid transaction-table pt-4 ">
          <div className="row">
            <div className="col-md-12">
              <div class="card-header align-items-center text-white">
                <h5 class="mb-0">Transactions</h5>
                <p class="mb-0 small">{`${currentDate}`}</p>
              </div>
              <table class=" table-border rounded ">
                <thead>
                  <tr className="fs-13 ">
                    <th>DETAILS</th>
                    <th className="d-none d-sm-table-cell">TYPE</th>
                    <th className="dashboard-status">STATUS</th>
                    <th className="d-none d-sm-table-cell">DATE</th>
                  </tr>
                </thead>
              </table>
              <table className="table table-dark table-border rounded">
                {tloader ? (
                  <>
                   <tbody >
                      <tr>
                        <td colSpan="4" className="text-center">
                        <span
                        className="text-white spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                          <span className="text-white"> Loading...</span>
                        </td>
                      </tr>
                   </tbody>
                  </>
                ):(
                  <tbody >
                  {transaction.length > 0 ? (
                    transaction.map((item, index) => (
                      <tr onClick={() => getDetails(item.id)}>
                        <td className="d-flex align-items-center dashboard-body">
                          <p className="gift-icon">
                            {/* <i class="bi bi-gift"></i> */}

                          </p>
                          <img src="/img/Withdrawal1.svg" alt=''  />
                          <p className="fs-12 ms-2  dashboard-list-header">
                            {item.giftCard}
                            <span className="d-block fs-10 dashboard-table-list-header">
                              Gift Cards worth
                              <span className="fs-13 dashboard-amount"> ₦{item?.total}</span>
                            </span>
                            <p className="fs-12 dashboard-type-hide-desktop">
                            {item?.cardType ? item.cardType : "Crypto"}
                          </p>
                          <p className="fs-10 dashboard-date-hide-desktop ">{item?.timestamp}</p>
                          </p>
                        </td>
                        <td>
                          <p className="fs-12 dashboard-type-hide-mobile">
                            {item?.cardType ? item.cardType : "Crypto"}
                          </p>
                        </td>
                        <td className="dashbaord-btn">
                          <a
                           
                            className={`complete-btn ${getButtonColor(
                              item?.status
                            )}`}
                          >
                            {/* <span className="dot">.</span>  */}
                            {item?.status.toUpperCase()}
                          </a>
                        </td>
                        <td className="dashboard-date-hide-mobile">
                          <p className="fs-10">{item?.timestamp}</p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr className="text-center not-ransctionbg">
                        <td colSpan={4}>
                          {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                          <div className="no-transaction-dashborad">
                            <img
                              src="img/EmptyState.svg"
                              alt=""
                              className="empty-img"
                            />
                            <h4 className="not-transction-wallat ">
                              Transaction
                            </h4>

                            <h5 className="not-transction-wallet-p text-center mt-3">
                              You don't have any pending or completed
                              transction.
                            </h5>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
                )}
                
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;


















// import React, { useState, useEffect } from "react";
// import "./dashboard.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Avatar from "@mui/material/Avatar";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import { useNavigate } from "react-router";
// import { Link } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";

// function Dashboard() {
//   const auth = JSON.parse(localStorage.getItem("user"));

//   const [balance, setBalance] = useState("");
//   const [tronBalance, setTronBalance] = useState("");
//   const [btcBalance, setBtcBalance] = useState("");
//   const [btccoinRate, setBtccoinRate] = useState("");
//   const navigate = useNavigate();
//   const [transaction, setTransaction] = useState([]);
//   const [tranDetails, setTransactionDetail] = useState("");
//   const [gettingWallet, setGettingWallet] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [tloader,setTLoader] = useState(false);
//   const [id, setId] = useState(0);

//   /*******************Get Current Date */
//   const today = new Date();
//   const date = today. getDate();
//   const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"
//   ];
//   var weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
//   const month = (monthNames[today.getMonth()]);
//   const day = (weekday[today.getDay()]);
//   const currentDate =day+ ' , '+ month + "  " + date+'th';
// /**************************End *******************/

//   useEffect(() => {
//     if (!auth) {
//       navigate("/");
//     }

//     setGettingWallet(true);
//     getTransaction();
//     getDetails(id);
//     getWalletBalance();
//     GetTronUserWallet();
//     GetBtcWallet();
//     GetBtccoinRate();
//     setTLoader(true);
//     setLoader(true);

//   }, []);

//   const getTransaction = async () => {
//     const apicall = await AfterLoginService.GetTransaction(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setTransaction(resp.data);
//           if (!resp.data) {
//             getDetails(resp.data[0].id);
//           }
//           setGettingWallet(false);
//           setTLoader(false);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   const getDetails = async (transaction_id) => {
//     setLoader(true);
//     const apicall = await AfterLoginService.GetTransactionDetails(
//       transaction_id,
//       auth
//     );
//     if (apicall?.state === true) {
//       const resp = await apicall.result.json();
//       if (resp?.status === 200) {
//         setTransactionDetail(resp?.data);
//       } else {
//         console.log(resp.message);
//       }
//     } else {
//       // Handle API call error
//     }
//   };


//   const getWalletBalance = async () => {
//     if (auth) {
//       const apicall = await AfterLoginService.GetWalletBalance(auth);
//       if (apicall.state === true) {
//         apicall.result.json().then((resp) => {
//           if (resp.status == 200) {
//             setBalance(resp);
//             setGettingWallet(false);
//           } else {
//             toast(resp.message);
//           }
//         });
//       } else {
//       }
//     }
//   };

//   const GetTronUserWallet = async () =>{
//     if(auth)
//     {
      
//       const apicall = await AfterLoginService.GetTronUserWallet(auth);
//       if (apicall.state === true) {
//         apicall.result.json().then((resp) => {
//           if (resp.status == 200) {
//             setTronBalance(resp);
//             setGettingWallet(false);
//           } else {
//             toast(resp.message);
//           }
//         });
//       } else {
//       }
//     }
//   }

//   const GetBtcWallet = async () =>{
//     if(auth)
//     {
      
//       const apicall = await AfterLoginService.GetBtcWallet(auth);
//       if (apicall.state === true) {
//         apicall.result.json().then((resp) => {
//           if (resp.status == 200) {
//             setBtcBalance(resp);
//             setGettingWallet(false);
//           } else {
//             toast(resp.message);
//           }
//         });
//       } else {
//       }
//     }
//   }

//   const GetBtccoinRate = async () =>{
//     if(auth)
//     {
      
//       const apicall = await AfterLoginService.GetBitcoinRate();
//       if (apicall.state === true) {
//          apicall.result.json().then((resp) => {
//           if (resp) {
//             setBtccoinRate(resp);
//             setGettingWallet(false);
//           } else {
//             toast(resp.message);
//           }
//         });
//       } else {
//       }
//     }
//   }
//   // for status button
//   function getButtonColor(status) {
//     switch (status.toUpperCase()) {
//       case "PENDING":
//         return "button-pending-dashboard";
//       case "DECLINED":
//         return "button-declined-dashboard";
//       case "APPROVED":
//         return "button-approved-dashboard";
//       case "PROCESSING":
//         return "button-processing-dashboard";
//         case "COMPLETED":
//           return "button-completed-dashboard";
//       default:
//         return "";
//     }
//   }


//   return (
//     <div className="container-fluid position-relative d-flex p-0 dashboard-container">
//       <Header />

//       <div className="content cont">
//         <Subheader />

//         {/* <div className="container-fluid pt-4  ">
//           <div className="row g-4">
//             <div className="col-sm-6 col-xl-4">
//               <div className="box-balance rounded p-4">
//                 <div className="ms-3 text-white mb-5">
//                   <p className="mb-2 wallet">
//                     <i className="bi bi-wallet"></i>
//                     <span className="ms-2 "> Overall Balance</span>
//                   </p>
//                   <h6 className="mb-0 h2">
//                     <span className="overalldollar">$</span>
//                     20,670.08
//                   </h6>
//                 </div>
//                 <div className="d-flex justify-content-center requestbtn">
//                   <Link to="/Walletdetails">
//                     <button className="btn btn-request mb-5">
//                       REQUEST WITHDRAW
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>

//             <div className="col-sm-6 col-xl-4">
//               <div className="bg-black rounded p-4">
//                 <div className="d-flex mb-5">
//                   <Avatar alt="" className="btc-icon">
//                     <img
//                       src="/img/icons8-tron-64.png "
//                       alt="tron"
//                       className="tron-icon"
//                     />
//                   </Avatar>
//                   <div className="ms-3 text-white">
//                     <p className="mb-0 h6">TRC20(USDT & TRON)</p>
//                     <h6 className="mb-0 p tron">Tether & Tron</h6>
//                   </div>
//                 </div>
//                 <div className="btc-container mb-1">
//                   <Avatar className="bg-success" alt="">
//                     <i class="bi bi-arrow-up-right"></i>
//                   </Avatar>
//                   <span className="btc-span">+98.89%</span>
//                 </div>
//                 <div className="text-white mb-3">
//                   <p className="mb-0 h2">
//                     102,61<span className="btc">BTC</span>
//                     <span className="d-block h6 tron">$203,018.92</span>
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="col-sm-6 col-xl-4">
//               <div className="bg-black rounded p-4">
//                 <div className="d-flex mb-5">
//                   <Avatar className="btc-icon" alt="">
//                     <i class="bi bi-currency-bitcoin"></i>
//                   </Avatar>
//                   <div className="ms-3 text-white">
//                     <p className="mb-0 h6">BTC</p>
//                     <h6 className="mb-0 p tron">Bitcoin</h6>
//                   </div>
//                 </div>
//                 <div className="btc-container mb-1">
//                   <Avatar className="bg-success" alt="">
//                     <i class="bi bi-arrow-up-right"></i>
//                   </Avatar>
//                   <span className="btc-span">+98.89%</span>
//                 </div>
//                 <div className="text-white mb-3">
//                   <p className="mb-0 h2">
//                     102,61<span className="btc">BTC</span>
//                     <span className="d-block h6 tron">$203,018.92</span>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div> */}

//         <div
//           className="container container-fluid pt-4 "
//           style={{ overflowX: "scroll", overflowY: "hidden" }}
//         >
//           <div className=" d-flex  g-4">
//             <div className="col-12 col-md-6 col-xl-4">
//               <div className="box-balance rounded p-4 mx-1">
//                 <div className="ms-3 text-white mb-5">
//                   <p className="mb-2 wallet">
//                     {/* <i className="bi bi-wallet"></i> */}
//                     <img src="/img/overallicon.svg" alt="" />
//                     <span className="ms-2 dashboard-header"> WALLET BALANCE</span>
//                   </p>
//                   {tloader?(<>
//                     <span
//                         className="text-white spinner-border spinner-border-sm"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>{" "}
//                       <span className="text-white">Loading...</span>
//                   </>):(
//                     <h6 className="mb-0 h2">
//                     <span className="dollor">₦</span>
//                     {balance.wallet}
//                     </h6>
//                   )}
                 
//                 </div>
//                 <div className="d-flex justify-content-center requestbtn">
//                   <Link to="/Walletdetails">
//                     <button className="btn btn-request mb-5">
//                       REQUEST WITHDRAW
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             {/* 1411 */}
//             {tronBalance.isdetailadded == 1 ? (
//               <>
//                  <div className="col-12 col-md-6 col-xl-4 d-none">
//                     <div className="bg-black rounded p-4 mx-1 trc-card">
//                       <div className="d-flex mb-5">
//                         <Avatar alt="" className="btc-icon">
//                           <img
//                             src="/img/icons8-tron-64.png "
//                             alt="tron"
//                             className="tron-icon"
//                           />
//                         </Avatar>
//                         <div className="ms-3 text-white">
//                           <p className="mb-0 h6">TRC20(USDT & TRON)</p>
//                           <h6 className="mb-0 p tron">Tether & Tron</h6>
//                         </div>
//                       </div>
//                       <div className="btc-container mb-1">
//                         <Avatar className="bg-success" alt="">
//                           <i class="bi bi-arrow-up-right"></i>
//                         </Avatar>
//                         <span className="btc-span">+98.89%</span>
//                       </div>
                      
//                       <div className="text-white mb-3">
//                         <p className="mb-0 h2">
//                         {tronBalance.data['tron_bal']}<span className="btc">TRX</span>
//                           <span className="d-block h6 tron">{tronBalance.data['usdt_bal']} USDT</span>
//                         </p>
//                       </div>
                      
//                     </div>
//                   </div>
//               </>
//             ):(<>
//                   <div className="col-12 col-md-6 col-xl-4 d-none ">
//                     <div className="bg-black rounded p-4 mx-1 ">
//                       <div className="d-flex mb-5">
//                         <img
//                             src="/img/Group.png "
//                             alt="tron"
//                             className="tron-icon"
//                           />
                       
//                       </div>
                     
                      
//                     </div>
//                   </div>
//             </>)}
//            {/* 1411     */}

//            {/* 1411 */}
//            {btcBalance.isdetailadded == 1?(<>
           
//             <div className="col-12 col-md-6 col-xl-4">
//               <div className="bg-black rounded p-4 mx-1 btc-card">
//                 <div className="d-flex mb-5">
//                   <Avatar className="btc-icon" alt="">
//                     <i class="bi bi-currency-bitcoin"></i>
                    
                    
//                   </Avatar>
//                   {/* <img src="/img/bitcoin1.svg" alt="" /> */}
//                   <div className="ms-3 text-white">
//                     <p className="mb-0 h6">BTC</p>
//                     <h6 className="mb-0 p tron">Bitcoin</h6>
//                   </div>
//                 </div>
//                 <div className="btc-container mb-1">
//                   {/* <Avatar className="bg-success" alt="">
//                     <i class="bi bi-arrow-up-right"></i>
//                   </Avatar> */}

//                   <img src="/img/export.svg" alt=""  />
//                   <span className="btc-span">+98.89%</span>
//                 </div>
//                 <div className="text-white mb-3">
//                   <p className="mb-0 h2">
//                     {btcBalance.data['blockio_balance']}<span className="btc">BTC</span>
//                     <span className="d-block h6 tron">${(btccoinRate * btcBalance.data['blockio_balance']).toFixed(2)}</span>
//                   </p>
//                 </div>
//               </div>
//             </div>
//            </>):(<>
//                   <div className="col-12 col-md-6 col-xl-4 ">
//                     <div className="bg-black rounded p-4 mx-1  ">
//                       <div className="d-flex mb-2 justify-content-center mt-2">
//                         <img
//                             src="/img/btcgruop.svg "
//                             alt="tron"
//                             className="tron-icon "
//                           />
//                       </div>
//                       <div>
//                       <h6 className="dashboard-btc">
//                           BTC Wallet
//                         </h6>
//                       </div>
//                       <div className="dashboard-btc-main-p">
//                       <p className="dashboard-btc-p">
//                           {" "}
//                           You do not have a BTC Wallet,
//                         </p>
//                         <p className="dashboard-btc-two" >
//                           Click on the link below to create a wallet
//                         </p>
//                       </div>
//                     <div className="text-center ">
//                     <button className="btn dashboard-create-btn">
//                      <Link to='/Logindetails' style={{textDecoration:'none',color:'#ffffff'}}>
//                      + Create  Wallet
//                      </Link>
//                       </button>
//                     </div>
//                     </div>
//                   </div>
//            </>)}
            
//            {/* 1411 */}
//           </div>
//         </div>
//         {/* </div> */}

//         <div className="container-fluid transaction-table pt-4 ">
//           <div className="row">
//             <div className="col-md-12">
//               <div class="card-header align-items-center text-white">
//                 <h5 class="mb-0">Transactions</h5>
//                 <p class="mb-0 small">{`${currentDate}`}</p>
//               </div>
//               <table class=" table-border rounded ">
//                 <thead>
//                   <tr className="fs-13 ">
//                     <th>DETAILS</th>
//                     <th className="d-none d-sm-table-cell">TYPE</th>
//                     <th className="dashboard-status">STATUS</th>
//                     <th className="d-none d-sm-table-cell">DATE</th>
//                   </tr>
//                 </thead>
//               </table>
//               <table className="table table-dark table-border rounded">
//                 {tloader ? (
//                   <>
//                    <tbody >
//                       <tr>
//                         <td colSpan="4" className="text-center">
//                         <span
//                         className="text-white spinner-border spinner-border-sm"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>{" "}
//                           <span className="text-white"> Loading...</span>
//                         </td>
//                       </tr>
//                    </tbody>
//                   </>
//                 ):(
//                   <tbody >
//                   {transaction.length > 0 ? (
//                     transaction.map((item, index) => (
//                       <tr onClick={() => getDetails(item.id)}>
//                         <td className="d-flex align-items-center dashboard-body">
//                           <p className="gift-icon">
//                             {/* <i class="bi bi-gift"></i> */}

//                           </p>
//                           <img src="/img/Withdrawal1.svg" alt=''  />
//                           <p className="fs-12 ms-2  dashboard-list-header">
//                             {item.giftCard}
//                             <span className="d-block fs-10 dashboard-table-list-header">
//                               Gift Cards worth
//                               <span className="fs-13 dashboard-amount"> ₦{item?.total}</span>
//                             </span>
//                             <p className="fs-12 dashboard-type-hide-desktop">
//                             {item?.cardType ? item.cardType : "Crypto"}
//                           </p>
//                           <p className="fs-10 dashboard-date-hide-desktop ">{item?.timestamp}</p>
//                           </p>
//                         </td>
//                         <td>
//                           <p className="fs-12 dashboard-type-hide-mobile">
//                             {item?.cardType ? item.cardType : "Crypto"}
//                           </p>
//                         </td>
//                         <td className="dashbaord-btn">
//                           <a
                           
//                             className={`complete-btn ${getButtonColor(
//                               item?.status
//                             )}`}
//                           >
//                             <span className="dot">.</span> {item?.status.toUpperCase()}
//                           </a>
//                         </td>
//                         <td className="dashboard-date-hide-mobile">
//                           <p className="fs-10">{item?.timestamp}</p>
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <>
//                       <tr className="text-center not-ransctionbg">
//                         <td colSpan={4}>
//                           {/* {" "}
//                                     <h6 className="text-center w-100">
//                                       Data not found
//                                     </h6> */}

//                           <div className="no-transaction-dashborad">
//                             <img
//                               src="img/EmptyState.svg"
//                               alt=""
//                               className="empty-img"
//                             />
//                             <h4 className="not-transction-wallat ">
//                               Transaction
//                             </h4>

//                             <h5 className="not-transction-wallet-p text-center mt-3">
//                               You don't have any pending or completed
//                               transction.
//                             </h5>
//                           </div>
//                         </td>
//                       </tr>
//                     </>
//                   )}
//                 </tbody>
//                 )}
                
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;











