import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Createsuccess from "./createsuccess";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import Select from "react-select";
import useSelect from "../../Hook/user-select";

const AccountCreate = ({virtualInfo,virtualBank,setVirtualAcScreenShow,setVirtualTranScreenShow,fname,
    setFName,
    surname,
    setSurname,
    phone,
    setPhone,
    dob,
    setDob,
    bvnNum,
    setBvnNum,
    referenceCode,
    setReferenceCode,virtualAccountDetails}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [email,setEmail] = useState("")
  const [bankCode,setBankCode] = useState("")
  const [isReadOnly,setIsReadyOnly] = useState(false);
  const [createLoader,setCreateLoader] = useState(false);

  const phoneSet = (e)=>{
    const reg = /^[0-9\b]+$/
    let preval=e.target.value
    if (e.target.value === '' || reg.test(e.target.value)){
        setPhone(preval)
      return true
    }else{
      e.target.value = preval.substring(0,(preval.length-1))
    }
  }
  const handleData = ()=>{
    setCreateLoader(true)
    if(bankCode == '' || bankCode == null){
        toast('Select bank');
        setCreateLoader(false)
        return false;
    }
    if(phone == '' || phone == null)
    {
        toast('Phone field is required');
        setCreateLoader(false)
        return false;
    }
    if(phone.length < 10)
    {
            toast('Phone 10 digit is required');
            setCreateLoader(false)
            return false;
    }
   
    if(email == '' || email == null)
    {
        toast('Email field is required');
        setCreateLoader(false)
        return false;
    }
    virtualAccountCreate()
  }
  const virtualAccountCreate = async ()=>{
    const item = {referenceCode:referenceCode,bankCode:bankCode,email:email,phoneNumber:phone}
    setCreateLoader(true)
    const apicall = await AfterLoginService.createVirtualAccount(auth,item);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
            setIsFirstModalOpen(true)
            setCreateLoader(false)
        }
        else
        {
            setCreateLoader(false)
        }
      });
    } 
    else
    {
    }
  }
  
  return (
    <div>
        <div className="row">
                        <div className="col-md-12">
                            <div>
                                <h3 className="heading-transaction mb-0">Create New Wallet</h3>
                            </div>
                            <div className="row virtual-bvn account_1">
                                <div className="col-md-4">
                                    <p>Bio</p>
                                    
                                    <div className="mt-1">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter Amount in NGN"  value={bvnNum}  readOnly={isReadOnly} />
                                        
                                    </div>
                                    <div className="mb-3 mt-3">
                                    
                                        <select
                                         style={{ backgroundColor:'#252731',color:"#fff",border:"0", borderColor:"inherit"}}
                                            class="form-control"
                                            onChange={(e)=>setBankCode(e.target.value)}
                                            aria-label="Default select example"
                                        >
                                        <option value="">Select Bank</option>
                                        {virtualBank.map((item) => (
                                            <option
                                                value={item.bankShortName}
                                            >
                                                {item.bankDisplayName}
                                            </option>
                                            ))}  
                                        </select>
                                    </div>
                                    <div className="mt-3">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="First Name"  value={fname}
                                            readOnly={isReadOnly} />
                                        
                                    </div>
                                    <div className="mt-3">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}}  value={surname}
                                            readOnly={isReadOnly} />
                                        
                                    </div>
                                    <div className="mt-3">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}}   value={phone} onChange={phoneSet}  placeholder="Phone Number" />
                                        
                                    </div>
                                    <div className="mt-3">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}}   value={dob} readOnly={isReadOnly} placeholder="Date Of Birth" />
                                        
                                    </div>
                                    <div className="mt-3 mb-2">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}}   
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address" />
                                        
                                    </div>
                                    <div className="">
                                    <Createsuccess handleData={handleData} isFirstModalOpen={isFirstModalOpen} setIsFirstModalOpen={setIsFirstModalOpen} setVirtualTranScreenShow={setVirtualTranScreenShow} setVirtualAcScreenShow={setVirtualAcScreenShow} createLoader={createLoader} setCreateLoader={setCreateLoader}virtualAccountDetails={virtualAccountDetails} />
                                    </div>
                                </div>
                                <div className="col-md-8"></div>
                                <div className="col-md-4">
                                   
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
    </div>
  );
};

export default AccountCreate;
