import React, { useState } from "react";
import "./account.scss";

import { Link } from "react-router-dom";
import Retrymodal from "./retrymodal";
import KYCmodal from "./kycmodal";
import TextField from "@mui/material/TextField";
const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const BVNmodal = ({setBvnCheckIconShow}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [bvn, setBvn] = useState(localStorage.getItem("bvn"));

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };
  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const bvnChange = (event) => {
    setBvn(event.target.value);
    // localStorage.setItem("bvn", event.target.value);
  };

  const handleData = () => {
    setIsFirstModalOpen(false);
  };

  // mui css
  const textFieldStyles = {
    // backgroundColor: "white",
    color: "white",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  return (
    <div>
      <button className="btn-continue bvn-btn-modal" onClick={openFirstModal}>
        BVN (Optional)
      </button>
      

      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content   w-100">
                <div className="modal-header">
                  <div className="back-icon-main" >
                    <img src="/img/CaretLeft1.svg" onClick={closeFirstModal} />
                  </div>
                  <h5 className="modal-title m-auto bvn-header  ">BVN</h5>

                  <button
                    type="button "
                    className="close-bvn"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="bvn-p">
                  <p> Please input your BVN in the section below</p>

                  <hr className="bvn-hr" />
                </div>

                <div className="modal-body ">
                  <div className="bvn-box">
                    {/* <div className=" "> */}
                      {/* <p className="bvn-p-box">BVN</p> */}

                      {/* <input 
                        type="text"
                         onChange={bvnChange}
                         value={bvn}
                         className=" bvn-input  "
                         placeholder="Enter BVN"
                         /> */}

                      <TextField
                        type="text"
                        value={bvn}
                        onChange={bvnChange}
                        label={
                          <span style={{ color: "#FFFFFF",fontSize:'12px' }}>Enter BVN</span>
                        }
                        className=" bvn-input  "
                        variant="filled"
                        style={{ ...textFieldStyles, color: "#FFFFFF",backgroundColor:'#252731' }}
                        InputLabelProps={{
                          style: {
                            color: "white",
                            backgroundColor:'#252731'
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "white",
                            backgroundColor:'#252731'
                          },
                        }}
                      />
                    </div>
                  {/* </div> */}
                </div>
                <div className="modal-footer ">
                  <button className="btn-bvn-continue">
                    <KYCmodal bvn={bvn} handleData={handleData} setBvnCheckIconShow={setBvnCheckIconShow}/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BVNmodal;
