import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-usd">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Successmodal = ({ amount, password , handleConfirm ,kyc, handleData,amountNull}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [loading, setloading] = useState(false);
  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
    handleConfirm();
    handleData();
  };

  const ProceedWallet = async () => {
    
    if (validate()) {
      setloading(true);
      const item = { password };
      let platform = "web";
      const item1 = { amount, platform };
      const apicall = await AfterLoginService.ValidatePassword(auth, item);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
           
             withDrawBal(item1);
          } else {
            toast(resp.message);
            setloading(false);
          }
        });
      } else {
      }
    }
  };

  const validate = () => {
    let result = true;
    if (amount === "" || amount === null || amount == '') {
      toast("The amount field is required");
      result = false;
    }
    if (password === "" || password === null) {
      toast("The password field is required");
      result = false;
    }
    return result;
  };

  const withDrawBal = async (item1) => {
    if(kyc === 0 && amount >= 300000)
    {
      setloading(false);
      toast("complete KYC to withdraw. Click on account to update your KYC");
      amountNull();
      return false;
    }
   
    const apicall = await AfterLoginService.WithdrawBalance(auth, item1);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setloading(false);
          setIsFirstModalOpen(true);
        } else {
          toast(resp.message);
          setloading(false);
        }
      });
    }
  };

  return (
    <div>
      <button
        className={`btn btn-update w-100 mb-3 ${loading ? "disabled" : ""}`}
        onClick={() => ProceedWallet()}
        style={{marginTop:"-20px"}}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{" "}
            Loading...
          </>
        ) : (
          "Submit"
        )}
      </button>
      <ToastContainer />
      {isFirstModalOpen && (
        <Modal>
          <div className="modal" >
            <div className="modal-dialog">
              <div className="modal-content  walletdetails-success-modal">
                {/* <div className="modal-header d-flex justify-content-end align-items-end " style={{marginTop:'4rem'}}>
                  <button
                    type="button"
                    className="close"
                    onClick={closeFirstModal}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div> */}
                <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col wallet-header-modal">
                  {/* <Avatar className="bg-success mb-3">
                     <img src="/img/righttick.svg" height='200px'  alt=""/>
                  </Avatar> */}
                  <img src="/img/righttick.svg" className="mb-4"  alt=""/>
                  <p className="h4 text-white">Congrats </p>
                  <p>
                    Your have successfully withdrawn
                    <span className="d-block">

                    ₦{amount} into your account</span>
                    <span className="text-white fs-5 d-block d-none">
                      {" "}
                      098*********0 Nneka Amara
                    </span>
                  </p>
                </div>

                <div className="modal-footer d-flex">
                  <div
                    className="done-btn"
                    style={{ width: "100%", textAlign: "center",marginTop:'20px' }}
                  >
                    <button
                      className="btn btn-update "
                      onClick={closeFirstModal}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Successmodal;
