import React, { useEffect, useRef, useState } from "react";
import "./account.scss";
import Webcam from "react-webcam";

import { Link } from "react-router-dom";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const InternationalPassport = ({setCheckIconShow}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [bvn, setBvn] = useState(localStorage.getItem("bvn"));
  const [st, setSt] = useState("");
  let videoRef = useRef(null);
  let photoRef = useRef(null);
  // get access to user webcamera
  const getUserCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        // attach the stream
        setSt(stream);
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();

        // var vidTrack = st.getVideoTracks();
        // vidTrack.forEach(track => track.enabled = false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // to take picture
  const takePicture = () => {
    let width = 400;
    let height = width / (12 / 9);
    let photo = photoRef.current;
    let video = videoRef.current;
    // set the photo
    photo.width = width;
    photo.height = height;
    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, photo.width, photo.height);

    const data = photo.toDataURL("image/jpeg");
    const link = document.createElement("a");
    link.href = data;
   
    localStorage.setItem("kycimage", data);
    closeFirstModal();
    st.getVideoTracks()[0].stop();
  };

  // clear selfe
  const clearPicture = () => {
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");
    ctx.clearRect(0, 0, photo.width, photo.height);
  };

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
    // getUserCamera();
  };
  const closeFirstModal = () => {
    // st.getVideoTracks()[0].stop();
    setIsFirstModalOpen(false);
  };

  const bvnChange = (event) => {
    setBvn(event.target.value);
    // localStorage.setItem("bvn", event.target.value);
  };

  const handleData = () => {
    setIsFirstModalOpen(false);
  };
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const videoConstraints = {
    facingMode: FACING_MODE_USER
  };
  const switchCamera = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      localStorage.setItem("kycimage", imageSrc);
      setIsFirstModalOpen(false);
      setCheckIconShow(true)
    },
    [webcamRef]
  );
  return (
    <div>
      <button className="btn-continue International-btn" onClick={openFirstModal}>
      <span className="passport-p mt-3 pb-3">
          International Passport or National <span className="identity1">Identity</span>
          <span className="NIMC1"> card(NIMC)*</span>
        </span>
      </button>

      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content   w-100">
                <div className="modal-header">
                  <button
                    type="button "
                    className="close-bvn kycClose"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close.svg" />
                  </button>
                  <Webcam
                      audio={false}
                      height={720}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        ...videoConstraints,
                        facingMode
                      }}
                    />
                  <button onClick={capture} className="take_selfi">
                    Take Selfie 
                  </button>
                  <button onClick={switchCamera} className="switch_selfi">
                  <i class="fa-solid fa-camera-rotate"></i>
                  </button>
                </div>

                <div className="modal-body "></div>
                <div className="modal-footer "></div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default InternationalPassport;
