import React, { useState } from "react";
import BTCWalletService from "../../Services/BTCService/BTCWalletService";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-btc">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sendbtc = ({
  amount,
  receiverAddress,
  closePreviousModal,
  setAmountRequiredError,
  setAddressRequiredError,
}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [btcEquivalentAmount, setBtcEquivalentAmount] = useState(null);


  const openSecModal = async () => {
    if (!amount) {
      setAmountRequiredError(true);
      return;
    }
    if (!receiverAddress) {
      setAddressRequiredError(true);
      return;
    }
    setLoading(true);
    const { result } = await BTCWalletService.GetBTCRate();
    const btcEquivalent = amount / result;

    const auth = JSON.parse(localStorage.getItem("user"));
    const { result: tokenData } =
      await BTCWalletService.GenerateThirdPartyToken(auth);
    const token = tokenData.data.token;
    setBtcEquivalentAmount(btcEquivalent);

    const roundedNum = btcEquivalent.toFixed(6);
    const body = {
      amount: roundedNum,
      to_address: receiverAddress,
    };

    const data = await BTCWalletService.checkTransactionValidity(token, body);

    if (!data.state) setErrorMessage(data.result.message);
    else setIsFirstModalOpen(true);
    setLoading(false);
  };

  const sendBtc = async () => {
    setSending(true);
    const auth = JSON.parse(localStorage.getItem("user"));
    const { result: tokenData } =
      await BTCWalletService.GenerateThirdPartyToken(auth);

    const token = tokenData.data.token;
    const roundedNum = btcEquivalentAmount.toFixed(6);
    const body = {
      amount: roundedNum,
      to_address: receiverAddress,
    };

    const { result: getBtcWalletData } = await BTCWalletService.GetBTCWallet(
      auth
    );
    const { result: blockIOAdmin } = await BTCWalletService.GetBlockIOAdmin(
      auth
    );
    const { result: btcRateData } = await BTCWalletService.GetBTCRate();

    const { result: sendData } = await BTCWalletService.sendBTC(token, body);
    // const sendData = {
    //   status: "success",
    //   data: {
    //     network: "BTC",
    //     txid: "f00fdec06b25dbecc994abd01f4b7f70bcf8bad80d80a258a0cb99f36c14d041",
    //   },
    // };

    const sendTransactionRecord = await BTCWalletService.saveTransaction(
      auth,
      roundedNum,
      sendData.data.txid,
      blockIOAdmin.data.block_io_rate,
      getBtcWalletData.data.blockio_btc,
      receiverAddress,
      btcRateData
    );
    setSending(false);
    setIsFirstModalOpen(false);
    closePreviousModal();
    if (sendTransactionRecord.state) toast.success("Transaction Successfull");
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };
  return (
    <div>
      <button
        className={`btn btn-update w-100 `}
        disabled={loading}
        onClick={openSecModal}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm "
              role="status"
              aria-hidden="true"
            ></span>{" "}
            checking validity...
          </>
        ) : (
          "Proceed"
        )}
      </button>
      <p className="text-danger text-sm"> {errorMessage}</p>

      {isFirstModalOpen && (
        <Modal>
          <div className="modal" >
            <div className="modal-dialog">
              <div className="modal-content sentbtc-modal">
                <div className="modal-header">
                  <h5 className="modal-title text-center confirm-header">
                    {" "}
                    Confirm Send Transaction
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="sell-btc-p">
                  <p>Molestie et amet cursus vestibulum quam commodo.</p>
                </div>
                <div className="modal-body text-white text-center">
                   {/* <p>
                    Procced to send BTC
                    <span className="d-block">
                      {" "}
                      {btcEquivalentAmount.toFixed(6)} worth ${amount} to the{" "}
                    </span>
                    <span className="d-block">
                      {" "}
                      specified recipient address?{" "}
                    </span>
                  </p>
                  <p>Note : Standard network fee applies.</p>  */}
                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <p
                        style={{
                          color: "#7D7DA1",
                          fontSize: "12px",
                          marginTop: "-15px",
                        }}
                      >
                        You are Sending
                      </p>
                      <h3 style={{ color: "#FFFFFF", marginTop: "-10px" }}>
                        {/* 1.023<span style={{ fontSize: "15px" }}>BTC</span> */}
                        {btcEquivalentAmount.toFixed(6)}{" "}<span style={{ fontSize: "15px",marginLeft:'-8px' }}>BTC</span>
                      </h3>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="/img/vertical.png" 
                          style={{ display: "inline-block"  }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-l-1 col-md-1 col-sm-1 col-1"></div>
                    <div className=" col-10 col-md-10 col-sm-10 col-12  ">
                      <div className="send-copy-heading">
                        <p
                          className="text-left ml-2 mt-1"
                          style={{ fontSize: "12px" }}
                        >
                          To
                        </p>
                        <p className="copy-heading ml-2">
                         {receiverAddress}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
                  </div>

                  <div className="row  text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12"></div>
                  </div>
                </div>
                <div className="modal-footer d-flex">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-cancle1">
                        <button
                          type="button"
                          className="btn btn-cancel "
                          onClick={closeFirstModal}
                        >
                          <img src="/img/cancel.png" />
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-update1">
                        <button
                          className={` btn-proceed-2 `}
                          disabled={sending}
                          onClick={sendBtc}
                        >
                          {sending ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm "
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            "Proceed"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sendbtc;

// company code

// import React, { useState } from "react";
// import BTCWalletService from "../../Services/BTCService/BTCWalletService";
// import { toast } from "react-toastify";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-btc">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Sendbtc = ({
//   amount,
//   receiverAddress,
//   closePreviousModal,
//   setAmountRequiredError,
//   setAddressRequiredError,
// }) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [sending, setSending] = useState(false);
//   const [btcEquivalentAmount, setBtcEquivalentAmount] = useState(null);

//   const openSecModal = async () => {
//     if (!amount) {
//       setAmountRequiredError(true);
//       return;
//     }
//     if (!receiverAddress) {
//       setAddressRequiredError(true);
//       return;
//     }
//     setLoading(true);
//     const { result } = await BTCWalletService.GetBTCRate();
//     const btcEquivalent = amount / result;

//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result: tokenData } =
//       await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = tokenData.data.token;
//     setBtcEquivalentAmount(btcEquivalent);

//     const roundedNum = btcEquivalent.toFixed(6);
//     const body = {
//       amount: roundedNum,
//       to_address: receiverAddress,
//     };

//     const data = await BTCWalletService.checkTransactionValidity(token, body);

//     if (!data.state) setErrorMessage(data.result.message);
//     else setIsFirstModalOpen(true);
//     setLoading(false);
//   };

//   const sendBtc = async () => {
//     setSending(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result: tokenData } =
//       await BTCWalletService.GenerateThirdPartyToken(auth);

//     const token = tokenData.data.token;
//     const roundedNum = btcEquivalentAmount.toFixed(6);
//     const body = {
//       amount: roundedNum,
//       to_address: receiverAddress,
//     };

//     const { result: getBtcWalletData } = await BTCWalletService.GetBTCWallet(
//       auth
//     );
//     const { result: blockIOAdmin } = await BTCWalletService.GetBlockIOAdmin(
//       auth
//     );
//     const { result: btcRateData } = await BTCWalletService.GetBTCRate();

//     const { result: sendData } = await BTCWalletService.sendBTC(token, body);
//     // const sendData = {
//     //   status: "success",
//     //   data: {
//     //     network: "BTC",
//     //     txid: "f00fdec06b25dbecc994abd01f4b7f70bcf10bad80d80a258a0cb99f36c14d041",
//     //   },
//     // };

//     const sendTransactionRecord = await BTCWalletService.saveTransaction(
//       auth,
//       roundedNum,
//       sendData.data.txid,
//       blockIOAdmin.data.block_io_rate,
//       getBtcWalletData.data.blockio_btc,
//       receiverAddress,
//       btcRateData
//     );
//     setSending(false);
//     setIsFirstModalOpen(false);
//     closePreviousModal();
//     if (sendTransactionRecord.state) toast.success("Transaction Successfull");
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };
//   return (
//     <div >
//       <button
//         className={`btn btn-update w-100 `}
//         disabled={loading}
//         onClick={openSecModal}
//       >
//         {loading ? (
//           <>
//             <span
//               className="spinner-border spinner-border-sm "
//               role="status"
//               aria-hidden="true"
//             ></span>{" "}
//             checking validity...
//           </>
//         ) : (
//           "Proceed"
//         )}
//       </button>
//       <p className="text-danger text-sm"> {errorMessage}</p>

//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className="modal-dialog">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title text-center ">Send BTC</h5>
//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="modal-body text-white text-center">
//                   <p>
//                     Procced to send BTC
//                     <span className="d-block">
//                       {" "}
//                       {btcEquivalentAmount.toFixed(6)} worth ${amount} to the{" "}
//                     </span>
//                     <span className="d-block">
//                       {" "}
//                       specified recipient address?{" "}
//                     </span>
//                   </p>
//                   <p>Note : Standard network fee applies.</p>
//                 </div>
//                 <div className="modal-footer d-flex">
//                   <button
//                     type="button"
//                     className="btn btn-cancel w-25 bg-danger text-white"
//                     onClick={closeFirstModal}
//                   >
//                     Back
//                   </button>
//                   <button
//                     className={`btn btn-update w-25 `}
//                     disabled={sending}
//                     onClick={sendBtc}
//                   >
//                     {sending ? (
//                       <>
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         Loading...
//                       </>
//                     ) : (
//                       "Proceed"
//                     )}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Sendbtc;
