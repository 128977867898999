import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Modal = ({ onClose, children  }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const FundRequestModel = ({virtualInfo}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  
  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const handleConfirm = () => {
    setIsFirstModalOpen(false);
  }
  // mui css
  const textFieldStyles = {
    background: "#252731 ",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  const copyReferralCode = (item) => {
    const referralCode = item;
       navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy referral code: ", error);
      });
  };
  return (

    <div >
      <button className="btn add_fund" onClick={openFirstModal} >
          Add Fund
        </button>

      {isFirstModalOpen && (
        <Modal>
          <div className="modal fundModal ">
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-header w-100 text-center mt-2">
                  <h5 className="modal-title">Account Details</h5>
                  <button
                    type="button"
                    className="close-wallet-btn"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop: "0px",
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
               
                <div className="modal-body text-white text-center">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <table className="table confT">
                                    <tbody>
                                       <tr>
                                          <td>Bank</td>
                                          <td className="text-right1">{virtualInfo?virtualInfo.bankName:''} 
                                          <div
                                              className="copy-icon-container "
                                              onClick={(e)=>copyReferralCode(virtualInfo?virtualInfo.bankName:'')}
                                        
                                            >
                                              <ContentCopyIcon className="text-light" />
                                              {copied && (
                                                <div className="copied-msg">Copied!</div>
                                              )}
                                            </div>
                                          </td>
                                       </tr>
                                       <tr>
                                        <td>Account Number</td>
                                        <td className="text-right1">{virtualInfo?virtualInfo.accountNumber:''}
                                            <div
                                              className="copy-icon-container "
                                              onClick={(e)=>copyReferralCode(virtualInfo?virtualInfo.accountNumber:'')}
                                        
                                            >
                                              <ContentCopyIcon className="text-light" />
                                              {copied && (
                                                <div className="copied-msg">Copied!</div>
                                              )}
                                            </div>
                                        </td>
                                       </tr>
                                       <tr>
                                       <td>Account Name</td>
                                       <td className="text-right1">{virtualInfo?virtualInfo.accountName:''}

                                            <div
                                              className="copy-icon-container "
                                              onClick={(e)=>copyReferralCode(virtualInfo?virtualInfo.accountName:'')}
                                        
                                            >
                                              <ContentCopyIcon className="text-light" />
                                              {copied && (
                                                <div className="copied-msg">Copied!</div>
                                              )}
                                            </div>
                                       </td>
                                       </tr>
                                    </tbody>
                                   
                                   
                                </table>
                                <small>Please fund this bank account to add funds in your virtual account wallet. You can use any payment app to add fund.</small>
                                <button className="btn btn-primary form-control share-button">Share Details</button>
                            </div>
                        </div>
                    </div>
                </div>
               
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FundRequestModel;


