import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import useSelect from "../../../Hook/user-select";
import Select from "react-select";
import CreateUtility from "./create";

  const Utility = ({isOpenUtility,setIsOpenUtility,isOpenMobile,setIsOpenMobile,setShowService}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isCountry1,setIsCountry1] = useState([]);
  const [countryOption, setCountryOption] = useState(null);
  const [categoryOption, setCategoryOption] = useState(null);
  const [isCategory,setIsCategory] = useState([]);
  const [categoryName,setCategoryName] = useState(null);
  const [pname,setPname] = useState(null);
  const [createOpen,setCreateOpen] = useState(false);
  const [countryISOCode,setCountryISOCode] = useState("");
  const [getBillerService,setGetBillerService] = useState([]);
  const [isLoader,setIsLoader] = useState(false);
  const [isMess,setIsMess] = useState(false);
  const [billerInfo,setBillerInfo] = useState([]);
  const [virtualAccountInfo, setVirtualAccountInfo] = useState("");

  let styles = useSelect();

  const [opList,setOpList] = useState([]);

  const goBack = () => {
    setIsOpenMobile(false)
    setShowService(true)
    setIsOpenUtility(false)
    
  };

  useEffect(() => {
    mobileCountry();
    allServices();  
    getVirtualBalance()
  }, []);

  const mobileCountry = async () => {
    const apicall = await AfterLoginService.allCountry(auth,2);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          let finalArr = [];

          let arr = [{ label: 'Select a country', value: '',disabled: true }]
          let arr1 = resp?.data.map((item) => ({
            label: (
              <div className="flex items-center gap-2">
                <p className="text-sm text-white !mb-0">{item?.name}</p>

              </div>
            ),
            value: item?.name,
            code: item?.isoName,
          }));
          finalArr = arr.concat(arr1);
          setCountryOption(finalArr);
        
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };
  const allServices = async () => {
    const apicall = await AfterLoginService.allCategory(auth,3);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
         console.log(resp.data)
         let finalArr = [];

          let arr = [{ label: 'Select a category', value: '',Searchable:true, disabled: true }]
          let arr1 = resp?.data.map((item) => ({
            label: (
              <div className="flex items-center gap-2">
                <p className="text-sm text-white !mb-0">{item?.categoryName}</p>

              </div>
            ),
            value: item?.categoryName,
          }));
          finalArr = arr.concat(arr1);
          setCategoryOption(finalArr);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const handleSelect = (e) => {
    setIsCountry1(e);
    setCountryISOCode(e?.code);
    var countryisocode = e?.code;
    filter(countryisocode,categoryName,pname)
  };

  const handleCategory = (e) => {
    setIsCategory(e);
    setCategoryName(e?.value);
    var categoryname = e?.value;
    filter(countryISOCode,categoryname,pname)
  };
 
  const filter = async (countryISOCode,categoryName,pname)=>{
    setIsLoader(true)
    let item = {type:categoryName,countryISOCode:countryISOCode,name:pname}
    const apicall = await AfterLoginService.getBiller(auth,item);
    if(apicall.state === true){
      apicall.result.json().then((resp)=>{
        setGetBillerService(resp.data.content)
        setIsLoader(false)
        setIsMess(true)
      })

    }else{
      apicall.result.json().then((resp)=>{})
      setIsLoader(false)

    }
  }
  const filter1 = async ()=>{
    filter(countryISOCode,categoryName,pname)
  }
  const viewTrans = async (item)=>{
      setIsOpenUtility(false);
      setCreateOpen(true)
      setBillerInfo(item)
  }
  const getVirtualBalance = async () => {
    const apicall = await AfterLoginService.getVirtualBalance(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setVirtualAccountInfo(resp.data)
          console.log(resp.data)
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };
  return (
    <div className="mobile-1">
        {isOpenUtility &&(
            <>
            <div className=" container-fluid pt-4 px-4 wallet-table dpDesign1">
            <div className="row px-4">
                <div className="col-md-2">
                    <div className="back-icon-main d-block">
                        <img src="../img/CaretLeft1.svg" onClick={goBack} />
                    </div>
                </div>
                <div className="col-md-10"></div>
                <div className="col-md-6 mt-2">
                    <div>
                        <h3 className="heading-transaction mb-0">Utility & Bills Pay</h3>
                    </div>    
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-3 col-12 country-pr-0">
                           <Select
                            placeholder="Select country"
                            onChange={(e) => handleSelect(e)}
                            name="currencyCode"
                            options={countryOption}
                            value={isCountry1}
                            styles={styles}
                          /> 
                        </div>
                        <div className="col-md-3 col-12 country-pr-0">
                           <Select
                            placeholder="Select category"
                            onChange={(e) => handleCategory(e)}
                            name="currencyCode"
                            options={categoryOption}
                            value={isCategory}
                            styles={styles}
                          /> 
                        </div>
                        <div className="col-md-3 col-12 country-pr-0">
                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Search Billers Name"  
                          value={pname}
                          onChange={(e)=>setPname(e.target.value)}   />
                            
                        </div>
                        <div className="col-md-1 col-12 searchicon1" onClick={filter1}>
                            <div className="searchbar-1">
                              <img src="/img/search.png" className="search_icon" />
                            </div>
                        </div>    
                    </div>
                    <div className="row pt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="wallet-scroll">
                            <p className="d-show-mobile" style={{color:"#fff"}}>Billers</p>
                            <table class="table table-dark table-border transaction_table_head">
                              <thead className="d-none-mobile">
                                <tr className="fs-13">
                                  <th colSpan="2">NAME</th>
                                  <th>TYPE</th>
                                  <th>SERVICE TYPE / COUNTRY NAME</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody className="trn-1">
                                {isLoader?(<>
                                  <tr>
                                     <td colSpan="5" className="text-center"> 
                                      <span
                                        className="text-white spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>{" "}
                                      <span className="text-white">Loading...</span></td>
                                  </tr>
                                </>):(<>
                                  {
                                  getBillerService.length>0?(<>
                                  {
                                    getBillerService.map((item,index)=>(
                                    <>
                                      <tr onClick={()=>viewTrans(item)} className="d-none-mobile">
                                        <td>
                                            &nbsp;
                                            {
                                              item.type == 'ELECTRICITY_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/bolt.svg"/>
                                              </>):("")
                                            }
                                            {
                                              item.type == 'WATER_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/Waterdrop.svg"/>
                                              </>):("")
                                            }
                                            {
                                              item.type == 'TV_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/TVbill.svg"/>
                                              </>):("")
                                            }
                                             {
                                              item.type == 'INTERNET_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/internetbill.svg"/>
                                              </>):("")
                                            }
                                            
                                            &nbsp;
                                            &nbsp;
                                           
                                        </td>
                                        <td> <p>{item.name}</p></td>
                                        <td><p>{item.type}</p></td>
                                        <td className="text-center"><p>{item.serviceType}&nbsp;{item.countryName}</p></td>
                                        <td><p>Min {item.internationalTransactionCurrencyCode}{item.minInternationalTransactionAmount} Max {item.internationalTransactionCurrencyCode} {item.maxInternationalTransactionAmount}</p></td>
                                      </tr>
                                      <tr onClick={()=>viewTrans(item)} className="d-show-mobile">
                                        <td className="td-mobile">
                                            <div>
                                            &nbsp;
                                            {
                                              item.type == 'ELECTRICITY_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/bolt.svg"/>
                                              </>):("")
                                            }
                                            {
                                              item.type == 'WATER_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/Waterdrop.svg"/>
                                              </>):("")
                                            }
                                            {
                                              item.type == 'TV_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/TVbill.svg"/>
                                              </>):("")
                                            }
                                             {
                                              item.type == 'INTERNET_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/internetbill.svg"/>
                                              </>):("")
                                            }
                                            &nbsp;
                                            &nbsp;
                                            </div>
                                            <div>
                                            <p>{item.name}</p><br/>
                                            <p>{item.type}</p><br/>
                                            <p>{item.serviceType}&nbsp;{item.countryName}</p><br/>
                                            <p>Min {item.localTransactionCurrencyCode}{item.minInternationalTransactionAmount} Max {item.internationalTransactionCurrencyCode} {item.maxInternationalTransactionAmount}</p>
                                            </div>
                                        </td>
                                       
                                      </tr>
                                    </>
                                    ))
                                  }
                                  </>):(<>
                                    <tr className="trn-22">
                                      <td colSpan={5} className="text-center">
                                        <img src="../../img/EmptyState.svg" />
                                        <br/>
                                        {isMess?(<>
                                          <p>No billers found</p>
                                        </>):(<>
                                          <p>Select country & Bill Type to fetch billers list</p>
                                        </>)}
                                       
                                      </td>
                                    </tr>
                                   
                                  </>)
                                }
                                </>)}
                                <tr>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </>
        )}  
        <CreateUtility createOpen={createOpen} setCreateOpen={setCreateOpen} setIsOpenUtility={setIsOpenUtility} billerInfo={billerInfo} virtualAccountInfo={virtualAccountInfo}/>
    </div>
  );
};

export default Utility;
