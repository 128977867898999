import React, { useState, useEffect } from "react";
import "./referral.scss";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Referralmodal from "./referralmodal";
import Referralsuccess from "./referralsuccess";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { useNavigate } from "react-router";

function Referralwallet() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [referralTransaction, setReferralTransaction] = useState([]);
  const [refferWallet, setRefferWallet] = useState("");
  const [gettingWallet, setGettingWallet] = useState(false);
  const [lowMsg, setLowMsg] = useState(false);
  /*******************Get Current Date */
  const today = new Date();
  const date = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = monthNames[today.getMonth()];
  const day = weekday[today.getDay()];
  const currentDate = day + " , " + month + "  " + date + "th";
  /**************************End *******************/

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      navigate("/");
    }
    setGettingWallet(true);
    getRefferWallet();
    getRefferTransaction();
  }, []);

  const getRefferWallet = async () => {
    const apicall = await AfterLoginService.GetRefferWallet(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setRefferWallet(resp);
          setLowMsg(true);
          setGettingWallet(false);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const getRefferTransaction = async () => {
    const apicall = await AfterLoginService.GetRefferTransaction(auth);
    console.log(apicall);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setReferralTransaction(resp.data);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const handle = () => {
    getRefferWallet();
    getRefferTransaction();
  };
  // for  status button
  function getButtonColor(status) {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "button-pending";
      case "DECLINED":
        return "button-declined";
      case "APPROVED":
        return "button-approved";
      case "PROCESSING":
        return "button-processing";
      case "COMPLETED":
        return "button-completed";
      default:
        return "";
    }
  }

  // Copy Paste

  const [copied, setCopied] = useState(false);

  const copyReferralCode = () => {
    const referralCode = auth ? auth.user.referral_code : "";
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy referral code: ", error);
      });
  };

  const goBack = () => navigate(-1);
  return (
    <>
      <div className="container-fluid position-relative d-flex p-0 crypto-container">
        <Header />
        <div className="content">
          <Subheader />
          <div className="container-fluid pt-4 px-4 referral-container ">
            <div className="row">
              {gettingWallet ? (
                <>
                  <div className="col-md-12 text-center ">
                    <span
                      className="text-white spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span className="text-white">Loading...</span>
                  </div>
                </>
              ) : (
                <div className="col-md-12 col-lg-12">
                   <div className="back-icon-main">
                   <img src="/img/back.svg" onClick={goBack}  />
            </div>
                  <h3 className="textWallet">Referral Wallet</h3>
                  <p className="textLorem">
                    Start referring to pile up your earnings
                  </p>
                  <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                      <div className="col-md-12 gx-5 wallet-header">
                        <div className="ms-3 text-white mb-3">
                          <p className="mb-2 d-flex align-items-center overallb">
                            <img
                              src="img/overallicon.svg"
                              alt=""
                              className="p-1"
                            />
                            <span className=""> Referral Balance</span>
                          </p>
                          <h6
                            className="mb-2 h1 referral-copy"
                            style={{ fontSize: "40px" }}
                          >
                            <span className="dollor">₦</span>
                            {refferWallet.wallet ? refferWallet.wallet : 0}
                          </h6>
                          <div className="request-modal-btn">
                            <Referralmodal
                              balance={refferWallet}
                              handle={handle}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row   copy-paste-header">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12"> 
                    <div className=" referral-modal-btn1">
                    <div className=" mb-3 text-center  text-light">
                        <p>Referral Code</p>
                        {/* <h5>
                          {auth ? auth.user.referral_code : ""}{" "}
                          <ContentCopyIcon className="copy-icon" />
                        </h5> */}

                        {/* <h5>
                          {auth ? auth.user.referral_code : ""}{" "}
                          <div
                            className="copy-icon-container"
                            onClick={copyReferralCode}
                          >
                            <ContentCopyIcon className="copy-icon" />
                            {copied && (
                              <div className="copied-msg">Copied!</div>
                            )}
                          </div>
                        </h5>
                      </div>
                        </div>
                    </div>  */}

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="referral-modal-btn1 mx-4">
                            <div className="mb-3 text-center text-light ">
                              <p className="ml-2">Referral Code</p>
                              {/* <h5 className="ml-2">
                                {auth ? auth.user.referral_code : ""}
                                <div
                                  className="copy-icon-container"
                                  onClick={copyReferralCode}
                                >
                                  <ContentCopyIcon className="copy-icon paste-icon" />
                                  {copied && (
                                    <div className="copied-msg">Copied!</div>
                                  )}
                                </div>
                              </h5> */}
                              <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                                  <h5 className="ml-2 refferal-code-address">
                                    {auth ? auth.user.referral_code : ""}
                                  </h5>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                  <div
                                    className="sm-2"
                                    onClick={copyReferralCode}
                                  >
                                    <ContentCopyIcon className="referral-copy-icon" />
                                    {copied && (
                                      <p
                                        className="text-light "
                                        style={{ textAlign: "right" }}
                                      >
                                        Copied!
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-3"> </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-5 col-md-7 col-sm-12 col-12">
                    {lowMsg?(<>
                      {refferWallet.wallet < 1000 ? (
                        // <button className="btn btn-request">
                        <div className="referral-pops-msg">
                          <p>
                            <img
                              src="/img/info-circle.svg"
                              className="cirle-info"
                            />
                            <span className=" referral-pops-msg-p">
                              Your balance is too low to initiate a withdrawal
                              process
                            </span>
                            {/* </button> */}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                     </>):(<></>)}
                    </div>
                    <div className="col-lg-7 col-md-5 col-sm-12 col-12"></div>
                  </div>
                  <div className="row transaction-header mt-2 pt-3">
                    <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                      <div className="heading-header">
                        <h5 className="mb-0 text-light"> Transaction</h5>
                        <span className="mb-0 small">{`${currentDate}`}</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3"> </div>
                  </div>

                  <div className="row bgtable">
                    <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                      <div className="refrral-scroll">
                        <table class="table table-dark table-border ">
                          <thead>
                            <tr className="fs-13">
                              <th>DETAILS</th>
                              <th className="referral-hide"></th>
                              <th className="d-none d-sm-table-cell">TYPE</th>
                              <th>STATUS</th>
                              <th className="d-none d-sm-table-cell">DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {referralTransaction.length ? (
                              referralTransaction.map((item) => (
                                <tr>
                                  <td className="d-flex align-items-center">
                                    <p>
                                      {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
                                    </p>
                                    <img src="/img/dashbordlist.svg" alt="" />
                                    <p className="fs-12 ms-2">
                                      <span className="d-block fs-10 referral-wallet-list-header">
                                        Referral wallet of
                                        <span className="fs-13 referral-wallet-amount">
                                          {" "}
                                          ₦{item.amount}
                                        </span>
                                      </span>
                                      <p className="fs-12 referral-mobile-date">{item.timestamp}</p>
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-12 referral-Wallet-withdrawal">{item.type}</p>
                                  </td>
                                  {/* <td>
                                    <a href="" className="complete-btn">
                                      {item.status}
                                    </a>
                                  </td> */}

                                  <td className="referral-mobile-btn">
                                    <a
                                      className={`complete-btn ${getButtonColor(
                                        item?.status
                                      )}`}
                                    >
                                      {/* <span className="dot">.</span>{" "} */}
                                      {item?.status.toUpperCase()}
                                    </a>
                                  </td>
                                  <td className="referral-Wallet-date">
                                    <p className="fs-12">{item.timestamp}</p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={4}>
                                    {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                                    <div>
                                      <img
                                        src="img/EmptyState.svg"
                                        alt=""
                                        className="referral-empty"
                                      />
                                      <h4 className="not-transction-referral">
                                        Transaction
                                      </h4>

                                      <h5 className="not-transction-referra-p text-center">
                                        You don't have any pending or completed
                                        transction.
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12"> </div>
                  </div>
                  <Stack spacing={2} className="d-none">
                    <Pagination
                      count={10}
                      variant="outlined"
                      color="secondary"
                    />
                  </Stack>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Referralwallet;

// import React, { useState, useEffect } from "react";
// import "./referral.scss";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import Referralmodal from "./referralmodal";
// import Referralsuccess from "./referralsuccess";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import { useNavigate } from "react-router";

// function Referralwallet() {
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const navigate = useNavigate();
//   const [referralTransaction, setReferralTransaction] = useState([]);
//   const [refferWallet, setRefferWallet] = useState("");
//   const [gettingWallet, setGettingWallet] = useState(false);

//   useEffect(() => {
//     if (!localStorage.getItem("user")) {
//       navigate("/");
//     }
//     setGettingWallet(true);
//     getRefferWallet();
//     getRefferTransaction();
//   }, []);

//   const getRefferWallet = async () => {
//     const apicall = await AfterLoginService.GetRefferWallet(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setRefferWallet(resp);
//           setGettingWallet(false);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   const getRefferTransaction = async () => {
//     const apicall = await AfterLoginService.GetRefferTransaction(auth);
//     console.log(apicall);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setReferralTransaction(resp.data);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   // for  status button
//   function getButtonColor(status) {
//     switch (status.toUpperCase()) {
//       case "PENDING":
//         return "button-pending";
//       case "DECLINED":
//         return "button-declined";
//       case "APPROVED":
//         return "button-approved";
//       case "PROCESSING":
//         return "button-processing";
//       default:
//         return "";
//     }
//   }

//   // Copy Paste

//   const [copied, setCopied] = useState(false);

//   const copyReferralCode = () => {
//     const referralCode = auth ? auth.user.referral_code : "";
//     navigator.clipboard
//       .writeText(referralCode)
//       .then(() => {
//         setCopied(true);
//         setTimeout(() => {
//           setCopied(false);
//         }, 2000);
//       })
//       .catch((error) => {
//         console.error("Failed to copy referral code: ", error);
//       });
//   };
//   return (
//     <>
//       <div className="container-fluid position-relative d-flex p-0 crypto-container">
//         <Header />
//         <div className="content">
//           <Subheader />
//           <div className="container-fluid pt-4 px-4 referral-container ">
//             <div className="row">
//               {gettingWallet ? (
//                 <>
//                   <div className="col-md-12 text-center m-4">
//                     <span
//                       className="text-white spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>{" "}
//                     <span className="text-white">Loading...</span>
//                   </div>
//                 </>
//               ) : (
//                 <div className="col-md-12 col-lg-12">
//                   <h3 className="textWallet">Referral Wallet</h3>
//                 <p className="textLorem">
//                   Lorem ipsum dolor sit amet consectetur.
//                 </p>
//                   <div className="row">
//                     <div className="col-lg-9 col-md-12 col-sm-12 col-12">
//                       <div className="col-md-12 gx-5 wallet-header">
//                         <div className="ms-3 text-white mb-3">
//                           <p className="mb-2 d-flex align-items-center overallb">
//                             <img src="img/wallet.png" />
//                             <span className=""> Referral Balance</span>
//                           </p>
//                           <h6 className="mb-2 h1 referral-copy" style={{fontSize:'40px'}}>
//                             <span className="dollor">$</span>
//                             {refferWallet.wallet ? refferWallet.wallet : 0}
//                           </h6>
//                         </div>
//                         <div className=" referral-modal-btn">
//                           <Referralmodal Referralmodal={refferWallet} />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-3"> </div>
//                   </div>

//                   <div className="row ">
//                     <div className="col-lg-9 col-md-12 col-sm-12 col-12 col-xs-12">
//                       <div className="copy-code mb-3 text-center wallet-header text-light">
//                         <p>Referral Code</p>
//                         {/* <h5>
//                           {auth ? auth.user.referral_code : ""}{" "}
//                           <ContentCopyIcon className="copy-icon" />
//                         </h5> */}

//                         <h5>
//                           {auth ? auth.user.referral_code : ""}{" "}
//                           <div
//                             className="copy-icon-container"
//                             onClick={copyReferralCode}
//                           >
//                             <ContentCopyIcon className="copy-icon" />
//                             {copied && (
//                               <div className="copied-msg">Copied!</div>
//                             )}
//                           </div>
//                         </h5>
//                       </div>
//                     </div>
//                     <div className="col-lg-3"> </div>
//                   </div>

//                   <div className="row ">
//                     <div className="col-lg-9 col-md-9 col-sm-12 col-12">
//                       <div className="heading-header">
//                         <h5 className="mb-0 text-light"> Transaction</h5>
//                         <span className="mb-0 small">Monday, October 17th</span>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-3"> </div>
//                   </div>

//                   <div className="row bgtable">
//                     <div className="col-lg-9 col-md-9 col-sm-12 col-12">
//                       <div className="refrral-scroll">
//                         <table class="table table-dark table-border">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th>TYPE</th>
//                               <th>STATUS</th>
//                               <th>DATE</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {referralTransaction.length ? (
//                               referralTransaction.map((item) => (
//                                 <tr>
//                                   <td className="d-flex align-items-center">
//                                     <p>
//                                       <i class="bi bi-arrow-down-left-circle-fill"></i>
//                                     </p>
//                                     <p className="fs-12 ms-2">
//                                       <span className="d-block fs-10">
//                                         Referral wallet of
//                                         <span className="fs-13">
//                                           {" "}
//                                           {item.amount}
//                                         </span>
//                                       </span>
//                                     </p>
//                                   </td>
//                                   <td>
//                                     <p className="fs-12">{item.type}</p>
//                                   </td>
//                                   {/* <td>
//                                     <a href="" className="complete-btn">
//                                       {item.status}
//                                     </a>
//                                   </td> */}

//                                   <td>
//                                     <a
//                                       href=""
//                                       className={`complete-btn ${getButtonColor(
//                                         item?.status
//                                       )}`}
//                                     >
//                                       {item?.status}
//                                     </a>
//                                   </td>
//                                   <td className="">
//                                     <p className="fs-12">{item.timestamp}</p>
//                                   </td>
//                                 </tr>
//                               ))
//                             ) : (
//                               <>
//                                 <tr>
//                                   <td colSpan={4}>
//                                     {/* {" "}
//                                     <h6 className="text-center w-100">
//                                       Data not found
//                                     </h6> */}

//                                     <div>
//                                       <img
//                                         src="img/EmptyState.png"
//                                         alt=""
//                                         style={{
//                                           margin: "auto",
//                                           marginTop: "60px",
//                                           height: "204px",
//                                         }}
//                                       />
//                                       <h4 className="not-transction-wallat">
//                                         Transaction
//                                       </h4>

//                                       <h5 className="not-transction-wallet text-center">
//                                         You don't have any pending or completed
//                                         transction.
//                                       </h5>
//                                     </div>
//                                   </td>
//                                 </tr>
//                               </>
//                             )}
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-3 col-sm-12 col-12"> </div>
//                   </div>
//                   <Stack spacing={2} className="d-none">
//                     <Pagination
//                       count={10}
//                       variant="outlined"
//                       color="secondary"
//                     />
//                   </Stack>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Referralwallet;

// ok code

// import React, { useState, useEffect } from "react";
// import "./referral.scss";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import Referralmodal from "./referralmodal";
// import Referralsuccess from "./referralsuccess";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import { useNavigate } from "react-router";

// function Referralwallet() {
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const navigate = useNavigate();
//   const [referralTransaction, setReferralTransaction] = useState([]);
//   const [refferWallet, setRefferWallet] = useState("");
//   const [gettingWallet, setGettingWallet] = useState(false);

//   useEffect(() => {
//     if (!localStorage.getItem("user")) {
//       navigate("/");
//     }
//     setGettingWallet(true);
//     getRefferWallet();
//     getRefferTransaction();
//   }, []);

//   const getRefferWallet = async () => {
//     const apicall = await AfterLoginService.GetRefferWallet(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setRefferWallet(resp);
//           setGettingWallet(false);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   const getRefferTransaction = async () => {
//     const apicall = await AfterLoginService.GetRefferTransaction(auth);
//     console.log(apicall);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setReferralTransaction(resp.data);
//         } else {
//           console.log(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   // for  status button
//   function getButtonColor(status) {
//     switch (status.toUpperCase()) {
//       case "PENDING":
//         return "button-pending";
//       case "DECLINED":
//         return "button-declined";
//       case "APPROVED":
//         return "button-approved";
//       case "PROCESSING":
//         return "button-processing";
//       default:
//         return "";
//     }
//   }

//   return (
//     <>
//       <div className="container-fluid position-relative d-flex p-0 crypto-container">
//         <Header />
//         <div className="content">
//           <Subheader />
//           <div className="container-fluid pt-4 px-4 referral-container ">
//             <div className="row">
//               {gettingWallet ? (
//                 <>
//                   <div className="col-md-12 text-center m-4">
//                     <span
//                       className="text-white spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>{" "}
//                     <span className="text-white">Loading...</span>
//                   </div>
//                 </>
//               ) : (
//                 <div className="col-md-12 col-lg-12">
//                   <div className="row">
//                     <div className="col-lg-9 col-md-12 col-sm-12 col-12">
//                       <div className="col-md-12 gx-5 wallet-header">
//                         <div className="ms-3 text-white mb-3">
//                           <p className="mb-2 d-flex align-items-center overallb">
//                             <img src="img/wallet.png" />
//                             <span className="ms-3"> Referral Balance</span>
//                           </p>
//                           <h6 className="mb-2 h1">
//                             <span className="dollor">$</span>
//                             {refferWallet.wallet ? refferWallet.wallet : 0}
//                           </h6>
//                         </div>
//                         <div className="mt-5">
//                           <Referralmodal Referralmodal={refferWallet} />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-3"> </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-lg-9 col-md-12 col-sm-12 col-12 col-xs-12">
//                       <div className="copy-code mb-3 text-center wallet-header text-light">
//                         <p>Referral Code</p>
//                         <h5>
//                           {auth ? auth.user.referral_code : ""}{" "}
//                           <ContentCopyIcon className="copy-icon" />
//                         </h5>
//                       </div>
//                     </div>
//                     <div className="col-lg-3"> </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-lg-9 col-md-9 col-sm-12 col-12">
//                       <div className="heading-header">
//                         <h3 style={{ fontSize: "23px" }}>
//                           Transaction
//                           <span className="d-block">Monday, October 17th</span>
//                         </h3>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-3"> </div>
//                   </div>

//                   <div className="row bgtable">
//                     <div className="col-lg-9 col-md-9 col-sm-12 col-12">
//                       <div className="refrral-scroll">
//                         <table class="table table-dark table-border">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th>TYPE</th>
//                               <th>STATUS</th>
//                               <th>DATE</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {referralTransaction.length ? (
//                               referralTransaction.map((item) => (
//                                 <tr>
//                                   <td className="d-flex align-items-center">
//                                     <p>
//                                       <i class="bi bi-arrow-down-left-circle-fill"></i>
//                                     </p>
//                                     <p className="fs-12 ms-2">
//                                       <span className="d-block fs-10">
//                                         Referral wallet of
//                                         <span className="fs-13">
//                                           {" "}
//                                           {item.amount}
//                                         </span>
//                                       </span>
//                                     </p>
//                                   </td>
//                                   <td>
//                                     <p className="fs-12">{item.type}</p>
//                                   </td>
//                                   {/* <td>
//                                     <a href="" className="complete-btn">
//                                       {item.status}
//                                     </a>
//                                   </td> */}

//                                   <td>
//                                     <a
//                                       href=""
//                                       className={`complete-btn ${getButtonColor(
//                                         item?.status
//                                       )}`}
//                                     >
//                                       {item?.status}
//                                     </a>
//                                   </td>
//                                   <td className="">
//                                     <p className="fs-12">{item.timestamp}</p>
//                                   </td>
//                                 </tr>
//                               ))
//                             ) : (
//                               <>
//                                 <tr>
//                                   <td colSpan={4}>
//                                     {/* {" "}
//                                     <h6 className="text-center w-100">
//                                       Data not found
//                                     </h6> */}

//                                     <div>
//                                       <img
//                                         src="img/EmptyState.png"
//                                         alt=""
//                                         style={{
//                                           margin: "auto",
//                                           marginTop: "60px",
//                                           height: "204px",
//                                         }}
//                                       />
//                                       <h4 className="not-transction-wallat">
//                                         Transaction
//                                       </h4>

//                                       <h5 className="not-transction-wallet text-center">
//                                         You don't have any pending or completed
//                                         transction.
//                                       </h5>
//                                     </div>
//                                   </td>
//                                 </tr>
//                               </>
//                             )}
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-3 col-sm-12 col-12"> </div>
//                   </div>
//                   <Stack spacing={2} className="d-none">
//                     <Pagination
//                       count={10}
//                       variant="outlined"
//                       color="secondary"
//                     />
//                   </Stack>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Referralwallet;
