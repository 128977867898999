const QxchangeService = {
  FromCurrencies: () => {
    return fetch(
      `https://api.changenow.io/v1/currencies?active=true&fixedRate=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  ToCurrencies: (ticker) => {
    return fetch(
      `https://api.changenow.io/v1/currencies-to/${ticker}?fixedRate=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetMinExchangeAmount: (from_to) => {
    return fetch(
      `https://api.changenow.io/v1/min-amount/${from_to}?api_key=b0a2f25ac0b212c25b66812032726fbd5961b3a2248ee2cb31921bc2e6cc298c`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  EstimatedExchangeAmount: (from_to, send_amount) => {
    if (from_to && send_amount)
      return fetch(
        `https://api.changenow.io/v1/exchange-amount/${send_amount}/${from_to}/?api_key=b0a2f25ac0b212c25b66812032726fbd5961b3a2248ee2cb31921bc2e6cc298c`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then(async (result) => {
          if (result.status === 200) {
            return { result: await result.json(), state: true };
          } else {
            return { result: (await result.json()) || false, state: false };
          }
        })
        .catch((err) => {
          console.log(err);
        });
  },
  CreateExchangeTransaction: (body) => {
    return fetch(
      `https://api.changenow.io/v1/transactions/b0a2f25ac0b212c25b66812032726fbd5961b3a2248ee2cb31921bc2e6cc298c`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  SaveQxchangeTransaction: (
    auth,
    refundAddress,
    toCurrency,
    fromCurrency,
    payoutAddress,
    payInAddress,
    uId,
    toAmount,
    fromAmount
  ) => {
    return fetch(
      `https://secureapi.thequchangeapp.xyz/api/auth/save-qxchange-transaction?refundAddress=${refundAddress}&fromAmount=${fromAmount}&toAmount=${toAmount}&from=${fromCurrency}&payOutAddress=${payoutAddress}&u_id=${uId}&to=${toCurrency}&payInAddress=${payInAddress}&platform=web`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + auth.api_access_token,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  GetQxchangeTransactions: (auth) => {
    return fetch(
      `https://secureapi.thequchangeapp.xyz/api/auth/get-qxchange-transaction`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + auth.api_access_token,
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  TransactionStatus: (uId) => {
    return fetch(
      `https://api.changenow.io/v1/transactions/${uId}/b0a2f25ac0b212c25b66812032726fbd5961b3a2248ee2cb31921bc2e6cc298c`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(async (result) => {
        if (result.status === 200) {
          return { result: await result.json(), state: true };
        } else {
          return { result: (await result.json()) || false, state: false };
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default QxchangeService;