import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

 
const ViewRedeemModel = ({isOpenViewRedeem,setIsOpenViewRedeem,gdetails}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [redeemCode,setRedeemCode] = useState([])
  const [loading,setLoading] = useState(false)
  const [copied, setCopied] = useState(false);

  const goBack = () => {
    setIsOpenViewRedeem(false)
  };
  const closeFirstModal = () =>{
    setIsOpenViewRedeem(false)
  }
  const handleRedeemModel = async ()=>{
    setLoading(true)
    const apicall = await AfterLoginService.showRedeemCode(auth,gdetails?.transactionId);
    if(apicall.state === true){
        apicall.result.json().then((resp)=>{
            setLoading(false)
            console.log(resp.data)
            setRedeemCode(resp.data)
            setIsOpenViewRedeem(true)
            setLoading(false)
        })
    }else{
        apicall.result.json().then((resp)=>{
            setLoading(false)
        }) 
    }
  }

  const copyReferralCode = (item) => {
    const referralCode = item;
       navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy referral code: ", error);
      });
  };
  return (
<div>
                <div className="col-md-6 mb-4">
                    <button className="buy_now form-control"
                     disabled={`${
                        loading ? "disabled" : ""
                      }`}
                    onClick={handleRedeemModel}>
                    {loading ? (
                                                    <>
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>{" "}
                                                      Loading...
                                                    </>
                                                  ) : (
                                                    " View Redeem Code(S)"
                                                  )} 
                       </button>
                </div>
                {isOpenViewRedeem &&(
                    <>
                        <div className="modal gift-success !z-[1027]">
                                <div className="modal-content">
                                <div className="modal">
                                    <div className="modal-dialog">
                                    <div className="modal-content">
                                        
                                        <div className="modal-header ">
                                            <h4>View Redeem Codes</h4>
                                            <button type="button" className="close" onClick={closeFirstModal}>
                                            <img src='img/close-icon.png'/>
                                            </button>
                                        </div>
                                        <div className="modal-body text-white text-center ">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <table className="table confT">
                                                        <tbody>
                                                        <tr>
                                                            <td>Gift Card Name</td>
                                                            <td className="tright">{gdetails?.productName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Quantity</td>
                                                            <td className="tright">{gdetails?.quantity}</td>
                                                        </tr>
                                                        {redeemCode.map((item,index)=>(<>
                                                            <tr>
                                                                <td>Card Number</td>
                                                                <td className="tright">
                                                                    {item.cardNumber}
                                                                    <div
                                                                        className="copy-icon-container "
                                                                        onClick={(e)=>copyReferralCode(item.cardNumber)}
                                                                    
                                                                        >
                                                                        <ContentCopyIcon className="text-light" />
                                                                        {copied && (
                                                                            <div className="copied-msg">Copied!</div>
                                                                        )}
                                                                        </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pincode</td>
                                                                <td className="tright">
                                                                {item.pinCode}
                                                                <div
                                                                    className="copy-icon-container "
                                                                    onClick={(e)=>copyReferralCode(item.pinCode)}
                                                                
                                                                    >
                                                                    <ContentCopyIcon className="text-light" />
                                                                    {copied && (
                                                                        <div className="copied-msg">Copied!</div>
                                                                    )}
                                                                    </div>
                                                            </td>
                                                        </tr>
                                                        </>))}
                                                        
                                                       
                                                        
                                                        </tbody>
                                                    </table>
                                                   
                                                </div>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                    </>
                )}
</div>
  );
};
 
export default ViewRedeemModel;