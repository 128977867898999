import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Createsuccess from "./createsuccess";
import FundRequestModel from "./fundrequestmodel";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import { useNavigate } from "react-router";

const Transaction = ({virtualInfo}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [virtualHis, setVirtualHis] = useState([]);
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
    getVirtualPassbook1();
  }, []);
  const getVirtualPassbook1 = async () => {
    const apicall = await AfterLoginService.getVirtualPassbook(auth);
    if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          setVirtualHis(resp.data)
       
      });
    } else {
    }
  };
  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DEBIT":
          return "button-declined";
        case "CREDIT":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }
  const btnColor = (item)=>{
    if(item == 'debit'){
        return {
            backgroundColor: "rgb(0, 151, 101)",
            textAlign: "center",
            borderRadius: "50px",
            textTransform: "uppercase",
            fontSize: "14px",
            padding: "4px"
          };
    }else{
       
            return {
                backgroundColor: "rgb(225, 106, 128)",
                textAlign: "center",
                borderRadius: "50px",
                textTransform: "uppercase",
                fontSize: "14px",
                padding: "4px"
              };
        
    }
  }
  return (
    <div>
        <div className="row">
            <div className="col-md-12">
                <div>
                    <h3 className="heading-transaction mb-0">Virtual Account</h3>
                </div>
                    <div className="row virtual-bvn">
                        <div className="col-md-10">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                       
                                        <div className="row">
                                            <div className="col-lg-9 col-md-12 col-sm-12 col-12 tran-m">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="ms-3 text-white mb-3">
                                                            <p className="mb-2 d-flex align-items-center overallb">
                                                                <img
                                                                src="img/overallicon.svg"
                                                                alt=""
                                                                className="p-1"
                                                                />
                                                                <span className="">Total Wallet Balance</span>
                                                            </p>
                                                            <h6
                                                                className="mb-2 h1 referral-copy"
                                                                style={{ fontSize: "40px" }}
                                                            >
                                                                <span className="dollor">₦ {virtualInfo?virtualInfo.walletBalance:0}</span>
                                                            
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-4 add_fund1">
                                                        <FundRequestModel virtualInfo={virtualInfo} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3"> </div>
                                            <div className="col-md-10">
                                                <div className="row bgtable">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="refrral-scroll">
                                                        <table class="table table-dark table-border transaction_table_head">
                                                        <thead className="d-none-mobile">
                                                            <tr className="fs-13">
                                                            <th>SERVICE TYPE NAME</th>
                                                            <th className="d-none d-sm-table-cell">TYPE</th>
                                                            <th>AMOUNT</th>
                                                            <th className="d-none d-sm-table-cell">DATE</th>
                                                            </tr>
                                                        </thead>
                                                       
                                                        <tbody className="trn-1">
                                                            {
                                                                virtualHis.length>0?(<>
                                                                {
                                                                    virtualHis.map((item)=>(<>
                                                                    <tr className="d-none-mobile">
                                                                    <td>
                                                                    &nbsp;
                                                                    &nbsp;
                                                                        {item.serviceTypeName == 'Buy Giftcard' && (<>
                                                                            <img src="../img/utility/giftcard.svg" />
                                                                        </>)}

                                                                        {item.serviceTypeName == 'Utility Bill Payment' && (<>
                                                                            <img src="../img/utility/bolt.svg" />
                                                                        </>)}

                                                                        {item.serviceTypeName == 'Mobile TopUp' && (<>
                                                                            <img src="../img/utility/moblerecharge.svg" />
                                                                        </>)}
                                                                       
                                                                        &nbsp;
                                                                        &nbsp;
                                                                        {item.serviceTypeName}
                                                                    </td>
                                                                    <td>
                                                                    
                                                                    <p className={`complete-btn btnS ${getButtonColor(
                                                                        item?.transactiontype
                                                                    )}`}>
                                                                            {item.transactiontype}
                                                                        </p>
                                                                    </td>
                                                                    <td>NGN {item.amount}</td>
                                                                    <td> {item.created_at}</td>
                                                                    </tr>

                                                                    <tr className="d-show-mobile">
                                                                    <td className="td-mobile">
                                                                        <div className="mb-a-1">
                                                                            <div className="sc-1">
                                                                            {item.serviceTypeName == 'Buy Giftcard' && (<>
                                                                            <img src="../img/utility/giftcard.svg" />
                                                                                </>)}

                                                                                {item.serviceTypeName == 'Utility Bill Payment' && (<>
                                                                                    <img src="../img/utility/bolt.svg" />
                                                                                </>)}

                                                                                {item.serviceTypeName == 'Mobile TopUp' && (<>
                                                                                    <img src="../img/utility/moblerecharge.svg" />
                                                                                </>)}
                                                                            </div>
                                                                            <div className="sc-2">
                                                                                <p><span className="mobile-gift">{item.serviceTypeName}
                                                                                    </span><br/>
                                                                                NGN {item.amount}<br/>
                                                                               <span className="mobile-gift">
                                                                               {item.created_at}
                                                                                </span> 
                                                                                </p>
                                                                              
                                                                                
                                                                            </div>
                                                                            
                                                                            <div className="mb-success sc-3">
                                                                                <p className={`complete-btn btnS ${getButtonColor(
                                                                                item?.transactiontype
                                                                                )}`}>
                                                                                {item.transactiontype}
                                                                                </p><br/>
                                                                            </div>
                                                                        </div>
                                                                       
                                                                    </td>
                                                                   
                                                                    
                                                                    </tr>
                                                                    </>))
                                                                }
                                                                </>):(<></>)
                                                            }
                                                           
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>     
                    </div>
            </div>
        </div>
    </div>
  );
};

export default Transaction;
