import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
 
const UtilityTransactionDetails = ({isOpenUTran,setIsOpenUTran,gdetails,setIsOpenGTran}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const goBack = () => {
    setIsOpenUTran(false)
    setIsOpenGTran(true)
  };
  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "SUCCESSFUL":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }
  return (
            <div>
                    {isOpenUTran &&(
                        <>
                        <div className="row pt-4">
                            <div className="back-icon-main d-block">
                                <img src="../img/CaretLeft1.svg" onClick={goBack} />
                            </div>
                            <div className="col-md-12">
                                <div>
                                    <h3 className="heading-transaction mb-0">Transaction Details</h3>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="det-1">
                                    <p className={`complete-btn d-inline ${getButtonColor(
                                        gdetails?.status
                                                )}`}>
                                        {gdetails.status}
                                    </p>
                                    <p style={{color:"#939394",marginTop:"5px"}}>Utility Transaction Details</p>
                                </div>
                                <table className="table confT">
                                    <tbody>
                                        <tr>
                                            <td>Transaction Id</td>
                                            <td className="tright">#{gdetails?gdetails.transactionId:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Biller Name</td>
                                            <td className="tright">{gdetails?gdetails.billerName:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Bill Type</td>
                                            <td className="tright">{gdetails?gdetails.billerType:''} </td>
                                        </tr>
                                        <tr>
                                            <td>Subscriber Account No.</td>
                                            <td className="tright">{gdetails?gdetails.subscriberAccountNumber:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Date & Time</td>
                                            <td className="tright">{gdetails?gdetails.created_at1:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Bill Amount</td>
                                            <td className="tright">NGN {gdetails?gdetails.amount:''}
                                            &nbsp;
                                           ({gdetails?gdetails.deliveryAmountCurrencyCode:''} {gdetails?gdetails.deliveryAmount:''})
                                            </td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Total Fee</td>
                                            <td className="tright">NGN {gdetails?gdetails.transactionFee:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Deducted Amount</td>
                                            <td className="tright">NGN {gdetails?gdetails.totalWalletDeduct	:''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                        </>
                    )}
            </div>
  );
};
 
export default UtilityTransactionDetails;