import React, { useState, useEffect } from "react";
import "./billpurchase.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import { useNavigate } from "react-router";
import GiftCard from "./giftcarddetails";
import Select from "react-select";
import useSelect from "../../Hook/user-select";
import TextField from "@mui/material/TextField";


function GiftCardList({open, isOpen,setShowService }) {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
 
  const [productlist, setProductList] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [allCategories, setallCategories] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [pname, setPName] = useState("");
  const [pimg, setPImg] = useState("");
  const [loader, setLoader] = useState(true);
  const [countryCode, setCountryCode] = useState("ng");
  const [countryCode1, setCountryCode1] = useState("");
  const [pdname, setPdName] = useState("");
  const [page, setPage] = useState("");
  const [productId, setProductId] = useState("");
  const [denominationType, setDenominationType] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [fxRate, setFxRate] = useState("");
  const [virtualAccountInfo, setVirtualAccountInfo] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryId1, setCategoryId1] = useState("");
  const [brand_Id, setBrand_Id] = useState("");
  let styles = useSelect();

  const goBack = () => {
    setShowService(true)
    isOpen(false)
    setCountryCode1("ng");
    setCategoryId("")
  };
  
  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
    getGiftCardList(auth,pdname,categoryId,page,productId,countryCode);
    allCountry();
    allCategory();
    getVirtualBalance();
  }, []);

  const getGiftCardList = async (auth,pdname,categoryId,page,productId,countryCode) => {
    
    setLoader(true)
    const apicall = await AfterLoginService.getGiftCardList(auth,pdname,categoryId,page,productId,countryCode);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if(resp.content){
          setProductList(resp.content)
          setLoader(false)
        }
        else{

        }
       
      });
    } else {
    }
  };

  const allCountry = async () => {
    const apicall = await AfterLoginService.allCountry(auth,1);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          let finalArr = [];
          let arr = [{ label: 'Select a country', value: '',Searchable:true, disabled: true }]
          let arr1 = resp?.data.map((item) => ({
            label: (
              <div className="flex items-center gap-2">
                <p className="text-sm text-white !mb-0"> {item.name}</p>

              </div>
            ),
            value: item?.name,
            code: item?.isoName,
          }));
          finalArr = arr.concat(arr1);
          console.log(finalArr)
          setallCountries(finalArr);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const allCategory = async () => {
    const apicall = await AfterLoginService.allCategory(auth,1);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          let finalArr = [];
          let arr = [{ label: 'Select a category', value: '',Searchable:true, disabled: true }]
          let arr1 = resp?.data.map((item) => ({
            label: (
              <div className="flex items-center gap-2">
                <p className="text-sm text-white !mb-0"> {item.categoryName}</p>
              </div>
            ),
            value: item?.categoryName,
            code: item?.categoryid,
          }));
          finalArr = arr.concat(arr1);
          setallCategories(finalArr);
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const getVirtualBalance = async () => {
    const apicall = await AfterLoginService.getVirtualBalance(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setVirtualAccountInfo(resp.data)
          console.log(resp.data)
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  const cardDetails = (productName,productImg,brandId,prodId,denominationType,pInfo)=>{
    setShowList(true);
    isOpen(false)
    setPName(productName)
    setPImg(productImg)
    setBrand_Id(brandId)
    setProductId(prodId)
    setDenominationType(denominationType)
    setProductInfo(pInfo)
    giftCardFxRate(1,pInfo.recipientCurrencyCode)
  }
  const handleSelect = (event) => {
    setCountryCode1(event)
    var countryId = event?.code;
    setCountryCode(event?.code)
    getGiftCardList(auth,pdname,categoryId,page,productId,countryId);
  };
  
  const handleCategory = (event1)=>{
    setCategoryId1(event1)
    var categoryIds = event1?.code;
    setCategoryId(event1?.code);
    getGiftCardList(auth,pdname,categoryIds,page,productId,countryCode);
  }
  const searchBar = () =>{
    getGiftCardList(auth,pdname,categoryId,page,productId,countryCode);
  }

  const giftCardFxRate = async (amount,currencyCode) => {
    const item = {currencyCode:currencyCode,amount:amount}
    const apicall = await AfterLoginService.giftCardFxRate(auth,item);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setFxRate(resp.data?resp.data['senderAmount']:'')
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };
  return (
    <>
        {
            open && (
                <div className="container-fluid pt-4 px-4 wallet-table dpDesign1">
                    <div className=" pt-4 px-4 mobile-1">
                        <div className="row">
                        <div className="back-icon-main d-block">
                            <img src="/img/CaretLeft1.svg" onClick={goBack} />
                        </div>
                        <h3 className="heading-transaction mb-0">Giftcards</h3>
                        <div className="row mt-2 ">
                            <div className="col-md-12">
                                <div className="row">
                                        <div className="col-md-4 col-12 col-sm-12 g_1">
                                        <Select
                                            placeholder="Select country"
                                            onChange={(e) => handleSelect(e)}
                                            name="currencyCode"
                                            options={allCountries}
                                            value={countryCode1}
                                            styles={styles}
                                          /> 
                                          
                                     
                                        </div>
                                        <div className="col-md-4 col-12 col-sm-12 g_1">
                                         <Select
                                            onChange={(e) => handleCategory(e)}
                                            placeholder="Select category"
                                            name="currencyCode"
                                            options={allCategories}
                                            value={categoryId1}
                                            styles={styles}
                                          />
                                       
                                        </div>
                                        <div className="col-md-3 col-12 col-sm-12 g_1">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Search by name"    value={pdname}
                                                onChange={(e) => {setPdName(e.target.value)}} />
                                            
                                        </div>
                                        <div className="col-md-1 col-12 col-sm-12 searchicon1" onClick={()=>searchBar()}>
                                        <div className="searchbar-1">
                                            <img src="/img/search.png" className="search_icon" />
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="row">
                                    {loader?<>
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <span
                                                className="text-white spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                                ></span>{" "}
                                                <span className="text-white">Loading...</span>
                                            </div>
                                        </div>
                                    </>:
                                    <>
                                        {productlist.length>0?(productlist.map((item,index)=>(
                                            <>
                                            <div className="col-md-3">
                                                <div className="product-img" onClick={()=>cardDetails(item.productName,item.logoUrls[0],item.brand.brandId,item.productId,item.denominationType,item)}>
                                                    <img src={item.logoUrls[0]}/>
                                                    <span>{item.productName.substring(0,16)}</span>
                                                </div>
                                            </div>
                                            </>
                                        ))):<>
                                        <div className="col-md-12">
                                                <div className="" >
                                                  <h5 className="datanot-f">Data not found</h5>
                                                </div>
                                            </div>
                                        </>}
                                    </>}   
                                    
                                    
                                </div>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
            )
        }
        <GiftCard showlist={showlist} setShowList={setShowList} pname={pname} isOpen={isOpen} pimg={pimg} brand_Id={brand_Id} productId={productId}  setProductId={setProductId} denominationType={denominationType} productInfo={productInfo} virtualAccountInfo={virtualAccountInfo} fxRate={fxRate} setFxRate={setFxRate}/>
    </>
  );
}

export default GiftCardList;


