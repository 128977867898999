import React from "react";
import "./header.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="sidebar pe-4 pb-3 d-md-none d-lg-block">
        <div className="navbar">
          <div className="logo ">
            <img src="img/logo.svg" alt="Dashboard Logo" />
          </div>
          <ul className="overflow-hidden">
            <li>
              <NavLink
                to="/Dashboard"
                className={`nav-link ${
                  window.location.pathname === "/Dashboard" ? "active" : ""
                }`}
                style={{ marginBottom: "-10px" }}
              >
                <img
                  src="img/shape.svg"
                  alt="Dashboard"
                  className="icon mt-1"
                  style={{ color: "transparent" }}
                />
                <span className=" " style={{ marginBottom: "8px" }}>
                  Dashboard
                </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Giftcard"
                className={`nav-link ${
                  window.location.pathname === "/Giftcard" ? "active" : ""
                }`}
              >
                <img src="img/giftcard.svg" />
                <span className="ms-2">Sell Gift Card</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/billpurchase"
                className={`nav-link ${
                  window.location.pathname === "/billpurchase" ? "active" : ""
                }`}
              >
                <img src="img/giftcard.svg" />
                <span className="ms-2">Bills & Purchases</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/virtualaccount"
                className={`nav-link ${
                  window.location.pathname === "/virtualaccount" ? "active" : ""
                }`}
              >
                <img src="img/giftcard.svg" />
                <span className="ms-2">Virtual Account</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/Cryptosell"
                className={`nav-link ${
                  window.location.pathname === "/Cryptosell" ? "active" : ""
                }`}
              >
                <img src="img/cashbackicon.svg" />
                <span className="ms-2">Crypto Quick Sell</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Transcation"
                className={`nav-link ${
                  window.location.pathname === "/Transcation" ? "active" : ""
                }`}
              >
                <img
                  src="img/transctionicon.svg"
                  alt="Transaction"
                  style={{ marginTop: "10px", color: "gray" }}
                  className={`icon ${
                    window.location.pathname === "/Transcation" ? "active" : ""
                  }`}
                />
                <span className="">Transaction</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Walletdetails"
                className={`nav-link ${
                  window.location.pathname === "/Walletdetails" ? "active" : ""
                }`}
              >
                <img src="img/wallet.svg" />
                <span className="ms-2">Wallet</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Cashbackwallet"
                className={`nav-link ${
                  window.location.pathname === "/Cashbackwallet" ? "active" : ""
                }`}
              >
                <img src="img/cashbackicon.svg" />
                <span className="ms-2">Cashback Wallet</span>
              </NavLink>
            </li>

           


            {/* <li>
              <NavLink
                to="/instant"
                className={`nav-link ${
                  window.location.pathname === "/instant" ? "active" : ""
                }`}
              >
                <img src="img/crypto.svg" />
                <span className="ms-2">BTC ( QUICK SELL )</span>
              </NavLink>
            </li> */}

             {/* <li>
              <NavLink
                to="/Logindetails"
                className={`nav-link ${
                  window.location.pathname === "/Logindetails" ? "active" : ""
                }`}
              >
                <img src="img/crypto.svg" />
                <span className="ms-2">BTC Wallet / Sell BTC</span>
              </NavLink>
            </li>  */}

            

            {/* <li>
                <NavLink
                  to="/instant"
                  className={`nav-link ${
                    window.location.pathname === "/instant" ? "active" : ""
                  }`}
                >
                  <img src="img/crypto.svg" />
                  <span className="ms-2">
                    <Link to="/instant">Instant Bitcoin Sales</Link>
                  </span>
                </NavLink>
              </li> */}

            <li className="d-none">
              <NavLink
                to="/Trc"
                className={`nav-link ${
                  window.location.pathname === "/Trc" ? "active" : ""
                }`}
              >
                <img src="img/crypto.svg" />
                <span className="ms-2">TRC20(USDT & TRON)</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Referral"
                className={`nav-link ${
                  window.location.pathname === "/Referral" ? "active" : ""
                }`}
              >
                <img src="img/wallet.svg" />
                <span className="ms-2">Referral Wallet</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Qxchange"
                className={`nav-link ${
                  window.location.pathname === "/Qxchange" ? "active" : ""
                }`}
              >
                <img src="img/crypto.svg" />
                <span className="ms-2">Swap Crypto</span>
              </NavLink>
            </li>

            {/* <NavLink
                to="/Qxchange"
                className={`nav-link ${
                  window.location.pathname === "/Qxchange" ? "active" : ""
                }`}
              >
                <img src="img/crypto.svg" />
                <span className="ms-2">Swap Crypto</span>
              </NavLink> */}
            {/* </li> */}

            <li>
              <NavLink
                to="/Account"
                className={`nav-link ${
                  window.location.pathname === "/Account" ? "active" : ""
                }`}
              >
                <img src="img/users.svg" />
                <span className="ms-2">Account</span>
              </NavLink>
            </li>

            {/* <li>
              <NavLink
                to="/Giftguide"
                className={`nav-link ${
                  window.location.pathname === "/Giftguide" ? "active" : ""
                }`}
              >
                <img src="img/giftcard.svg" />
                <span className="ms-2">Gift Card Sell Guide</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/Bitcoinguide"
                className={`nav-link ${
                  window.location.pathname === "/Bitcoinguide" ? "active" : ""
                }`}
              >
                <img src="img/giftcard.svg" />
                <span className="ms-2">
                  <Link to="/Bitcoinguide">Bitcoin Sell Guide</Link>
                </span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
export default Header;

//navlink
// import React from "react";
// import "./header.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import { Link, NavLink } from "react-router-dom";

// function Header() {
//   return (
//     <>
//       <div className="sidebar pe-4 pb-3 d-md-none d-lg-block">
//         <div className="navbar">
//           <div className="logo ">
//             <img src="img/signup.png" alt="Dashboard Logo" />
//           </div>
//           <ul className="overflow-hidden">
//             <li>
//               <div
//                 className={`flex flex-row items-center ${
//                   window.location.pathname === "/Dashboard" ? "active-link" : ""
//                 }`}
//               >
//                 <NavLink to="/Dashboard" className="flex flex-row items-center">
//                   <img
//                     src="img/dashboard.png"
//                     alt="Dashboard"
//                     className="icon"
//                   />
//                   <span className="ms-2">Dashboard</span>
//                 </NavLink>
//               </div>
//             </li>
//             <li>
//               <NavLink
//                 to="/Giftcard"
//                 className={`nav-link ${
//                   window.location.pathname === "/Giftcard" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/Icon.png" />
//                 <span className="ms-2">Sell Gift Card</span>
//               </NavLink>
//             </li>

//             <li>
//               <NavLink
//                 to="/Transcation"
//                 className={`nav-link ${
//                   window.location.pathname === "/Transcation" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/tran.png" />
//                 <span className="ms-2">Transaction</span>
//               </NavLink>
//             </li>

//             <li>
//               <NavLink
//                 to="/Walletdetails"
//                 className={`nav-link ${
//                   window.location.pathname === "/Walletdetails" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/wallet.png" />
//                 <span className="ms-2">Wallet</span>
//               </NavLink>
//             </li>

//             <li>
//               <NavLink
//                 to="/Cashbackwallet"
//                 className={`nav-link ${
//                   window.location.pathname === "/Cashbackwallet" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/cashback.png" />
//                 <span className="ms-2">Cashback Wallet</span>
//               </NavLink>
//             </li>

//             <li>
//               <li>
//                 <NavLink
//                   to="/Logindetails"
//                   className={`nav-link ${
//                     window.location.pathname === "/Logindetails" ? "active" : ""
//                   }`}
//                 >
//                   <img src="img/crypto.png" />
//                   <span className="ms-2">
//                     <Link to="/Logindetails">BTC Wallet / Sell BTC</Link>
//                   </span>
//                 </NavLink>
//               </li>

//               <li>
//                 <NavLink
//                   to="/Trc"
//                   className={`nav-link ${
//                     window.location.pathname === "/Trc" ? "active" : ""
//                   }`}
//                 >
//                   <img src="img/crypto.png" />
//                   <span className="ms-2">TRC20(USDT & TRON)</span>
//                 </NavLink>
//               </li>

//               <li>
//                 <NavLink
//                   to="/Referral"
//                   className={`nav-link ${
//                     window.location.pathname === "/Referral" ? "active" : ""
//                   }`}
//                 >
//                   <img src="img/crypto.png" />
//                   <span className="ms-2">Referral Wallet</span>
//                 </NavLink>
//               </li>

//               <NavLink
//                 to="/Qxchange"
//                 className={`nav-link ${
//                   window.location.pathname === "/Qxchange" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/coins.png" />
//                 <span className="ms-2">Qxchange Crypto</span>
//               </NavLink>
//             </li>

//             <li>
//               <NavLink
//                 to="/Account"
//                 className={`nav-link ${
//                   window.location.pathname === "/Account" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/users.png" />
//                 <span className="ms-2">Account</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/Giftguide"
//                 className={`nav-link ${
//                   window.location.pathname === "/Giftguide" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/gift.png" />
//                 <span className="ms-2">Gift Card Sell Guide</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/Bitcoinguide"
//                 className={`nav-link ${
//                   window.location.pathname === "/Bitcoinguide" ? "active" : ""
//                 }`}
//               >
//                 <img src="img/gift.png" />
//                 <span className="ms-2">
//                   <Link to="/Bitcoinguide">Bitcoin Sell Guide</Link>
//                 </span>
//               </NavLink>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// }
// export default Header;

// import React from 'react';
// import './header.scss';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import "bootstrap-icons/font/bootstrap-icons.css";
// import { Link } from "react-router-dom";

// function Header(){
//     return(
//         <>
//          <div className="sidebar pe-4 pb-3 d-md-none d-lg-block">
//                 <div className='navbar'>
//                 <div className='logo '>
//                     <img src='img/signup.png' alt='Dashboard Logo'/>
//                 </div>
//                     <ul className='overflow-hidden'>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/dashboard.png' />
//                                 <span className='ms-2'>
//                                 <Link to="/Dashboard">Dashboard</Link>
//                                 </span>
//                             </a>
//                         </li>
//                         <li><a className='flex flex-row items-center' href=''>
//                             <img src='img/gift.png' />
//                                 <span className='ms-2'>
//                                 <Link to="/Giftcard">Sell Gift Card</Link>
//                                 </span>
//                             </a>
//                         </li>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                             <img src='img/linechart.png' />
//                             <span className='ms-2'>
//                                 <Link to="/Transcation">Transaction</Link>
//                             </span>
//                             </a>
//                         </li>
//                         <li>
//                         <a className='flex flex-row items-center' href=''>
//                             <img src='img/wallet.png' />
//                             <span className='ms-2'>
//                             <Link to="/Walletdetails"> Wallet</Link>
//                             </span>
//                         </a>
//                         </li>
//                         <li><a className='flex flex-row items-center' href=''>
//                             <img src='img/cashback.png' />
//                             <span className='ms-2'>
//                             <Link to="/Cashbackwallet">Cashback Wallet</Link>
//                             </span>
//                             </a>
//                             </li>
//                         <li>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/crypto.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Logindetails">BTC Wallet / Sell BTC</Link>
//                                 </span>
//                             </a>
//                         </li>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/crypto.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Trc">TRC20(USDT & TRON)</Link>
//                                 </span>
//                             </a>
//                         </li>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/crypto.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Referral">Referral Wallet</Link>
//                                 </span>
//                             </a>
//                         </li>

//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/coins.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Qxchange"> Qxchange Crypto</Link>
//                                 </span>
//                             </a>
//                         </li>

//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/users.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Account">Account</Link>
//                                 </span>
//                             </a>
//                         </li>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/gift.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Giftguide">Gift Card Sell Guide</Link>
//                                 </span>
//                             </a>
//                         </li>
//                         <li>
//                             <a className='flex flex-row items-center' href=''>
//                                 <img src='img/gift.png' />
//                                 <span className='ms-2'>
//                                     <Link to="/Bitcoinguide">Bitcoin Sell Guide</Link>
//                                 </span>
//                             </a>
//                         </li>
//                     </ul>
//                 </div>
//         </div>
//         </>
//     );
// }
// export default Header;
