import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import useSelect from "../../../Hook/user-select";
import Select from "react-select";
import Confirmation from "./confirmation";
import styled from 'styled-components';

const OperatorDetails = ({isOpenOperator,setIsOpenMobile,setShowService,setIsOpenOperator,isOpenOperatorDetails,setIsOpenOperatorDetails,opDetails,countryOption,isCountry1,phoneNumber}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [amount,setAmount] = useState('');
  const [samount,setSAmount] = useState('');
  const [supportsGeographicalRechargePlans,setSupportsGeographicalRechargePlans] = useState(opDetails?opDetails.supportsGeographicalRechargePlans:'');
  const [minamount,setMinAmount] = useState(opDetails?opDetails.minAmount:0);
  const [maxamount,setMaxAmount] = useState(opDetails?opDetails.maxAmount:0);
  const [senderCurrencyCode,setSenderCurrencyCode] = useState(opDetails?opDetails.senderCurrencySymbol:'')
  const [destinationCurrencyCode,setDestinationCurrencyCode] = useState(opDetails?opDetails.destinationCurrencySymbol:'')
  const [fxRate,setFxRate] = useState(opDetails?opDetails.fx.rate:0)
  const [feeLocal,setFeeLocal] = useState(opDetails?opDetails.fees.local:0)
  const [feeInternational,setFeeInternational] = useState(opDetails?opDetails.fees.international:0)
  const [virtualAccountInfo,setVirtualAccountInfo] = useState("");
  const [isSelected, setisSelected] = useState(null);
  let styles = useSelect();
  const [isDisabled, setIsDisabled] = useState(true)
  var description = ""
  const goBack = () => {
    setIsOpenMobile(false)
    setShowService(false)
    setIsOpenOperator(true)
    setIsOpenOperatorDetails(false)

  };
  
 const handleAmount = (e)=>{
    const reg = /^[.0-9\b]+$/
    let preval=e.target.value
    if (e.target.value === '' || reg.test(e.target.value)){
        setAmount(e.target.value)
        var amt = e.target.value;
        var payamt = fxRate*amt;
        setSAmount(payamt.toFixed(2))
      return true
    }else{
      e.target.value = preval.substring(0,(preval.length-1))
    }
 }

 useEffect(() => {
    getVirtualBalance();
  }, []);

  const getVirtualBalance = async () => {
    const apicall = await AfterLoginService.getVirtualBalance(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setVirtualAccountInfo(resp.data)
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  
  if(opDetails.geographicalRechargePlans.length>0){
    var fixedAmountsDescriptions = opDetails?opDetails.geographicalRechargePlans[0].fixedAmountsDescriptions:'';
  }
  else if(opDetails.fixedAmountsDescriptions){
    var fixedAmountsDescriptions = opDetails?opDetails.fixedAmountsDescriptions:''
  }
  else{
    var fixedAmountsDescriptions = {}
  }

   if(opDetails.geographicalRechargePlans.length>0){
    var fixedAmountsPlan =opDetails?opDetails.geographicalRechargePlans[0].localFixedAmountsPlanNames:'';
  }
  else{
    var fixedAmountsPlan ={}
  }

   const BorderCard = styled.div`
    width: 100%;
    box-sizing: border-box;
    color: ${props => (props.selected ? '#fff' : '#7e7a8a')};
    background: ${props => (props.selected ? '#3c0d68' : 'rgb(17, 19, 20)')};
    border: ${props =>
        props.selected ? '1.5px solid #3c0d68' : '1px solid rgb(17, 19, 20)'};
    `;

    const PaymentCard1 = ({ item,index, selected, onChange }) => {
        return (
          <BorderCard style={{ marginTop: '10px' }} selected={selected} onClick={onChange}>
               <div className="mbcard mb-4">
                                                    <div className="row">
                                                        <div className="col-md-6 col-6">
                                                            <div className="">
                                                                {/* <p>Get Utility Details</p> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-3">
                                                            <div className="">
                                                                <p>{destinationCurrencyCode+' '+item}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-3">
                                                            <div className="">
                                                                <p>{senderCurrencyCode+' '+item}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-12">
                                                            <div className="">
                                                            {
                                                                Object.keys(fixedAmountsDescriptions).map((key, index1)=>(
                                                                  <>{key == item.toFixed(2)?(<>
                                                                  <p>{fixedAmountsDescriptions[key]}</p>
                                                                  </>):(<>
                                                                    {/* <p>No Description Available</p> */}
                                                                  </>)}</>
                                                                ))
                                                            } 
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div> 
          </BorderCard>
        );
      };  
    const PaymentCard = ({ item,index, selected, onChange }) => {
        return (
        <BorderCard style={{ marginTop: '10px' }} selected={selected} onClick={onChange}>
                <div className="mbcard mb-4 " >
                    <div className="row">
                        <div className="col-md-6 col-6">
                                                                    <div className="">
                                                                        <p>Airtime Plans</p>
                                                                    </div>
                        </div>                                                    
                        <div className="col-md-3 col-3">
                                                                    <div className="">
                                                                        <p>
                                                                        {destinationCurrencyCode}
                                                                        {opDetails.localFixedAmounts?opDetails.localFixedAmounts[index]:''}
                                                                        </p>
                                                                    </div>
                        </div>
                        <div className="col-md-3 col-3">
                                                                    <div className="">
                                                                        <p>{senderCurrencyCode+' '+item}</p>
                                                                    </div>
                        </div>
                        <div className="col-md-12 col-12">
                                                                    <div className="">
                                                                    {
                                                                        Object.keys(fixedAmountsDescriptions).map((key, index1)=>(
                                                                        <>{key == item.toFixed(2)?(<>
                                                                        <p>{fixedAmountsDescriptions[key]}</p>
                                                                        </>):(<>
                                                                            {/* <p>No Description Available</p> */}
                                                                        </>)}</>
                                                                        ))
                                                                    } 
                                                                    </div> 
                        </div>
                    </div>
                </div>
        </BorderCard>
        );
    };
    const PaymentCard2 = ({ item,index, selected, onChange }) => {
        return (
        <BorderCard style={{ marginTop: '10px' }} selected={selected} onClick={onChange}>
           <div className="mbcard mb-4">
                <div className="row">
                    <div className="col-md-6 col-6">
                                                                        <div className="">
                                                                        {
                                                                            Object.keys(fixedAmountsPlan).map((key, index1)=>(
                                                                            <>{key == opDetails.geographicalRechargePlans[0].localAmounts[index]?(<>
                                                                            <p>{fixedAmountsPlan[key]}</p>
                                                                            </>):(<>
                                                                                <p></p>
                                                                            </>)}</>
                                                                            ))
                                                                        } 
                                                                            
                                                                        </div>
                    </div>                                                              
                    <div className="col-md-3 col-3">
                                                                        <div className="">
                                                                            <p>{destinationCurrencyCode+' '+opDetails.geographicalRechargePlans[0].localAmounts[index]}</p>
                                                                        </div>
                    </div>
                    <div className="col-md-3 col-3">
                                                                        <div className="">
                                                                            <p>{senderCurrencyCode+' '+item}</p>
                                                                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                                                                        <div className="">
                                                                        {
                                                                            Object.keys(fixedAmountsDescriptions).map((key, index1)=>(
                                                                            <>{key == item.toFixed(2)?(<>
                                                                            <p>{fixedAmountsDescriptions[key]}</p>
                                                                            </>):(<>
                                                                                <p></p>
                                                                            </>)}</>
                                                                            ))
                                                                        } 
                                                                        </div> 
                    </div>
                </div>
            </div>  
        </BorderCard>
        );
    };

    const handlePayment1 =(item,index,localamount)=>{
        setisSelected(index)
        setAmount(item)
        setSAmount(localamount)
      }
    const handlePayment =(item,index,localamount)=>{
        setisSelected(index)
        setAmount(item)
        setSAmount(localamount)
    }
    const handlePayment2 =(item,index,localamount)=>{
        setisSelected(index)
        setAmount(item)
        setSAmount(localamount)
    }
  return (
    <div className="mobile_response container-fluid">
        
        {isOpenOperatorDetails &&(
            <>
            <div className="row pt-4 px-4 wallet-table dpDesign1">
                <div className="col-md-2">
                    <div className="back-icon-main d-block">
                        <img src="../img/CaretLeft1.svg" onClick={goBack} />
                    </div>
                </div>
                <div className="col-md-8"></div>
                <div className="col-md-6">
                    <div>
                            <h3 className="heading-transaction mb-0">Mobile TopUp</h3>
                            <p className="mb-p">Follow three easy steps to top-up in seconds. To do, please set the reciever phone number below:</p>
                    </div> 
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4 col-6 country-pr-0">
                                            <Select
                                                name="currencyCode"
                                                options={countryOption}
                                                value={isCountry1}
                                                styles={styles}
                                                isDisabled={isDisabled}
                                            /> 
                                </div>
                                <div className="col-md-6 col-6 input-pl-0">
                                <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder=""   value={phoneNumber}   isDisabled={isDisabled}/>
                                            
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <h5 className="h-5">Choose Operator</h5>
                            <p className="p_2">Select the right operator</p>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="">
                                        <img src={opDetails.logoUrls[2]}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="">
                                        <p className="p-t"><span className="color-1">You selected</span> {opDetails?opDetails.name:''}</p>
                                        <p className="p-t1">
                                        <span className="color-1"> Not the operator </span>
                                            ?<a href="javascript:void(0)" onClick={goBack}>Check operators</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                 {opDetails.denominationType =="RANGE"?(<>
                                <div className="col-md-12">
                                    <div className="">
                                        <h5 className="h-5">Add Amount</h5>
                                        <p className="p_2">add the right Amount</p>
                                    </div>
                                
                                </div>
                                <div className="col-md-6 col-6 mb-2">
                                    <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter Amount in NGN"  value={amount} onChange={handleAmount} />
                                               
                                </div>
                                <div className="col-md-4 col-6">
                                <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder=""  value={destinationCurrencyCode+' '+samount} isDisabled={isDisabled} />
                                               
                                </div>
                                <div className="col-md-12 mt-4">
                                    <div className="">
                                        <p className="p_2">Amount has to be between {senderCurrencyCode+' '+minamount} to {senderCurrencyCode+' '+maxamount}</p>
                                    </div>
                                </div>

                                 </>):(<>
                                    <div className="col-md-12">
                                        <div className="">
                                            <h5 className="h-5">Choose Plan</h5>
                                            <p className="p_2">Select the right amount</p>
                                        </div>
                                    
                                    </div>
                                    <div className="col-md-10 plan_d">
                                        {senderCurrencyCode == destinationCurrencyCode?(<>
                                        
                                            {opDetails.fixedAmounts.map((item,index)=>(
                                            <>
                                                <PaymentCard1
                                                index={index}
                                                item={item}
                                                selected={isSelected === index}
                                                onChange={(e) => handlePayment1(item,index,item)}
                                                /> 
                                               
                                            </>
                                            ))}

                                        </>):(<>
                                            {opDetails.supportsGeographicalRechargePlans === false?(<>
                                            {   
                                                opDetails.fixedAmounts.map((item,index)=>         
                                                (
                                                <>
                                                    <PaymentCard
                                                        index={index}
                                                        item={item}
                                                        selected={isSelected === index}
                                                        onChange={(e) => handlePayment(item,index,opDetails.localFixedAmounts?opDetails.localFixedAmounts[index]:'')}
                                                    /> 
                                                </>
                                                ))
                                            }
                                            
                                            </>):
                                            (<>
                                                {
                                                     opDetails.geographicalRechargePlans[0].fixedAmounts.map((item,index)=>         
                                                        (
                                                        <>
                                                            <PaymentCard2
                                                            index={index}
                                                            item={item}
                                                            selected={isSelected === index}
                                                            onChange={(e) => handlePayment2(item,index,opDetails.geographicalRechargePlans[0].localAmounts[index])}
                                                            /> 
                                                           
                                                        </>
                                                        ))
                                                }
                                            </>)}
                                        </>)}
                                        
                                       
                                    </div>
                                 
                                 </>)}
                                

                                <Confirmation opDetails={opDetails} phoneNumber={phoneNumber} amount={amount} senderCurrencyCode={senderCurrencyCode}
                                destinationCurrencyCode={destinationCurrencyCode}
                                fxRate={fxRate}
                                feeLocal={feeLocal}
                                minamount={minamount}
                                maxamount={maxamount}
                                feeInternational={feeInternational} samount={samount} virtualAccountInfo={virtualAccountInfo} setIsOpenOperatorDetails={setIsOpenOperatorDetails}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </div>
  );
};

export default OperatorDetails;
