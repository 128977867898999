import React from "react";
import Avatar from "@mui/material/Avatar";
import { Divider, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const Modal = ({ children }) => {
  return (
    <div className="modal send-usd">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const SuccessTransactionModal = ({
  isOpen,
  onClose,
  fromCurrency = "cd",
  toCurrency = "dec",
  toAmount = "12",
  fromAmount = "5.33",
  refunded,
}) => {
  if (!isOpen) return null;
  return (
    <div>
      <Modal>
        <div className="modal bg-[#000000cc]">
          <div className="modal-dialog">
            <div
              className="modal-content bg-black"
              style={{
                border: "3px solid #0f85dc",
                width: "23rem",
              }}
            >
              <div className="p-2 d-flex justify-content-end align-items-end w-100">
                <IconButton
                  onClick={onClose}
                  className="absolute top-0 right-0 text-white"
                >
                  <CancelIcon />
                </IconButton>
              </div>
              <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col">
                <Avatar className="bg-success mb-3">
                  <i class="bi bi-check-lg text-white"></i>
                </Avatar>
                <p className="h4 text-white">Congrats</p>
                <p className="text-gray-400 text-sm">
                  Your have successfully {refunded ? "Refunded" : "Converted"}
                  <span className="text-white d-block font-bold text-sm">
                    {fromAmount}
                    <span className="text-xs">
                      {fromCurrency.toUpperCase()}
                    </span>{" "}
                    to {toAmount}
                    <span className="text-xs">{toCurrency.toUpperCase()}</span>
                  </span>
                </p>
              </div>
              <Divider style={{ backgroundColor: "white" }} />
              <div
                className=" d-flex w-full px-6"
                style={{ padding: "1rem 1.5rem" }}
              >
                <button
                  className="rounded-md px-2 py-1 bg-[#260c5e] text-white w-full"
                  onClick={onClose}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessTransactionModal;
