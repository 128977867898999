import React, { useState } from "react";
import "./account.scss";

import { Link } from "react-router-dom";
import Retrymodal from "./retrymodal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const KYCmodal = ({ handleDatas }) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isFirstModalOpenSuccess, setIsFirstModalOpenSuccess] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoging] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const kycSubmit = async () => {
    setLoging(true);
    const image  = (localStorage.getItem("kycimage")); 
    const bvn = (localStorage.getItem("bvn"));
    const item = { image, bvn };
    const apicall = await AfterLoginService.kycSave(auth,item); 
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
            if(resp.error)
            {
              toast('International Passport Or National Identity card(NIMC) image is required');
              setLoging(false);
              return false;
            }
            localStorage.removeItem('kycimage');
            localStorage.removeItem('bvn');
            setIsFirstModalOpenSuccess(true);
            setLoging(false);
           
        } else {
              toast(resp.message);
              setLoging(false);
            // setIsFirstModalOpen(true);
        }
      });
    } else {
    }
  }

  const closeSuccess = ()=>{
    setIsFirstModalOpenSuccess(false);
    handleDatas();
  }
  return (
    <div>

      <button
          className={`btn-continue btn-bvn-submit  ${
                      loading ? "disabled" : ""
            }`}
                    onClick={kycSubmit}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm "
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>



      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content  update-name-modal ">
                <div className="modal-header">
                  <div className="row">
                    <div className="col-lg-12 col-ms-12 col-sm-12 col-12">
                      <img src="/img/close.png" className=" close-red" alt="" />
                    </div>
                  </div>
                  <ToastContainer />
                  <h5 className="modal-title  ">KYC Verification failed</h5>

                  <button
                    type="button profile-close"
                    className="close"
                    onClick={closeFirstModal}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="bvn-p-modal">
                  <p>BVN is already in use by another account</p>
                </div>
                <div className="modal-body "></div>
                <div className="modal-footer ">
                  <div className="row kyc-modal-btn">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      {/* <Retrymodal /> */}
                        <button className="retry-btn" onClick={closeFirstModal}>
                            <img src="/img/retry.png" className="retry-btn" alt="" />
                        </button>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <img
                        src="/img/cancel.png"
                        className="kyc-cancel-btn"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        
      )}
      {isFirstModalOpenSuccess && (
         <Modal>
            <div className="modal ">
            <div className="modal-dialog">
                <div className="modal-content  update-name-modal ">
                <div className="modal-header">
                    <div className="row">
                    <div className="col-lg-12 col-ms-12 col-sm-12 col-12">
                        <img src="/img/TICK.png" className=" close-red" alt="" />
                    </div>
                    </div>

                    <h5 className="modal-title  kyc-varification">
                    KYC Verification Request Sent
                    </h5>

                    <button
                    type="button profile-close"
                    className="close"
                    onClick={closeFirstModal}
                    >
                    <img src="img/close-icon.png" />
                    </button>
                </div>
                <div className="bvn-p-modal">
                    <p>This may take upto 24 hours.</p>
                </div>

                <div className="modal-footer ">
                    <button className="sucess-continue-btn" onClick={closeSuccess}>Continue</button>
                </div>
                </div>
            </div>
            </div>
        </Modal>
      )}
    </div>
  );
};

export default KYCmodal;
