import React, { useState } from "react";
import Successmodal from "./successmodal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

const Modal = ({ onClose, children  }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Requestmodal = ({balance , handleData}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [kyc, setIsKyc] = useState("");
  
  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const handleConfirm = () => {
    setIsFirstModalOpen(false);
  }

  const checkBankDetails = async () => {
    const apicall = await AfterLoginService.CheckBankDetail(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          if (resp.isdetailadded == 1) {
            setIsFirstModalOpen(true);
            setIsKyc(resp.data.is_kyc_verified);
          } else {
            toast(resp.message);
          }
        } else {
          toast(resp.message);
        }
      });
    } else {
    }
  };

  const amountNull = ()=>{
    setAmount("");
  }
  // mui css
  const textFieldStyles = {
    background: "#252731 ",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  return (

    <div >
      {balance.wallet < 1000 ? (
        // <p className="text-white">
        //   withdraw button pops up when you have a balance in your wallet
          
        // </p>
      //   <button className="btn btn-request1" >
      //    <img src="/img/balance-low.png" alt="" />
      // </button>
      <div className="balance-low-btn">
        <img src="/img/balancelow.svg" alt="" />
         </div>
 
      ) : (
        <button className="btn btn-request" onClick={() => checkBankDetails()}>
          REQUEST WITHDRAW
        </button>
      )}

      {isFirstModalOpen && (
        <Modal>
          <div className="modal  ">
            <div className="modal-dialog">
              <div className="modal-content walletdetils-modal">
                <div className="modal-header w-100 text-center mt-2">
                  <h5 className="modal-title">Withdraw Balance</h5>

                  <button
                    type="button"
                    className="close-wallet-btn"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop: "-30px",
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="wallet-para">
                  {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
                </div>
                <div className="modal-body">
                  <form _lpchecked="1" style={{ marginTop: "-20px" }}>
                    <div className="form-group w-100 mt-3">
                      <TextField
                        type="number"
                        min="1"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        // className="form-control "
                        id="name"
                        label={
                          <span style={{ color: "#ffffff" }}>
                            Amount to be withdrawn
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        sx={{ input: { color: "white" } }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-3">
                      <TextField
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        // className="form-control mb-3"
                        id="name"
                        label={
                          <span style={{ color: "#ffffff" }}>
                            Enter your password{" "}
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        sx={{ input: { color: "white" } }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                  <div className="wallet-p-f mt-3">
                    <p className="mt-3">
                      By continuing, you agree to accept our{" "}
                    </p>
                    <p style={{ color: "#FFFFFF", marginTop: "-15px" }}>
                    <a href='https://www.thequchange.com/privacypolicy' target="_blank" style={{color:'#FFFFFF', textDecoration:'none'}}>Privacy policy &</a>
                      <a href='https://www.thequchange.com/terms-of-service' target="_blank" style={{color:'#FFFFFF', textDecoration:'none'}}> Terms of Services</a> 


                    </p>
                  </div>
                </div>
                <div className="modal-footer ">
                  {/* <button onClick={()=>ProceedWallet()} className='btn btn-update w-100'>Submit</button> */}
                  <ToastContainer />

                  <div className="w-100 success-btn "  >
                    <Successmodal amount={amount} password={password} handleConfirm = {handleConfirm} kyc={kyc} handleData = {handleData} amountNull = {amountNull} />

                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Requestmodal;

// new changes

// import React, { useState } from "react";
// import Successmodal from "./successmodal";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import TextField from "@mui/material/TextField";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-modal">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Requestmodal = (balance) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [amount, setAmount] = useState("");
//   const [password, setPassword] = useState("");

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   const checkBankDetails = async () => {
//     const apicall = await AfterLoginService.CheckBankDetail(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           if (resp.isdetailadded == 1) {
//             setIsFirstModalOpen(true);

//           } else {
//             toast(resp.message);
//           }
//         } else {
//           toast(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   // mui css
//   const textFieldStyles = {
//     background: "#252731 ",
//     width: "100%",
//     height: "40px",
//     borderRadius: "2px",
//   };

//   return (
//     <div >
//       {balance.Requestmodal.wallet < 1000 ? (
//         <p className="text-white">
//           withdraw button pops up when you have a balance in your wallet
//         </p>
//       ) : (
//         <button className="btn btn-request" onClick={() => checkBankDetails()}>
//          REQUEST WITHDRAW
//         </button>
//       )}

//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal  "  >
//             <div className="modal-dialog">
//               <div className="modal-content walletdetils-modal" >
//                 <div className="modal-header w-100 text-center mt-2">
//                   <h5 className="modal-title">Withdraw Balance</h5>

//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                     style={{
//                       background: "#313131",
//                       color: "#FFFFFF",
//                       padding: "1px",
//                       marginTop:'-30px'
//                     }}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="wallet-para">
//                   <p>Lorem ipsum dolor sit amet consectetur.</p>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1" style={{marginTop:'-20px'}}>
//                     <div className="form-group w-100 mt-3">
//                       <TextField
//                         type="number"
//                         min="1"
//                         value={amount}
//                         onChange={(e) => setAmount(e.target.value)}
//                         // className="form-control "
//                         id="name"
//                         label={
//                           <span style={{ color: "#ffffff" }}>Amount to be withdrawn</span>
//                         }
//                         variant="filled"
//                         style={textFieldStyles}
//                         sx={{ input: { color: "white" } }}
//                         InputProps={{ disableUnderline: true }}
//                       />
//                     </div>
//                     <div className="form-group w-100 mt-5" >
//                       <TextField
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         // className="form-control mb-3"
//                         id="name"

//                         label={
//                           <span style={{ color: "#ffffff" }}>Enter your password </span>
//                         }
//                         variant="filled"

//                          style={textFieldStyles}
//                         sx={{ input: { color: "white" } }}
//                         InputProps={{ disableUnderline: true }}
//                       />
//                     </div>
//                   </form>
//                   <div className="wallet-p-f mt-5">
//                     <p className="mt-3">By continuing, you agree to accept our </p>
//                     <p  style={{ color: "#FFFFFF", marginTop: "-15px" }}>
//                       Privacy policy & Terms of Services
//                     </p>
//                   </div>
//                 </div>
//                 <div className="modal-footer " >
//                   {/* <button onClick={()=>ProceedWallet()} className='btn btn-update w-100'>Submit</button> */}
//                   <ToastContainer />
//                   <div className="w-100 success-btn "  >
//                     <Successmodal amount={amount} password={password}  />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Requestmodal;

// import React, { useState } from "react";
// import Successmodal from "./successmodal";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import TextField from "@mui/material/TextField";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-modal">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Requestmodal = (balance) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [amount, setAmount] = useState("");
//   const [password, setPassword] = useState("");

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   const checkBankDetails = async () => {
//     const apicall = await AfterLoginService.CheckBankDetail(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           if (resp.isdetailadded == 1) {
//             setIsFirstModalOpen(true);
//           } else {
//             toast(resp.message);
//           }
//         } else {
//           toast(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   // mui css
//   const textFieldStyles = {
//     background: "#252731 ",
//     width: "100%",
//     height: "40px",
//     borderRadius: "2px",
//   };

//   return (
//     <div>
//       {balance.Requestmodal.wallet < 1000 ? (
//         <p className="text-white">
//           withdraw button pops up when you have a balance in your wallet
//         </p>
//       ) : (
//         <button className="btn btn-request" onClick={() => checkBankDetails()}>
//           Request Withdraw
//         </button>
//       )}

//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal   " >
//             <div className="modal-dialog">
//               <div className="modal-content walletdetils-modal" >
//                 <div className="modal-header w-100 text-center mt-2">
//                   <h5 className="modal-title">Withdraw Balance</h5>

//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="wallet-para">
//                   <p>Lorem ipsum dolor sit amet consectetur.</p>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1" style={{marginTop:'-20px'}}>
//                     <div className="form-group w-100 mt-3">
//                       <TextField
//                         type="number"
//                         min="1"
//                         value={amount}
//                         onChange={(e) => setAmount(e.target.value)}
//                         // className="form-control "
//                         id="name"
//                         label={
//                           <span style={{ color: "#ffffff" }}>Amount to be withdrawn</span>
//                         }
//                         variant="filled"
//                         style={textFieldStyles}
//                         sx={{ input: { color: "white" } }}
//                         InputProps={{ disableUnderline: true }}
//                       />
//                     </div>
//                     <div className="form-group w-100 mt-5" >
//                       <TextField
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         // className="form-control mb-3"
//                         id="name"

//                         label={
//                           <span style={{ color: "#ffffff" }}>Enter your password </span>
//                         }
//                         variant="filled"

//                          style={textFieldStyles}
//                         sx={{ input: { color: "white" } }}
//                         InputProps={{ disableUnderline: true }}
//                       />
//                     </div>
//                   </form>
//                   <div className="wallet-p-f ">
//                     <p className="mt-3">By continuing, you agree to accept our </p>
//                     <p  style={{ color: "#FFFFFF", marginTop: "-15px" }}>
//                       Privacy policy & Terms of Services
//                     </p>
//                   </div>
//                 </div>
//                 <div className="modal-footer " >
//                   {/* <button onClick={()=>ProceedWallet()} className='btn btn-update w-100'>Submit</button> */}
//                   <ToastContainer />
//                   <div className="w-100 success-btn" >
//                     <Successmodal amount={amount} password={password} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Requestmodal;
