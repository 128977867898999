const auth = JSON.parse(localStorage.getItem('user'));
const access_token = auth?.api_access_token

const GiftCardService = {
    CurrencyList : function(item,auth){
        const access_token = auth?.api_access_token
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-currency?gift_card_id=${item}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+ access_token
            },
         }).then((result)=>{
             if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GiftCardType : function(card_id, currency_id,auth){
        const access_token = auth?.api_access_token
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-type?gift_card_id=${card_id}&currency_id=${currency_id}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+ access_token
            },
         }).then((result)=>{
             if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GiftCardValueList : function(card_id, currency_id,card_type_id,auth){
        const access_token = auth?.api_access_token
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-value?gift_card_id=${card_id}&currency_id=${currency_id}&card_type_id=${card_type_id}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+ access_token
            },
         }).then((result)=>{
             if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    SellGiftCardTransaction: function (formDataObject,auth) {
        const access_token = auth?.api_access_token
        // var requestOptions = {
        //     method: 'POST',
        //     headers: {"Accept": "application/json"},
        //     body: formDataObject,
        //     redirect: 'follow'
        //   };
        return fetch(`https://secureapi.thequchangeapp.xyz/api/auth/sellgift-card-transaction`, {
            method: 'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ access_token},
            // headers:{
            // "Content-Type":"multipart/form-data",
            //     // "Accept": "application/json",
            //     // "mimeType": "multipart/form-data",
            // "Authorization":'Bearer'+ access_token
            // },
            body: formDataObject,
            redirect: 'follow'
        }).then((result)=>{
             if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    SellCryptoTransaction: function (formDataObject,auth) {
        const access_token = auth?.api_access_token
        return fetch(`https://secureapi.thequchangeapp.xyz/api/auth/sellcrypto-transaction`, {
            method: 'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ access_token},
            body: formDataObject,
            redirect: 'follow'
        }).then((result)=>{
             if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
}

export default GiftCardService