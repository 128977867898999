import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import "./giftguide.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";

function Giftguide() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <div className="container-fluid position-relative d-flex p-0 gift-container">
      <Header />
      <div className="content">
        <Subheader />
        <div className="container-fluid pt-4 px-4 gift-guide bg-zinc-900">
          <div className="row">
            <div className="back-icon-main">
              <a href="#">
                <img src="/img/CaretLeft1.svg" onClick={goBack}/>
              </a>
            </div>
            <div className="col-md-12 col-lg-12 text-white">
              <h3>HOW TO SELL GIFT CARD</h3>
              <ul>
                <li>
                  Search or scroll to find the gift card you wish to sell, tap
                  on it, select the currency of your card.
                </li>
                <li>
                  Select the type of your card, either e code or physical
                  (meaning a picture of the card).
                </li>
                <li> Click on tap to add value.</li>
                <li>
                  You will see a list of predefined values, if your card value
                  is not there, click on “TAP TO ADD OTHER VALUE”, here you can
                  enter a custom value, in this case the value of your card.
                </li>
                <li>
                  Click on plus to indicate the number of card images you will
                  be uploading. For instance, if your card is 50 ebay 2 pieces,
                  you will select or enter custom amount of 50 and tap on the
                  plus button twice.
                </li>
                <li>
                  Click on the plus image to attach a picture of the card, NOTE,
                  the card image must be fully scratched to reveal the code
                  which is what’s important i.e, the grey strip must be
                  scratched off.
                </li>
                <li>Click on Sell.</li>
                <li>
                  The card is processed and when it is done, it is credited to
                  your wallet. Which you tap on wallet and request a withdrawal.
                  However, before you can request a withdrawal, you have to
                  enter your payment details.
                </li>
                <li>
                  Click on “Account” which can be found on the sidebar menu, it
                  leads to a tab that says “update payment details”.
                </li>
                <li>
                  NOTE: All countries accepted i.e USD, EUROS, CAD, GBP, NAIRA,
                  PHP. Payment methods included cashapp, bank transfer, mobile
                  money, bitcoin etc.
                </li>
                <li>
                  Payment is sent instantly once you request a withdrawal.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Giftguide;
