import React, { useState } from "react";
import "./account.scss";

import { Link } from "react-router-dom";
import Retrymodal from "./retrymodal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal continue">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const KYCmodal = ({bvn,handleData,setBvnCheckIconShow}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const continueStore = ()=>{
        if(bvn == '' || bvn == null)
        {
          toast('The bnv field is required');
          return false;
        }
       localStorage.setItem("bvn", bvn);
       setIsFirstModalOpen(false);
       setBvnCheckIconShow(true)
       handleData();
  }
  return (
    <div>
      <button className="btn-continue " onClick={continueStore}>
        Continue
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content  update-name-modal ">
                <div className="modal-header">
                  <div className="row">
                    <div className="col-lg-12 col-ms-12 col-sm-12 col-12">
                      <img src="/img/close.png" className=" close-red" alt="" />
                    </div>
                  </div>
                  <ToastContainer />
                  <h5 className="modal-title  ">KYC Verification failed</h5>

                  <button
                    type="button profile-close"
                    className="close"
                    onClick={closeFirstModal}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="bvn-p-modal">
                  <p>BVN is already in use by another account</p>
                </div>
                <div className="modal-body "></div>
                <div className="modal-footer ">
                  <div className="row kyc-modal-btn">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <Retrymodal />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <img
                        src="/img/cancel.png"
                        className="kyc-cancel-btn"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default KYCmodal;
