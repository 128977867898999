import React, { useEffect, useState } from "react";
import "./cashbackwallet.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Cashbackmodal from "./cashbackmodal";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Cashbackwallet() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [balance, setBalance] = useState("");
  const navigate = useNavigate();
  const [cashHis, setCashHistory] = useState([]);
  const [gettingWallet, setGettingWallet] = useState(false);
  const [lowMsg, setLowMsg] = useState(false);

  useEffect(() => {
    if (!auth) {
      navigate("/");
    }

    setGettingWallet(true);
    getCashbackWallet();
    getCashbackHistory();
  }, []);


   const handleData = () =>{
    getCashbackWallet();
    getCashbackHistory();
   }
    const getCashbackWallet = async () => {

    const apicall = await AfterLoginService.GetCashbackWallet(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setBalance(resp);
          setLowMsg(true);
          setGettingWallet(false);
        } else {
          toast(resp.message);
        }
      });
    } else {
    }
  };

  const getCashbackHistory = async () => {
    const apicall = await AfterLoginService.GetCashbackHistory(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setCashHistory(resp.data);
        } else {
          toast(resp.message);
        }
      });
    } else {
    }
  };


  

  // for status button
  function getButtonColor(status) {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "button-pending";
      case "DECLINED":
        return "button-declined";
      case "APPROVED":
        return "button-approved";
      case "PROCESSING":
        return "button-processing";
        case "COMPLETED":
          return "button-completed";
      default:
        return "";
    }
  }

  const goBack = () => navigate(-1);

  return (
    <div className="container-fluid position-relative d-flex p-0 wallet-container">
      <Header />
      <div className="content">
        <Subheader />
        <div className="container-fluid pt-4 px-4 wallet-table">
          <div className="row">
            {gettingWallet ? (
              <>
                <div className="col-md-12 text-center m-4">
                  <span
                    className="text-white spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="text-white">Loading...</span>
                </div>
              </>
            ) : (
              <div className="col-md-12 col-lg-12">
                <div className="back-icon-main">
                <img src="/img/back.svg" onClick={goBack}  />
            </div>
                <h3 className="textWallet"> Cashback Wallet</h3>
                <p className="textLorem">
                Get cash back on every transaction you make!
                </p>

                <div className="row">
                  <div className="col-lg-9 col-md-12 col-sm-12 sm-12 ">
                    <div className="col-md-12 gx-5 wallet-header  ">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <p className="mt-2 d-flex align-items-center overall ml-3 mt-3">
                            <img src="img/overallicon.svg" />
                            <span className="ms-2 "> Overall Balance</span>
                          </p>
                          <h6 className="h1 dolor-cashback-heading ml-3 mt-1" style={{ fontSize: "40px" }}>
                              <span className="dollor">₦</span>
                              {balance.wallet}
                            </h6>
                        </div>
                      </div>

                      <div className="row mt-5 mx-5 ">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="text-left ">
                            {/* <h6 className="h1 " style={{ fontSize: "40px" }}>
                              <span className="dollor">$</span>
                              {balance.wallet}
                            </h6> */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="cashback-btn-container ">
                            <div className="btn-container  ">
                            {/* <Cashbackmodal Cashbackmodal={balance} /> */}
                            <Cashbackmodal balance={balance} handleData = {handleData} />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div
                        className="ms-3 text-white  "
                        style={{ marginTop: "30px" }}
                      >
                        <p className="mb-2 d-flex align-items-center overall">
                          <img src="img/wallet.png" />
                          <span className="ms-2 "> Overall Balance</span>
                        </p>
                        <h6 className=" h1">
                          <span className="dollor">$</span>
                          {balance.wallet}
                        </h6>
                      </div> */}
                      {/* <div className="btn-container ">
                        <Requestmodal Requestmodal={balance} />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3"> </div>
                </div>
                <div className="row ">
                  <div className="col-lg-5 col-md-7 col-sm-12 col-12">
                    {lowMsg?(<>
                      {balance.wallet < 1000 ? (
                      // <button className="btn btn-request">
                      <div className="pops-msg">
                        <p>
                          <img
                            src="/img/info-circle.svg"
                            className="cirle-info"
                          />
                          <span className=" msg-p">
                          Your balance is too low to initiate a withdrawal process
                          </span>
                          {/* </button> */}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    </>):(<></>)}
                   
                  </div>
                  <div className="col-lg-7 col-md-5 col-sm-12 col-12"></div>
                </div>
                <div className="container-fluid pt-4  wallet-table ">
                  <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12 ">
                      <div className="cashback-wallet-scroll">
                        {/* <table style={{background:"#2b2f32",color:"#A4A4A4"}}>
                          <thead >
                          <tr className="fs-13 " style={{marginLeft:"15px"}}>
                              <th className="p-3 " style={{marginLeft:"10px"}}>DETAILS</th>
                              <th className="p-3">TYPE</th>
                              <th className="p-3">STATUS</th>
                              <th className="p-3">DATE</th>
                              </tr>
                          </thead>
                        </table> */}
                        <table class="table table-dark table-border ">
                          <thead>
                            <tr className="fs-13">
                              <th>DETAILS</th>
                              <th className="cashback-desktop-hide"></th>
                              
                              <th className="d-none d-sm-table-cell">TYPE</th>
                              <th>STATUS</th>
                              <th className="d-none d-sm-table-cell">DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cashHis.length > 0 ? (
 
                              cashHis.map((item) => (
 

                                <tr>
                                  <td className="d-flex align-items-center">
                                    <p>
                                    {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
                                  
                                    </p>
                                    <img src="/img/dashbordlist.svg" alt='' className="cahsback-list-icon1"  />
                                    <p className="fs-12 ms-2  cahsback-amount-text ">
                                      <span className="d-block fs-10 cashback-wallet-list-header">
                                        Wallet withdrawal of
                                        <span className="fs-13 cahsback-amount1 cashback-wallet-amount">
                                          {" "}
                                          ₦{item.amount}
                                        </span>
                                      </span>
                                      <p className="fs-12 cashback-mobile-date">{item.timestamp}</p>
                                    </p>
                                    
                                  </td>
                                  <td>
                                    <p className="fs-12 hide-on-mobile">Wallet withdrawal</p>
                                  </td>
                                  {/* <td>
                                    <a href="" className="complete-btn">
                                      {data.status}
                                    </a>
                                  </td>  */}

                                  <td className="cashback-wallet-btn">
                                    <a
                                      
                                      className={`complete-btn ${getButtonColor(
                                        item.status
                                      )}`}
                                    >
                                       {/* <span className="dot">.</span>  */}
                                       {item.status.toUpperCase()}
                                    </a>
                                  </td>

                                  <td className="hide-on-mobile">
                                    <p className="fs-12">{item.timestamp}</p>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={4}>
                                    {/* {" "}
                                    <h6 className="text-center w-100">
                                      Data not found
                                    </h6> */}

                                    <div className="no-cashback-transaction">
                                      <img
                                        src="img/EmptyState.svg"
                                        alt=""
                                        style={{
                                          margin: "auto",
                                          marginTop: "60px",
                                          height: "120px",
                                        }}
                                      />
                                      <h4 className="not-transction-wallat">
                                        Transaction
                                      </h4>

                                      <h5 className="not-transction-wallet-p text-center">
                                        You don't have any pending or completed
                                        transction.
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <div></div>
                        </table>
                        <div className="pagination d-none">
                          <div>
                            <a href="">
                              <i class="bi bi-chevron-left"></i>
                            </a>
                            <ul>
                              <li>1</li>
                              <li>2</li>
                              <li>3</li>
                              <li>4</li>
                              <li>10</li>
                            </ul>
                            <a className="next-btn" href="">
                              <i class="bi bi-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cashbackwallet;











// old

// import React, { useEffect, useState } from "react";
// import "./cashbackwallet.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import Cashbackmodal from "./cashbackmodal";
// import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
// import { useNavigate } from "react-router";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function Cashbackwallet() {
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [balance, setBalance] = useState("");
//   const navigate = useNavigate();
//   const [cashHis, setCashHistory] = useState([]);
//   const [gettingWallet, setGettingWallet] = useState(false);
//   const [lowMsg, setLowMsg] = useState(false);

//   useEffect(() => {
//     if (!auth) {
//       navigate("/");
//     }

//     setGettingWallet(true);
//     getCashbackWallet();
//     getCashbackHistory();
//   }, []);


//    const handleData = () =>{
//     getCashbackWallet();
//     getCashbackHistory();
//    }
//     const getCashbackWallet = async () => {

//     const apicall = await AfterLoginService.GetCashbackWallet(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setBalance(resp);
//           setLowMsg(true);
//           setGettingWallet(false);
//         } else {
//           toast(resp.message);
//         }
//       });
//     } else {
//     }
//   };

//   const getCashbackHistory = async () => {
//     const apicall = await AfterLoginService.GetCashbackHistory(auth);
//     if (apicall.state === true) {
//       apicall.result.json().then((resp) => {
//         if (resp.status == 200) {
//           setCashHistory(resp.data);
//         } else {
//           toast(resp.message);
//         }
//       });
//     } else {
//     }
//   };


  

//   // for status button
//   function getButtonColor(status) {
//     switch (status.toUpperCase()) {
//       case "PENDING":
//         return "button-pending";
//       case "DECLINED":
//         return "button-declined";
//       case "APPROVED":
//         return "button-approved";
//       case "PROCESSING":
//         return "button-processing";
//         case "COMPLETED":
//           return "button-completed";
//       default:
//         return "";
//     }
//   }

//   const goBack = () => navigate(-1);

//   return (
//     <div className="container-fluid position-relative d-flex p-0 wallet-container">
//       <Header />
//       <div className="content">
//         <Subheader />
//         <div className="container-fluid pt-4 px-4 wallet-table">
//           <div className="row">
//             {gettingWallet ? (
//               <>
//                 <div className="col-md-12 text-center m-4">
//                   <span
//                     className="text-white spinner-border spinner-border-sm"
//                     role="status"
//                     aria-hidden="true"
//                   ></span>{" "}
//                   <span className="text-white">Loading...</span>
//                 </div>
//               </>
//             ) : (
//               <div className="col-md-12 col-lg-12">
//                 <div className="back-icon-main">
//               <a href="#">
//                 <img src="/img/CaretLeft1.svg" onClick={goBack}/>
//               </a>
//             </div>
//                 <h3 className="textWallet"> Cashback Wallet</h3>
//                 <p className="textLorem">
//                 Get cash back on every transaction you make!
//                 </p>

//                 <div className="row">
//                   <div className="col-lg-9 col-md-12 col-sm-12 sm-12 ">
//                     <div className="col-md-12 gx-5 wallet-header  ">
//                       <div className="row">
//                         <div className="col-lg-12 col-md-12 col-sm-12 col-12">
//                           <p className="mt-2 d-flex align-items-center overall ml-3 mt-3">
//                             <img src="img/overallicon.svg" />
//                             <span className="ms-2 "> Overall Balance</span>
//                           </p>
//                           <h6 className="h1 dolor-cashback-heading ml-3 mt-1" style={{ fontSize: "40px" }}>
//                               <span className="dollor">₦</span>
//                               {balance.wallet}
//                             </h6>
//                         </div>
//                       </div>

//                       <div className="row mt-5 mx-5 ">
//                         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
//                           <div className="text-left ">
//                             {/* <h6 className="h1 " style={{ fontSize: "40px" }}>
//                               <span className="dollor">$</span>
//                               {balance.wallet}
//                             </h6> */}
//                           </div>
//                         </div>
//                         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
//                           <div className="cashback-btn-container ">
//                             <div className="btn-container  ">
//                             {/* <Cashbackmodal Cashbackmodal={balance} /> */}
//                             <Cashbackmodal balance={balance} handleData = {handleData} />
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       {/* <div
//                         className="ms-3 text-white  "
//                         style={{ marginTop: "30px" }}
//                       >
//                         <p className="mb-2 d-flex align-items-center overall">
//                           <img src="img/wallet.png" />
//                           <span className="ms-2 "> Overall Balance</span>
//                         </p>
//                         <h6 className=" h1">
//                           <span className="dollor">$</span>
//                           {balance.wallet}
//                         </h6>
//                       </div> */}
//                       {/* <div className="btn-container ">
//                         <Requestmodal Requestmodal={balance} />
//                       </div> */}
//                     </div>
//                   </div>
//                   <div className="col-lg-3 col-md-3"> </div>
//                 </div>
//                 <div className="row ">
//                   <div className="col-lg-5 col-md-7 col-sm-12 col-12">
//                     {lowMsg?(<>
//                       {balance.wallet < 1000 ? (
//                       // <button className="btn btn-request">
//                       <div className="pops-msg">
//                         <p>
//                           <img
//                             src="/img/info-circle.svg"
//                             className="cirle-info"
//                           />
//                           <span className=" msg-p">
//                           Your balance is too low to initiate a withdrawal process
//                           </span>
//                           {/* </button> */}
//                         </p>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                     </>):(<></>)}
                   
//                   </div>
//                   <div className="col-lg-7 col-md-5 col-sm-12 col-12"></div>
//                 </div>
//                 <div className="container-fluid pt-4  wallet-table ">
//                   <div className="row">
//                     <div className="col-lg-9 col-md-12 col-sm-12 ">
//                       <div className="cashback-wallet-scroll">
//                         {/* <table style={{background:"#2b2f32",color:"#A4A4A4"}}>
//                           <thead >
//                           <tr className="fs-13 " style={{marginLeft:"15px"}}>
//                               <th className="p-3 " style={{marginLeft:"10px"}}>DETAILS</th>
//                               <th className="p-3">TYPE</th>
//                               <th className="p-3">STATUS</th>
//                               <th className="p-3">DATE</th>
//                               </tr>
//                           </thead>
//                         </table> */}
//                         <table class="table table-dark table-border ">
//                           <thead>
//                             <tr className="fs-13">
//                               <th>DETAILS</th>
//                               <th className="d-none d-sm-table-cell">TYPE</th>
//                               <th>STATUS</th>
//                               <th className="d-none d-sm-table-cell">DATE</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {cashHis.length > 0 ? (
 
//                               cashHis.map((item) => (
 

//                                 <tr>
//                                   <td className="d-flex align-items-center">
//                                     <p>
//                                     {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
                                  
//                                     </p>
//                                     <img src="/img/dashbordlist.svg" alt='' className="cahsback-list-icon1"  />
//                                     <p className="fs-12 ms-2 cahsback-amount-text1 ">
//                                       <span className="d-block fs-10 cashback-wallet-list-header">
//                                         Wallet withdrawal of
//                                         <span className="fs-13 cahsback-amount1 cashback-wallet-amount">
//                                           {" "}
//                                           ₦{item.amount}
//                                         </span>
//                                       </span>
//                                     </p>
//                                   </td>
//                                   <td>
//                                     <p className="fs-12 cashback-Wallet-withdrawal">Wallet withdrawal</p>
//                                   </td>
//                                   {/* <td>
//                                     <a href="" className="complete-btn">
//                                       {data.status}
//                                     </a>
//                                   </td>  */}

//                                   <td className="cashback-wallet-btn">
//                                     <a
                                      
//                                       className={`complete-btn ${getButtonColor(
//                                         item.status
//                                       )}`}
//                                     >
//                                        <span className="dot">.</span> {item.status.toUpperCase()}
//                                     </a>
//                                   </td>

//                                   <td className="cashback-Wallet-date">
//                                     <p className="fs-12">{item.timestamp}</p>
//                                   </td>
//                                 </tr>
//                               ))
//                             ) : (
//                               <>
//                                 <tr>
//                                   <td colSpan={4}>
//                                     {/* {" "}
//                                     <h6 className="text-center w-100">
//                                       Data not found
//                                     </h6> */}

//                                     <div className="no-cashback-transaction">
//                                       <img
//                                         src="img/EmptyState.svg"
//                                         alt=""
//                                         style={{
//                                           margin: "auto",
//                                           marginTop: "60px",
//                                           height: "120px",
//                                         }}
//                                       />
//                                       <h4 className="not-transction-wallat">
//                                         Transaction
//                                       </h4>

//                                       <h5 className="not-transction-wallet-p text-center">
//                                         You don't have any pending or completed
//                                         transction.
//                                       </h5>
//                                     </div>
//                                   </td>
//                                 </tr>
//                               </>
//                             )}
//                           </tbody>
//                           <div></div>
//                         </table>
//                         <div className="pagination d-none">
//                           <div>
//                             <a href="">
//                               <i class="bi bi-chevron-left"></i>
//                             </a>
//                             <ul>
//                               <li>1</li>
//                               <li>2</li>
//                               <li>3</li>
//                               <li>4</li>
//                               <li>10</li>
//                             </ul>
//                             <a className="next-btn" href="">
//                               <i class="bi bi-chevron-right"></i>
//                             </a>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-3 col-md-12 col-sm-12"></div>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Cashbackwallet;





