import React, { useState } from "react";
import BTCWalletService from "../../Services/BTCService/BTCWalletService";
import { toast } from "react-toastify";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal sell-btc">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sellbtc = ({ amount, closePreviousModal, setRequiredError }) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminRate, setAdminRate] = useState("");
  const [btcEquivalentAmount, setBtcEquivalentAmount] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [sending, setSending] = useState(false);

  const openSecModal = async () => {
    if (!amount) {
      setRequiredError(true);
      return;
    }
    setLoading(true);
    const { result } = await BTCWalletService.GetBTCRate();
    const btcEquivalent = amount / result;

    const auth = JSON.parse(localStorage.getItem("user"));
    const { result: tokenData } =
      await BTCWalletService.GenerateThirdPartyToken(auth);
    const token = tokenData.data.token;
    setBtcEquivalentAmount(btcEquivalent);

    const { result: blockIoAdmin } = await BTCWalletService.GetBlockIOAdmin(
      auth
    );
    setAdminRate(blockIoAdmin.data.block_io_rate);

    const roundedNum = btcEquivalent.toFixed(6);
    const body = {
      amount: roundedNum,
      to_address: blockIoAdmin.data.block_io_address,
    };

    const data = await BTCWalletService.checkTransactionValidity(token, body);
    if (!data.state) setErrorMessage(data.result.message);
    else setIsFirstModalOpen(true);
    setLoading(false);
  };

  const sellBtc = async () => {
    setSending(true);
    const auth = JSON.parse(localStorage.getItem("user"));
    const { result: tokenData } =
      await BTCWalletService.GenerateThirdPartyToken(auth);

    const { result: blockIoAdmin } = await BTCWalletService.GetBlockIOAdmin(
      auth
    );
    const { result: getBtcWalletData } = await BTCWalletService.GetBTCWallet(
      auth
    );
    const { result: btcRateData } = await BTCWalletService.GetBTCRate();

    const token = tokenData.data.token;
    const roundedNum = btcEquivalentAmount.toFixed(6);
    const body = {
      amount: roundedNum,
      to_address: blockIoAdmin.data.block_io_address,
    };
    const { result: sendData } = await BTCWalletService.sendBTC(token, body);
    // const sendData = {
    //   status: "success",
    //   data: {
    //     network: "BTC",
    //     txid: "f00fdec06b25dbecc994abd01f4b7f70bcf8bad80d80a258a0cb99f36c14d081",
    //   },
    // };

    const saveSendBtcRecord = await BTCWalletService.saveTransaction(
      auth,
      roundedNum,
      sendData.data.txid,
      blockIoAdmin.data.block_io_rate,
      getBtcWalletData.data.blockio_btc,
      blockIoAdmin.data.block_io_address,
      btcRateData,
      "sell_btc"
    );
    setSending(false);
    setIsFirstModalOpen(false);
    closePreviousModal();
    if (saveSendBtcRecord.state) toast.success("Transaction Successfull");
  };

  const closeFirstModal = () => setIsFirstModalOpen(false);
  return (
    <div>
      <button
        className={`btn btn-update w-100 `}
        disabled={loading}
        onClick={openSecModal}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{" "}
            checking validity...
          </>
        ) : (
          "Proceed"
        )}
      </button>
      <p className="text-danger text-sm"> {errorMessage}</p>

      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content sell-btc-modal">
                <div className="modal-header">
                  <h5 className="modal-title text-center">
                    Confirm Sell Transaction
                  </h5>

                  <button
                    type="button"
                    className="close"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="sell-btc-p">
                  {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
                </div>
                <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col">
                  {/* <p>
                    Procced to selling BTC
                    <span className="d-block">
                      {" "}
                      {btcEquivalentAmount.toFixed(6)} worth ${amount} to{" "}
                    </span>
                    <span className="d-block">
                      {" "}
                      Quchange for ₦{adminRate * amount}{" "}
                    </span>
                  </p>
                  <p>Note : Standard network fee applies.</p> */}

                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <p
                        style={{
                          color: "#7D7DA1",
                          fontSize: "12px",
                          marginTop: "-15px",
                        }}
                      >
                        You are Selling
                      </p>
                      <h3 style={{ color: "#FFFFFF", marginTop: "-10px" }}>
                      {btcEquivalentAmount.toFixed(6)} <span style={{ fontSize: "15px",marginLeft:'-8px' }}>BTC</span>
                      </h3>
                      <p className="sell-para">Rate = {adminRate}</p>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src="/img/vertical.png"
                          style={{ display: "inline-block" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2 text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <p style={{ color: "#7D7DA1", fontSize: "12px" }}>
                        You would receive
                      </p>
                      <h3 style={{ color: "#FFFFFF", marginTop: "-10px" }}>
                      <span className="d-block">
                      {" "}
                      ₦{adminRate * amount}{" "}
                    </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex">
                  <div className="row mx-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-cancle1">
                        <button
                          type="button"
                          className="btn btn-cancel   "
                          onClick={closeFirstModal}
                        >
                          <img src="/img/cancel.png" />
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-update1">
                        <button
                          className={`btn-proceed-2`}
                          disabled={sending}
                          onClick={sellBtc}
                        >
                          {sending ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm "
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            "Proceed"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* 
                  <button
                    className={`btn btn-update w-50 `}
                    disabled={sending}
                    onClick={sellBtc}
                  >
                    {sending ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm "
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Loading...
                      </>
                    ) : (
                      "Proceed"
                    )}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sellbtc;

// company code

// import React, { useState } from "react";
// import BTCWalletService from "../../Services/BTCService/BTCWalletService";
// import { toast } from "react-toastify";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal sell-btc">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Sellbtc = ({ amount, closePreviousModal,setRequiredError }) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [adminRate, setAdminRate] = useState("");
//   const [btcEquivalentAmount, setBtcEquivalentAmount] = useState();
//   const [errorMessage, setErrorMessage] = useState("");
//   const [sending, setSending] = useState(false);

//   const openSecModal = async () => {
//     if (!amount) {
//       setRequiredError(true);
//       return;
//     }
//     setLoading(true);
//     const { result } = await BTCWalletService.GetBTCRate();
//     const btcEquivalent = amount / result;

//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result: tokenData } =
//       await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = tokenData.data.token;
//     setBtcEquivalentAmount(btcEquivalent);

//     const { result: blockIoAdmin } = await BTCWalletService.GetBlockIOAdmin(
//       auth
//     );
//     setAdminRate(blockIoAdmin.data.block_io_rate);

//     const roundedNum = btcEquivalent.toFixed(6);
//     const body = {
//       amount: roundedNum,
//       to_address: blockIoAdmin.data.block_io_address,
//     };

//     const data = await BTCWalletService.checkTransactionValidity(token, body);
//     if (!data.state) setErrorMessage(data.result.message);
//     else setIsFirstModalOpen(true);
//     setLoading(false);
//   };

//   const sellBtc = async () => {
//     setSending(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result: tokenData } =
//       await BTCWalletService.GenerateThirdPartyToken(auth);

//     const { result: blockIoAdmin } = await BTCWalletService.GetBlockIOAdmin(
//       auth
//     );
//     const { result: getBtcWalletData } = await BTCWalletService.GetBTCWallet(
//       auth
//     );
//     const { result: btcRateData } = await BTCWalletService.GetBTCRate();

//     const token = tokenData.data.token;
//     const roundedNum = btcEquivalentAmount.toFixed(6);
//     const body = {
//       amount: roundedNum,
//       to_address: blockIoAdmin.data.block_io_address,
//     };
//     const { result: sendData } = await BTCWalletService.sendBTC(token, body);
//     // const sendData = {
//     //   status: "success",
//     //   data: {
//     //     network: "BTC",
//     //     txid: "f00fdec06b25dbecc994abd01f4b7f70bcf8bad80d80a258a0cb99f36c14d081",
//     //   },
//     // };

//     const saveSendBtcRecord = await BTCWalletService.saveTransaction(
//       auth,
//       roundedNum,
//       sendData.data.txid,
//       blockIoAdmin.data.block_io_rate,
//       getBtcWalletData.data.blockio_btc,
//       blockIoAdmin.data.block_io_address,
//       btcRateData,
//       "sell_btc"
//     );
//     setSending(false);
//     setIsFirstModalOpen(false);
//     closePreviousModal();
//     if (saveSendBtcRecord.state) toast.success("Transaction Successfull");
//   };

//   const closeFirstModal = () => setIsFirstModalOpen(false);
//   return (
//     <div>
//       <button
//         className={`btn btn-update w-100 `}
//         disabled={loading}
//         onClick={openSecModal}

//       >
//         {loading ? (
//           <>
//             <span
//               className="spinner-border spinner-border-sm"
//               role="status"
//               aria-hidden="true"
//             ></span>{" "}
//             checking validity...
//           </>
//         ) : (
//           "Proceed"
//         )}
//       </button>
//       <p className="text-danger text-sm"> {errorMessage}</p>

//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className="modal-dialog">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title text-center">Sell BTC</h5>
//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col">
//                   <p>
//                     Procced to selling BTC
//                     <span className="d-block">
//                       {" "}
//                       {btcEquivalentAmount.toFixed(6)} worth ${amount} to{" "}
//                     </span>
//                     <span className="d-block">
//                       {" "}
//                       Quchange for ₦{adminRate * amount}{" "}
//                     </span>
//                   </p>
//                   <p>Note : Standard network fee applies.</p>
//                 </div>
//                 <div className="modal-footer d-flex">
//                   <button
//                     type="button"
//                     className="btn btn-cancel w-50 bg-danger text-white "
//                     onClick={closeFirstModal}
//                   >
//                     Back
//                   </button>
//                   <button
//                     className={`btn btn-update w-50 `}
//                     disabled={sending}
//                     onClick={sellBtc}
//                   >
//                     {sending ? (
//                       <>
//                         <span
//                           className="spinner-border spinner-border-sm "
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         Loading...
//                       </>
//                     ) : (
//                       "Proceed"
//                     )}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Sellbtc;
