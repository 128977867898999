import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
 
const MobileTransactionDetails = ({isOpenMTran,setIsOpenMTran,gdetails,setIsOpenGTran}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const goBack = () => {
    setIsOpenMTran(false)
    setIsOpenGTran(true)
  };

  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "SUCCESSFUL":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }
  return (
            <div>
                    {isOpenMTran &&(
                        <>
                        <div className="row pt-4">
                            <div className="back-icon-main d-block">
                                <img src="../img/CaretLeft1.svg" onClick={goBack} />
                            </div>
                            <div className="col-md-12">
                                <div>
                                    <h3 className="heading-transaction mb-0">Transaction Details</h3>
                                </div>    
                            </div>
                            <div className="col-md-6">
                                <div className="det-1">
                                    <p className={`complete-btn d-inline ${getButtonColor(
                                        gdetails?.status
                                                )}`}>
                                        {gdetails.status}
                                    </p>
                                    <p style={{color:"#939394",marginTop:"5px"}}>Airtime Transaction Details</p>
                                </div>
                                <table className="table confT">
                                    <tbody>
                                        <tr>
                                            <td>Transaction Id</td>
                                            <td className="tright">#{gdetails?gdetails.transactionId:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Operator Name</td>
                                            <td className="tright">{gdetails?gdetails.operatorName:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td className="tright">{gdetails?gdetails.recipientPhone:''} </td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Date & Time</td>
                                            <td className="tright">{gdetails?gdetails.created_at1:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td className="tright">NGN {gdetails?gdetails.amount:''} ({gdetails?gdetails.deliveredAmountCurrencyCode:''} {gdetails?gdetails.deliveryAmount:''})</td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Total Fee</td>
                                            <td className="tright">NGN {gdetails?gdetails.transactionFee:''}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Deducted Amount</td>
                                            <td className="tright">NGN {gdetails?gdetails.totalWalletDeduct	:''}</td>
                                        </tr>
                                        {gdetails.order_json.pinDetail?  (<>
                                            <tr>
                                                <td>Pin Code</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.code:''}</td>
                                            </tr>
                                        </>):""}

                                        {gdetails.order_json.pinDetail? (<>
                                            <tr>
                                                <td>Pin Info 1</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.info1:''}</td>
                                            </tr>
                                        </>):""}
                                        {gdetails.order_json.pinDetail? (<>
                                            <tr>
                                                <td>Pin Info 2</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.info2:''}</td>
                                            </tr>
                                        </>):''}
                                       {gdetails.order_json.pinDetail? (<>
                                            <tr>
                                                <td>Pin Info 3</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.info3:''}</td>
                                            </tr>
                                       </>):''}
                                       {gdetails.order_json.pinDetail? (<>
                                            <tr>
                                                <td>Pin IVR</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.ivr:''}</td>
                                            </tr>
                                       </>):''}
                                       {gdetails.order_json.pinDetail? (<>
                                            <tr>
                                                <td>Pin Serial</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.serial:''}</td>
                                            </tr>
                                       </>):''}
                                       {gdetails.order_json.pinDetail ? (<>
                                            <tr>
                                                <td>Pin Validate</td>
                                                <td className="tright">{gdetails?gdetails.order_json.pinDetail.validity:''}</td>
                                            </tr>
                                       </>):''}
                                      
                                       
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                        </>
                    )}
            </div>
  );
};
 
export default MobileTransactionDetails;