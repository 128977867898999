import React, { useState } from "react";
import "./subheader.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dropdown from "react-bootstrap/Dropdown";
import Requestaccount from "./requestaccount";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const Modal = ({ onClose, children }) => {
  return (
    <div className="modal profile-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Profilemodal = () => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const styleObj = {
    color:'white',
    textDecoration:'none'
}
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  // Copy Paste

  const [copied, setCopied] = useState(false);

  const copyReferralCode = () => {
    const referralCode = auth ? auth.user.referral_code : "";
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy referral code: ", error);
      });
  };

  return (
    <div>
      <div className="nav-item dropdown" onClick={openFirstModal}>
        <img
          className="rounded-circle1 me-lg-2 ms-3 profile-icon"
          src="img/profile.svg"
          alt=""
          onClick={openFirstModal}
        />
        <a href="#" className="nav-link pt-3  profile-dropdown">
          <p>
            {auth ? auth.user.firstname : ""} {auth ? auth.user.Lastname : ""}
            <span className="d-block">{auth ? auth.user.email : ""}</span>
          </p>
          <Dropdown>
            <Dropdown.Toggle>
              <KeyboardArrowDownIcon
                className="text-white"
                onClick={openFirstModal}
              />
            </Dropdown.Toggle>
          </Dropdown>
        </a>
      </div>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal" onClick={closeFirstModal}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="profile-header d-flex align-items-center mb-3">
                    <img
                      className="rounded-circle me-lg-2 ms-4"
                      src="img/profile.svg"
                    />
                    <p className="text-white mb-0 pl-2">
                      {auth ? auth.user.firstname : ""}{" "}
                      {auth ? auth.user.Lastname : ""}
                      <span className="d-block">
                        {auth ? auth.user.email : ""}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="drag-drop-profile mb-4 ml-3">
                    <div class="copy-code text-gray small font-light bg-black p-1">
                      <p className=" referralHeading">
                        <Link
                          to="/Referral"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Referral Code{" "}
                        </Link>
                      </p>
                      <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-10">
                          <h5 className="profile-referral ml-1  ">
                            {auth ? auth.user.referer : ""}
                            {auth ? auth.user.referral_code : ""}{" "}
                          </h5>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 ">
                          <div
                            className="copy-icon-container "
                            onClick={copyReferralCode}
                       
                          >
                          
                                 <ContentCopyIcon className="text-light" />
                            {copied && (
                              <div className="copied-msg">Copied!</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                  <div className="">
                    <p className="text-white">
                      <i class="bi bi-people"></i>
                      <span className="ms-4 small font-light text-light">
                        {" "}
                        <Link to="/Referral" style={styleObj}>
                        Referral
                        </Link>
                      </span>
                    </p>
                  </div>
                  <div className="border-bottom border-secondary mb-3 d-none">
                    <Requestaccount />
                  </div>
                  <div className="">
                    <p className="text-danger">
                      <i class="bi bi-box-arrow-right"></i>
                      <span
                        className="ms-4 small font-light text-white"
                        onClick={logout}
                      >
                        {" "}
                        Logout
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="text-secondary text-center small font-light">
                      Quchange - V485
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Profilemodal;

//old code

// import React, { useState } from 'react';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Requestaccount from './requestaccount';
// import { useNavigate } from 'react-router';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal profile-modal">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Profilemodal = () => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const auth = JSON.parse(localStorage.getItem('user'));
//   const navigate = useNavigate();

//   const logout= ()=>{
//     localStorage.clear();
//     navigate('/');
//   }

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   return (
//     <div>

//         <div className="nav-item dropdown" onClick={openFirstModal} >
//         <img className="rounded-circle me-lg-2 ms-3" src="img/profile.png" alt="" onClick={openFirstModal}/>
//         <a href="#" className="nav-link pt-3 profile-dropdown">
//             <p>{auth?auth.user.firstname:''} {auth?auth.user.Lastname:''}
//             <span className='d-block'>{auth?auth.user.email:''}</span>
//         </p>
//         <Dropdown>
//         <Dropdown.Toggle>
//             <KeyboardArrowDownIcon className='text-white' onClick={openFirstModal}  />
//         </Dropdown.Toggle>
//         </Dropdown>
//         </a>
//         </div>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal" onClick={closeFirstModal}>
//             <div className='modal-dialog'>
//                 <div className="modal-content">
//                 <div className="modal-header">
//                 <div className='profile-header d-flex align-items-center mb-3'>
//                     <img className='rounded-circle me-lg-2 ms-4' src='img/profile.png'/>
//                     <p className='text-white mb-0'>{auth?auth.user.firstname:''} {auth?auth.user.Lastname:''}
//                         <span className='d-block'>{auth?auth.user.email:''}</span>
//                     </p>
//                 </div>
//                 </div>
//                 <div className="modal-body">
//                 <div class="copy-code text-white small font-light bg-black p-1">
//                     <p className='ms-3'>Referral Code</p>
//                     <h5 className='small font-light justify-evenly d-flex'>{auth?auth.user.referer:''}
//                     <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium copy-icon css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ContentCopyIcon"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
//                     </svg>
//                     </h5>
//                 </div>
//                 <div className=''>
//                 <p className='text-white'><i class="bi bi-people"></i><span className='ms-4 small font-light'> Referral</span></p>
//                 </div>
//                 <div className='border-bottom border-secondary mb-3' >
//                     <Requestaccount />
//                 </div>
//                 <div className=''>
//                     <p className='text-danger'><i class="bi bi-box-arrow-right"></i><span className='ms-4 small font-light text-white' onClick={logout}> Logout</span></p>
//                 </div>
//                 <div>
//                     <p className='text-secondary text-center small font-light'>Quchange - V485</p>
//                 </div>

//                 </div>
//                 </div>
//             </div>
//             </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Profilemodal;
