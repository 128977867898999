import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Trc20Services from "../../Services/LoginSignService/Trc20Service";
import Selltron from "./selltron";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sellmodal = ({ refreshHistory }) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);

  const [token, setToken] = useState("");
  const [uiAmount, setUiAmount] = useState(0);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  // mui css
  const textFieldStyles = {
    background: "#6C6C6C ",
    width: "100%",
     height: "30px",
    borderRadius: "2px",
  };
  return (
    <div>
      <Avatar className="bg-receive" alt="" onClick={openFirstModal}>
        <img src="img/sell1.svg" />
      </Avatar>
      <p className="text-white small text-center" style={{ fontSize: "18px" }}>
        Sell
      </p>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div
                className="modal-content sell-modal"
                style={{ backgroundColor: "transparent" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title">
                    Sell (USDT & TRON)
                    <span
                      className="d-block"
                      style={{
                        color: "#7D7DA1",
                        fontSize: "12px",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur.
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close-sell-btn"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <form _lpchecked="1" style={{ marginTop: "-15px" }}>
                    <div className="form-group w-100">
                      <select
                        class="form-select"
                         label="Default select example"
                        onChange={(e) => setToken(e.target.value)}
                         style={{height:'80px', fontSize:'17px',fontWeight:'400'}}
                      >
                        <option selected style={{fontSize:'14px'}}>Select Token</option>
                        <option value="trx" style={{fontSize:'14px'}}>TRX</option>
                        <option value="usdt" style={{fontSize:'14px'}}>USDT</option>
                      </select>
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="text"
                        // className="form-control mt-2"
                        onChange={(e) => setUiAmount(e.target.value)}
                        label={
                          <span style={{ color: "#ffffff" }}>
                            {" "}
                            Enter Amount{" "}
                          </span>
                        }
                        variant="filled"
                        style={{
                          ...textFieldStyles,
                        }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer ">
                <div className="w-100">
                  <Selltron
                 
                 token={token}
                 uiAmount={uiAmount}
                 setIsFirstModalOpen={setIsFirstModalOpen}
                 refreshHistory={refreshHistory}
               />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sellmodal;




// company code

// import React, { useState } from 'react';
// import Avatar from '@mui/material/Avatar';
// import Trc20Services from "../../Services/LoginSignService/Trc20Service";
// import Selltron from './selltron';

// import { toast } from 'react-toastify';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-modal">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Sellmodal = ({refreshHistory}) => {
//   const auth = JSON.parse(localStorage.getItem("user"));
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);

//   const [token, setToken] = useState('');
//   const [uiAmount, setUiAmount] = useState(0);

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   return (
//     <div>
//       <Avatar className='bg-receive' alt='' onClick={openFirstModal}>
//         <img src='img/sell.png' />
//       </Avatar>
//       <p className='text-white small text-center'>Sell</p>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className='modal-dialog'>
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title">
//                     Sell (USDT & TRON)
//                     <span className='d-block'>
//                       Lorem ipsum dolor sit amet consectetur.
//                     </span>
//                   </h5>
//                   <button type="button" className="close" onClick={closeFirstModal}>
//                     <img src='img/close-icon.png' />
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1">
//                     <div className="form-group w-75 mb-3">
{
  /* <select class="form-select" aria-label="Default select example" onChange={(e) => setToken(e.target.value)}>
  <option selected>Select Token</option>
  <option value="trx">TRX</option>
  <option value="usdt">USDT</option>
</select> */
}
//                     </div>
//                     <div className="form-group w-75">
//                       <input type="text" className="form-control mb-3" id="name" placeholder="Enter Amount" onChange={(e) => setUiAmount(e.target.value)} />
//                     </div>
//                   </form>
//                 </div>
//                 <div className="modal-footer">

//                   <Selltron token={token} uiAmount={uiAmount} setIsFirstModalOpen={setIsFirstModalOpen} refreshHistory={refreshHistory}/>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}

//     </div>
//   );
// };

// export default Sellmodal;
