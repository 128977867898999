import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Giftsuccess from "./giftsuccess";
import "../scss/giftconfirmmodal.scss";

const Giftconfirmmodal = ({
  handleProceed,
  proceedModal,
  setProceedModal,
  total,
  setConfirmModal,
  confirmModal,
  gitCardCurrency,
  handleConfirm,
  qty,
}) => {
  const [proceedClicked, setProceedClicked] = useState(false);
  const [giftSuccessShown, setGiftSuccessShown] = useState(false);

  useEffect(() => {
    if (!giftSuccessShown) {
      setProceedClicked(false);
    }
  }, [giftSuccessShown]);

  const closeFirstModal = () => {
    setProceedModal(false);
  };

  const handleProceedClick = () => {
    if (!proceedClicked) {
      setProceedClicked(true);
      handleProceed();
    }
  };

  const handleGiftSuccessClose = () => {
    setGiftSuccessShown(false);
  };

  return (
    <>
      {proceedModal && (
        <>
          <Giftsuccess
            handleProceed={handleProceed}
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            handleConfirm={handleConfirm}
            onClose={handleGiftSuccessClose}
            onShow={() => setGiftSuccessShown(true)}
          />
          <div className="modal gift-confirm !z-[1025] ">
            <div className="modal-content ">
              <div className="modal ">
                <div className="modal-dialog !bg-[#e2dddd1a] modal-card">
                  <div className="modal-content !bg-white  ">
                    <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col w-100">
                      <p className="h4 text-white confirm-header mt-5">
                        Confirm Transaction
                      </p>
                      <p className="mt-3" style={{ color: "#68687C" }}>
                        You are about to sell {qty !== 0 && qty}{" "}
                        <span className="text-white ">
                          {gitCardCurrency?.name}{" "}
                        </span>
                        <span className="d-block text-light ">
                          Cards
                          <span className="ml-2" style={{ color: "#68687C" }}>
                            with
                          </span>{" "}
                          <span className="text-white"> ₦{total}</span>
                        </span>
                      </p>
                    </div>
                    <div className="modal-footer d-flex ">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="btn-cancle1">
                            <button onClick={closeFirstModal}>
                              <img src="/img/cancel.svg" />
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="btn-update-confirm">
                            {!giftSuccessShown && (
                              <button
                                className="proceed-p"
                                onClick={handleProceedClick}
                                disabled={proceedClicked}
                              >
                                Proceed
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Giftconfirmmodal;





// 12/20/23 good code without loader on proceed btn

// import React from "react";
// import Avatar from "@mui/material/Avatar";
// import Giftsuccess from "./giftsuccess";
// import "../scss/giftconfirmmodal.scss";

// const Giftconfirmmodal = ({
//   handleProceed,
//   proceedModal,
//   setProceedModal,
//   total,
//   setConfirmModal,
//   confirmModal,
//   gitCardCurrency,
//   handleConfirm,
//   qty,
// }) => {
//   const closeFirstModal = () => {
//     setProceedModal(false);
//   };

//   return (
//     <>
//       {proceedModal && (
//         <>
//           <Giftsuccess
//             handleProceed={handleProceed}
//             confirmModal={confirmModal}
//             setConfirmModal={setConfirmModal}
//             handleConfirm={handleConfirm}
//           />
//           <div className="modal gift-confirm !z-[1025] ">
//             <div className="modal-content ">
//               <div className="modal ">
//                 <div className="modal-dialog !bg-[#e2dddd1a] modal-card" >
//                   <div className="modal-content !bg-white  ">
                    // {/* <div className="modal-header d-flex justify-content-end align-items-end w-100">
                    //   <button
                    //     type="button"
                    //     className="close"
                    //     onClick={closeFirstModal}
                    //     style={{
                    //       background: "#313131",
                    //       color: "#FFFFFF",
                    //       padding: "1px",

                    //     }}
                    //   >
                    //     <img src="img/close-icon.png" alt="" />
                    //   </button>
                    // </div> */}
                    // <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col w-100">
                    //   {/* <Avatar className="bg-success mb-5 ">
                    //     <img src='/img/balance.png'  />
                    //   </Avatar> */}
                    //   <p className="h4 text-white confirm-header mt-5">
                    //     Confirm Transaction
                    //   </p>
                    //   <p className="mt-3" style={{ color: "#68687C" }}>
                    //     You are about to sell {qty !== 0 && qty}{" "}
                    //     <span className="text-white ">
                    //       {gitCardCurrency?.name}{" "}
                    //     </span>
                    //     <span className="d-block text-light ">
                    //       {" "}
                    //       Cards
                    //       <span className="ml-2" style={{ color: "#68687C" }}>
                    //         with
                    //       </span>{" "}
                    //       <span className="text-white"> ₦{total}</span>
                    //     </span>
                    //   </p>
                    // </div>
//                     <div className="modal-footer d-flex ">
//                       <div className="row">
//                         <div className="col-lg-6 col-md-6 col-sm-6 col-6">
//                           <div className="btn-cancle1">
//                             <button onClick={closeFirstModal}>
//                               <img src="/img/cancel.svg" />
//                             </button>
//                           </div>
//                         </div>
//                         <div className="col-lg-6 col-md-6 col-sm-6 col-6">
//                           <div className="btn-update-confirm ">
//                             <button
//                             className='proceed-p'
//                               onClick={handleProceed}
//                             >
//                               Proceed
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </>
//   );
// };

// export default Giftconfirmmodal;





