import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Tokenmodal from "./tokenmodal";
import { useAccordionButton } from "react-bootstrap";
import TextField from "@mui/material/TextField";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sendmodal = ({ refreshHistory }) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressMessage, setAddressMessage] = useState("");

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  // mui css
  const textFieldStyles = {
    background: "#6C6C6C ",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  return (
    <div>
      <Avatar className="bg-white bg-receive" alt="" onClick={openFirstModal}>
        <img src="img/send1.svg" />
      </Avatar>
      <p className="text-white small text-center" style={{ fontSize: "18px" }}>
        Send
      </p>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content trc-send-modal">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Send (USDT & TRON)
                    <span
                      className="d-block"
                      style={{
                        color: "#7D7DA1",
                        fontSize: "12px",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur.
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close-send-btn"
                    onClick={closeFirstModal}
               
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <form _lpchecked="1">
                    <div className="form-group w-100 ">
                      <TextField
                        type="text"
                        //  className="form-control mb-3"
                        id="name"
                        onChange={(e) => {
                          setAddressMessage("");
                          setAddress(e.target.value);
                        }}
                        label={
                          <span style={{ color: "#B8BCC3" }}>
                            Enter the Receive Address
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    {addressMessage && addressMessage !== "" && (
                      <p className="text-[#ff0000]">{addressMessage}</p>
                    )}
                  </form>
                </div>
                <div className="modal-footer">
                  <div style={{ width: "100%" }}>
                    <Tokenmodal
                      address={address}
                      setAddressMessage={setAddressMessage}
                      setIsFirstModalOpen={setIsFirstModalOpen}
                      refreshHistory={refreshHistory}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sendmodal;

// company code

// import React, { useState } from 'react';
// import Avatar from '@mui/material/Avatar';
// import Tokenmodal from './tokenmodal';
// import { useAccordionButton } from 'react-bootstrap';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-modal">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Sendmodal = ({refreshHistory}) => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [address, setAddress] = useState('');
//   const [addressMessage, setAddressMessage] = useState('');
//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   return (
//     <div>
//       <Avatar className='bg-white ' alt='' onClick={openFirstModal}>
//         <img src='img/send.png'/>
//       </Avatar>
//       <p className='text-white small text-center'>Send</p>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className='modal-dialog'>
//                 <div className="modal-content">
//                 <div className="modal-header">
//                     <h5 className="modal-title">
//                         Send (USDT & TRON)
//                         <span className='d-block'>
//                         Lorem ipsum dolor sit amet consectetur.
//                         </span>
//                     </h5>
//                     <button type="button" className="close" onClick={closeFirstModal}>
//                        <img src='img/close-icon.png'/>
//                     </button>
//                 </div>
//                 <div className="modal-body">
//                     <form _lpchecked="1">
//                     <div className="form-group w-75">
//                         <input type="text" className="form-control mb-3" id="name" placeholder="Enter the Receive Address" onChange={(e)=>{setAddressMessage(''); setAddress(e.target.value)}}/>
//                     </div>
//                     {addressMessage && addressMessage!=='' && <p className='text-[#ff0000]'>{addressMessage}</p>}
//                     </form>
//                 </div>
//                 <div className="modal-footer">
//                   <Tokenmodal address={address} setAddressMessage={setAddressMessage} setIsFirstModalOpen={setIsFirstModalOpen} refreshHistory={refreshHistory}/>
//                 </div>
//                 </div>
//             </div>
//             </div>
//         </Modal>
//       )}

//     </div>
//   );
// };

// export default Sendmodal;
