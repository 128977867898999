var BillPurchase = {
    reloadlyService : function(auth,page,limit){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/reloadly-available-services?platform=web`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    getGiftCardList : function(auth,pname,categoryId,page,productId,countryCode){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-productlist?productName=${pname}&productCategoryId=${categoryId}&page=${page}&productId=${productId}&countryCode=${countryCode}`,{
            method:'POST',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    allCountry : function(auth,serviceTypeId){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-available-countries?serviceType=${serviceTypeId}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    allCategory : function(auth,serviceTypeId){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-servicetype-categories?serviceTypeId=${serviceTypeId}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    }, 
    seeRedeemInstruction : function(auth,brandId){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-redeem-instructions?brandId=${brandId}`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    }, 
    getVirtualBalance : function(auth){
        return  fetch(`https://secureapi.thequchangeapp.xyz/api/auth/get-virtual-account-balance`,{
            method:'GET',
            headers:{
            "Content-Type":"application/json",
            "Accept":"application/json",
            "Authorization":'Bearer'+auth.api_access_token
            },
            
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },  
    orderGiftCard:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/order-giftcard",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },  
    giftCardFxRate:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/giftcard-fx-rate",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    }, 
    virtualDetails:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-virtual-account-details",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    }, 
    verifyBvn:function(auth,bvnNumber)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/verify-bvn?bvnNumber="+bvnNumber+"",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    createVirtualAccount:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/create-virtual-account",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    virtualBankList:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-virtual-banks-list",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                }
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    }, 
    getOperator:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-operators",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    createTopupOrder:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/create-topup-order",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    getBiller:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-biller",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    getUtilityTransaction:function(auth,productId='')
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-utility-transactions?transactionId="+productId+"",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    getMobileTopUpTransaction:function(auth,productId='')
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-mobile-topup-transactions?transactionId="+productId+"",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    getGiftCardTransaction:function(auth,productId='')
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-giftcard-transactions?transactionId="+productId+"",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    createUtilityOrder:function(auth,item)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/order-utility-bill",{
                 method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
                body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    showRedeemCode:function(auth,transactionId='')
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/show-redeemcode?id="+transactionId+"",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    },
    getVirtualPassbook:function(auth)
    {
        let au = auth?auth.api_access_token:'';
        return  fetch("https://secureapi.thequchangeapp.xyz/api/auth/get-virtual-passbook-history",{
                 method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json',
                    "Authorization":'Bearer'+au
                },
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
        
    }
}
export default BillPurchase