import React from "react"; // latest
import { Route, Routes, Link } from "react-router-dom";
import "./App.scss";
import Signup from "./Layout/signup/signup";
import Login from "./Layout/login/login";
import Password from "./Layout/password/password";
import Dashboard from "./Layout/dashboard/dashboard";
import Header from "./Layout/header/header";
import Subheader from "./Layout/subheader/subheader";
import Logindetails from "./Layout/logindetails/logindetails";
import Instant from "./Layout/instant/instant";
import Giftcard from "./Layout/giftcard/giftcard";
import Walletdetails from "./Layout/walletdetails/walletdetails";
import Cashbackwallet from "./Layout/cashbackwallet/cashbackwallet";
import Qxchange from "./Layout/qxchange/qxchange";
import Transcation from "./Layout/transcation/transcation";
import Account from "./Layout/account/account";
import Giftguide from "./Layout/giftguide/giftguide";
import Bitcoinguide from "./Layout/bitcoinguide/bitcoinguide";
import Editmodal from "./Layout/account/editnamemodal";
import Trc from "./Layout/trc/trc";
import Referral from "./Layout/referral/referral";
import Cryptosell from "./Layout/cryptosell/cryptosell";
import Billpurchase from "./Layout/billpurchase/billpurchase";
import VirtualAccount from "./Layout/virtualaccount/virtualaccount"
import billTransaction from "./Layout/billpurchase/transaction/transactionList"

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/Signup" Component={Signup} />
        <Route path="/" Component={Login} />
        <Route path="/Password" Component={Password} />
        <Route path="Dashboard" Component={Dashboard} />
        <Route path="/header" Component={Header} />
        <Route path="/subheader" Component={Subheader} />
        <Route path="/Logindetails" Component={Logindetails} />
        <Route path="/Instant" Component={Instant} />
        <Route path="/Giftcard" Component={Giftcard} />
        <Route path="/Walletdetails" Component={Walletdetails} />
        <Route path="/Cashbackwallet" Component={Cashbackwallet} />
        <Route path="/Qxchange" Component={Qxchange} />
        <Route path="/Transcation" Component={Transcation} />
        <Route path="/Account" Component={Account} />
        <Route path="/Giftguide" Component={Giftguide} />
        <Route path="/Bitcoinguide" Component={Bitcoinguide} />
        <Route path="/Editmodal" Component={Editmodal} />
        <Route path="/Trc" Component={Trc} />
        <Route path="Referral" Component={Referral} />
        <Route path="Cryptosell" Component={Cryptosell} />
        <Route path="billpurchase" Component={Billpurchase} />
        <Route path="virtualaccount" Component={VirtualAccount} />
        <Route path="billTransaction" Component={billTransaction}/>
      </Routes>
    </div>
  );
}

export default App;
