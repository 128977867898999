import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import Confirmation from "./confirmation";
import styled from 'styled-components';

const CreateUtility = ({createOpen,setCreateOpen,setIsOpenUtility,billerInfo,virtualAccountInfo}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [openConfirmation,setOpenConfirmation] = useState(false);
  const [subscriberAccountNumber,setSubscriberAccountNumber] = useState("");
  const [amount,setAmount] = useState("");
  const [amountInternational,setAmountInternational] = useState("");
  const [amountId,setAmountId] = useState("");
  const [transactionFee,setTransactionFee] = useState("");
  const [billerName,setBillerName] = useState("");
  const [billerType,setBillerType] = useState("");
  const [fxRate,setFxRate] = useState("");
  const [denominationType,setDenominationType] = useState("")
  const [localTransactionCurrencyCode,setLocalTransactionCurrencyCode] = useState("")
  const [internationalTransactionCurrencyCode,setInternationalTransactionCurrencyCode] = useState("")
  const [localTFee,setLocalTFee] = useState("");
  const [internationalTFee,setInternationalTFee] = useState("");
  const [minITA,setMinITA] = useState("");
  const [maxITA,setMaxITA] = useState("");
  const [billerId,setBillerId] = useState("")
  const [isSelected, setisSelected] = useState(null);

 console.log(minITA)
  const goBack = () => {
    setCreateOpen(false)
    setIsOpenUtility(true)
  };
 
  useEffect(() => {
    
  }, []);
  
  const handlePrevious = ()=>{
    setBillerId(billerInfo?.id)
    setBillerName(billerInfo?.name)
    setBillerType(billerInfo?.type)
    setFxRate(billerInfo?.fx.rate)
    setDenominationType(billerInfo?.denominationType)
    setLocalTransactionCurrencyCode(billerInfo?.localTransactionCurrencyCode)
    setInternationalTransactionCurrencyCode(billerInfo?.internationalTransactionCurrencyCode)
    setLocalTFee(billerInfo?.localTransactionFee)
    setInternationalTFee(billerInfo?.internationalTransactionFee)
    setMinITA(billerInfo.minInternationalTransactionAmount)
    setMaxITA(billerInfo?.maxInternationalTransactionAmount)
    if(subscriberAccountNumber == ""){
        toast("Please enter subscriber account number")
        return false;
    }
    if(amount == ""){
        toast("Please enter amount")
        return false;
    }
    if(billerInfo.minInternationalTransactionAmount == null){

    }else{
    if(amount < billerInfo.minInternationalTransactionAmount){
      toast("Select an amount between "+billerInfo?.internationalTransactionFeeCurrencyCode	+" "+billerInfo.minInternationalTransactionAmount+" to "+billerInfo?.internationalTransactionFeeCurrencyCode	+" "+billerInfo?.maxInternationalTransactionAmount+"");
      return false;
    }
    if(billerInfo?.maxInternationalTransactionAmount < amount){
      toast("Select an amount between "+billerInfo?.internationalTransactionFeeCurrencyCode	+" "+billerInfo.minInternationalTransactionAmount+" to "+billerInfo?.internationalTransactionFeeCurrencyCode	+" "+billerInfo?.maxInternationalTransactionAmount+"");
      return false;
    }}
    setOpenConfirmation(true)
  }

  const confirmClose = ()=>{
    setSubscriberAccountNumber("")
    setAmount("")
    setAmountInternational("")
    setAmountId("")
    setTransactionFee("")
    setBillerName("")
    setBillerType("")
    setFxRate("")
    setDenominationType("")
    setLocalTransactionCurrencyCode("")
    setInternationalTransactionCurrencyCode("")
    setLocalTFee("")
    setInternationalTFee("")
    setMinITA("")
    setMaxITA("")
    setBillerId("")
    setisSelected('-1')
  }
  const BorderCard = styled.div`
    width: 100%;
    box-sizing: border-box;
    color: ${props => (props.selected ? '#fff' : '#7e7a8a')};
    background: ${props => (props.selected ? '#3c0d68' : 'rgb(17, 19, 20)')};
    border: ${props =>
        props.selected ? '1.5px solid #3c0d68' : '1px solid rgb(17, 19, 20)'};
    `;  

    const UtilityCard = ({ item,index,info,internationDetail, selected, onChange }) => {
        return (
          <BorderCard style={{ marginTop: '10px' }} selected={selected} onClick={onChange}>
               <div className="mbcard mb-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="">
                            Description
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="">
                                <p>{info.localTransactionCurrencyCode} {item.amount}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="">
                                <p>{info.internationalTransactionFeeCurrencyCode} {internationDetail[index].amount}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>{item.description}</p>
                        </div>
                    </div>
                </div> 
          </BorderCard>
        );
    }; 

    const handleUtility =(item,index,internationalAmount)=>{
        setisSelected(index)
        setAmount(item.amount)
        setAmountId(item.id)
        setAmountInternational(internationalAmount)
    }
    const handleAmount = (e)=>{
        const reg = /^[.0-9\b]+$/
        let preval=e.target.value
        if (e.target.value === '' || reg.test(e.target.value)){
          setAmount(e.target.value)
          return true
        }else{
          e.target.value = preval.substring(0,(preval.length-1))
        }
      }
  return (
        <div className="">
            {createOpen &&(
                <>
                    <div className=" container-fluid pt-4 px-4 wallet-table dpDesign1">
                        <div className="back-icon-main d-block">
                            <img src="../img/CaretLeft1.svg" onClick={goBack} />
                        </div>
                        <div className="col-md-12">
                            <div>
                                <h3 className="heading-transaction mb-0">Utility & Bills Pay</h3>
                            </div>    
                        </div>
                        <div className="col-md-6">
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="box-3">
                                    <div className="row">
                                        <div className="col-md-2 col-2">
                                            <div className="icon_u">
                                            {
                                              billerInfo.type == 'ELECTRICITY_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/bolt.svg"/>
                                              </>):("")
                                            }
                                            {
                                              billerInfo.type == 'WATER_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/Waterdrop.svg"/>
                                              </>):("")
                                            }
                                            {
                                              billerInfo.type == 'TV_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/TVbill.svg"/>
                                              </>):("")
                                            }
                                             {
                                              billerInfo.type == 'INTERNET_BILL_PAYMENT'?(<>
                                               <img src="../img/utility/internetbill.svg"/>
                                              </>):("")
                                            }
                                            </div>
                                        </div>
                                        <div className="col-md-10 col-10">
                                            <div className="">
                                                <span>{billerInfo?.name}</span><br/>
                                                <span>{billerInfo?.type}</span><br/>
                                                <span>{billerInfo?.serviceType} &nbsp; {billerInfo?.countryName}</span><br/>
                                                {billerInfo?.denominationType == 'RANGE'&&(<>
                                                    <span>Min {billerInfo?.internationalTransactionCurrencyCode} {billerInfo?.minInternationalTransactionAmount} Max {billerInfo?.internationalTransactionCurrencyCode} {billerInfo?.maxInternationalTransactionAmount}</span><br/>
                                                </>)}
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="">
                                    <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter Subscriber Account Number" value={subscriberAccountNumber}
                                        onChange={(e)=>setSubscriberAccountNumber(e.target.value)} />
                                       
                                    </div>
                                </div> 
                                {billerInfo?.denominationType == 'RANGE'?(<>
                                    <div className="col-md-12 mt-2">
                                        <div className="">
                                        <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter Amount"  value={amount}
                                            onChange={handleAmount} />
                                           
                                            <p className="mb-p">Min NGN {billerInfo?.minInternationalTransactionAmount} Max NGN {billerInfo?.maxInternationalTransactionAmount}</p>
                                        </div>
                                    </div> 
                                </>):(<>
                                    <div className="col-md-12 mt-2">
                                        <div className="">
                                            {billerInfo.localFixedAmounts.map((item1,index1)=>(<>
                                                <UtilityCard
                                                 index={index1}
                                                 item={item1}
                                                 info={billerInfo}
                                                 internationDetail={billerInfo.internationalFixedAmounts}
                                                 selected={isSelected === index1}
                                                 onChange={(e) => handleUtility(item1,index1,billerInfo.internationalFixedAmounts[index1].amount)}
                                                />
                                            </>))}
                                           
                                        </div>
                                    </div> 
                                    
                                </>)}
                               
                                <Confirmation openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation} handlePrevious={handlePrevious} subscriberAccountNumber={subscriberAccountNumber}
                                amount={amount}
                                amountId={amountId}
                                transactionFee={transactionFee}
                                billerName={billerName}
                                billerType={billerType}
                                fxRate={fxRate}
                                denominationType={denominationType}
                                localTransactionCurrencyCode={localTransactionCurrencyCode}
                                internationalTransactionCurrencyCode={internationalTransactionCurrencyCode}
                                localTFee={localTFee}
                                internationalTFee={internationalTFee}
                                minITA={minITA}
                                maxITA={maxITA}
                                billerId={billerId}
                                amountInternational={amountInternational}
                                virtualAccountInfo={virtualAccountInfo}
                                confirmClose={confirmClose}/>
                            </div>    
                        </div>
                    </div>
                </>
            )}
        </div>
  );
};
 
export default CreateUtility;