import React, { useState } from "react";
import Referralsuccess from "./referralsuccess";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Referralmodal = ({balance,handle}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  
  const handleConfirm = () =>{
    setIsFirstModalOpen(false);
  } 
  const checkBankDetails = async () => {
    const apicall = await AfterLoginService.CheckBankDetail(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          if (resp.isdetailadded == 1) {
            setIsFirstModalOpen(true);
          } else {
            toast(resp.message);
          }
        } else {
          toast(resp.message);
        }
      });
    } else {
    }
  };

  // mui css
  const textFieldStyles = {
    background: "#252731 ",
    width: "100%",
    // height: "auto",
    borderRadius: "2px",
  };

  return (
    <div>
      {balance.wallet < 1000 ? (
        // <p className="text-white">
        //   Referral withdraw button pops up when you have a balance in your
        //   wallet
        // </p>
        <div className="referral-balance-low-btn">
        <img src="/img/balancelow.svg" alt="" />
         </div>
      ) : (
        <button className="btn btn-request" onClick={openFirstModal}>
          Request Withdraw
        </button>
      )}

      {isFirstModalOpen && (
        <Modal>
          <div className="modal ">
            <div className="modal-dialog">
              <div className="modal-content referral-modal">
                <div className="modal-header w-100 text-center">
                  <h5 className="modal-title" style={{marginLeft:'-5px'}} >Withdraw Referral</h5>
                  <button
                    type="button"
                    className="close-referral-btn"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>

                <div className="referral-p">
                  {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
                </div>
                <div className="modal-body">
                  <form _lpchecked="1" style={{marginTop:'-10px'}}>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="number"
                        min="1"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        // className="form-control mb-3"
                        id="name"
                        label={
                          <span style={{ color: "#ffffff" }}>
                            Amount to be withdrawn
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        sx={{ input: { color: "white" } }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        // className="form-control mb-3"
                        id="name"
                        label={
                          <span style={{ color: "#ffffff" }}>
                            Enter your password{" "}
                          </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        sx={{ input: { color: "white" } }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                  <div className="wallet-p-f mt-2">
                    <p>By continuing, you agree to accept our </p>
                    <p style={{ color: "#FFFFFF", marginTop: "-15px" }}>
                    <a href='https://www.thequchange.com/privacypolicy' target="_blank" style={{color:'#FFFFFF', textDecoration:'none'}}>Privacy policy &</a>
                      <a href='https://www.thequchange.com/terms-of-service' target="_blank" style={{color:'#FFFFFF', textDecoration:'none'}}> Terms of Services</a> 


                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <Referralsuccess amount={amount} password={password} handleConfirm = {handleConfirm} handle ={handle}/>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Referralmodal;
