import React, { useState } from "react";
import Successfullmodal from "./successfullmodal";
import Trc20Services from "../../Services/LoginSignService/Trc20Service";
import { toast } from "react-toastify";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-usd">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sendtron = (props) => {
  const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);
  const [validData, setValidData] = useState({});
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);

  const openFirstModal = async () => {
    if (props.token === "") {
      props.setErrorMessage("Please select token!");
      return;
    } else if (props.amount === "" || props.amount <= 0) {
      props.setErrorMessage("Please enter postive number value!");
      return;
    }

    setIsLoading(true);
    let body = {
      key: "apr1$geca4tup$yw3OJ2mAqZWmedykeHMR71",
      email: auth?.user.email,
      phone: auth?.user.phone,
    };

    if (auth) {
      const tronToken = await Trc20Services.CheckValidTransaction(
        auth,
        body,
        props.address,
        props.amount,
        props.token
      );
      if (tronToken.result?.statusCode === 400) {
        setIsLoading(false);
        toast(tronToken?.result?.message);
      } else {
        setValidData(tronToken.result);
        setIsLoading(false);
        setIsThirdModalOpen(true);
      }
    }
    // setIsThirdModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsThirdModalOpen(false);
    props.setIsFirstModalOpen(false);
  };

  const Loader = () => {
    return (
      <>
        <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
          <div class="flex items-center">
            <svg
              class="animate-spin h-20 w-20 text-blue-950"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="gray"
                stroke-width="4"
              ></circle>
              <path
                fill="#fff"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {props.errorMessage && props.errorMessage !== "" && (
        <p className="text-[#ff0000]">{props.errorMessage}</p>
      )}
      <button className="btn btn-update w-100 mt-5" onClick={openFirstModal}>
        Send{" "}
      </button>
      {isThirdModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center text-light trc-trx-heading">
                    Send ({props.token.toUpperCase()}) Transaction
                    <p className="sell-trx-p">
                      Molestie et amet cursus vestibulum quam commodo.
                    </p>
                    <span
                      className="d-block text-success fw-bold"
                      style={{ marginTop: "-30px", fontSize: "20px" }}
                    >
                      1 {props.token} = ${validData && validData?.trxusdtprice}
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={closeFirstModal}
                    style={{
                      background: "#313131",
                      color: "#FFFFFF",
                      padding: "1px",
                      marginTop: "-50px",
                    }}
                  >
                    <img src="img/close-icon.png" />
                  </button>
                </div>
                <div className="modal-body text-white text-center">
                  {/* <p>
                    Procced to send {props.token}{" "}
                    {validData && validData?.amount.toFixed(6)}
                    <span className="d-block">
                      worth ${props.amount} to the specified{" "}
                    </span>
                    <span className="d-block"> recipient address? </span>
                  </p>
                  <p>Note : Standard network fee applies.</p> */}

                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h3 style={{ color: "#FFFFFF", marginTop: "15px" }}>
                        0.0045
                        <span style={{ fontSize: "15px", padding: "3px" }}>
                          TRX
                        </span>
                      </h3>
                      <p className="send-trc-p">10,0000USDT</p>
                    </div>
                  </div>

                  <div className="row mt-3 ">
                    <div className="col-l-1 col-md-1 col-sm-1 col-1"></div>
                    <div className=" col-10 col-md-10 col-sm-10 col-12  ">
                      <div className="send-copy-heading1 ">
                        <p
                          className="text-left ml-2"
                          style={{ fontSize: "12px" }}
                        >
                          From
                        </p>
                        <p className="copy-heading ml-2">
                          hbdhucucduykjkduhuikjfk
                        </p>
                      </div>
                      <hr style={{ marginTop: "-3px" }} />
                      <div
                        className="ArrowsDownUp"
                        style={{ marginTop: "-40px" }}
                      >
                        <img
                          src="/img/ArrowsDownUp.png"
                          className="ArrowsDownUp1"
                          alt=""
                        />
                      </div>
                      <div
                        className="send-copy-heading2"
                        style={{ marginTop: "-1px" }}
                      >
                        <p
                          className="text-left ml-2 mt-2"
                          style={{ fontSize: "12px" }}
                        >
                          To
                        </p>
                        <p className="copy-heading2 ml-2">
                          hbdhucucduykjkduhuikjfk
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
                  </div>
                </div>
                <div className="modal-footer d-flex">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-cancle1">
                        <button
                          type="button"
                          className="btn btn-cancel "
                          onClick={closeFirstModal}
                        >
                          <img src="/img/cancel.png" />
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="btn-update1 ">
                        <Successfullmodal
                          uiamount={props.amount}
                          amount={
                            props.token === "USDT"
                              ? props.amount
                              : validData?.amount * props.amount
                          }
                          address={props.address}
                          token={props.token}
                          setIsFirstModalOpen={props.setIsFirstModalOpen}
                          conversion_rate={validData && validData?.trxusdtprice}
                          refreshHistory={props.refreshHistory}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isLoading === true && <Loader />}
    </div>
  );
};

export default Sendtron;
