import React, { useEffect, useState } from "react";
import {useNavigate,useLocation} from "react-router-dom";
import "./logindetails.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Sendmodal from "./sendmodal";
import Sellmodal from "./sellmodal";
import Qrcodemodal from "./qrcodemodal";
import BTCWalletService from "../../Services/BTCService/BTCWalletService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
import CachedIcon from "@mui/icons-material/Cached";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Logindetails() {
 
  const [btcWalletDetails, setBtcWalletDetails] = useState({
    data: {
      blockio_btc: "",
    },
  });
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [btcRate, setBtcRate] = useState("");
  const [usdBalance, setUsdBalance] = useState("");
  const [loading, setLoading] = useState(false);
  const [balanceRefreshing, setBalanceRefreshing] = useState(false);
  const [gettingWallet, setGettingWallet] = useState(false);
  const [transactionHistoryLoading, setTransactionHistoryLoading] =
    useState(false);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);


  const location = useLocation();
  // if(location.pathname == '/Logindetails')
  // {
  //   navigate("/dashboard");
  // }
  /*******************Get Current Date */
  const today = new Date();
  const date = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = monthNames[today.getMonth()];
  const day = weekday[today.getDay()];
  const currentDate = day + " , " + month + "  " + date + "th";
  /**************************End *******************/
  const getBtcWallet = async () => {
    const auth = JSON.parse(localStorage.getItem("user"));
    const { result } = await BTCWalletService.GetBTCWallet(auth);
    setBtcWalletDetails(result);
    setGettingWallet(false);
    const getblockIoAdmin = await BTCWalletService.GetBlockIOAdmin(auth);
    setBtcRate(getblockIoAdmin.result.data.block_io_rate);
    const getBtcRate = await BTCWalletService.GetBTCRate();
    setUsdBalance(getBtcRate.result * result.data.blockio_balance);
  };

  const transactionsHistory = async () => {
    setTransactionHistoryLoading(true);
    const auth = JSON.parse(localStorage.getItem("user"));
    const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
    const token = result.data.token;
    const { result: transactionHistoryDetail } =
      await BTCWalletService.GetTransactions(token);
    setTransactionHistoryData(transactionHistoryDetail.data.txs);
    setTransactionHistoryLoading(false);
  };

  const CreateBTCWallet = async () => {
    setLoading(true);
    const auth = JSON.parse(localStorage.getItem("user"));
    const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
    const token = result.data.token;
    const data = await BTCWalletService.GetBTCAddressThirdParty(token);
    let address;
    let userId;
    let label;
    if (data.state) {
      address = data.result.address;
      userId = data.result.userId;
      label = data.result.label;
    }
    if (!data.state) {
      const walletCreated = await BTCWalletService.CreateWallet(token);
      address = walletCreated.result.address;
      userId = walletCreated.result.userId;
      label = walletCreated.result.label;
    }
    await BTCWalletService.SaveBTCWallet(address, label, userId, auth);
    getBtcWallet();
    setLoading(false);
  };

  const refreshBalance = async () => {
    setBalanceRefreshing(true);
    const auth = JSON.parse(localStorage.getItem("user"));
    const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
    const token = result.data.token;
    const data = await BTCWalletService.GetWalletBalance(token);
    await BTCWalletService.UpdateBtcBalance(
      data.result.data.available_balance,
      auth
    );
    getBtcWallet();
    setBalanceRefreshing(false);
  };

  const handleRedirect = (txid) => {
    window.open(`https://sochain.com/tx/BTC/${txid}`, "_blank");
  };

  useEffect(() => {
    setGettingWallet(true);
    getBtcWallet();
  }, []);

  // copy paste

  const [isCopied, setIsCopied] = useState(false);
  const copyBtcAddress = () => {
    const btcAddress = btcWalletDetails.data.blockio_btc;
    navigator.clipboard.writeText(btcAddress);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  return (
    <div className="container-fluid position-relative d-flex  crypto-container">
      <ToastContainer />
      <Header />
      <div className="content bg-black">
        <Subheader />
        <div className="container-fluid pt-4 px-4 btc-table ">
          <div className="row ">
            <div
              className={`${
                btcWalletDetails.isdetailadded
                  ? "col-md-7 col-lg-7 col-sm-12 col-12"
                  : "col-md-12 col-lg-12 col-sm-12 col-12"
              }`}
            >
              <div className="d-flex">
                <div className="mb-3">
                  <span className="">
                    <img src="/img/back.svg" onClick={goBack} className="right-icon" />
                  </span>
                </div>
                <div className="ms-2">
                  <h4 className="h6 btc-heaidng-m mt-2">BTC Wallet</h4>{" "}
                  <span className="d-none">
                    <img src="/img/Bitcoin.png" className="bitcoin" />
                  </span>
                  <p className="mb-0 trc-p-m fs-12">
                    {/* Molestie et amet cursus vestibulum quam commodo. */}
                  </p>
                </div>
              </div>
              <div
                className={`col-md-12 gx-5 ${
                  btcWalletDetails.isdetailadded ? "balance-header " : ""
                }`}
              >
                {gettingWallet ? (
                  <div className="ms-3 text-white w-full flex justify-center items-center">
                    <div className=" text-white h-[230px] flex items-center">
                      {" "}
                      <span
                        class="spinner-border spinner-border-sm "
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp; Getting Wallet Details...
                    </div>
                  </div>
                ) : btcWalletDetails.isdetailadded ? (
                  <>
                    <div className=" text-center">
                      <div className=" text-light">
                        <div className="row mt-2 ">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <p className="mb-2 btc-p ">
                              <img src="/img/overallicon.svg" className="btc-img" />
                              <h5 className=" total-btc ">
                                {" "}
                                Total balance(BTC)
                              </h5>
                              <span className="rotation-btc-top">
                                <button
                                  className={`  ${
                                    balanceRefreshing
                                      ? " flex-row gap-2 cursor-not-allowed"
                                      : " cursor-pointer"
                                  }`}
                                  disabled={balanceRefreshing}
                                  onClick={refreshBalance}
                                >
                                  {balanceRefreshing ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>{" "}
                                      {/* Loading... */}
                                    </>
                                  ) : (
                                    <img
                                      src="/img/shape1.svg"
                                      style={{ height: "14px" }}
                                    />
                                  )}
                                </button>
                              </span>
                            </p>
                            <h6 className="mb-0 h4  btc-h ml-3">
                              {btcWalletDetails.data.blockio_balance}
                              <span style={{ fontSize: "14px" }}>BTC</span>
                              <span className="d-block h4 btc-p1 mt-1">
                                ${usdBalance ? usdBalance.toFixed(2) : "0.00"}
                              </span>
                            </h6>
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className=" rate ">
                              <p>
                                <span className="rate-title  h5 ">
                                  <img
                                    src="/img/crypto.svg"
                                    className="mb-3 rate-img-logindetails"
                                    alt=""
                                  />
                                  <h5 className="rate-btc ">Rate</h5>
                                </span>
                              </p>
                            </div>

                            <div className=" rateN mt-4">
                              <p className="p-rate-n">
                                <span className="d-block-N h5 ">
                                  <span className="p-2">₦</span>
                                  {btcRate}
                                </span>
                              </p>
                            </div>
                          </div>
                          {/* </div> */}
                          <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                            <span className="rotation-btc">
                              <button
                                className={`  ${
                                  balanceRefreshing
                                    ? " flex-row gap-2 cursor-not-allowed"
                                    : " cursor-pointer"
                                }`}
                                disabled={balanceRefreshing}
                                onClick={refreshBalance}
                              >
                                {balanceRefreshing ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    {/* Loading... */}
                                  </>
                                ) : (
                                  <img
                                    src="/img/shape1.svg"
                                    style={{ height: "14px" }}
                                  />
                                )}
                              </button>
                            </span>
                          </div>
                          <div className="row  mt-3">
                            <div className=" col-12 col-md-12 col-sm-12 col-12 ml-3 ">
                              <div className="btc-block1">
                                <p className="tap-usd">
                                  Tap to copy BTC Address
                                </p>

                                <span className="d-block h6 block-btc-h ">
                                  {btcWalletDetails.data.blockio_btc}
                                  <ContentCopyIcon
                                    className="copy-icon-btc "
                                    onClick={copyBtcAddress}
                                  />
                                </span>
                                {isCopied && (
                                  <p
                                    className="text-light "
                                    style={{ textAlign: "right" }}
                                  >
                                    Copied!
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="ms-3 text-white w-full flex justify-center items-center no-btc-bal">
                    <div className="text-white h-[230px] flex items-center">
                      <div className="flex flex-col items-center mt-5">
                        <img src="/img/btcgruop.svg" alt="BTC Wallet" />
                        <h6 className="btc-no-bal-heading">BTC Wallet</h6>
                        <p className="btc-no-bal-p">
                          {" "}
                          You do not have a BTC Wallet,
                        </p>
                        <p className="btc-no-bal-p-two">
                          Click on the link below to create a wallet
                        </p>
                        <button
                          className={`btn btn-exchange1  btn-sm ${
                            loading ? "disabled" : ""
                          }`}
                          onClick={CreateBTCWallet}
                          style={{ marginTop: "-10px" }}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            " + Create  Wallet"
                          )}
                        </button>
                      </div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* 1411 */}
            {btcWalletDetails.isdetailadded == 1 ? (
              <>
                <div className="col-md-5 col-lg-5 col-sm-12 col-12 mt-5">
                  <div className="balance-header1 ">
                    <div
                      className="btn-container mt-5 m-auto   "
                      style={{ marginLeft: "60px" }}
                    >
                      <Sendmodal />
                      <Sellmodal
                        btcWalletDetails={btcWalletDetails}
                        btcRate={btcRate}
                      />
                      <Qrcodemodal
                        recieveBtcAddress={btcWalletDetails.data.blockio_btc}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {/* 1411 */}
          </div>
          {/* 1411 */}
          {btcWalletDetails.isdetailadded == 1 ? (
            <>
              <div className="row logindetails">
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="btc-heading-transaction">
                    <h4 className="ml-4 transaction-heading1">Transaction</h4>
                    <p className="ml-4 transaction-heading-p">
                      {`${currentDate}`}
                    </p>
                    <button class="load-btn " onClick={transactionsHistory}>
                      Load Transactions
                    </button>
                    <hr style={{ color: "gray" }} />
                  </div>
                  {!gettingWallet &&
                  btcWalletDetails &&
                  btcWalletDetails.isdetailadded ? (
                    <>
                      {/* <button
                    className={`card-header align-items-center h5 text-white d-flex flex-col justify-content-center mb-2 border-bottom-0 w-full ${
                      balanceRefreshing
                        ? " flex-row gap-2 cursor-not-allowed"
                        : " cursor-pointer"
                    }`}
                    disabled={balanceRefreshing}
                    onClick={refreshBalance}
                  >
                    {balanceRefreshing ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Loading...
                      </>
                    ) : (
                      "Tap to Refresh Balance"
                    )}
                  </button> */}
                      {/* <button
                    className={`w-full card-header align-items-center h5 text-white d-flex flex-col justify-content-center ${
                      transactionHistoryLoading
                        ? " flex-row gap-2 cursor-not-allowed"
                        : " cursor-pointer"
                    }`}
                    onClick={transactionsHistory}
                    disabled={transactionHistoryLoading}
                  >
                    {transactionHistoryLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Loading...
                      </>
                    ) : (
                      "Tap to View Transaction History"
                    )}
                  </button> */}

                      <div className="btc-scroll">
                        {/* {transactionHistoryData.length ?  ( */}

                        <table class="table table-dark table-border">
                          <thead>
                            <tr className="fs-13">
                              <th>DETAILS</th>
                              <th className="trasaction-heading-mobile">&nbsp;</th>
                              <th className="d-none d-sm-table-cell">TYPE</th>
                              <th className="instant-status">STATUS</th>
                              <th className="d-none d-sm-table-cell">DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionHistoryData.map((record) => {
                              return (
                                <tr
                                  onClick={() => handleRedirect(record.txid)}
                                  className={`cursor-pointer ${
                                    record.confidence < 1 ? "blink_me" : ""
                                  }`}
                                >
                                  <td className="d-flex align-items-center">
                                    <p>
                                    {/* <img src="/img/dashbordlist.svg" alt=''  /> */}
                                    </p>
                                    <img src="/img/dashbordlist.svg" alt='' className="dashboard-list-icon"  />
                                    <p className="fs-12 ms-2 btc-mobile-header">
                                      {record.hasOwnProperty("amounts_received")
                                        ? record.amounts_received[0].amount
                                        : record.amounts_sent[0].amount}{" "}
                                       BTC
                                      <span className="d-block fs-10 btc-list-table-mobile-span" >
                                        {record.hasOwnProperty(
                                          "amounts_received"
                                        )
                                          ? `Received from ${record.senders[0]}`
                                          : `Sent to ${record.amounts_sent[0].recipient}`}
                                      </span>
                                      
                                    </p>
                                    <p className="fs-12 trnsaction-mobile-date">
                                      {moment
                                        .unix(record.time)
                                        .format("MMMM D, YYYY HH:mm")}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="fs-12 btc-wallet-mobile-type">
                                      {record.hasOwnProperty("amounts_received")
                                        ? "RECEIVED "
                                        : "SENT"}
                                    </p>
                                  </td>
                                  <td className="transaction-btc-btn-list ">
                                    <a className="complete-btn">
                                      {record.confidence < 1
                                        ? "PENDING"
                                        : "SUCCESS"}
                                    </a>
                                  </td>
                                  <td className=" ">
                                    <p className="fs-12 btc-wallet-mobile-type">
                                      {moment
                                        .unix(record.time)
                                        .format("MMMM D, YYYY HH:mm")}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* ) : null} */}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* 1411 */}
        </div>
      </div>
    </div>
  );
}

export default Logindetails;

// good code without refersh

// import React, { useEffect, useState } from "react";
// import "./logindetails.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import Sendmodal from "./sendmodal";
// import Sellmodal from "./sellmodal";
// import Qrcodemodal from "./qrcodemodal";
// import BTCWalletService from "../../Services/BTCService/BTCWalletService";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import moment from "moment/moment";
// import CachedIcon from "@mui/icons-material/Cached";
// import JoinFullIcon from "@mui/icons-material/JoinFull";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// function Logindetails() {
//   const [btcWalletDetails, setBtcWalletDetails] = useState({
//     data: {
//       blockio_btc: "",
//     },
//   });
//   const [btcRate, setBtcRate] = useState("");
//   const [usdBalance, setUsdBalance] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [balanceRefreshing, setBalanceRefreshing] = useState(false);
//   const [gettingWallet, setGettingWallet] = useState(false);
//   const [transactionHistoryLoading, setTransactionHistoryLoading] =
//     useState(false);
//   const [transactionHistoryData, setTransactionHistoryData] = useState([]);

//   const getBtcWallet = async () => {
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GetBTCWallet(auth);
//     setBtcWalletDetails(result);
//     setGettingWallet(false);
//     const getblockIoAdmin = await BTCWalletService.GetBlockIOAdmin(auth);
//     setBtcRate(getblockIoAdmin.result.data.block_io_rate);
//     const getBtcRate = await BTCWalletService.GetBTCRate();
//     setUsdBalance(getBtcRate.result * result.data.blockio_balance);
//   };

//   const transactionsHistory = async () => {
//     setTransactionHistoryLoading(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = result.data.token;
//     const { result: transactionHistoryDetail } =
//       await BTCWalletService.GetTransactions(token);
//     setTransactionHistoryData(transactionHistoryDetail.data.txs);
//     setTransactionHistoryLoading(false);
//   };

//   const CreateBTCWallet = async () => {
//     setLoading(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = result.data.token;
//     const data = await BTCWalletService.GetBTCAddressThirdParty(token);
//     let address;
//     let userId;
//     let label;
//     if (data.state) {
//       address = data.result.address;
//       userId = data.result.userId;
//       label = data.result.label;
//     }
//     if (!data.state) {
//       const walletCreated = await BTCWalletService.CreateWallet(token);
//       address = walletCreated.result.address;
//       userId = walletCreated.result.userId;
//       label = walletCreated.result.label;
//     }
//     await BTCWalletService.SaveBTCWallet(address, label, userId, auth);
//     getBtcWallet();
//     setLoading(false);
//   };

//   const refreshBalance = async () => {
//     setBalanceRefreshing(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = result.data.token;
//     const data = await BTCWalletService.GetWalletBalance(token);
//     await BTCWalletService.UpdateBtcBalance(
//       data.result.data.available_balance,
//       auth
//     );
//     getBtcWallet();
//     setBalanceRefreshing(false);
//   };

//   const handleRedirect = (txid) => {
//     window.open(`https://sochain.com/tx/BTC/${txid}`, "_blank");
//   };

//   useEffect(() => {
//     setGettingWallet(true);
//     getBtcWallet();
//   }, []);

//   return (
//     <div className="container-fluid position-relative d-flex p-0 crypto-container">
//       <ToastContainer />
//       <Header />
//       <div className="content bg-black">
//         <Subheader />
//         <div className="container-fluid pt-4 px-4 btc-table">
//           <div className="row ">
//             <div className="col-md-7 col-lg-7 col-sm-12 col-12">
//               <div className="d-flex">
//                 <div className="mb-3">
//                   <span className="">
//                     <ChevronLeftIcon className="text-white right-icon" />
//                   </span>
//                 </div>
//                 <div className="ms-2">
//                   <h4 className="h6  mb-0" style={{color:'#FFFFFF'}}>BTC</h4>
//                   <p className="mb-0  fs-12" style={{color:"#9D76BE"}}>
//                     Molestie et amet cursus vestibulum quam commodo.
//                   </p>
//                 </div>
//               </div>
//               <div className="balance-header col-md-12 gx-5">
//                 {gettingWallet ? (
//                   <div className="ms-3 text-white w-full flex justify-center items-center">
//                     <div className=" text-white h-[230px] flex items-center">
//                       {" "}
//                       <span
//                         class="spinner-border spinner-border-sm "
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                       &nbsp; Getting Wallet Details...
//                     </div>
//                   </div>
//                 ) : btcWalletDetails.isdetailadded ? (
//                   <>
//                     <div className=" text-center">
//                       <div className=" text-light">
//                         <div className="row mt-2 ">
//                           <div className="col-lg-6 col-md-6 col-sm-6 col-6 ">
//                             <p className="mb-2 btc-p"  style={{ color: "#c4c4c4" }} >
//                              <img src="/img/wallet.png"  style={{height:'18px'}}/>
//                               <h5
//                                 className=" total-btc ">
//                                 {" "}
//                                 Total balance(BTC)
//                               </h5>
//                             </p>
//                             <h6 className="mb-0 h4  btc-h ml-3">
//                               {btcWalletDetails.data.blockio_balance}<span style={{fontSize:"14px"}}>BTC</span>
//                               <span
//                                 className="d-block h4 btc-p1 mt-2"
//                                 style={{ color: "#BCBCE" }}
//                               >
//                                 ${usdBalance ? usdBalance.toFixed(2) : "0.00"}
//                               </span>
//                             </h6>
//                           </div>

//                           <div className="col-lg-4 col-md-4 col-sm-4 col-4">

//                               <div className=" rate ">
//                                 <p>
//                                   <span className="rate-title  h5 ">
//                                     <img src="/img/rate.png" className="mb-3 rate-img" alt=""/>
//                                     <h5 className="rate2 ">Rate</h5>
//                                   </span>
//                                 </p>
//                               </div>

//                               <div className=" rateN mt-4">
//                                 <p>
//                                   <span className="d-block-N h5 ">
//                                     <span className="p-2">₦</span>{btcRate}
//                                   </span>
//                                 </p>
//                               </div>
//                             </div>
//                           {/* </div> */}
//                           <div className="col-lg-2 col-md-2 col-sm-2 col-2">
//                             <span className="rotation-btc" >
//                               {/* <CachedIcon style={{height:'16px', marginTop:'-2px'}} /> */}
//                               <img src="/img/shape1.png" style={{height:'14px'}} />
//                             </span>
//                           </div>
//                           <div className="row  mt-3">
//                             <div className=" col-12 col-md-12 col-sm-12 col-12 ml-3 ">
//                            <div className="btc-block1">
//                            <p className="tap-usd">Tap to copy BTC Address</p>
//                            <span className="d-block h6 block-btc-h ">
//                                 {btcWalletDetails.data.blockio_btc}
//                                 <ContentCopyIcon
//                                   className="copy-icon "
//                                   style={{ float: "right",marginTop:'-10px' }}
//                                 />
//                               </span>
//                            </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 ) : (
//                   <div className="ms-3 text-white w-full flex justify-center items-center">
//                     <div className=" text-white h-[230px] flex items-center">
//                       <button
//                         className={`btn btn-exchange btn-primary btn-sm ${
//                           loading ? "disabled" : ""
//                         }`}
//                         onClick={CreateBTCWallet}
//                       >
//                         {loading ? (
//                           <>
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>{" "}
//                             Loading...
//                           </>
//                         ) : (
//                           "Create Bitcoin Wallet"
//                         )}
//                       </button>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="col-md-5 col-lg-5 col-sm-12 col-12 mt-5">
//               <div className="balance-header1 ">
//                 <div
//                   className="btn-container mt-5 p-2   "
//                   style={{ marginLeft:'60px' }}
//                 >
//                   <Sendmodal  />
//                   <Sellmodal />
//                   <Qrcodemodal
//                     recieveBtcAddress={btcWalletDetails.data.blockio_btc}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row logindetails">
//             <div className="col-lg-8 col-md-8 col-sm-12 col-12">
//               <div className="transaction-heading">
//                 <h4 className="ml-4 transaction-heading1">Transaction</h4>
//                 <p className="ml-4 transaction-heading-p">Monday October 17th</p>
//                 <button class="right">Tap to Close</button>
//                 <hr style={{color:'gray'}} />
//               </div>
//               {!gettingWallet  && btcWalletDetails &&  btcWalletDetails.isdetailadded ? (
//                 <>
//                   {/* <button
//                     className={`card-header align-items-center h5 text-white d-flex flex-col justify-content-center mb-2 border-bottom-0 w-full ${
//                       balanceRefreshing
//                         ? " flex-row gap-2 cursor-not-allowed"
//                         : " cursor-pointer"
//                     }`}
//                     disabled={balanceRefreshing}
//                     onClick={refreshBalance}
//                   >
//                     {balanceRefreshing ? (
//                       <>
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         Loading...
//                       </>
//                     ) : (
//                       "Tap to Refresh Balance"
//                     )}
//                   </button> */}
//                   {/* <button
//                     className={`w-full card-header align-items-center h5 text-white d-flex flex-col justify-content-center ${
//                       transactionHistoryLoading
//                         ? " flex-row gap-2 cursor-not-allowed"
//                         : " cursor-pointer"
//                     }`}
//                     onClick={transactionsHistory}
//                     disabled={transactionHistoryLoading}
//                   >
//                     {transactionHistoryLoading ? (
//                       <>
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         Loading...
//                       </>
//                     ) : (
//                       "Tap to View Transaction History"
//                     )}
//                   </button> */}

//                   <div className="btc-scroll">
//                     {/* {transactionHistoryData.length ?  ( */}

//                     <table class="table table-dark table-border">
//                       <thead>
//                         <tr className="fs-13">
//                           <th>DETAILS</th>
//                           <th>TYPE</th>
//                           <th>STATUS</th>
//                           <th>DATE</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {transactionHistoryData.map((record) => {
//                           return (
//                             <tr
//                               onClick={() => handleRedirect(record.txid)}
//                               className={`cursor-pointer ${
//                                 record.confidence < 1 ? "blink_me" : ""
//                               }`}
//                             >
//                               <td className="d-flex align-items-center">
//                                 <p>
//                                   <i class="bi bi-arrow-down-left-circle-fill"></i>
//                                 </p>
//                                 <p className="fs-12 ms-2">
//                                   {record.hasOwnProperty("amounts_received")
//                                     ? record.amounts_received[0].amount
//                                     : record.amounts_sent[0].amount}{" "}
//                                   BTC
//                                   <span className="d-block fs-10">
//                                     {record.hasOwnProperty("amounts_received")
//                                       ? `Received from ${record.senders[0]}`
//                                       : `Sent to ${record.amounts_sent[0].recipient}`}
//                                   </span>
//                                 </p>
//                               </td>
//                               <td>
//                                 <p className="fs-12">
//                                   {record.hasOwnProperty("amounts_received")
//                                     ? "RECEIVED "
//                                     : "SENT"}
//                                 </p>
//                               </td>
//                               <td>
//                                 <a className="complete-btn">
//                                   {record.confidence < 1
//                                     ? "PENDING"
//                                     : "SUCCESS"}
//                                 </a>
//                               </td>
//                               <td className="">
//                                 <p className="fs-12">
//                                   {moment
//                                     .unix(record.time)
//                                     .format("MMMM D, YYYY HH:mm")}
//                                 </p>
//                               </td>
//                             </tr>
//                           );
//                         })}
//                       </tbody>
//                     </table>
//                     {/* ) : null} */}
//                   </div>
//                 </>
//               ) : null}
//             </div>
//             <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Logindetails;

// company code

// import React, { useEffect, useState } from "react";
// import "./logindetails.scss";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from "../header/header";
// import Subheader from "../subheader/subheader";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import Sendmodal from "./sendmodal";
// import Sellmodal from "./sellmodal";
// import Qrcodemodal from "./qrcodemodal";
// import BTCWalletService from "../../Services/BTCService/BTCWalletService";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import moment from "moment/moment";

// function Logindetails() {
//   const [btcWalletDetails, setBtcWalletDetails] = useState({});
//   const [btcRate, setBtcRate] = useState("");
//   const [usdBalance, setUsdBalance] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [balanceRefreshing, setBalanceRefreshing] = useState(false);
//   const [gettingWallet, setGettingWallet] = useState(false);
//   const [transactionHistoryLoading, setTransactionHistoryLoading] =
//     useState(false);
//   const [transactionHistoryData, setTransactionHistoryData] = useState([]);

//   const getBtcWallet = async () => {
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GetBTCWallet(auth);
//     setBtcWalletDetails(result);
//     setGettingWallet(false);
//     const getblockIoAdmin = await BTCWalletService.GetBlockIOAdmin(auth);
//     setBtcRate(getblockIoAdmin.result.data.block_io_rate);
//     const getBtcRate = await BTCWalletService.GetBTCRate();
//     setUsdBalance(getBtcRate.result * result.data.blockio_balance);
//   };

//   const transactionsHistory = async () => {
//     setTransactionHistoryLoading(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = result.data.token;
//     const { result: transactionHistoryDetail } =
//       await BTCWalletService.GetTransactions(token);
//     setTransactionHistoryData(transactionHistoryDetail.data.txs);
//     setTransactionHistoryLoading(false);
//   };

//   const CreateBTCWallet = async () => {
//     setLoading(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = result.data.token;
//     const data = await BTCWalletService.GetBTCAddressThirdParty(token);
//     let address;
//     let userId;
//     let label;
//     if (data.state) {
//       address = data.result.address;
//       userId = data.result.userId;
//       label = data.result.label;
//     }
//     if (!data.state) {
//       const walletCreated = await BTCWalletService.CreateWallet(token);
//       address = walletCreated.result.address;
//       userId = walletCreated.result.userId;
//       label = walletCreated.result.label;
//     }
//     await BTCWalletService.SaveBTCWallet(address, label, userId, auth);
//     getBtcWallet();
//     setLoading(false);
//   };

//   const refreshBalance = async () => {
//     setBalanceRefreshing(true);
//     const auth = JSON.parse(localStorage.getItem("user"));
//     const { result } = await BTCWalletService.GenerateThirdPartyToken(auth);
//     const token = result.data.token;
//     const data = await BTCWalletService.GetWalletBalance(token);
//     await BTCWalletService.UpdateBtcBalance(
//       data.result.data.available_balance,
//       auth
//     );
//     getBtcWallet();
//     setBalanceRefreshing(false);
//   };

//   const handleRedirect = (txid) => {
//     window.open(`https://sochain.com/tx/BTC/${txid}`, "_blank");
//   };

//   useEffect(() => {
//     setGettingWallet(true);
//     getBtcWallet();
//   }, []);

//   return (
//     <div className="container-fluid position-relative d-flex p-0 crypto-container">
//       <ToastContainer />
//       <Header />
//       <div className="content bg-black">
//         <Subheader />
//         <div className="container-fluid pt-4 px-4 btc-table">
//           <div className="row">
//             <div className="col-md-9 col-lg-9">
//               <div className="d-flex">
//                 <div className="mb-3">
//                   <span className="">
//                     <ChevronLeftIcon className="text-white right-icon" />
//                   </span>
//                 </div>
//                 <div className="ms-2">
//                   <h4 className="h6 text-white mb-0">BTC</h4>
//                   <p className="mb-0 text-white fs-12">
//                     Molestie et amet cursus vestibulum quam commodo.
//                   </p>
//                 </div>
//               </div>
//               <div className="balance-header col-md-12 gx-5">
//                 {gettingWallet ? (
//                   <div className="ms-3 text-white w-full flex justify-center items-center">
//                     <div className=" text-white h-[230px] flex items-center">
//                       {" "}
//                       <span
//                         class="spinner-border spinner-border-sm "
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                       &nbsp; Getting Wallet Details...
//                     </div>
//                   </div>
//                 ) : btcWalletDetails.isdetailadded ? (
//                   <>
//                     <div className="ms-3 text-white mb-5 ">
//                       <p className="mb-2">
//                         <i className="bi bi-wallet"></i>
//                         <span className="ms-2 h6"> Total balance(BTC)</span>
//                       </p>
//                       <h6 className="mb-0 h2">
//                         {btcWalletDetails.data.blockio_balance}BTC
//                         <span className="d-block h4">
//                           ${usdBalance ? usdBalance.toFixed(2) : "0.00"}
//                         </span>
//                         <span className="d-block text-success h5 mt-4">
//                           Rate: ₦{btcRate}
//                         </span>
//                         <span className="d-block h6">
//                           {btcWalletDetails.data.blockio_btc}
//                         </span>
//                       </h6>
//                     </div>
//                     <div className="btn-container">
//                       <Sendmodal />
//                       <Sellmodal />
//                       <Qrcodemodal
//                         recieveBtcAddress={btcWalletDetails.data.blockio_btc}
//                       />
//                     </div>
//                   </>
//                 ) : (
//                   <div className="ms-3 text-white w-full flex justify-center items-center">
//                     <div className=" text-white h-[230px] flex items-center">
//                       <button
//                         className={`btn btn-exchange btn-primary btn-sm ${
//                           loading ? "disabled" : ""
//                         }`}
//                         onClick={CreateBTCWallet}
//                       >
//                         {loading ? (
//                           <>
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>{" "}
//                             Loading...
//                           </>
//                         ) : (
//                           "Create Bitcoin Wallet"
//                         )}
//                       </button>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               {!gettingWallet && btcWalletDetails.isdetailadded ? (
//                 <>
// <button
//   className={`card-header align-items-center h5 text-white d-flex flex-col justify-content-center mb-2 border-bottom-0 w-full ${
//     balanceRefreshing
//       ? " flex-row gap-2 cursor-not-allowed"
//       : " cursor-pointer"
//   }`}
//   disabled={balanceRefreshing}
//   onClick={refreshBalance}
// >
//   {balanceRefreshing ? (
//     <>
//       <span
//         className="spinner-border spinner-border-sm"
//         role="status"
//         aria-hidden="true"
//       ></span>{" "}
//       Loading...
//     </>
//   ) : (
//     "Tap to Refresh Balance"
//   )}
// </button>
//                   <button
//                     className={`w-full card-header align-items-center h5 text-white d-flex flex-col justify-content-center ${
//                       transactionHistoryLoading
//                         ? " flex-row gap-2 cursor-not-allowed"
//                         : " cursor-pointer"
//                     }`}
//                     onClick={transactionsHistory}
//                     disabled={transactionHistoryLoading}
//                   >
//                     {transactionHistoryLoading ? (
//                       <>
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>{" "}
//                         Loading...
//                       </>
//                     ) : (
//                       "Tap to View Transaction History"
//                     )}
//                   </button>
//                   <div className="btc-scroll">
//                     {transactionHistoryData.length ? (
//                       <table class="table table-dark table-border">
//                         <thead>
//                           <tr className="fs-13">
//                             <th>DETAILS</th>
//                             <th>TYPE</th>
//                             <th>STATUS</th>
//                             <th>DATE</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {transactionHistoryData.map((record) => {
//                             return (
//                               <tr
//                                 onClick={() => handleRedirect(record.txid)}
//                                 className={`cursor-pointer ${
//                                   record.confidence < 1 ? "blink_me" : ""
//                                 }`}
//                               >
//                                 <td className="d-flex align-items-center">
//                                   <p>
//                                     <i class="bi bi-arrow-down-left-circle-fill"></i>
//                                   </p>
//                                   <p className="fs-12 ms-2">
//                                     {record.hasOwnProperty("amounts_received")
//                                       ? record.amounts_received[0].amount
//                                       : record.amounts_sent[0].amount}{" "}
//                                     BTC
//                                     <span className="d-block fs-10">
//                                       {record.hasOwnProperty("amounts_received")
//                                         ? `Received from ${record.senders[0]}`
//                                         : `Sent to ${record.amounts_sent[0].recipient}`}
//                                     </span>
//                                   </p>
//                                 </td>
//                                 <td>
//                                   <p className="fs-12">
//                                     {record.hasOwnProperty("amounts_received")
//                                       ? "RECEIVED "
//                                       : "SENT"}
//                                   </p>
//                                 </td>
//                                 <td>
//                                   <a className="complete-btn">
//                                     {record.confidence < 1
//                                       ? "PENDING"
//                                       : "SUCCESS"}
//                                   </a>
//                                 </td>
//                                 <td className="">
//                                   <p className="fs-12">
//                                     {moment
//                                       .unix(record.time)
//                                       .format("MMMM D, YYYY HH:mm")}
//                                   </p>
//                                 </td>
//                               </tr>
//                             );
//                           })}
//                         </tbody>
//                       </table>
//                     ) : null}
//                   </div>
//                 </>
//               ) : null}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Logindetails;
