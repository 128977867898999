import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import OperatorList from "./operatorlist";
import OperatorDetails from "./operatorDetails";
import useSelect from "../../../Hook/user-select";
import Select from "react-select";


  const Mobile = ({isOpenMobile,setIsOpenMobile,setShowService}) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isCountry1,setIsCountry1] = useState([]);
  const [countryOption, setCountryOption] = useState(null);

  const [countryISOCode,setCountryISOCode] = useState("");
  const [phoneNumber,setPhoneNumber] = useState("");
  const [isOpenOperator,setIsOpenOperator] = useState(false);
  const [isOpenOperatorDetails,setIsOpenOperatorDetails] = useState(false);
  const [opDetails,setOpDetails]=useState([])
  const [nextLoader,isNextLoader] = useState(false);

  let styles = useSelect();

  const [opList,setOpList] = useState([]);

  const goBack = () => {
    setIsOpenMobile(false)
    setShowService(true)
  };

  useEffect(() => {
    mobileCountry();  
  }, []);

  const mobileCountry = async () => {
    const apicall = await AfterLoginService.allCountry(auth,2);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          let finalArr = [];

          let arr = [{ label: 'Select a country', value: 'Any',Searchable:true, disabled: true }]
          let arr1 = resp?.data.map((item) => ({
            label: (
              <div className="flex items-center gap-2">
                <img src={item?.flag} alt="img" className="w-7 h-7" />
                <p className="text-sm text-white !mb-0"> {item?.callingCodes[0]} {item?.name}</p>

              </div>
            ),
            value: item?.name,
            code: item?.isoName,
          }));
          finalArr = arr.concat(arr1);
          setCountryOption(finalArr);
        
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };
  
  const getOp = async ()=>{
    if(countryISOCode == '' || countryISOCode == null){
        toast("Select country code")
        return false;
    }
    if(phoneNumber == '' || phoneNumber == null){
        toast("Enter phone number")
        return false;
    }
    getOperator();
  }

  const getOperator = async () => {
    isNextLoader(true)
    const item = {iosCode:countryISOCode?countryISOCode:''}
    const apicall = await AfterLoginService.getOperator(auth,item);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          console.log(resp.data)
          setOpList(resp.data);
          setShowService(false)
          setIsOpenOperator(true)
          setIsOpenMobile(false)
          isNextLoader(false)
        } else {
          console.log(resp.message);
          isNextLoader(false)
        }
      });
    } else {
    }
  };

  const handleSelect = (e) => {
    setIsCountry1(e);
    setCountryISOCode(e?.code);
    // console.log(isCountry)
  };
  const handlePhone = (e)=>{
    const reg = /^[0-9\b]+$/
    let preval=e.target.value
    if (e.target.value === '' || reg.test(e.target.value)){
      setPhoneNumber(e.target.value)
      return true
    }else{
      e.target.value = preval.substring(0,(preval.length-1))
    }
  }
  return (
    <div className="mobile-1">
        
        {isOpenMobile &&(
            <>
            <div className="container-fluid pt-4 px-4 wallet-table dpDesign1">
              <div className="row px-4">
                  <div className="col-md-2">
                      <div className="back-icon-main d-block">
                          <img src="../img/CaretLeft1.svg" onClick={goBack} />
                      </div>
                  </div>
                  <div className="col-md-10"></div>
                  <div className="col-md-6 mt-2">
                      <div>
                          <h3 className="heading-transaction mb-0">Mobile TopUp</h3>
                          <p className="mb-p">Follow three easy steps to top-up in seconds. To do, please set the reciever phone number below:</p>
                      </div>    
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-6 col-12">
                      <div className="row">
                          <div className="col-md-4 col-6 country-pr-0">
                            <Select
                              placeholder="Select country"
                              onChange={(e) => handleSelect(e)}
                              name="currencyCode"
                              options={countryOption}
                              value={isCountry1}
                              styles={styles}
                            /> 
                          </div>
                          <div className="col-md-6 col-6 input-pl-0">
                          <input type="text" className="form-control myInput" style={{ backgroundColor:'#252731',color:"#fff",border:"0"}} placeholder="Enter Phone Number"    value={phoneNumber}
                                onChange={handlePhone} />
                              
                          </div>
                          <div className="col-md-10 mt-4">
                              <button className="buy_now form-control" onClick={getOp}>
                                {
                                  nextLoader?(<>
                                        <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>{" "}
                                      Loading...
                                  </>):(<>Next</>)
                                }
                              </button>
                          </div>
                        
                          
                      </div>
                  </div>
              </div>
            </div>
            </>
        )}
        
        {isOpenOperator && (
            <>
                <OperatorList setShowService={setShowService} countryISOCode={countryISOCode} phoneNumber={phoneNumber} isOpenOperator={isOpenOperator} setIsOpenOperator={setIsOpenOperator} setIsOpenMobile={setIsOpenMobile} opList={opList} setIsOpenOperatorDetails={setIsOpenOperatorDetails} isOpenOperatorDetails={isOpenOperatorDetails}  opDetails={opDetails} setOpDetails={setOpDetails} countryOption={countryOption} isCountry1={isCountry1}/>
            </>
        )}
       
        {isOpenOperatorDetails&&(<>
             <OperatorDetails setIsOpenOperatorDetails={setIsOpenOperatorDetails} isOpenOperatorDetails={isOpenOperatorDetails}  opDetails={opDetails} isOpenOperator={isOpenOperator} setIsOpenOperator={setIsOpenOperator} setIsOpenMobile={setIsOpenMobile} setShowService={setShowService} setOpDetails={setOpDetails} countryOption={countryOption} isCountry1={isCountry1} phoneNumber={phoneNumber}/>
        </>)}
    </div>
  );
};

export default Mobile;
