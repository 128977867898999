import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Sendbtc from "./sendbtc";
import TextField from "@mui/material/TextField";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal send-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Sendmodal = () => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [amountRequiredError, setAmountRequiredError] = useState(false);
  const [addressRequiredError, setAddressRequiredError] = useState(false);

  const handleAmountChange = (e) => {
    setAmountRequiredError(false);
    const inputAmount = e.target.value;
    const numericInput = inputAmount.replace(/[^0-9.]/g, "");
    setAmount(numericInput);
  };
  const handleAddressChange = (e) => {
    setAddressRequiredError(false);
    setReceiverAddress(e.target.value);
  };

  const openFirstModal = () => setIsFirstModalOpen(true);
  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
    setAmount("");
    setReceiverAddress("");
  };

  // mui css
  const textFieldStyles = {
    background: "#6C6C6C ",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };
  return (
    <div>
      <Avatar className="bg-white bg-receive" alt="" onClick={openFirstModal}>
        <img src="img/send1.svg" />
      </Avatar>
      <p className="text-white small text-center " style={{fontSize:'18px'}} >Send</p>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal " >
            <div className="modal-dialog">
              <div className="modal-content btc-send-modal">
                <div className="modal-header">
                  <div className="row">
                    <div className="col-12 col-md-12 col-sm-12 col-12">
                      <h5
                        className="modal-title"
                         style={{ textAlign:'left' }}
                      >
                        Send BTC
                      </h5>
                      <p
                        style={{
                          color: "#7D7DA1",
                          // marginLeft: "55px",
                          fontSize: "11px",
                        }}
                      >
                        Send BTC to another BTC address
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="close-sell-btc"
                    onClick={closeFirstModal}
                   
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body text-center" style={{ marginTop: "-25px" }}>
                  <form _lpchecked="1">
                    <div className="form-group w-100 mt-3">
                      <TextField
                        type="text"
                        // className="form-control "
                        id="usdAmount"
                        value={amount}
                        onChange={handleAmountChange}
                        label={
                          <span style={{ color: "#B8BCC3" }}>Amount</span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                      {amountRequiredError && (
                        <p className="text-danger text-xs">Field is Required</p>
                      )}
                      
                    </div>
                    <div className="form-group w-100 mt-3">
                      <TextField
                        type="text"
                        // className="form-control "
                        id="recieverAddress"
                        value={receiverAddress}
                        onChange={handleAddressChange}
                        label={
                          <span style={{ color: "#B8BCC3" }}> Address </span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                      {addressRequiredError && (
                        <p className="text-danger text-xs">Field is Required</p>
                      )}
                     
                    </div>
                  </form>
                </div>
                <div className="modal-footer ">
                  <div style={{width:'100%'}}>
                  <Sendbtc
                    amount={amount}
                    receiverAddress={receiverAddress}
                    closePreviousModal={closeFirstModal}
                    setAmountRequiredError={setAmountRequiredError}
                    setAddressRequiredError={setAddressRequiredError}
                    set
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Sendmodal;





// company code

// import React, { useState } from "react";
// import Avatar from "@mui/material/Avatar";
// import Sendbtc from "./sendbtc";

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal send-modal">
//       <div className="modal-content">{children}</div>
//     </div>
//   );
// };

// const Sendmodal = () => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const [amount, setAmount] = useState("");
//   const [receiverAddress, setReceiverAddress] = useState("");
//   const [amountRequiredError, setAmountRequiredError] = useState(false);
//   const [addressRequiredError, setAddressRequiredError] = useState(false);

//   const handleAmountChange = (e) => {
//     setAmountRequiredError(false);
//     const inputAmount = e.target.value;
//     const numericInput = inputAmount.replace(/[^0-9.]/g, "");
//     setAmount(numericInput);
//   };
//   const handleAddressChange = (e) => {
//     setAddressRequiredError(false);
//     setReceiverAddress(e.target.value);
//   };

//   const openFirstModal = () => setIsFirstModalOpen(true);
//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//     setAmount("");
//     setReceiverAddress("");
//   };
//   return (
//     <div>
//       <Avatar className="bg-white" alt="" onClick={openFirstModal}>
//         <img src="img/send.png" />
//       </Avatar>
//       <p className="text-white small text-center">Send</p>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className="modal-dialog">
//               <div className="modal-content">
//                 <div className="modal-header">
//                   <h5 className="modal-title text-center">Send BTC</h5>
//                   <button
//                     type="button"
//                     className="close"
//                     onClick={closeFirstModal}
//                   >
//                     <img src="img/close-icon.png" />
//                   </button>
//                 </div>
//                 <div className="modal-body">
//                   <form _lpchecked="1">
//                     <div className="form-group w-75 mb-3">
//                       <input
//                         type="text"
//                         className="form-control "
//                         id="usdAmount"
//                         placeholder="Enter Amount in USD"
//                         value={amount}
//                         onChange={handleAmountChange}
//                       />
//                       {amountRequiredError && (
//                         <p className="text-danger text-xs">Field is Required</p>
//                       )}
//                     </div>
//                     <div className="form-group w-75 mb-3">
//                       <input
//                         type="text"
//                         className="form-control "
//                         id="recieverAddress"
//                         placeholder="Receiver Address"
//                         value={receiverAddress}
//                         onChange={handleAddressChange}
//                       />
//                       {addressRequiredError && (
//                         <p className="text-danger text-xs">Field is Required</p>
//                       )}
//                     </div>
//                   </form>
//                 </div>
//                 <div className="modal-footer">
//                   <Sendbtc
//                     amount={amount}
//                     receiverAddress={receiverAddress}
//                     closePreviousModal={closeFirstModal}
//                     setAmountRequiredError={setAmountRequiredError}
//                     setAddressRequiredError={setAddressRequiredError}
//                     set
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Sendmodal;
