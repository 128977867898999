import React, { useState, useEffect } from "react";
import "./billpurchase.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import { useNavigate } from "react-router";
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import GiftCardList from "./giftcardlist";
import Mobile from "./mobile/mobile";
import Utility from "./utility/utility";
function BillPurchases() {
  const auth = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [reloadlyServices, setReloadlyServices] = useState([]);
  const [showService, setShowService] = useState(true);
  const [open, isOpen] = useState(false);
  const [isOpenMobile,setIsOpenMobile] = useState(false);
  const [isOpenUtility,setIsOpenUtility] = useState(false);
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
    reloadlyService();    
  }, []);

  const reloadlyService = async () => {
    const apicall = await AfterLoginService.reloadlyService(auth);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
          setReloadlyServices(resp.data)
          setLoader(false)
        } else {
          console.log(resp.message);
        }
      });
    } else {
    }
  };

 
 const serviceDetails = async(serviceId)=>{
  if(serviceId == 1){
    isOpen(true);
    setShowService(false);
  }
  else if(serviceId == 2){
    setIsOpenMobile(true)
    setShowService(false);
  }
  else if(serviceId == 3){
    setIsOpenMobile(false)
    setShowService(false);
    setIsOpenUtility(true)
  }
 }
  
  // select button

  const [selectedButton, setSelectedButton] = useState("All");

  const handleButtonClick = (buttonName) => {
   navigate('/billTransaction')
  };

  const getButtonStyle = (buttonName) => {
    // return {
    //   marginRight: "10px",
    //   backgroundColor: selectedButton === buttonName ? " #27ee91" : "",
    // };
  };


  function getButtonColor(status) {
    if (status && typeof status === "string") {
      switch (status.toUpperCase()) {
        case "PENDING":
          return "button-pending";
        case "DECLINED":
          return "button-declined";
        case "APPROVED":
          return "button-approved";
        case "PROCESSING":
          return "button-processing";
          case "COMPLETED":
          return "button-completed";
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  return (
    <div className="position-relative d-flex p-0  dpDesign">
      <Header />
      <div className="content">
        <Subheader />
        <div className={`${showService && ("container-fluid pt-4 px-4 wallet-table dpDesign1")}`}>
        {
          loader?
          (<> <div className="row">
                <div className="col-md-12 text-center">
                    <span
                      className="text-white spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span className="text-white">Loading...</span>
                </div>
              </div>
          </>)
          :<>
              {showService && (
                <>
                  
                        <div className="row">
                          <div className="col-md-6 col-12">
                              {/* <div className="back-icon-main">
                                <img src="/img/CaretLeft1.svg" onClick={goBack} />
                              </div> */}
                              <h3 className="heading-transaction mb-0">Bills & Purchases</h3>
                              <div className="row mt-2 ">
                                <div className="col-md-6 col-12">
                                    <div className="row bil_1">
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 bil_2">
                                        <button
                                          type="button"
                                          className="btn text-white"
                                          style={getButtonStyle("All")}
                                        >
                                            Purchases
                                        </button>    
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                        <button
                                          type="button"
                                          className="btn text-white"
                                          style={getButtonStyle("All")}
                                          onClick={() => handleButtonClick("All")}
                                        >
                                            Transactions
                                        </button>    
                                      </div>
                                    </div>
                                </div>
                              </div>
                              {reloadlyServices.length>0?(reloadlyServices.map((item,index)=>(
                        
                              <>
                                <div className="row">
                                  <div className="col-md-6 col-12 mb-4">
                                      <div className="service-img">
                                          <a href="javascript:void(0)" onClick={()=>serviceDetails(item.id)}>
                                            <img src={item.image_path}/>
                                            </a>
                                      </div>
                                  </div>
                                </div>
                              </>
                              ))):<></>}
                          </div>
                        </div>
                   
                </>
              )}  
          </>
        }
         </div>

        <GiftCardList isOpen={isOpen} open={open} setShowService={setShowService}/>
        <Mobile isOpenMobile={isOpenMobile} setIsOpenMobile={setIsOpenMobile} setShowService={setShowService}/>
        <Utility isOpenUtility={isOpenUtility} setIsOpenUtility={setIsOpenUtility}  setIsOpenMobile={setIsOpenMobile} setShowService={setShowService}/>
        
      </div>
    </div>
  );
}

export default BillPurchases;


