import {
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState, useEffect } from "react";
import QxchangeService from "../../Services/QxchangeService/QxchangeService";
import "./TransactionStatusModal.scss";
import { toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const whiteStyles = {
  color: "white", // Text color
  "& .MuiInputBase-input": {
    color: "white", // Input text color
  },
  "& .MuifilledInput-input": {
    borderColor: "white", 
  },
  "&:hover .MuifilledInput-notchedOutline": {
    borderColor: "white", 
  },
  width: "100%",
  border: "none",
  backgroundColor: "#070809",
  borderRadius: "0.375rem",
};

const TransactionStatusModal = ({
  isOpen,
  onClose,
  transactionDetails,
  setSuccessModalOpen,
}) => {
  const [apiState, setApiState] = useState("waiting");

  useEffect(() => {
    const delay = 15000;

    const fetchData = async () => {
      if (transactionDetails.id) {
        const { result } = await QxchangeService.TransactionStatus(
          transactionDetails.id
        );
        setApiState(result.status);
      }
    };

    const timerId = setInterval(() => {
      fetchData();
    }, delay);

    return () => {
      clearInterval(timerId); // Use clearInterval instead of clearTimeout for setInterval
    };
  }, [transactionDetails]);

  useEffect(() => {
    const otherStates = ["finished", "refunded"];

    if (otherStates.includes(apiState)) {
      onClose();
      setSuccessModalOpen(true);
    } else if (apiState === "failed") {
      toast.error("Transaction failed");
    }
  }, [apiState]);

  const handleCopyPayinAddress = () => {
    const textField = document.createElement("textarea");
    textField.innerText = transactionDetails.payinAddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  if (!isOpen) return null;
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-70"></div>

        <div className="modal-container bg-[#070809] text-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="flex items-start justify-center h-[500px] mt-24 qchange-heading">
            <div className="relative flex flex-col gap-2 justify-around items-center w-full py-4 px-4">
              <div>
                <div className="flex justify-end items-start">
                  <h2 className="text-center p-2 mr-[1rem]">
                    {transactionDetails.fromCurrency.toUpperCase()} to{" "}
                    {transactionDetails.toCurrency.toUpperCase()}
                  </h2>
                  <IconButton
                    color="inherit"
                    onClick={onClose}
                    className="absolute top-0 right-0"
                  >
                    <CancelIcon />
                  </IconButton>
                </div>

                <p className="text-center px-2 para">
                  Send {transactionDetails.payinAmount}{" "}
                  {transactionDetails.fromCurrency} to the
                  <span> specified Payin Wallet Address</span> below. Please be
                  carefull NOT to deposit from a smart contract
                </p>
              </div>
              <div className="flex gap-2 justify-around items-center w-full border bg-gray-900 rounded-lg p-4 m-2">
                {/* Waiting */}
                <div className="flex justify-center items-center flex-col">
                  {apiState === "waiting" ? (
                    <div>
                      <CircularProgress
                        color="success"
                        size={20}
                        className="mr-2"
                      />
                    </div>
                  ) : (
                    <CheckCircleIcon color={"success"} />
                  )}
                  <div className="text-xs text-center mt-1">Waiting</div>
                </div>

                <div className="flex justify-center items-center flex-col">
                  {apiState === "confirming" || apiState === "verifying" ? (
                    <div>
                      <CircularProgress
                        color="success"
                        size={20}
                        className="mr-2"
                      />
                    </div>
                  ) : apiState !== "waiting" ? (
                    <CheckCircleIcon color={"success"} />
                  ) : (
                    <div
                      className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
                    >
                      {apiState === "confirming" ||
                        (apiState === "verifying" && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
                          </div>
                        ))}
                    </div>
                  )}
                  <div className="text-xs text-center mt-1">Confirming</div>
                </div>

                <div className="flex justify-center items-center flex-col">
                  {apiState === "exchanging" ? (
                    <div>
                      <CircularProgress
                        color="success"
                        size={20}
                        className="mr-2"
                      />
                    </div>
                  ) : apiState !== "waiting" ? (
                    apiState !== "confirming" ? (
                      <CheckCircleIcon color={"success"} />
                    ) : (
                      <div
                        className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
                      >
                        {apiState === "exchanging" && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <div
                      className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
                    >
                      {apiState === "exchanging" && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="text-xs text-center mt-1">Exchanging</div>
                </div>

                <div className="flex justify-center items-center flex-col">
                  {apiState === "sending" ? (
                    <div>
                      <CircularProgress
                        color="success"
                        size={20}
                        className="mr-2"
                      />
                    </div>
                  ) : (
                    <div
                      className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
                    >
                      {apiState === "sending" && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="text-xs text-center mt-1">Sending</div>
                </div>
              </div>

              <div className="gap-1 flex flex-col w-full py-2">
                <TextField
                 label={<span style={{ color: "#7D7DA1" }}>ID</span>}
                  variant="filled"
                  fullWidth
                  value={transactionDetails.id}
                  color="success"
                  focused
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                />
                <TextField
                  label={<span style={{ color: "#7D7DA1" }}>Amount to Receive</span>}
                  variant="filled"
                  fullWidth
                  color="success"
                  value={`${
                    transactionDetails.amount
                  } ${transactionDetails.toCurrency.toUpperCase()}`}
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                  focused
                />
                <TextField
                  label={<span style={{ color: "#7D7DA1" }}>Receiving Address</span>}
                  variant="filled"
                  fullWidth
                  color="success"
                  value={transactionDetails.payoutAddress}
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                  focused
                />
                {transactionDetails.refundAddress && (
                  <TextField
                    label={<span style={{ color: "#7D7DA1" }}>Refund Address</span>}
                    variant="filled"
                    fullWidth
                    color="success"
                    value={transactionDetails.refundAddress}
                    InputProps={{
                      readOnly: true,
                      style: whiteStyles,
                    }}
                    focused
                  />
                )}
                <TextField
                  label={<span style={{ color: "#7D7DA1" }}>Payin Amount</span>}
                  variant="filled"
                  fullWidth
                  color="success"
                  value={`${
                    transactionDetails.payinAmount
                  } ${transactionDetails.fromCurrency.toUpperCase()}`}
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                  focused
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label={<span style={{ color: "#7D7DA1" }}>Payin Address</span>}
                    variant="filled"
                    fullWidth
                    color="success"
                    value={transactionDetails.payinAddress}
                    InputProps={{
                      readOnly: true,
                      style: whiteStyles,
                    }}
                    focused
                  />
                  <Tooltip title="Tap to Copy Payin Address" arrow>
                    <IconButton onClick={handleCopyPayinAddress}>
                      <ContentCopyIcon className="text-white" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Divider />
              <div className="w-100">
                <Button
                  color="success"
                  className="btn-proceed  "
                  variant="contained"
                  onClick={onClose}
                >
                  Cancel Qxchange
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionStatusModal;












// import {
//   CircularProgress,
//   Divider,
//   IconButton,
//   TextField,
//   Tooltip,
//   Button,
// } from "@mui/material";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import CancelIcon from "@mui/icons-material/Cancel";
// import React, { useState, useEffect } from "react";
// import QxchangeService from "../../Services/QxchangeService/QxchangeService";
// import "./TransactionStatusModal.scss";
// import { toast } from "react-toastify";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// const whiteStyles = {
//   color: "white", // Text color
//   "& .MuiInputBase-input": {
//     color: "white", // Input text color
//   },
//   "& .MuifilledInput-input": {
//     borderColor: "white", // Border color
//   },
//   "&:hover .MuifilledInput-notchedOutline": {
//     borderColor: "white", // Border color on hover
//   },
//   width: "100%",
//   border: "none",
//   backgroundColor: "#070809",
//   borderRadius: "0.375rem",
// };

// const TransactionStatusModal = ({
//   isOpen,
//   onClose,
//   transactionDetails,
//   setSuccessModalOpen,
// }) => {
//   const [apiState, setApiState] = useState("waiting");

//   useEffect(() => {
//     const delay = 15000;

//     const fetchData = async () => {
//       if (transactionDetails.id) {
//         const { result } = await QxchangeService.TransactionStatus(
//           transactionDetails.id
//         );
//         setApiState(result.status);
//       }
//     };

//     const timerId = setInterval(() => {
//       fetchData();
//     }, delay);

//     return () => {
//       clearInterval(timerId); // Use clearInterval instead of clearTimeout for setInterval
//     };
//   }, [transactionDetails]);

//   useEffect(() => {
//     const otherStates = ["finished", "refunded"];

//     if (otherStates.includes(apiState)) {
//       onClose();
//       setSuccessModalOpen(true);
//     } else if (apiState === "failed") {
//       toast.error("Transaction failed");
//     }
//   }, [apiState]);

//   const handleCopyPayinAddress = () => {
//     const textField = document.createElement("textarea");
//     textField.innerText = transactionDetails.payinAddress;
//     document.body.appendChild(textField);
//     textField.select();
//     document.execCommand("copy");
//     textField.remove();
//   };

//   if (!isOpen) return null;
//   return (
//     <div className="fixed z-10 inset-0 overflow-y-auto">
//       <div className="flex items-center justify-center ">
//         <div className="modal-overlay absolute w-full h-full bg-black opacity-70"></div>

//         <div className="modal-container bg-[#070809] text-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto" >
//           <div className="flex items-start justify-center h-[600px] mt-20">
//             <div className="relative flex flex-col gap-2 justify-around items-center w-full py-4 px-4 ">
//               <div>
//                 <div className="flex justify-end items-start mt-4">
//                   <h2 className="text-center p-2 mr-[1rem]">
//                     {transactionDetails.fromCurrency.toUpperCase()} to{" "}
//                     {transactionDetails.toCurrency.toUpperCase()}
//                   </h2>
//                   <IconButton
//                     color="inherit"
//                     onClick={onClose}
//                     className="absolute top-0 right-0"
//                   >
//                     <CancelIcon />
//                   </IconButton>
//                 </div>

//                 <p className="text-center px-2 para">
//                   Send {transactionDetails.payinAmount}{" "}
//                   {transactionDetails.fromCurrency} to the
//                   <span> specified Payin Wallet Address</span> below. Please be
//                   carefull NOT to deposit from a smart contract
//                 </p>
//               </div>
//               <div className="flex gap-2 justify-around items-center w-full border bg-gray-900 rounded-lg p-4 m-2">
//                 {/* Waiting */}
//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "waiting" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : (
//                     <CheckCircleIcon color={"success"} />
//                   )}
//                   <div className="text-xs text-center mt-1">Waiting</div>
//                 </div>

//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "confirming" || apiState === "verifying" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : apiState !== "waiting" ? (
//                     <CheckCircleIcon color={"success"} />
//                   ) : (
//                     <div
//                       className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                     >
//                       {apiState === "confirming" ||
//                         (apiState === "verifying" && (
//                           <div className="absolute inset-0 flex items-center justify-center">
//                             <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                           </div>
//                         ))}
//                     </div>
//                   )}
//                   <div className="text-xs text-center mt-1">Confirming</div>
//                 </div>

//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "exchanging" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : apiState !== "waiting" ? (
//                     apiState !== "confirming" ? (
//                       <CheckCircleIcon color={"success"} />
//                     ) : (
//                       <div
//                         className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                       >
//                         {apiState === "exchanging" && (
//                           <div className="absolute inset-0 flex items-center justify-center">
//                             <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                           </div>
//                         )}
//                       </div>
//                     )
//                   ) : (
//                     <div
//                       className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                     >
//                       {apiState === "exchanging" && (
//                         <div className="absolute inset-0 flex items-center justify-center">
//                           <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                         </div>
//                       )}
//                     </div>
//                   )}
//                   <div className="text-xs text-center mt-1">Exchanging</div>
//                 </div>

//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "sending" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : (
//                     <div
//                       className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                     >
//                       {apiState === "sending" && (
//                         <div className="absolute inset-0 flex items-center justify-center">
//                           <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                         </div>
//                       )}
//                     </div>
//                   )}
//                   <div className="text-xs text-center mt-1">Sending</div>
//                 </div>
//               </div>

//               <div className="gap-4 flex flex-col w-full py-2">
//                 <TextField
//                   label={<span style={{ color: "#7D7DA1" }}>ID</span>}
//                   variant="filled"
//                   fullWidth
//                   value={transactionDetails.id}
//                   color="success"
//                   focused
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                 />
//                 <TextField
//                   label={
//                     <span style={{ color: "#7D7DA1" }}>Amount to Receive</span>
//                   }
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={`${
//                     transactionDetails.amount
//                   } ${transactionDetails.toCurrency.toUpperCase()}`}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 <TextField
//                   label={
//                     <span style={{ color: "#7D7DA1" }}>Receiving Address</span>
//                   }
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={transactionDetails.payoutAddress}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 {transactionDetails.refundAddress && (
//                   <TextField
//                     label={
//                       <span style={{ color: "#7D7DA1" }}>Refund Address</span>
//                     }
//                     variant="filled"
//                     fullWidth
//                     color="success"
//                     value={transactionDetails.refundAddress}
//                     InputProps={{
//                       readOnly: true,
//                       style: whiteStyles,
//                     }}
//                     focused
//                   />
//                 )}
//                 <TextField
//                   label={<span style={{ color: "#7D7DA1" }}>Payin Amount</span>}
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={`${
//                     transactionDetails.payinAmount
//                   } ${transactionDetails.fromCurrency.toUpperCase()}`}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <TextField
//                     label={
//                       <span style={{ color: "#7D7DA1" }}>Payin Address</span>
//                     }
//                     variant="filled"
//                     fullWidth
//                     color="success"
//                     value={transactionDetails.payinAddress}
//                     InputProps={{
//                       readOnly: true,
//                       style: whiteStyles,
//                     }}
//                     focused
//                   />
//                   <Tooltip title="Tap to Copy Payin Address" arrow>
//                     <IconButton onClick={handleCopyPayinAddress}>
//                       <ContentCopyIcon className="text-white" />
//                     </IconButton>
//                   </Tooltip>
//                 </div>
//               </div>
//               <Divider />
//               <div className="w-100">
//                 <Button
//                   color="success"
//                   className="btn-proceed "
//                   variant="contained"
//                   onClick={onClose}
//                 >
//                   Cancel Qxchange
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransactionStatusModal;









// //company code

// import {
//   CircularProgress,
//   Divider,
//   IconButton,
//   TextField,
//   Tooltip,
//   Button,
// } from "@mui/material";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import CancelIcon from "@mui/icons-material/Cancel";
// import React, { useState, useEffect } from "react";
// import QxchangeService from "../../Services/QxchangeService/QxchangeService";
// import "./TransactionStatusModal.scss";
// import { toast } from "react-toastify";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// const whiteStyles = {
//   color: "white", // Text color
//   "& .MuiInputBase-input": {
//     color: "white", // Input text color
//   },
//   "& .MuifilledInput-input": {
//     borderColor: "white", // Border color
//   },
//   "&:hover .MuifilledInput-notchedOutline": {
//     borderColor: "white", // Border color on hover
//   },
//   width: "100%",
//   border: "none",
//   backgroundColor: "#252731",
//   borderRadius: "0.375rem",
// };

// const TransactionStatusModal = ({
//   isOpen,
//   onClose,
//   transactionDetails,
//   setSuccessModalOpen,
// }) => {
//   const [apiState, setApiState] = useState("waiting");

//   useEffect(() => {
//     const delay = 15000;

//     const fetchData = async () => {
//       if (transactionDetails.id) {
//         const { result } = await QxchangeService.TransactionStatus(
//           transactionDetails.id
//         );
//         setApiState(result.status);
//       }
//     };

//     const timerId = setInterval(() => {
//       fetchData();
//     }, delay);

//     return () => {
//       clearInterval(timerId); // Use clearInterval instead of clearTimeout for setInterval
//     };
//   }, [transactionDetails]);

//   useEffect(() => {
//     const otherStates = ["finished", "refunded"];

//     if (otherStates.includes(apiState)) {
//       onClose();
//       setSuccessModalOpen(true);
//     } else if (apiState === "failed") {
//       toast.error("Transaction failed");
//     }
//   }, [apiState]);

//   const handleCopyPayinAddress = () => {
//     const textField = document.createElement("textarea");
//     textField.innerText = transactionDetails.payinAddress;
//     document.body.appendChild(textField);
//     textField.select();
//     document.execCommand("copy");
//     textField.remove();
//   };

//   if (!isOpen) return null;
//   return (
//     <div className="fixed z-10 inset-0 overflow-y-auto">
//       <div className="flex items-center justify-center min-h-screen">
//         <div className="modal-overlay absolute w-full h-full bg-black opacity-70"></div>

//         <div className="modal-container bg-[#070809] text-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
//           <div className="flex items-start justify-center h-[600px] mt-24">
//             <div className="relative flex flex-col gap-2 justify-around items-center w-full py-4 px-4">
//               <div>
//                 <div className="flex justify-end items-start">
//                   <h2 className="text-center p-2 mr-[1rem]">
//                     {transactionDetails.fromCurrency.toUpperCase()} to{" "}
//                     {transactionDetails.toCurrency.toUpperCase()}
//                   </h2>
//                   <IconButton
//                     color="inherit"
//                     onClick={onClose}
//                     className="absolute top-0 right-0"
//                   >
//                     <CancelIcon />
//                   </IconButton>
//                 </div>

//                 <p className="text-center px-2 para">
//                   Send {transactionDetails.payinAmount}{" "}
//                   {transactionDetails.fromCurrency} to the
//                   <span> specified Payin Wallet Address</span> below. Please be
//                   carefull NOT to deposit from a smart contract
//                 </p>
//               </div>
//               <div className="flex gap-2 justify-around items-center w-full border bg-gray-900 rounded-lg p-4 m-2">
//                 {/* Waiting */}
//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "waiting" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : (
//                     <CheckCircleIcon color={"success"} />
//                   )}
//                   <div className="text-xs text-center mt-1">Waiting</div>
//                 </div>

//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "confirming" || apiState === "verifying" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : apiState !== "waiting" ? (
//                     <CheckCircleIcon color={"success"} />
//                   ) : (
//                     <div
//                       className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                     >
//                       {apiState === "confirming" ||
//                         (apiState === "verifying" && (
//                           <div className="absolute inset-0 flex items-center justify-center">
//                             <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                           </div>
//                         ))}
//                     </div>
//                   )}
//                   <div className="text-xs text-center mt-1">Confirming</div>
//                 </div>

//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "exchanging" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : apiState !== "waiting" ? (
//                     apiState !== "confirming" ? (
//                       <CheckCircleIcon color={"success"} />
//                     ) : (
//                       <div
//                         className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                       >
//                         {apiState === "exchanging" && (
//                           <div className="absolute inset-0 flex items-center justify-center">
//                             <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                           </div>
//                         )}
//                       </div>
//                     )
//                   ) : (
//                     <div
//                       className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                     >
//                       {apiState === "exchanging" && (
//                         <div className="absolute inset-0 flex items-center justify-center">
//                           <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                         </div>
//                       )}
//                     </div>
//                   )}
//                   <div className="text-xs text-center mt-1">Exchanging</div>
//                 </div>

//                 <div className="flex justify-center items-center flex-col">
//                   {apiState === "sending" ? (
//                     <div>
//                       <CircularProgress
//                         color="success"
//                         size={20}
//                         className="mr-2"
//                       />
//                     </div>
//                   ) : (
//                     <div
//                       className={`w-8 h-8 rounded-full border-4 border-gray-300 transition-opacity opacity-50`}
//                     >
//                       {apiState === "sending" && (
//                         <div className="absolute inset-0 flex items-center justify-center">
//                           <div className="w-4 h-4 border-t-2 border-r-2 border-b-2 border-gray-500 rounded-full animate-spin"></div>
//                         </div>
//                       )}
//                     </div>
//                   )}
//                   <div className="text-xs text-center mt-1">Sending</div>
//                 </div>
//               </div>

//               <div className="gap-4 flex flex-col w-full py-2">
//                 <TextField
//                   label="ID"
//                   variant="filled"
//                   fullWidth
//                   value={transactionDetails.id}
//                   color="success"
//                   focused
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                 />
//                 <TextField
//                   label="Amount to Receive"
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={`${
//                     transactionDetails.amount
//                   } ${transactionDetails.toCurrency.toUpperCase()}`}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 <TextField
//                   label="Receiving Address"
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={transactionDetails.payoutAddress}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 {transactionDetails.refundAddress && (
//                   <TextField
//                     label="Refund Address"
//                     variant="filled"
//                     fullWidth
//                     color="success"
//                     value={transactionDetails.refundAddress}
//                     InputProps={{
//                       readOnly: true,
//                       style: whiteStyles,
//                     }}
//                     focused
//                   />
//                 )}
//                 <TextField
//                   label="Payin Amount"
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={`${
//                     transactionDetails.payinAmount
//                   } ${transactionDetails.fromCurrency.toUpperCase()}`}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <TextField
//                     label="Payin Address"
//                     variant="filled"
//                     fullWidth
//                     color="success"
//                     value={transactionDetails.payinAddress}
//                     InputProps={{
//                       readOnly: true,
//                       style: whiteStyles,
//                     }}
//                     focused
//                   />
//                   <Tooltip title="Tap to Copy Payin Address" arrow>
//                     <IconButton onClick={handleCopyPayinAddress}>
//                       <ContentCopyIcon className="text-white" />
//                     </IconButton>
//                   </Tooltip>
//                 </div>
//               </div>
//               <Divider />
//               <div>
//                 <Button
//                   color="success"
//                   className="btn-proceed "
//                   variant="contained"
//                   onClick={onClose}
//                 >
//                   Cancel Qxchange
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransactionStatusModal;





