import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Trc20Services from "../../Services/LoginSignService/Trc20Service";
import { toast } from 'react-toastify';

const Modal = ({ onClose, children }) => {
    return (
        <div className="modal send-usd">
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
};

const SellSuccessfullmodal = (props) => {

    const auth = JSON.parse(localStorage.getItem("user"));
    const [isLoading, setIsLoading] = useState(false);
    const [successResult, setSuccessResult] = useState({});
    const [successModelOpen, setSuccessModelOpen] = useState(false);

    const Loader = () => {
        return (
            <>
                <div class="fixed top-0 z-[9999] left-0 bottom-0 right-0 bg-black opacity-25 bg-opacity-60  h-full w-full flex items-center justify-center">
                    <div class="flex items-center">
                        <svg
                            class="animate-spin h-20 w-20 text-blue-950"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="gray"
                                stroke-width="4"
                            ></circle>
                            <path
                                fill="#fff"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div>
                </div>
            </>
        )
    }

    const sellTransaction = async () => {
        setIsLoading(true);
        const sellTransactionResponse = await Trc20Services.sellTransaction(auth, props.validResponse, props.token);
        if ((sellTransactionResponse.result !== undefined && sellTransactionResponse.result.status !== undefined && sellTransactionResponse?.result?.status === 500) || (sellTransactionResponse?.statusCode && sellTransactionResponse?.statusCode === 400)) {
            toast.error((sellTransactionResponse.result !== undefined && sellTransactionResponse.result.message) || (sellTransactionResponse.message && sellTransactionResponse.message));
            setIsLoading(false);
            return;
        }
        setSuccessResult(sellTransactionResponse.result)
        setSuccessModelOpen(true);
        setIsLoading(false);
    }

    return (
        <div>
            <button className='btn btn-updat text-light' onClick={sellTransaction} style={{marginTop:'-2px'}}>Send </button>
            {successModelOpen && (
                <Modal>
                    <div className="modal">
                        <div className='modal-dialog'>
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-end align-items-end w-100">
                                    <button type="button" className="close" onClick={()=>props.setIsFirstModalOpen(false)}>
                                        <img src='img/close-icon.png' />
                                    </button>
                                </div>
                                <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col">
                                    <Avatar className='bg-success mb-3'>
                                        <i class="bi bi-check-lg text-white"></i>
                                    </Avatar>
                                    <p className='h4 text-white'>
                                        Successfully
                                    </p>
                                    <p>Your transaction has been successfully initiated. Refresh the transaction to check current status. Track your transaction 
                                        
                                        <span className='text-white fs-5 d-block'><a href={`https://tronscan.org/#/transaction/${successResult?.txID}`} target='_blank'>Transaction Detail</a> </span>
                                    </p>
                                </div>
                                <div className="modal-footer d-flex">
                                    <button className='btn btn-update w-25' onClick={()=>{props.setIsFirstModalOpen(false); props.refreshHistory()}}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {isLoading === true && (
                <Loader />
            )}
        </div>
    );
};

export default SellSuccessfullmodal;
