import React, {useState} from 'react';
import './subheader.scss';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../node_modules/bootstrap/js/dist/dropdown';
import "bootstrap-icons/font/bootstrap-icons.css";
import Notificationmodal from '../notification/notification';
import Profilemodal from './profilemodal';
import Mobilesidebar from '../mobilesidebar/mobilesidebar';
import { useNavigate } from 'react-router';



function Subheader(){
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/');
    }

    return(
        <>
        <nav className="navbar navbar-expand  sticky-top header "> 
            <div className='mobile-menu d-lg-none d-sm-block'> 
               <Mobilesidebar />
            </div>
            <div className="navbar-nav align-items-center ms-auto">
                <div className="nav-item dropdown">                 
                    <Notificationmodal />
                    
                </div>
                <div className='profile-box'>
                    <Profilemodal />
                </div>
            </div>
        </nav>
    
        </>
    );
}
export default Subheader;







// import React, {useState} from 'react';
// import './subheader.scss';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../../../node_modules/bootstrap/js/dist/dropdown';
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Notificationmodal from '../notification/notification';
// import Profilemodal from './profilemodal';
// import Mobilesidebar from '../mobilesidebar/mobilesidebar';
// import { useNavigate } from 'react-router';



// function Subheader(){
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const toggleSidebar = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };


//     const navigate = useNavigate();

//     const logout = () => {
//         localStorage.clear();
//         navigate('/');
//     }

//     return(
//         <>
//         <nav className="navbar navbar-expand  sticky-top header"> 
//             <div className='mobile-menu d-lg-none d-sm-block'> 
//                <Mobilesidebar />
//             </div>
//             <div className="navbar-nav align-items-center ms-auto">
//                 <div className="nav-item dropdown">                 
//                     <Notificationmodal />
//                 </div>
//                 <div className='profile-box'>
//                     <Profilemodal />
//                 </div>
//             </div>
//         </nav>
    
//         </>
//     );
// }
// export default Subheader;