import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useEffect, useRef } from "react";
import moment from "moment";
import "./SingleTransStatusModal.scss";
import CancelIcon from "@mui/icons-material/Cancel";

const getStatusStyles = (status) => {
  switch (status) {
    case "waiting":
      return {
        color: "#ffba52",
        // backgroundColor: "#ffba52",
        borderColor: "#ffba52",
      };
    case "declined":
      return {
        color: "#ed495c",
        backgroundColor: "#ed495c",
        borderColor: "#ed495c",
      };
    case "approved":
      return {
        color: "#5aecab",
        backgroundColor: "#5aecab",
        borderColor: "#5aecab",
      };
    case "processing":
      return {
        color: "#02a9f5",
        backgroundColor: "#02a9f5",
        borderColor: "#02a9f5",
      };
    default:
      return {};
  }
};

const whiteStyles = {
  color: "white", // Text color
  fontSize:'11px',
  "& .MuiInputBase-input": {
    color: "white", // Input text color
    
  },
  "& .MuiFilledInput-input": {
    borderColor: "white", // Border color
    
  },
  "&:hover .MuiFilledInput-notchedOutline": {
    borderColor: "white", // Border color on hover
  },

  width: "100%",
  border: "none",
  backgroundColor: "#070809",
  borderRadius: "0.375rem",
  
};

const SingleTransStatusModal = ({ isOpen, onClose, transactionDetails }) => {
  const modalRef = useRef(null);
  const handleCloseModal = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleCloseModal);
    return () => {
      window.removeEventListener("mousedown", handleCloseModal);
    };
  }, []);

  const handleCopyPayinAddress = () => {
    const textField = document.createElement("textarea");
    textField.innerText = transactionDetails.payinAddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  if (!isOpen) return null;

  const statusStyles = getStatusStyles(transactionDetails.status);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-70"></div>

        <div
          ref={modalRef}
          className="modal-container bg-[#070809] text-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto qchnage-modal"
        >
          <div className="flex items-start justify-center h-[600px] mt-24">
            <div className="relative flex flex-col gap-2 justify-around items-center w-full py-4">
              <div className="text-center w-full px-4">
                <h2 className="text-center p-2 ">
                  {transactionDetails.fromCurrency.toUpperCase()} to
                  <span className="p-1"></span>
                  {transactionDetails.toCurrency.toUpperCase()}
                </h2>
                <div className="flex justify-end items-start btn-waiting">
                  <Button
                    className="text-sm"
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      marginRight: "7rem",
                      ...statusStyles, // Apply the status-specific styles here
                    }}
                  >
                    {transactionDetails.status}
                  </Button>
                  <IconButton
                    color="inherit"
                    onClick={onClose}
                    className="absolute  right-0"
                    style={{ marginTop: "-80px" }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
                <p className=" px-4 mt-2" style={{ color: "#ACACAC" }}>
                  {moment(transactionDetails.createdAt).format(
                    "MMMM D, YYYY HH:mm"
                  )}
                </p>
              </div>

              <div className="gap-4 flex flex-col w-full px-4 py-2">
                <TextField
                  label={<span style={{ color: "#7D7DA1" }}>ID</span>}
                  variant="filled"
                  fullWidth
                  value={transactionDetails.id}
                  color="success"
                  focused
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                />

                <TextField
                  label={
                    <span style={{ color: "#7D7DA1" }}>Amount to Receive</span>
                  }
                  variant="filled"
                  fullWidth
                  color="success"
                  value={`${
                    transactionDetails.expectedReceiveAmount
                  } ${transactionDetails.toCurrency.toUpperCase()}`}
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                  focused
                />
                <TextField
                  label={
                    <span style={{ color: "#7D7DA1", }}>Receiving Address</span>
                  }
                  variant="filled"
                  fullWidth
                  color="success"
                  value={transactionDetails.payoutAddress}
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                    
                  }}
                  focused
                />
                {transactionDetails.refundAddress && (
                  <TextField
                    label={
                      <span style={{ color: "#7D7DA1" }}>Refund Address</span>
                    }
                    variant="filled"
                    fullWidth
                    color="success"
                    value={transactionDetails.refundAddress}
                    InputProps={{
                      readOnly: true,
                      style: whiteStyles,
                    }}
                    focused
                  />
                )}
                <TextField
                  label={<span style={{ color: "#7D7DA1" }}>Payin Amount</span>}
                  variant="filled"
                  fullWidth
                  color="success"
                  value={`${
                    transactionDetails.expectedSendAmount
                  } ${transactionDetails.fromCurrency.toUpperCase()}`}
                  InputProps={{
                    readOnly: true,
                    style: whiteStyles,
                  }}
                  focused
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label={
                      <span style={{ color: "#7D7DA1" }}>Payin Address</span>
                    }
                    variant="filled"
                    fullWidth
                    color="success"
                    value={transactionDetails.payinAddress}
                    InputProps={{
                      readOnly: true,
                      style: whiteStyles,
                    }}
                    focused
                  />
                  <Tooltip title="Tap to Copy Payin Address" arrow>
                    <IconButton onClick={handleCopyPayinAddress}>
                      <ContentCopyIcon className="text-white" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTransStatusModal;

// company code

// import { Button, IconButton, TextField, Tooltip } from "@mui/material";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import React, { useEffect, useRef } from "react";
// import moment from "moment";
// import "./SingleTransStatusModal.scss";
// import CancelIcon from "@mui/icons-material/Cancel";

// const whiteStyles = {
//   color: "white", // Text color
//   "& .MuiInputBase-input": {
//     color: "white", // Input text color
//   },
//   "& .MuifilledInput-input": {
//     borderColor: "white", // Border color
//   },
//   "&:hover .MuifilledInput-notchedOutline": {
//     borderColor: "white", // Border color on hover
//   },
//   width: "100%",
//   border: "none",
//   backgroundColor: "#252731",
//   borderRadius: "0.375rem",
// };

// const SingleTransStatusModal = ({ isOpen, onClose, transactionDetails }) => {
//   const modalRef = useRef(null);
//   const handleCloseModal = (e) => {
//     if (modalRef.current && !modalRef.current.contains(e.target)) {
//       onClose();
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("mousedown", handleCloseModal);
//     return () => {
//       window.removeEventListener("mousedown", handleCloseModal);
//     };
//   }, []);

//   const handleCopyPayinAddress = () => {
//     const textField = document.createElement("textarea");
//     textField.innerText = transactionDetails.payinAddress;
//     document.body.appendChild(textField);
//     textField.select();
//     document.execCommand("copy");
//     textField.remove();
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed z-10 inset-0 overflow-y-auto">
//       <div className="flex items-center justify-center min-h-screen">
//         <div className="modal-overlay absolute w-full h-full bg-black opacity-70"></div>

//         <div
//           ref={modalRef}
//           className="modal-container bg-[#070809] text-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
//         >
//           <div className="flex items-start justify-center h-[600px] mt-24">
//             <div className="relative flex flex-col gap-2 justify-around items-center w-full py-4">
//               <div className="text-center w-full px-4">
//                 <div className="flex justify-end items-start">
//                   <Button
//                     className="text-sm"
//                     variant="outlined"
//                     color="success"
//                     sx={{
//                       borderRadius: "20px",
//                       marginRight: "7rem",
//                       color: "#a076f9 !important",
//                       border: "1px solid #a076f9 !important",
//                     }}
//                   >
//                     {transactionDetails.status}
//                   </Button>
//                   <IconButton
//                     color="inherit"
//                     onClick={onClose}
//                     className="absolute top-0 right-0"
//                   >
//                     <CancelIcon />
//                   </IconButton>
//                 </div>

//                 <h2 className="text-center p-2">
//                   {transactionDetails.fromCurrency.toUpperCase()} to
//                   {transactionDetails.toCurrency.toUpperCase()}
//                 </h2>

//                 <p className=" px-4" style={{marginTop:'-10px'}}>
//                   {moment(transactionDetails.createdAt).format(
//                     "MMMM D, YYYY HH:mm"
//                   )}
//                 </p>
//               </div>

//               <div className="gap-4 flex flex-col w-full px-4 py-2">
//                 <TextField
//                   label="ID"
//                   variant="filled"
//                   fullWidth
//                   value={transactionDetails.id}
//                   color="success"
//                   focused
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                 />
//                 <TextField
//                   label="Amount to Receive"
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={`${
//                     transactionDetails.expectedReceiveAmount
//                   } ${transactionDetails.toCurrency.toUpperCase()}`}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 <TextField
//                   label="Receiving Address"
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={transactionDetails.payoutAddress}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 {transactionDetails.refundAddress && (
//                   <TextField
//                     label="Refund Address"
//                     variant="filled"
//                     fullWidth
//                     color="success"
//                     value={transactionDetails.refundAddress}
//                     InputProps={{
//                       readOnly: true,
//                       style: whiteStyles,
//                     }}
//                     focused
//                   />
//                 )}
//                 <TextField
//                   label="Payin Amount"
//                   variant="filled"
//                   fullWidth
//                   color="success"
//                   value={`${
//                     transactionDetails.expectedSendAmount
//                   } ${transactionDetails.fromCurrency.toUpperCase()}`}
//                   InputProps={{
//                     readOnly: true,
//                     style: whiteStyles,
//                   }}
//                   focused
//                 />
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   <TextField
//                     label="Payin Address"
//                     variant="filled"
//                     fullWidth
//                     color="success"
//                     value={transactionDetails.payinAddress}
//                     InputProps={{
//                       readOnly: true,
//                       style: whiteStyles,
//                     }}
//                     focused
//                   />
//                   <Tooltip title="Tap to Copy Payin Address" arrow>
//                     <IconButton onClick={handleCopyPayinAddress}>
//                       <ContentCopyIcon className="text-white" />
//                     </IconButton>
//                   </Tooltip>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SingleTransStatusModal;
