import React, { useState, useEffect, useRef } from "react";
import "./giftcard.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "../header/header";
import Subheader from "../subheader/subheader";
import Giftcardmodal from "./giftcardmodal";
import { useNavigate } from "react-router";
import LoginSignService from "../../Services/LoginSignService/LoginSignService";
import CryptoModal from "../crypto/cryptoModal";
import { MdClose } from "react-icons/md";

function Giftcard() {
  const [modal, setmodal] = useState(false);
  const [open, isOpen] = useState(false);
  const [openCrypto, setOpenCrypto] = useState(false);
  const [data, setGift] = useState([]);
  const [cryptoData, setCryptoData] = useState([]);
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("user"));
  const [cardId, setCardId] = useState({});
  const [cryptoInfo, setCryptoInfo] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const containerRef = useRef(null);

  const [getCry, setCrypto] = useState(false);


  const cryptoList = async () => {
    setLoading(true);
    const apiCall = await LoginSignService.cryptoList(auth);
    if (apiCall.state == true) {
      apiCall.result.json().then((resp) => {
        if (resp.status == 200) {
          setCrypto(false);
          setCryptoData(resp.data);
          setLoading(false);
        } else {
          setLoading(false);
          console.log(resp.message);
        }
      });
    } else {
    }
  };

  useEffect(() => {
    const newGiftCardList = data.filter((item) =>
      item?.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setGift(newGiftCardList);
    
  }, [searchValue]);

  const handleOpen = async (item) => {
    setCardId(item);
    isOpen(true);
  };

  const handleOpenCrypto = async (item) => {
   
    if (item?.id === 1) {
      navigate("/Logindetails");
    }
    else if (item?.id === 4) {
      navigate("/Trc");
    } 
    else if (item?.id === 6) {
      navigate("/instant");
    }
    else if (item?.id === 7) {
      navigate("/Cryptosell");
    }
    else {
      setCryptoInfo(item);
      setOpenCrypto(true);
    }
  };

  const removeSearch = async (e) => {
    setSearchValue("");
    // giftCardList()
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();

    const filteredData = data.filter((item) =>
       item.name.toLowerCase().includes(inputValue.toLowerCase())
      // item.name.toLowerCase().startsWith(inputValue)
    );
    // Update the filtered data state
    setFilteredData(filteredData);

    // Update the searchQuery state
    setSearchValue(inputValue);
  };

  useEffect(() => {
    setCrypto(true);
   cryptoList();
  }, []);

  // 1411
  const calculatePerPage = (page) => {
    return 100 + (page - 1) * 100;
  };
  // 1411

  const giftCardList = async (page) => {
    //// if (loading || (page > 0 && !hasMore)) return;
    if (loading) return;

    setLoading(true);

    const perPage = calculatePerPage(page);

    const apiCall = await LoginSignService.GiftCardList(auth, page, perPage);

    if (apiCall.state == true) {
      apiCall.result.json().then((resp) => {
        if (resp.status == 200) {
          
          if (page === 0) {
            setGift(resp.data);
          } else {
            setGift((prevData) => [...prevData, ...resp.data]);
          }

          if (resp.data.length < perPage) {
            setHasMore(false);
          }
        } else {
          console.log(resp.message);
        }
        setLoading(false); // Loading is complete
      });
    } else {
      setLoading(false); // Loading is complete
    }
  };

  const handleInfiniteScroll = async () => {
    try {
      const contentElement = containerRef.current;

      if (
        contentElement &&
        contentElement.scrollTop + contentElement.clientHeight + 1 >=
          contentElement.scrollHeight
      ) {
        setCurrentPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!containerRef.current) return; // Ensure containerRef is available

    const contentElement = containerRef.current;
    contentElement.addEventListener("scroll", handleInfiniteScroll);

    return () => {
      contentElement.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [currentPage, containerRef]);

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      navigate("/");
    }

    if (auth) {
      
      giftCardList(currentPage, searchValue);
    }
  }, [currentPage, searchValue]);

  return (
    <>
      
      <Giftcardmodal gitCardCurrency={cardId} isOpen={isOpen} open={open} />
      <CryptoModal
        openCrypto={openCrypto}
        setOpenCrypto={setOpenCrypto}
        cryptoInfo={cryptoInfo}
      />
      
      <div className="container-fluid position-relative d-flex p-0 gift-container overflow-hidden">
        <Header />

        <div
          className="content overflow-x-hidden !w-full bg-black"
          ref={containerRef}
        >
          <Subheader />
          {getCry ? (
              <>
                <div className="col-md-12 text-center m-4">
                  <span
                    className="text-white spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="text-white">Loading...</span>
                </div>
              </>
            ) : (
              <>
                  {/* Crypto Details  */}
                  {cryptoData?.length > 0 && (
                    <div className="container-fluid box-container bg-zinc-900 !h-auto overflow-hidden">
                      <div className="row">
                        <div className="col-lg-9 col-md-9 col-sm-12 col-12"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-12">

                          <div className="form-group  relative search-header mt-3">
                            <img src="/img/search.png" className="search-icon" />

                            <input
                              type="text"
                              className="p-[6px] border-[1px] border-[#dee2e6] rounded-md mb-[10px] w-100 text-white !text-sm focus:!outline-none pl-[40px] serach-input" 
                              id="name"
                              onChange={(e) => handleSearch(e)}
                              value={searchValue}
                              placeholder="Search by name"
                              style={{
                                background: "#252731",
                              }}
                            />
                          </div>

                          <div
                            className="hover:cursor-pointer rounded-full h-6 w-6 text-white flex justify-center items-center absolute !top-1 !right-0 pr-2"
                            onClick={(e) => removeSearch(e)}
                          >
                            <MdClose size={20} />{" "}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-lg-4 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center">
                          <div className="mt-2">
                            <h5 className="mb-0 h3 ms-2 text-heading">
                              {searchValue === "" && cryptoData?.length > 0
                                ? "Crypto"
                                : "Gift Card"}
                            </h5>
                            <p className="small ms-2 " style={{ marginTop: "10px" }}>
                              What would you like to do today
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12"> </div>
                        <div className="row main-card">
                          {searchValue === "" && cryptoData?.length > 0 ? (
                            cryptoData.map((item, index) => (
                              <div
                                className={`col-lg-3 col-md-6 col-sm-6 col-6  mb-2 ${item.id ===4 ? "d-none":""}`}
                                key={index}
                              >
                                <div className="box h-40 rectangle mt-3" key={index}>
                                  <div className="text-center">
                                    <h5 className="text-white pt-2 text-card-heading">
                                      {item.name}{" "}
                                    </h5>
                                  </div>
                                  <div className="header1 mt-1">
                                    <div
                                      className="box-header img-card-box "
                                      // className="box-header h-12 justify-content-center d-flex align-items-center bg-cyan-700 m-1"
                                      style={{
                                        backgroundColor: item.background || "#252731",
                                      }}
                                    >
                                      <img
                                        className="card-img"
                                        src={item.image}
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex flex-col justify-content-between align-items-center h-32 ">
                                    <div
                                      className=" sell-btn text-center p-2  "
                                      onClick={() => handleOpenCrypto(item)}
                                    >
                                      Sell{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : filteredData.length > 0 ? (
                            filteredData.map((item, index) => (
                              <div className="col-lg-3 col-md-6 col-sm-6 col-6  mb-2">
                              <div
                                className="box h-40 rectangle mt-3"
                                onClick={() => setmodal(true)}
                                key={index}
                              >
                                <div className="text-center">
                                  <h5 className="text-white pt-2 text-card-heading">
                                    {item.name}
                                  </h5>
                                </div>
                                <div className="header1 mt-1">
                                  <div
                                    className="box-header img-card-box"
                                    style={{
                                      backgroundColor: item.background || "#252731",
                                    }}
                                  >
                                    <img
                                      className="card-img"
                                      src={item.image}
                                      alt="img"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-col justify-content-between align-items-center h-32">
                                  <div
                                    className=" sell-btn text-center p-2  "
                                    onClick={() => handleOpen(item)}
                                  >
                                    Sell{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            ))
                          ) : (
                            <p className="text-white flex justify-center items-center py-20">
                              No Data Found
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Gift Card Detail  */}
                  {searchValue === "" && (
                    <div className="container-fluid box-container mb-m bg-zinc-900 !h-auto overflow-hidden">
                      <div className="row">
                        <div
                          className={`col-md-4 col-lg-4 col-xs-12 text-white ${
                            searchValue === "" && cryptoData?.length > 0
                              ? "my-5"
                              : "mb-10"
                          } gift-heading  `}
                        >
                          <h5 className="mb-0 h3 ms-2 pt-3 text-heading">Gift Card</h5>
                          <p
                            className="small ms-2 mb-3 gift-p"
                            style={{ marginTop: "10px" }}
                          >
                            What would you like to do today
                          </p>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12"> </div>

                        <div className="row main-card">
                          {data.map((item, index) => (
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6  mb-2">
                              <div
                                className="box h-40 rectangle mt-3"
                                onClick={() => setmodal(true)}
                                key={index}
                              >
                                <div className="text-center">
                                  <h5 className="text-white pt-2 text-card-heading">
                                    {item.name}
                                  </h5>
                                </div>
                                <div className="header1 mt-1">
                                  <div
                                    className="box-header img-card-box"
                                    style={{
                                      backgroundColor: item.background || "#252731",
                                    }}
                                  >
                                    <img
                                      className="card-img"
                                      src={item.image}
                                      alt="img"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-col justify-content-between align-items-center h-32">
                                  <div
                                    className=" sell-btn text-center p-2  "
                                    onClick={() => handleOpen(item)}
                                  >
                                    Sell{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                 
              </>
            )}
        </div>
      </div>
     
    </>
  );
}

export default Giftcard;





// company code

// /* eslint-disable no-unused-vars */
// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable eqeqeq */
// import React, { useState, useEffect, useRef } from 'react';
// import './giftcard.scss';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import "bootstrap-icons/font/bootstrap-icons.css";
// import Header from '../header/header';
// import Subheader from '../subheader/subheader';
// import Giftcardmodal from './giftcardmodal';
// import { useNavigate } from 'react-router';
// import LoginSignService from '../../Services/LoginSignService/LoginSignService'
// import CryptoModal from '../crypto/cryptoModal';
// import { MdClose } from 'react-icons/md';

// function Giftcard() {
//     const [modal, setmodal] = useState(false)
//     const [open, isOpen] = useState(false)
//     const [openCrypto, setOpenCrypto] = useState(false)
//     const [data, setGift] = useState([]);
//     const [cryptoData, setCryptoData] = useState([]);
//     const navigate = useNavigate();
//     const auth = JSON.parse(localStorage.getItem('user'));
//     const [cardId, setCardId] = useState({})
//     const [cryptoInfo, setCryptoInfo] = useState({})
//     const [searchValue, setSearchValue] = useState("")
//     const [currentPage, setCurrentPage] = useState(0);
//     const [loading, setLoading] = useState(false);
//     const [hasMore, setHasMore] = useState(true);
//     const [filteredData, setFilteredData] = useState([]);
//     const containerRef = useRef(null);

//     const cryptoList = async () => {
//         setLoading(true);
//         const apiCall = await LoginSignService.cryptoList(auth);
//         if (apiCall.state == true) {
//             apiCall.result.json().then((resp) => {
//                 if (resp.status == 200) {
//                     setCryptoData(resp.data);
//                     setLoading(false);
//                 }
//                 else {
//                     setLoading(false);
//                     console.log(resp.message);
//                 }
//             })
//         }
//         else {
//         }
//     }

//     useEffect(() => {
//         const newGiftCardList = data.filter(item => item?.name.toLowerCase().includes(searchValue.toLowerCase()))
//         setGift(newGiftCardList)
//     }, [searchValue])

//     const handleOpen = async (item) => {
//         setCardId(item)
//         isOpen(true)
//     }

//     const handleOpenCrypto = async (item) => {
//         if (item?.id === 1) {
//             navigate('/Logindetails');
//         } else if (item?.id === 4) {
//             navigate('/Trc');
//         } else {
//             setCryptoInfo(item)
//             setOpenCrypto(true)
//         }
//     }

//     const removeSearch = async (e) => {
//         setSearchValue("")
//         // giftCardList()
//     }

//     const handleSearch = (e) => {
//         const inputValue = e.target.value.toLowerCase();

//         const filteredData = data.filter((item) =>
//             // item.name.toLowerCase().includes(searchQuery.toLowerCase())
//             item.name.toLowerCase().startsWith(inputValue)

//         );
//         // Update the filtered data state
//         setFilteredData(filteredData);

//         // Update the searchQuery state
//         setSearchValue(inputValue);
//     };

//     useEffect(() => {
//         cryptoList()
//     }, [])

//     const calculatePerPage = (page) => {
//         return 20 + (page - 1) * 20;
//     };

//     const giftCardList = async (page) => {
//         // if (loading || (page > 0 && !hasMore)) return;
//         if (loading) return;

//         setLoading(true);

//         const perPage = calculatePerPage(page);

//         const apiCall = await LoginSignService.GiftCardList(auth, page, perPage);

//         if (apiCall.state == true) {
//             apiCall.result.json().then((resp) => {
//                 if (resp.status == 200) {
//                     if (page === 0) {
//                         setGift(resp.data);
//                     } else {
//                         setGift((prevData) => [...prevData, ...resp.data]);

//                     }

//                     if (resp.data.length < perPage) {
//                         setHasMore(false);
//                     }
//                 } else {
//                     console.log(resp.message);
//                 }
//                 setLoading(false); // Loading is complete
//             });
//         } else {
//             setLoading(false); // Loading is complete
//         }
//     }

//     const handleInfiniteScroll = async () => {
//         try {
//             const contentElement = containerRef.current;

//             if (
//                 contentElement &&
//                 contentElement.scrollTop + contentElement.clientHeight + 1 >=
//                 contentElement.scrollHeight
//             ) {
//                 setCurrentPage((prev) => prev + 1);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         if (!containerRef.current) return; // Ensure containerRef is available

//         const contentElement = containerRef.current;
//         contentElement.addEventListener("scroll", handleInfiniteScroll);

//         return () => {
//             contentElement.removeEventListener("scroll", handleInfiniteScroll);
//         };
//     }, [currentPage, containerRef]);

//     useEffect(() => {
//         if (!localStorage.getItem('user')) {
//             navigate('/');
//         }

//         if (auth) {
//             giftCardList(currentPage, searchValue);
//         }
//     }, [currentPage, searchValue])

//     return (
//         <>
//             <Giftcardmodal gitCardCurrency={cardId} isOpen={isOpen} open={open} />
//             <CryptoModal openCrypto={openCrypto} setOpenCrypto={setOpenCrypto} cryptoInfo={cryptoInfo} />
//             <div className="container-fluid position-relative d-flex p-0 gift-container overflow-hidden">
//                 <Header />

//                 <div className="content overflow-x-hidden !w-full bg-black" ref={containerRef}>
//                     <Subheader />

//                     {/* Crypto Details  */}
//                     {cryptoData?.length > 0 &&
                        // <div className='container-fluid box-container bg-zinc-900 !h-auto overflow-hidden'>
                        //     <div className='row'>
                        //         <div className='col-md-12 col-lg-12 col-xs-12 text-left text-white mb-5 gift-heading flex justify-between items-center'>
                        //             <div>
                        //                 <h5 className="mb-0 h3 ms-2">{(searchValue === "" && cryptoData?.length > 0) ? 'Crypto' : "Gift Card"}</h5>
                        //                 <p className="small ms-2 mb-3">What would you like to do today</p>
                        //             </div>

                        //             <div className="form-group w-[250px] relative" >
                        //                 <input type="text" className="p-[6px] border-[1px] border-[#dee2e6] rounded-md mb-[10px] w-100 text-white !text-sm focus:!outline-none" id="name" onChange={(e) => handleSearch(e)} value={searchValue} placeholder="Search by name" style={{ background: "#252731" }}
                        //                 />
                        //                 <div className=" hover:cursor-pointer rounded-full h-6 w-6 text-white flex justify-center items-center absolute !top-1 !right-0 pr-2"
                        //                     onClick={(e) => removeSearch(e)}
                        //                 ><MdClose size={20} /> </div>
                        //             </div>
                        //         </div>
//                                 <div className='col-md-12 col-xs-12 box-grid'>

//                                     {searchValue === "" && cryptoData?.length > 0 ?
//                                         (
//                                             cryptoData.map((item, index) => (
//                                                 <div className="box h-40" key={index}>
//                                                     <div className="box-header h-12 justify-content-center d-flex align-items-center bg-cyan-700">
//                                                         <img className='w-75 p-4' src={item.image} alt='img' />
//                                                     </div>
//                                                     <div className='d-flex flex-col justify-content-between align-items-center h-32'>
//                                                         <h5 className='text-white pt-4'>{item.name} </h5>
//                                                         <div className='bg-violet-950 w-40 !h-10 text-white rounded-full flex items-center justify-center' onClick={() => handleOpenCrypto(item)}>Sell </div>
//                                                     </div>
//                                                 </div>
//                                             )
//                                             )
//                                         )
//                                         : (
//                                             filteredData.length > 0 ?
//                                                 (filteredData.map((item, index) => (
//                                                     <div className="box h-40" onClick={() => setmodal(true)} key={index}>
//                                                         <div className="box-header h-12 justify-content-center d-flex align-items-center bg-cyan-700">
//                                                             <img className='w-75 p-4' src={item.image} alt='img' />
//                                                         </div>
//                                                         <div className='d-flex flex-col justify-content-between align-items-center h-32'>
//                                                             <h5 className='text-white pt-4'>{item.name} </h5>
//                                                             <div className='bg-violet-950 w-40 !h-10 text-white rounded-full flex items-center justify-center' onClick={() => handleOpen(item)}>Sell/Buy </div>

//                                                         </div>
//                                                     </div>
//                                                 )
//                                                     // data.map((item, index) => (
//                                                     //     <div className="box h-40" onClick={() => setmodal(true)} key={index}>
//                                                     //         <div className="box-header h-12 justify-content-center d-flex align-items-center bg-cyan-700">
//                                                     //             <img className='w-75 p-4' src={item.image} alt='img' />
//                                                     //         </div>
//                                                     //         <div className='d-flex flex-col justify-content-between align-items-center h-32'>
//                                                     //             <h5 className='text-white pt-4'>{item.name} </h5>
//                                                     //             <div className='bg-violet-950 w-40 !h-10 text-white rounded-full flex items-center justify-center' onClick={() => handleOpen(item)}>Sell/Buy </div>
//                                                     //         </div>
//                                                     //     </div>
//                                                     // )
//                                                 )) :
//                                                 (
//                                                     <p className='text-white flex justify-center items-center py-20'>
//                                                         No Data Found
//                                                     </p>
//                                                 )
//                                         )
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     }

//                     {/* Gift Card Detail  */}
//                     {searchValue === "" &&
//                         <div className='container-fluid box-container bg-zinc-900 !h-auto overflow-hidden'>
//                             <div className='row'>
//                                 <div className={`col-md-12 col-lg-12 col-xs-12 text-left text-white ${searchValue === "" && cryptoData?.length > 0 ? "my-5" : 'mb-10'} gift-heading  `}>

//                                     <h5 className="mb-0 h3 ms-2 pt-3">Gift Card</h5>
//                                     <p className="small ms-2 mb-3">What would you like to do today</p>

//                                 </div>

//                                 <div className='col-md-12 col-xs-12 box-grid'>
//                                     {
//                                         data.map((item, index) => (
//                                             <div className="box h-40" onClick={() => setmodal(true)} key={index}>
//                                                 <div className="box-header h-12 justify-content-center d-flex align-items-center bg-cyan-700">
//                                                     <img className='w-75 p-4' src={item.image} alt='img' />
//                                                 </div>
//                                                 <div className='d-flex flex-col justify-content-between align-items-center h-32'>
//                                                     <h5 className='text-white pt-4'>{item.name} </h5>
//                                                     <div className='bg-violet-950 w-40 !h-10 text-white rounded-full flex items-center justify-center' onClick={() => handleOpen(item)}>Sell/Buy </div>
//                                                 </div>
//                                             </div>
//                                         )
//                                         )}
//                                 </div>

//                             </div>
//                         </div>}
//                 </div>
//             </div>
//         </>

//     );
// }

// export default Giftcard;
