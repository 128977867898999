import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AfterLoginService from "../../Services/LoginSignService/BillPurchase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessModel from './successmodel';
import { useNavigate } from "react-router";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal image-modal">
      <div className="modal-content">
        {children}
      </div>
    </div>
  );
};
const auth = JSON.parse(localStorage.getItem("user"));

const Chechout = ({productInfo,unitPriceDisplay,pimg,pname,quantity,from,to,unitPrice,checkBoxChecked,unitPriceDisplayCurrency,virtualAccountInfo,setShowList,isOpen,goBack,minEnterValue,maxEnterValue}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const Subtotal = (unitPrice*quantity)?(unitPrice*quantity):0;
  const totalFee = (productInfo['senderFee']*quantity)?(productInfo['senderFee']*quantity):0;
  const totalPrice = (Subtotal+totalFee)?(Subtotal+totalFee):0;
  const [confirmModal,setConfirmModal] = useState(false)
  const [successMessage,setSuccessMessage]=useState("");
  const navigate = useNavigate();
  const openFirstModal = async () => {
      
      if(unitPriceDisplay==''){
        toast('Amount field is required');
        return false;
      }
      if(productInfo.denominationType == 'RANGE'){
        if((unitPriceDisplay < minEnterValue)){
          toast("Select an amount between "+unitPriceDisplayCurrency+" "+minEnterValue+" - "+unitPriceDisplayCurrency+" "+maxEnterValue+"");
          return false;
        }
        if(maxEnterValue < unitPriceDisplay){
          toast("Select an amount between "+unitPriceDisplayCurrency+" "+minEnterValue+" - "+unitPriceDisplayCurrency+" "+maxEnterValue+"");
          return false;
        }
      }
      const validateEmail = (email) => {
        var emailReg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return emailReg.test( email );
      }
      if(to==''){
        toast('Email field is required');
        return false;
      }
      if(to){
        if (!validateEmail(to)) {
          toast('Please enter valid email');
          return false;
        }
      }
      if(from==''){
        toast('Name field is required');
        return false;
      }
      if(quantity=='')
      {
        toast('Quantity field is required');
        return false;
      }
      setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const handleSuccess = ()=>{
    setIsFirstModalOpen(false);
    setShowList(false)
    isOpen(true)
    setConfirmModal(false)
    goBack();
  }

  const payNow = async () => {
    setLoader(true)
    const item ={productId:productInfo.productId,quantity:quantity,unitPrice:unitPriceDisplay,recipientName:from,recipientEmail:to,fxValue:unitPrice,unitFee:productInfo['senderFee']}
    const apicall = await AfterLoginService.orderGiftCard(auth,item);
    if (apicall.state === true) {
      apicall.result.json().then((resp) => {
        if (resp.status == 200) {
            setConfirmModal(true)
            setSuccessMessage(resp.data)
            setLoader(false)
            setIsFirstModalOpen(false);
        } else {
          if(resp.status == 400){
            if(resp.error){
              toast('Please provide all details');
            }
            else{
              toast(resp.message);
            }
            setLoader(false)
          }
          console.log(resp.message);
          setLoader(false)
        }
      });
    } else {
      apicall.result.json().then((resp) => {
     
        if (resp.status == 400) {
            toast(resp.data['message'])
            setLoader(false)
           
        } 
      });
    }
  };

  const virtualAccountScreen =()=>{
    navigate('/virtualaccount');
  }

  return (
    <div>
     
    <button className={`btn btn-primary buy_now mb-2 w-100 ${
                     checkBoxChecked ? "" : "disabled"
                   }`} onClick={openFirstModal}>Buy Now</button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className='modal-dialog'>
                <div className="modal-content">
                <div className="modal-header">
                    <h4>Checkout</h4>
                    <button type="button" className="close" onClick={closeFirstModal}>
                       <img src='img/close-icon.png'/>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='row'>
                      <div className="col-md-4">
                        <div className=''>
                          <img src={pimg}/>
                        </div>
                      </div>
                      <div className="col-md-8">
                          <div className=''>
                              <h4>{pname}</h4>
                          </div>
                          <div className=''>
                             <div className='row'>
                               <div className='col-md-12'>
                                  <table className='table confT'>
                                      <tbody>
                                        <tr>
                                          <td>Recipient</td>
                                          <td>{to?to:''}</td>
                                        </tr>
                                        <tr>
                                          <td>From</td>
                                          <td><span>{from?from:''}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Unit Price</td>
                                          <td><span>₦ {unitPrice} &nbsp; ({unitPriceDisplayCurrency+' '+unitPriceDisplay})</span></td>
                                        </tr>
                                        <tr>
                                          <td>Quantity</td>
                                          <td><span>{quantity?quantity:''}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Subtotal</td>
                                          <td> <span>₦{Subtotal}</span></td>
                                        </tr>
                                        <tr>
                                          <td>Total Fee</td>
                                          <td><span>₦{totalFee}</span></td>
                                        </tr>
                                      </tbody>
                                  </table>
                               </div>
                             </div>
                            
                          </div>
                      </div>
                      <div className='col-md-12'>
                        <hr/>
                      </div>
                      <div className='col-md-6 col-6'>
                        <strong>Total Price:&nbsp;  <span class="t-price">₦ {totalPrice}</span>
                          &nbsp;
                        </strong>
                      </div>
                      <div className='col-md-6 col-6 text-right'>
                        {
                          virtualAccountInfo.isAccountCreated == 1?(<>
                          {
                            virtualAccountInfo. walletBalance >= totalPrice?(<>
                            
                             <button
                            className={`btn btn-primary buy_now   ${
                              loading ? "disabled" : ""
                            }`}
                            onClick={payNow}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Loading...
                            </>
                          ) : (
                            "Pay now"
                          )}
                        </button>
                            </>):(<>
                              <button className='btn btn-primary' onClick={virtualAccountScreen}>Add Fund</button>
                            </>)

                          }
                         
                          </>):(<>
                            <button className='btn btn-primary' onClick={virtualAccountScreen}>Create Virtual Account</button>
                          </>)
                        }
                        
                      </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </Modal>
      )}
      <SuccessModel
            successMessage={successMessage}
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            handleSuccess ={handleSuccess}
           
          />
    </div>
  );
};

export default Chechout;
