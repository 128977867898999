import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "qrcode.react";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal qrcode-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Qrcodemodal = ({ recieveBtcAddress }) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  function copyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
      <Avatar className="bg-white bg-receive" alt="" onClick={openFirstModal}>
        <img src="img/receive1.svg" />
      </Avatar>
      <p className="text-white small " style={{ fontSize: "18px" }}>
        Receive
      </p>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content btc-qrc-modal">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Receive BTC
                    <span className="d-block" style={{ color: "#7D7DA1" }}>
                      {/* Lorem ipsum dolor sit amet consectetur. */}
                      Scan QR code to receive bitcoin
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close-btc-qrc"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <form _lpchecked="1">
                    <div className="form-group qr-code-box mb-3 flex justify-center items-center bg-white py-8">
                      <QRCode value={recieveBtcAddress} />;
                    </div>
                    <p className="btc-qrc-p-main">
                      Send only BTC to the deposit address
                    </p>
                    <div
                      class="copy-code cursor-pointer transform transition-transform active:scale-95 "
                      style={{ width: "" }}
                      onClick={() => copyTextToClipboard(recieveBtcAddress)}
                    >
                      <h5 className="">
                        <span className="btc-qrc">Tap to copy BTC Address</span>
                        <span
                          className="d-block btc-qrc-address"
                          style={{ color: "#B8BCC3", marginLeft: "-10px" }}
                        >
                          {recieveBtcAddress}
                        </span>
                      </h5>
                      <ContentCopyIcon className="copy-icon-qrc" />
                    </div>
                    {copied ? (
                      <div
                        className="fixed bottom-0 z-50 pointer-events-none"
                        style={{ background: "rgba(0, 0, 0, 0.6)" }}
                      >
                        <div className="shadow-md">
                          <p className="bg-green-200  rounded-lg   p-2 text-center">
                            BTC address is copied to clipboard
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
                <div className="modal-footer">
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <button
                      className="btn btn-update"
                      // onClick={() => copyTextToClipboard(recieveBtcAddress)}
                      onClick={closeFirstModal}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Qrcodemodal;
