import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';




const Modal = ({ onClose, children }) => {
  return (
    <div className="modal gift-confirm">
      <div className="modal-content">
        {children}
      </div>
    </div>
  );
};

const Requestaccount = () => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
        <button className='text-danger mb-3' onClick={openFirstModal}>
            <i class="bi bi-trash"></i>
            <span className='ms-4 small font-light'>Request Account Deletion</span>
        </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className='modal-dialog'>
                <div className="modal-content">
                <div className="modal-header d-flex justify-content-end align-items-end w-100">
                    <button type="button" className="close" onClick={closeFirstModal}>
                       <img src='img/close-icon.png'/>
                    </button>
                </div>
                <div className="modal-body text-white text-center d-flex justify-content-center align-items-center flex-col">
                    <Avatar className='bg-success mb-3'>
                       <i class="bi bi-check-lg text-white"></i>
                    </Avatar>
                    <p className='h4 text-white'>
                          Confirm Transaction
                    </p>
                    <p>
                      You are about to sell <span className='text-white'>Amazon Gift </span>
                        <span className='d-block'><span className='text-white'>Cards</span> with<span className='text-white'> N6900</span></span>
                      
                     </p>
                     
                </div>
                <div className="modal-footer d-flex"> 
                    <button className='btn btn-update'>Cancel</button>
                    <button className='btn btn-update'>Proceed</button> 
                </div>
                </div>
            </div>
            </div>
        </Modal>
      )}
    </div>
  );
};

export default Requestaccount;
