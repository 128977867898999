import React, { useEffect, useState } from "react";
import "./account.scss";
import { useNavigate } from "react-router";
import AfterLoginService from "../../Services/LoginSignService/AfterLoginService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal edit-name">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Editmodal = ({profileHandle}) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("user"));
  const [detail, setDetails] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoging] = useState(false);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  const getDetails = async () => {
    if (auth) {
      const apicall = await AfterLoginService.GetDetails(auth);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            setDetails(resp.data);
            setFirstName(resp.data.firstname);
            setLastName(resp.data.lastname);
            setPhone(resp.data.phone);
          } else {
            console.log(resp.message);
          }
        });
      } else {
      }
    }
  };

  const userDetailUpdate = async (id) => {
    const item = { id, firstname, lastname, phone };

    if (validateDetails()) {
      setLoging(true);
      const apicall = await AfterLoginService.UpdateUserDetails(auth, item);
      if (apicall.state === true) {
        apicall.result.json().then((resp) => {
          if (resp.status == 200) {
            toast(resp.message);
            setFirstName("");
            setLastName("");
            setPhone("");
            getDetails();
            setTimeout(() => {
              closeFirstModal();
              getDetails();
              setLoging(false);
              profileHandle();
            }, 2000);
          } else {
            setLoging(false);
            toast(resp.data.firstname[0]);
            toast(resp.data.phone[0]);
          }
        });
      } else {
      }
    }
  };

  const validateDetails = () => {
    var result = true;
    if (firstname === "" || firstname === null) {
      toast("The Firstname field is required");
      result = false;
    }
    if (phone === "" || phone === null) {
      toast("The Phone field is required");
      result = false;
    }
    return result;
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) {
      navigate("/");
    }
    getDetails();
  }, []);

  // mui css
  const textFieldStyles = {
    backgroundColor: "white",
    color: "black",
    width: "100%",
    height: "auto",
    borderRadius: "2px",
  };

  return (
    <div>
      <button className="btn btn-edit-name " onClick={openFirstModal}>
        Edit
      </button>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content  update-name-modal">
                <div className="modal-header updateProfileM">
                
                  <h5 className="modal-title profile-title">
                    Update Profile
                    <span className="d-block"></span>
                  </h5>
                  <div className="back-icon-updateprofile ">
                    <img src="/img/CaretLeft1.svg"  onClick={closeFirstModal} />
                  </div>
                  <div className="profileText">
                    <h4>Update Profile </h4>
                  </div>
                  <div className="profile d-none">
                    <img
                      src="img/profile.png"
                      className=" profile-icon"
                      alt=""
                    />
                    <p className="profile-p">
                      <Link to="" className="chanege-profile">
                        Change profile
                      </Link>{" "}
                      <br />
                      <Link to="" className="ml-5 pl-2 chanege-profile">
                        {" "}
                        picture
                      </Link>
                      <span className="d-block"></span>
                    </p>
                  </div>
                   <button
                    type="button "
                    className="email-update-close"
                    onClick={closeFirstModal}
                    >
                     
                    <img src="img/Close1.svg" alt='' />
                  </button> 
                </div>

                <div className="modal-body">
                  <form _lpchecked="1" className="profile-from">
                    <div className="form-group w-100">
                      <TextField
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                        defaultValue={detail.firstname}
                        id="name"
                        label={
                          <span style={{ color: "gray" }}>First Name</span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                        defaultValue={detail.lastname}
                        id="name"
                        label={<span style={{ color: "gray" }}>Last Name</span>}
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div className="form-group w-100 mt-2">
                      <TextField
                        type="tel"
                        defaultValue={detail.phone}
                        onChange={(e) => setPhone(e.target.value)}
                        name="phone number"
                        id="phone"
                        label={
                          <span style={{ color: "gray" }}>Phone Number</span>
                        }
                        variant="filled"
                        style={textFieldStyles}
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer ">
                  <button
                    className={`btn btn-update profile-btn ${
                      loading ? "disabled" : ""
                    }`}
                    onClick={() => userDetailUpdate(detail.id)}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm "
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </>
                    ) : (
                      "Update"
                    )}
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Editmodal;

// import React, { useEffect,useState } from 'react';
// import './account.scss';
// import { useNavigate } from 'react-router';
// import AfterLoginService from '../../Services/LoginSignService/AfterLoginService';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const Modal = ({ onClose, children }) => {
//   return (
//     <div className="modal edit-name">
//       <div className="modal-content">
//         {children}
//       </div>
//     </div>
//   );
// };

// const Editmodal = () => {
//   const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
//   const navigate = useNavigate();
//   const auth = JSON.parse(localStorage.getItem('user'));
//   const [detail,setDetails] = useState("");
//   const [firstname,setFirstName] = useState("");
//   const [lastname,setLastName] = useState("");
//   const [phone,setPhone] = useState("");
//   const [loading,setLoging] = useState(false);

//   const openFirstModal = () => {
//     setIsFirstModalOpen(true);
//   };

//   const closeFirstModal = () => {
//     setIsFirstModalOpen(false);
//   };

//   const getDetails = async () =>{
//     if(auth)
//     {
//         const apicall = await AfterLoginService.GetDetails(auth);
//         if(apicall.state === true)
//         {
//             apicall.result.json().then((resp)=>{
//                 if(resp.status == 200)
//                 {
//                     setDetails(resp.data);
//                     setFirstName(resp.data.firstname);
//                     setLastName(resp.data.lastname);
//                     setPhone(resp.data.phone);
//                 }
//                 else
//                 {
//                     console.log(resp.message);
//                 }
//             })
//         }
//         else{}
//     }
//   }

//   const userDetailUpdate = async (id)=>{

//      const item = {id,firstname,lastname,phone};

//      if(validateDetails())
//      {
//         setLoging(true);
//         const apicall = await AfterLoginService.UpdateUserDetails(auth,item);
//         if(apicall.state === true)
//         {
//           apicall.result.json().then((resp)=>{
//             if(resp.status == 200){
//               toast(resp.message);
//               setFirstName("");
//               setLastName("");
//               setPhone("");
//               getDetails();
//               setTimeout(() => {
//                 closeFirstModal();
//                 getDetails();
//                 setLoging(false)
//               }, 2000);
//             }
//             else
//             {
//               setLoging(false)
//               toast(resp.data.firstname[0]);
//               toast(resp.data.phone[0]);
//             }
//           })
//         }
//         else{

//         }
//      }
//   }

//   const validateDetails = () =>{

//     var result = true;
//     if(firstname === '' || firstname === null)
//     {
//         toast("The Firstname field is required");
//         result = false;
//     }
//     if(phone === '' || phone === null)
//     {
//         toast("The Phone field is required");
//         result = false;
//     }
//      return  result;

//   }

//   useEffect(()=>{
//     if(!localStorage.getItem('user'))
//     {
//         navigate('/');
//     }
//     getDetails();
//   },[])

//   return (
//     <div>
//       <button className='btn btn-edit' onClick={openFirstModal}>Edit</button>
//       {isFirstModalOpen && (
//         <Modal>
//           <div className="modal">
//             <div className='modal-dialog'>
//                 <div className="modal-content">
//                 <div className="modal-header">
// <h5 className="modal-title">
//     Update Profile
//     <span className='d-block'>
//     </span>
// </h5>
//                     <button type="button" className="close" onClick={closeFirstModal}>
//                        <img src='img/close-icon.png'/>
//                     </button>
//                 </div>
//                 <div className="modal-body">
//                     <form _lpchecked="1">
//                     <div className="form-group w-75">
//                         <input type="text"
//                         onChange={(e)=>setFirstName(e.target.value)}
//                         defaultValue={detail.firstname}  className="form-control mb-3" id="name" placeholder="First Name"/>
//                     </div>
//                     <div className="form-group w-75">
//                         <input type="text"
//                          onChange={(e)=>setLastName(e.target.value)}
//                         defaultValue={detail.lastname} className="form-control mb-3" id="name" placeholder="Last Name"/>
//                     </div>
//                     <div className="form-group w-75">
//                         <input type="tel" defaultValue={detail.phone} onChange={(e)=>setPhone(e.target.value)} name='phone number' className="form-control mb-3" id="phone" placeholder="Phone Number"/>
//                     </div>

//                     </form>
//                 </div>
//                 <div className="modal-footer">
//                     <button className={`btn btn-update ${loading ? "disabled" : ""}`} onClick={()=>userDetailUpdate(detail.id)}>
//                       {
//                         loading ? (
//                           <>
//                            <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>
//                             Loading...
//                           </>
//                         ):(
//                           "Update"
//                         )
//                       }

//                       </button>
//                     <ToastContainer/>
//                 </div>
//                 </div>
//             </div>
//             </div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Editmodal;
