import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import AfterLoginService from "../../../Services/LoginSignService/BillPurchase";
import SuccessModel from "./successModel";
import { useNavigate } from "react-router";

const Confirmation = ({openConfirmation,setOpenConfirmation,setShowService,handlePrevious,              subscriberAccountNumber,
    amount,virtualAccountInfo,
    amountId,
    transactionFee,
    billerName,
    billerType,
    fxRate,
    denominationType,
    localTransactionCurrencyCode,
    internationalTransactionCurrencyCode,
    localTFee,
    internationalTFee,
    minITA,amountInternational,billerId,
    maxITA,confirmClose}) => {
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)
    const [isOpenSuccess,setIsOpenSuccess] = useState(false);
    const [message,setMessage] = useState(false)
    const navigate = useNavigate();

    if(localTransactionCurrencyCode == internationalTransactionCurrencyCode){
        var transactionFees = localTFee; 
       
        if(denominationType == 'RANGE'){
            var totalPrice = (Number(transactionFees)+Number(amount));
            var amount1 = amount;
        }else{
            var totalPrice = (Number(transactionFees)+Number(amountInternational));
            var amount1 = amountInternational;
        }

    }else{
        var transactionFees = internationalTFee; 
        if(denominationType == 'RANGE'){
            var totalPrice = (Number(transactionFees)+Number(amount));
            var amount1 = amount;

        }else{
            var totalPrice = (Number(transactionFees)+Number(amountInternational));
            var amount1 = amountInternational;
        }
    }

    if(amountInternational == ""){
        var internationalAmount = amount*fxRate;
    }else{
        var internationalAmount = amountInternational;
    }

    const goBack = () => {
        setOpenConfirmation(false)
        setShowService(true)
    };

    const closeFirstModal =()=>{
        setOpenConfirmation(false)
        confirmClose();
    }

    const Next =  ()=>{
        handlePrevious()
    }

    const confirm = async ()=>{
        setLoading(true)
        let param = {billerId:billerId,amount:amount1,amountId:amountId?amountId:1,subscriberAccountNumber:subscriberAccountNumber,transactionFee:transactionFees,billerName:billerName,billerType:billerType}
        console.log(param)
       
        const apicall = await AfterLoginService.createUtilityOrder(auth,param);
        if (apicall.state === true) {
          apicall.result.json().then((resp) => {
            if (resp.status == 200) {
                console.log(resp.data)
                setMessage(resp.data)
                setIsOpenSuccess(true)
                setLoading(false)
            } else {
              console.log(resp.message);
              setLoading(false)
            }
          });
        } else {
            setLoading(false)
        }
    }

    const handleSuccess = ()=>{
        setOpenConfirmation(false);
        confirmClose();
    }
    
    const virtualAccountScreen =()=>{
        navigate('/virtualaccount');
    }
    
  return (
            <div>
                    <div className="col-md-12 mt-4" onClick={()=>Next()}>
                        <button className="buy_now form-control">Next</button>
                    </div>
                    {openConfirmation &&(
                        <>
                            <div className="modal gift-success !z-[1027]">
                                <div className="modal-content">
                                <div className="modal">
                                    <div className="modal-dialog">
                                    <div className="modal-content">
                                        
                                        <div className="modal-header ">
                                            <h4>Confirmation</h4>
                                            <button type="button" className="close" onClick={closeFirstModal}>
                                            <img src='img/close-icon.png'/>
                                            </button>
                                        </div>
                                        <div className="modal-body text-white text-center ">
                                            <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <table className="table confT">
                                                        <tbody>
                                                        <tr>
                                                            <td>Biller Name</td>
                                                            <td className="tright">{billerName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bill Type</td>
                                                            <td className="tright">{billerType}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Subscriber Account No.</td>
                                                            <td className="tright">
                                                            {subscriberAccountNumber}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bill Amount</td>
                                                            <td className="tright">
                                                                {
                                                                   denominationType == 'RANGE'?(<>
                                                                    {internationalTransactionCurrencyCode} {amount1} ({localTransactionCurrencyCode}  {(internationalAmount).toFixed(2)})
                                                                   </>):(<>
                                                                    {internationalTransactionCurrencyCode}  {(amount1).toFixed(2)}
                                                                    &nbsp;
                                                                     ({localTransactionCurrencyCode} {amount})
                                                                   </>) 
                                                                }
                                                          
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Fee</td>
                                                            <td className="tright">
                                                            {internationalTransactionCurrencyCode} {transactionFees.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Payable Amount</td>
                                                            <td className="tright">{internationalTransactionCurrencyCode} {totalPrice.toFixed(2)}</td>
                                                        </tr>
                                                        
                                                        </tbody>
                                                    </table>
                                                    {
                                              virtualAccountInfo.isAccountCreated == 1?(<>
                                              
                                              {
                                                virtualAccountInfo. walletBalance >= totalPrice?(<>
                                               
                                                 <button
                                                    className={`buy_now form-control`}
                                                    onClick={confirm}
                                                    disabled={`${
                                                      loading ? "disabled" : ""
                                                    }`}
                                                >
                                                  {loading ? (
                                                    <>
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>{" "}
                                                      Loading...
                                                    </>
                                                  ) : (
                                                    "Confirm"
                                                  )}
                                                </button>

                                                </>):(<>
                                                <button className="buy_now form-control addfund" onClick={virtualAccountScreen}>Add Fund</button>
                                                </>)
                                              }
                                              </>):(<>
                                               <button className="buy_now form-control cvr" onClick={virtualAccountScreen}>Create Virtual Account</button>
                                              </>)
                                             }
                                                    

                                                </div>
                                            </div>
                                            
                                            </div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </>
                    )}
                    <SuccessModel isOpenSuccess={isOpenSuccess} setIsOpenSuccess={setIsOpenSuccess} handleSuccess={handleSuccess} message={message}/>
            </div>
  );
};
 
export default Confirmation;