import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal qrcode-modal">
      <div className="modal-content">{children}</div>
    </div>
  );
};

const Receivemodal = ({ tronAddress }) => {
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  function copyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
  };

  return (
    <div>
      <Avatar className="bg-white bg-receive" alt="" onClick={openFirstModal}>
        <img src="img/receive1.svg" />
      </Avatar>
      <p className="text-white small text-center" style={{ fontSize: "18px" }}>
        Receive
      </p>
      {isFirstModalOpen && (
        <Modal>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content receive-modal">
                <div className="modal-header">
                  <h5 className="modal-title">
                    TRC20(USDT & TRON)
                    <span
                      className="d-block"
                      style={{
                        color: "#7D7DA1",
                        fontSize: "12px",
                      }}
                    >
                      Lorem ipsum dolor sit amet consectetur.
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close-trc-qrc"
                    onClick={closeFirstModal}
                  >
                    <img src="img/Close1.svg" />
                  </button>
                </div>
                <div className="modal-body">
                  <form _lpchecked="1">
                    <div className="form-group w-75 mb-3">
                      {/* <img src='img/qrcode.png'/> */}
                      <QRCode
                        size={150}
                        style={{
                          height: "250px",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={tronAddress}
                        viewBox={`0 0 150 150`}
                      />
                    </div>
                    <div
                      class="copy-code w-100"
                      onClick={() => copyTextToClipboard(tronAddress)}
                    >
                      <h5>

                        <span className="trc-qrc">Tap to copy USDT(TRC20) Address</span>
                        <span
                          className="d-block trc-qrc-address"
                         
                        >
                          {tronAddress}
                        </span>
                      </h5>
                      <ContentCopyIcon className="copy-icon-receivemodal" />
                    </div>
                    {copied ? (
                      <div
                        className="fixed bottom-0 z-50 pointer-events-none"
                        style={{ background: "rgba(0, 0, 0, 0.6)" }}
                      >
                        <div className="shadow-md">
                          <p className="bg-green-200  rounded-lg   p-2 text-center">
                            TRC20(USDT & TRON) address is copied to clipboard
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
                <div className="modal-footer ">
                  <button className="btn btn-update w-100" onClick={closeFirstModal}>Done</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Receivemodal;
